import { IconAntennaBars5, IconPhotoVideo, IconTrendingUp } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import NavBarButton from "campaigns/navbar/v2/buttons/components/NavBarButton";
import { useMatch, useNavigate } from "react-router-dom";
import { CAMPAIGN_PATH, CAMPAIGN_PORTAL } from "campaigns/PathConstants";

const ResultsNavBarButton = ({ showMinimizedVersion }: { showMinimizedVersion: boolean }) => {
  const navigate = useNavigate();

  const isMetricsSelected = useMatch(`${CAMPAIGN_PORTAL}/metrics`) != null;
  const specificMetricsMatch = useMatch(`${CAMPAIGN_PORTAL}/${CAMPAIGN_PATH}`);
  const isSpecificMetricsSelected =
    specificMetricsMatch != null &&
    !Number.isNaN(parseInt(specificMetricsMatch.params.campaignId, 10));
  const isLiveContentSelected = useMatch(`${CAMPAIGN_PORTAL}/live`) != null;
  const isSpecificLiveContentSelected =
    useMatch(`${CAMPAIGN_PORTAL}/${CAMPAIGN_PATH}/live`) != null;
  const isChildrenRouteMatched =
    isMetricsSelected ||
    isSpecificMetricsSelected ||
    isLiveContentSelected ||
    isSpecificLiveContentSelected;
  const [isExpanded, setIsExpanded] = useState<boolean>(isChildrenRouteMatched);

  useEffect(() => {
    // If I’m in the collapsed nav menu state and I navigate to a subpage, and then I expand the menu,
    // it’d be great for the page that I’m currently on to be expanded
    if (!showMinimizedVersion && isChildrenRouteMatched) {
      setIsExpanded(true);
    }
  }, [showMinimizedVersion]);

  const childButtons = (
    <>
      <NavBarButton
        isSelected={isMetricsSelected || isSpecificMetricsSelected}
        LeftIconName={IconAntennaBars5}
        onClick={() => {
          navigate(`${CAMPAIGN_PORTAL}/metrics`);
        }}
        title="Metrics"
        variant={showMinimizedVersion ? "secondaryHover" : "secondary"}
      />
      <NavBarButton
        isSelected={isLiveContentSelected || isSpecificLiveContentSelected}
        LeftIconName={IconPhotoVideo}
        onClick={() => {
          navigate(`${CAMPAIGN_PORTAL}/live`);
        }}
        title="Live Content"
        variant={showMinimizedVersion ? "secondaryHover" : "secondary"}
      />
    </>
  );

  return (
    <>
      <NavBarButton
        hoverChildren={childButtons}
        isSelected={showMinimizedVersion && isChildrenRouteMatched}
        LeftIconName={IconTrendingUp}
        onClick={() => {
          if (!showMinimizedVersion) {
            setIsExpanded(!isExpanded);
          }
        }}
        showExpansionToggle
        showMinimizedVersion={showMinimizedVersion}
        title="Results"
      />
      {isExpanded && !showMinimizedVersion ? childButtons : null}
    </>
  );
};

export default ResultsNavBarButton;
