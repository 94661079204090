import React, { useEffect, useState } from "react";
import { Button, Flex, Text, Heading, Box } from "gestalt";
import "gestalt/dist/gestalt.css";
import { User as FirebaseUser } from "firebase/auth";
import { Link } from "react-router-dom";

import { handleResult } from "utils/ApiUtils";
import {
  MANUAL_ADD_TO_CREATOR_SET_URL,
  InputSeedsTextField,
  BrandCreatorSetSelector,
  BrandRecords,
  fetchAdminBrandData,
} from "admin/AdminUtils";
import Spacer from "components/Spacer";
import { getOpsUser } from "ops_team/OpsLayout";

const AddCreatorToCreatorSet = async (
  user: FirebaseUser,
  leadNames: string[],
  brandId: number,
  creatorSetId: number,
  platform: string,
) => {
  const firebaseToken = await user.getIdToken();
  const request = new Request(`${MANUAL_ADD_TO_CREATOR_SET_URL}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
    body: JSON.stringify({
      leadNames,
      brandId,
      creatorSetId,
      platform,
    }),
  });
  return handleResult(request);
};

const ManualUploadToCreatorSetForm = ({
  user,
  brandData,
}: {
  user: FirebaseUser;
  brandData: BrandRecords;
}) => {
  const [seeds, setSeeds] = useState([]);
  const [brandId, setBrandId] = useState(0);
  const [creatorSetId, setCreatorSetId] = useState(0);
  const [platform, setPlatform] = useState("youtube");
  const [message, setMessage] = useState("");

  return (
    <>
      <Heading size="300">Manually upload creators</Heading>
      <Box alignItems="end" direction="row" display="flex">
        <Box width={400}>
          <InputSeedsTextField
            seeds={seeds}
            setSeeds={setSeeds}
            label="Creators"
            formId="creator-seeds"
          />
        </Box>
        <Spacer width={16} />
        <BrandCreatorSetSelector
          brandData={brandData}
          platforms={["select platform", "youtube", "tiktok", "instagram"]}
          brandId={brandId}
          setBrandId={setBrandId}
          setCreatorSetId={setCreatorSetId}
          setPlatform={setPlatform}
        />
        <Spacer width={16} />
        <Box paddingY={1}>
          <Button
            text="Add creators"
            color="blue"
            onClick={() => {
              setMessage("Starting to add users - please limit to 5 at a time to avoid timeouts");
              AddCreatorToCreatorSet(user, seeds, brandId, creatorSetId, platform)
                .then((_) => {
                  setMessage("Success: Finish adding users");
                  setTimeout(() => {
                    setMessage("");
                  }, 2000);
                })
                .catch((error) => {
                  const parsed = JSON.parse(error.message);
                  setMessage(parsed.result.error);
                });
            }}
          />
        </Box>
        <Spacer width={16} />
        <Box width={200}>
          <Text>{message}</Text>
        </Box>
      </Box>
    </>
  );
};

const ManualUploadCreators = ({
  user,
  brandData,
}: {
  user: FirebaseUser;
  brandData: BrandRecords;
}) => (
  <Flex direction="column" gap={4}>
    <Heading size="400">Manual upload commands</Heading>
    <ManualUploadToCreatorSetForm user={user} brandData={brandData} />
  </Flex>
);

const OpsCampaignDataset = () => {
  const user: FirebaseUser = getOpsUser();
  // make a get request to get base data for this page
  const [brandData, setBrandData] = useState({});

  useEffect(() => {
    const abortController = new AbortController();

    fetchAdminBrandData(abortController).then((data) => {
      setBrandData(data?.brands);
    });

    return () => {
      abortController.abort();
    };
  }, []);

  // Add youtube datasets + instagram datasets here
  return (
    <Flex direction="column" gap={8}>
      <Text weight="bold">Operations home</Text>
      <Link to="/labeling-search">Labeling search</Link>
      <Link to="/labeling-search-summary">Search summary</Link>
      <Link to="/labeling-search-recs">Search recommendations</Link>
      <ManualUploadCreators user={user} brandData={brandData} />
    </Flex>
  );
};

export default OpsCampaignDataset;
