import React, { useEffect, useState } from "react";
import { IconListDetails, IconTool, IconUsers } from "@tabler/icons-react";
import NavBarButton from "campaigns/navbar/v2/buttons/components/NavBarButton";
import { useMatch, useNavigate } from "react-router-dom";
import { CAMPAIGN_PORTAL, CREATOR_SET_PATH } from "campaigns/PathConstants";

const MyCreatorsNavBarButton = ({ showMinimizedVersion }: { showMinimizedVersion: boolean }) => {
  // navigator
  const navigate = useNavigate();

  const isViewAllSelected = useMatch(`${CAMPAIGN_PORTAL}/creator_sets/all`) != null;
  const isCreatorSetInViewAllSelected =
    useMatch(`${CAMPAIGN_PORTAL}/creator_sets/${CREATOR_SET_PATH}/view`) != null;
  const isExternalPartnersInViewAllSelected =
    useMatch(`${CAMPAIGN_PORTAL}/creator_sets/existing_partners`) != null;
  const isManageSelected = useMatch(`${CAMPAIGN_PORTAL}/creator_sets/manage`) != null;
  const isChildrenRouteMatched =
    isViewAllSelected ||
    isManageSelected ||
    isCreatorSetInViewAllSelected ||
    isExternalPartnersInViewAllSelected;

  const [isExpanded, setIsExpanded] = useState<boolean>(isChildrenRouteMatched);

  useEffect(() => {
    // If I’m in the collapsed nav menu state and I navigate to a subpage, and then I expand the menu,
    // it’d be great for the page that I’m currently on to be expanded
    if (!showMinimizedVersion && isChildrenRouteMatched) {
      setIsExpanded(true);
    }
  }, [showMinimizedVersion]);

  const childButtons = (
    <>
      <NavBarButton
        isSelected={
          isViewAllSelected || isCreatorSetInViewAllSelected || isExternalPartnersInViewAllSelected
        }
        LeftIconName={IconListDetails}
        onClick={() => {
          navigate(`${CAMPAIGN_PORTAL}/creator_sets/all`);
        }}
        title="View All"
        variant={showMinimizedVersion ? "secondaryHover" : "secondary"}
      />
      <NavBarButton
        isSelected={isManageSelected}
        LeftIconName={IconTool}
        onClick={() => {
          navigate(`${CAMPAIGN_PORTAL}/creator_sets/manage`);
        }}
        title="Manage Creator Sets"
        variant={showMinimizedVersion ? "secondaryHover" : "secondary"}
      />
    </>
  );

  return (
    <>
      <NavBarButton
        hoverChildren={childButtons}
        isExpanded={isExpanded}
        isSelected={showMinimizedVersion && isChildrenRouteMatched}
        LeftIconName={IconUsers}
        onClick={() => {
          if (!showMinimizedVersion) {
            setIsExpanded(!isExpanded);
          }
        }}
        showExpansionToggle
        showMinimizedVersion={showMinimizedVersion}
        title="Creators"
      />
      {isExpanded && !showMinimizedVersion ? childButtons : null}
    </>
  );
};

export default MyCreatorsNavBarButton;
