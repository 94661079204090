import { Button, Stack, TextInput } from "@mantine/core";
import { isEmail, useForm } from "@mantine/form";
import CustomTagsInput from "admin/CustomTagsInput";
import CardBase from "campaigns/create/common/CardBase";
import { Brand } from "models/Brand";
import React, { useEffect } from "react";
import { useAppDispatch } from "reduxStore/hooks";
import { updateMyBrand } from "reduxStore/meSlice";

const BrandContact = ({ brand }: { brand: Brand }) => {
  const dispatch = useAppDispatch();
  const form = useForm({
    mode: "uncontrolled",
    initialValues: {
      mainContact: brand.contact_email,
      billingEmail: brand.billing_email,
      memberEmails: brand.member_emails ?? [],
    },

    validate: {
      mainContact: isEmail("Please enter a valid email address"),
      billingEmail: isEmail("Please enter a valid email address"),
      memberEmails: (value, values) => {
        const validEmails = value.every((email) => {
          const emailRegex = /^[a-zA-Z0-9._%+-]+@(?<domain>[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
          const emailMatch = emailRegex.exec(email);

          // first check if email is in email form
          if (emailMatch === null) {
            return false;
          }
          return true;
        });
        return validEmails ? null : "Please verify every email is valid.";
      },
    },
  });

  useEffect(() => {
    form.setInitialValues({
      mainContact: brand.contact_email,
      billingEmail: brand.billing_email,
      memberEmails: brand.member_emails ?? [],
    });
    form.resetDirty();
  }, [brand]);

  return (
    <CardBase
      isActive
      nodeShownWhenActive={
        <form
          onSubmit={form.onSubmit((values) => {
            dispatch(
              updateMyBrand({
                updatedFields: {
                  ...(form.isDirty("mainContact") && { contact_email: values.mainContact }),
                  ...(form.isDirty("billingEmail") && { billing_email: values.billingEmail }),
                  ...(form.isDirty("memberEmails") && { member_emails: values.memberEmails }),
                },
              }),
            );
          })}>
          <Stack px={0} style={{ "--stack-gap": "24px" }}>
            <TextInput
              withAsterisk
              label="Main Contact"
              description="The main point of contact for the brand. This is the user who will be responsible for executing the campaign and will get notifications related to campaign progress and todo items."
              placeholder="Email address"
              key={form.key("mainContact")}
              {...form.getInputProps("mainContact")}
            />
            <TextInput
              withAsterisk
              label="Billing Email"
              description="This is the email address invoices will be sent to."
              placeholder="Email address"
              key={form.key("billingEmail")}
              {...form.getInputProps("billingEmail")}
            />
            <CustomTagsInput
              label="Other Member Emails"
              description="The email addresses of other 1stCollab accounts for whom you want to provide access to your brand dashboard."
              placeholder="leon@1stcollab.com"
              splitChars={[",", " "]}
              key={form.key("memberEmails")}
              {...form.getInputProps("memberEmails")}
            />
            <Button variant="filled" type="submit">
              Save
            </Button>
          </Stack>
        </form>
      }
      title="Contact Information"
      subtitle=""
    />
  );
};

export default BrandContact;
