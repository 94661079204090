import React, { useEffect, useState } from "react";

import { Button, Center, Container, Divider, Loader, Stack } from "@mantine/core";

import { useAppDispatch } from "reduxStore/hooks";
import { fetchTaskCount } from "reduxStore/tasksSlice";

import { User } from "models/User";

import {
  getCodeAndLinkTaskDetails,
  getContentReviewTaskDetails,
  getContractOfferReviewTaskDetails,
  getContractReviewTaskDetails,
  getLiveVerificationTaskDetails,
  getProductAccessTaskDetails,
  getUnreadMessageTaskDetails,
} from "components/contracts/tasks/api/Api";

import { deserializeTask, Task } from "components/contracts/tasks/models/Task";
import { getTaskTypeLabel } from "components/contracts/tasks/common/Utils";

import { TaskCountType, TaskStage, TaskStatus } from "components/contracts/tasks/models/Common";
import ContractCard from "components/contracts/tasks/cards/ContractCard";
import ContractOfferReviewCard from "components/contracts/tasks/cards/ContractOfferReviewCard";
import ContractReviewCard from "components/contracts/tasks/cards/ContractReviewCard";
import { IconRefresh } from "@tabler/icons-react";

/**
 * Unify a number of task responses into a single list, like what is required for ALL tasks.
 * @param responses A list of task responses from the backend.
 * @returns A response that looks like one returned from the backend.
 */
const unifyTasksList = (responses: any[]) => {
  // Combine pending tasks from all responses
  const allPendingTasks = responses.reduce((acc: any[], response) => {
    if (response.data.pendingTasks) {
      // Handle content review response format
      return [...acc, ...response.data.pendingTasks];
    }
    return [...acc, ...response.data];
  }, []);

  const allCompletedTasks = responses.reduce((acc: any[], response) => {
    if (response.data.completedTasks) {
      return [...acc, ...response.data.completedTasks];
    }
    return acc;
  }, []);

  // Sort tasks with deadlines first, then by date created
  const sortedPendingTasks = allPendingTasks.sort((a: any, b: any) => {
    // If both have deadlines, compare deadlines
    if (a.deadline && b.deadline) {
      return new Date(a.deadline).getTime() - new Date(b.deadline).getTime();
    }

    // Tasks with deadlines come before tasks without deadlines
    if (a.deadline && !b.deadline) {
      return -1;
    }
    if (!a.deadline && b.deadline) {
      return 1;
    }

    // If neither have deadlines, sort by date created
    return new Date(a.date_created).getTime() - new Date(b.date_created).getTime();
  });

  const sortedCompletedTasks = allCompletedTasks.sort((a: any, b: any) => {
    return new Date(a.date_created).getTime() - new Date(b.date_created).getTime();
  });

  return {
    success: true,
    data: {
      pendingTasks: sortedPendingTasks,
      completedTasks: sortedCompletedTasks,
    },
  };
};

export default function BaseTaskFeed({
  user,
  testMode,
  isActive,
  selectedCampaignId,
  taskType,
  numTasks,
  numMessagingTasks,
  setNumTasks,
}: {
  user: User;
  testMode: boolean;
  isActive: boolean;
  selectedCampaignId: string;
  taskType: TaskStage;
  numTasks: Record<TaskCountType, number>;
  numMessagingTasks: number;
  setNumTasks: (taskType: TaskStage, pendingCount: number, completedCount: number) => void;
}) {
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const [pendingTasksData, setPendingTasksData] = useState<Task[]>([]);
  const [completedTasksData, setCompletedTasksData] = useState<Task[]>([]);

  const [numNewTasks, setNumNewTasks] = useState(0);

  // Maintain a list of completed task IDs locally in order to update related tasks
  // once they have been completed.
  const [completedTaskIds, setCompletedTaskIds] = useState<Set<string>>(new Set());

  const decrementUnreadMessageCount = () => {
    setNumTasks(TaskStage.MESSAGES, -1, 0);
  };

  let fetchTasks:
    | ((params: { campaignHashId?: string; abortController: AbortController }) => Promise<any>)
    | null = null;
  const responseHandler = (response: any) => {
    if (!response) {
      return;
    }

    if (response.success) {
      const { pendingTasks, completedTasks } = response.data;
      setPendingTasksData(pendingTasks.map(deserializeTask));
      setCompletedTasksData(completedTasks.map(deserializeTask));
      setNumNewTasks(0);
      setLoading(false);
    }
  };

  if (taskType === TaskStage.ALL) {
    fetchTasks = async ({ campaignHashId, abortController }) => {
      const fetchPromises = [
        getContractReviewTaskDetails({ campaignHashId, abortController }).then((response) => {
          if (!response) return { success: false, data: { pendingTasks: [], completedTasks: [] } };
          const { pendingTasks } = response.data;
          return {
            ...response,
            data: {
              pendingTasks: pendingTasks.map((task: any) => ({
                ...task,
                task_type: TaskStage.CONTRACT_REVIEW,
              })),
              completedTasks: [],
            },
          };
        }),
        getContractOfferReviewTaskDetails({ campaignHashId, abortController }).then((response) => {
          if (!response) return { success: false, data: { pendingTasks: [], completedTasks: [] } };
          const { pendingTasks } = response.data;
          return {
            ...response,
            data: {
              pendingTasks: pendingTasks.map((task: any) => ({
                ...task,
                task_type: TaskStage.CONTRACT_OFFER_REVIEW,
              })),
              completedTasks: [],
            },
          };
        }),
        getProductAccessTaskDetails({ campaignHashId, abortController }).then((response) => {
          if (!response) return { success: false, data: { pendingTasks: [], completedTasks: [] } };
          const { pendingTasks } = response.data;
          return {
            ...response,
            data: {
              pendingTasks: pendingTasks.map((task: any) => ({
                ...task,
                task_type: TaskStage.PRODUCT_ACCESS,
              })),
              completedTasks: [],
            },
          };
        }),
        getCodeAndLinkTaskDetails({ campaignHashId, abortController }).then((response) => {
          if (!response) return { success: false, data: { pendingTasks: [], completedTasks: [] } };
          const { pendingTasks } = response.data;
          return {
            ...response,
            data: {
              pendingTasks: pendingTasks.map((task: any) => ({
                ...task,
                task_type: TaskStage.CODES_LINKS,
              })),
              completedTasks: [],
            },
          };
        }),
        getContentReviewTaskDetails({ campaignHashId, abortController }).then((response) => {
          if (!response) return { success: false, data: { pendingTasks: [], completedTasks: [] } };
          const { pendingTasks, completedTasks } = response.data;
          return {
            ...response,
            data: {
              pendingTasks: pendingTasks.map((task: any) => ({
                ...task,
                task_type: TaskStage.CONTENT_PRODUCTION,
              })),
              completedTasks: completedTasks.map((task: any) => ({
                ...task,
                task_type: TaskStage.CONTENT_PRODUCTION,
              })),
            },
          };
        }),
        getLiveVerificationTaskDetails({ campaignHashId, abortController }).then((response) => {
          if (!response) return { success: false, data: { pendingTasks: [], completedTasks: [] } };
          const { pendingTasks } = response.data;
          return {
            ...response,
            data: {
              pendingTasks: pendingTasks.map((task: any) => ({
                ...task,
                task_type: TaskStage.LIVE_VERIFICATION,
              })),
              completedTasks: [],
            },
          };
        }),
      ];
      const responses = await Promise.all(fetchPromises);
      // Check if any requests failed
      if (responses.some((response) => !response.success)) {
        return {
          success: false,
          data: {
            pendingTasks: [],
            completedTasks: [],
          },
        };
      }
      return unifyTasksList(responses);
    };
  } else if (taskType === TaskStage.UNIFIED_CONTRACT_REVIEW) {
    // Similar to all tasks feed.
    fetchTasks = async ({ campaignHashId, abortController }) => {
      const fetchPromises = [
        getContractReviewTaskDetails({ campaignHashId, abortController }).then((response) => {
          if (!response) return { success: false, data: { pendingTasks: [], completedTasks: [] } };
          const { pendingTasks } = response.data;
          return {
            ...response,
            data: {
              pendingTasks: pendingTasks.map((task: any) => ({
                ...task,
                task_type: TaskStage.CONTRACT_REVIEW,
              })),
              completedTasks: [],
            },
          };
        }),
        getContractOfferReviewTaskDetails({ campaignHashId, abortController }).then((response) => {
          if (!response) return { success: false, data: { pendingTasks: [], completedTasks: [] } };
          const { pendingTasks } = response.data;
          return {
            ...response,
            data: {
              pendingTasks: pendingTasks.map((task: any) => ({
                ...task,
                task_type: TaskStage.CONTRACT_OFFER_REVIEW,
              })),
              completedTasks: [],
            },
          };
        }),
      ];

      const responses = await Promise.all(fetchPromises);
      // Check if any requests failed
      if (responses.some((response) => !response.success)) {
        return {
          success: false,
          data: {
            pendingTasks: [],
            completedTasks: [],
          },
        };
      }
      return unifyTasksList(responses);
    };
  } else if (taskType === TaskStage.CONTRACT_REVIEW) {
    // Included for completeness, should be part of the unified contract review
    fetchTasks = getContractReviewTaskDetails;
  } else if (taskType === TaskStage.CONTRACT_OFFER_REVIEW) {
    // Included for completeness, should be part of the unified contract review
    fetchTasks = getContractOfferReviewTaskDetails;
  } else if (taskType === TaskStage.PRODUCT_ACCESS) {
    fetchTasks = getProductAccessTaskDetails;
  } else if (taskType === TaskStage.CODES_LINKS) {
    fetchTasks = getCodeAndLinkTaskDetails;
  } else if (taskType === TaskStage.CONTENT_PRODUCTION) {
    fetchTasks = getContentReviewTaskDetails;
  } else if (taskType === TaskStage.MESSAGES) {
    fetchTasks = getUnreadMessageTaskDetails;
  } else if (taskType === TaskStage.LIVE_VERIFICATION) {
    fetchTasks = getLiveVerificationTaskDetails;
  } else {
    throw new Error(`Unknown task type: ${taskType}`);
  }

  const handleFetchTasks = (useLoader: boolean, abortController?: AbortController) => {
    setLoading(useLoader);
    fetchTasks({ campaignHashId: selectedCampaignId, abortController }).then(responseHandler);
  };

  useEffect(() => {
    const abortController = new AbortController();

    if (isActive) {
      handleFetchTasks(true, abortController);
    }

    return () => {
      abortController.abort();
    };
  }, [selectedCampaignId, isActive]);

  // Show refresh button if there are new tasks.
  useEffect(() => {
    const abortController = new AbortController();

    if (isActive) {
      let numPendingTasks = pendingTasksData.filter(
        (task) => task.isTestCampaign === testMode,
      ).length;
      const numCompletedTasks = completedTasksData.filter(
        (task) => task.isTestCampaign === testMode,
      ).length;

      // Account for messaging tasks in the All Tasks view, since these are not shown
      if (taskType === TaskStage.ALL) {
        numPendingTasks += numMessagingTasks;
      }

      if (
        numTasks[TaskCountType.PENDING] > numPendingTasks ||
        numTasks[TaskCountType.COMPLETED] > numCompletedTasks
      ) {
        setNumNewTasks(numTasks[TaskCountType.PENDING] - numPendingTasks);
        // Refetch the global task count if we detect that there are new tasks.
        dispatch(fetchTaskCount({ testMode }));
      } else if (numTasks[TaskCountType.PENDING] < numPendingTasks) {
        setNumNewTasks(0);
        // Refetch the global task count if we detect that there are new tasks.
        dispatch(fetchTaskCount({ testMode }));
        // In the case that we discover there are fewer tasks than being displayed,
        // automatically refresh to avoid an inconsistent state for the user.
        handleFetchTasks(false, abortController);
      } else {
        setNumNewTasks(0);
      }
    }
    return () => {
      abortController.abort();
    };
  }, [numTasks, pendingTasksData, isActive]);

  if (loading) {
    return (
      <Center mt="xl">
        <Loader />
      </Center>
    );
  }

  return (
    <Container mt="sm">
      <Stack gap="xs">
        {numNewTasks > 0 && (
          <Button
            onClick={() => handleFetchTasks(true)}
            fullWidth
            variant="light"
            size="xs"
            leftSection={<IconRefresh size="1rem" />}>
            {`${numNewTasks} new${
              taskType === TaskStage.ALL ? "" : ` ${getTaskTypeLabel(taskType)}`
            }${numNewTasks === 1 ? " task" : " tasks"} available`}
          </Button>
        )}
        {pendingTasksData
          .filter((task) => task.isTestCampaign === testMode)
          .map((task) => {
            // ALL and UNIFIED_CONTRACT_REVIEW are "virtual" types that show more than one actual type
            // of card. To determine which card to use, we have to look at the task's actual type, not
            // the tab's type (which may be virtual). No task should have a virtual type.
            const taskTypeToUse =
              taskType === TaskStage.ALL || taskType === TaskStage.UNIFIED_CONTRACT_REVIEW
                ? task.taskType
                : taskType;
            let CardComponent;
            switch (taskTypeToUse) {
              case TaskStage.CONTRACT_REVIEW:
                CardComponent = ContractReviewCard;
                break;
              case TaskStage.CONTRACT_OFFER_REVIEW:
                CardComponent = ContractOfferReviewCard;
                break;
              default:
                CardComponent = ContractCard;
                break;
            }

            const handleCompleteTask = (taskId: string, requireCreatorAction?: boolean) => {
              setPendingTasksData((prevTasks) => prevTasks.filter((t) => t.hashId !== taskId));
              // Note(albert): This is a special case for Messages tasks. This is necessary
              // because Messages tasks are simply a filter on Content Production tasks.
              // Messages task are only ever present in a dedicated tab, not in the All Tasks
              // tab.
              if (taskTypeToUse === TaskStage.MESSAGES) {
                if (task.status === TaskStatus.PENDING && requireCreatorAction) {
                  setNumTasks(TaskStage.CONTENT_PRODUCTION, -1, 1);
                } else if (task.status === TaskStatus.PENDING && !requireCreatorAction) {
                  setNumTasks(TaskStage.CONTENT_PRODUCTION, -1, 0);
                } else if (task.status === TaskStatus.COMPLETE && !requireCreatorAction) {
                  setNumTasks(TaskStage.CONTENT_PRODUCTION, 0, -1);
                }
              }

              if (taskTypeToUse === TaskStage.CONTENT_PRODUCTION && requireCreatorAction) {
                setCompletedTasksData((prevTasks) => [task, ...prevTasks]);
                setNumTasks(taskTypeToUse, -1, 1);
              } else {
                setNumTasks(taskTypeToUse, -1, 0);
              }
            };

            return (
              <CardComponent
                key={task.hashId}
                user={user}
                taskType={taskTypeToUse}
                primaryTask={task}
                isCompletedTask={task.status === TaskStatus.COMPLETE}
                handleCompleteTask={(requireCreatorAction?: boolean) =>
                  handleCompleteTask(task.hashId, requireCreatorAction)
                }
                handleCompleteRelatedTask={(
                  relatedTaskId: string,
                  relatedTaskType: TaskStage,
                  requireCreatorAction?: boolean,
                ) => {
                  if (relatedTaskType === taskTypeToUse) {
                    handleCompleteTask(relatedTaskId, requireCreatorAction);
                  } else {
                    setNumTasks(relatedTaskType, -1, 1);
                  }
                  setCompletedTaskIds((prevTaskIds) => new Set([...prevTaskIds, relatedTaskId]));
                }}
                decrementUnreadMessageCount={decrementUnreadMessageCount}
                completedTaskIds={completedTaskIds}
              />
            );
          })}
        {completedTasksData.filter((task) => task.isTestCampaign === testMode).length > 0 && (
          <Divider label="WAITING FOR CREATOR" variant="dashed" my="xs" mx="-lg" />
        )}
        {completedTasksData
          .filter((task) => task.isTestCampaign === testMode)
          .map((task) => {
            const taskTypeToUse = taskType === TaskStage.ALL ? task.taskType : taskType;
            const handleCompleteTask = (taskId: string, requireCreatorAction?: boolean) => {
              if (taskTypeToUse === TaskStage.CONTENT_PRODUCTION && !requireCreatorAction) {
                setCompletedTasksData((prevTasks) =>
                  prevTasks.filter((t) => t.hashId !== taskId),
                );
                setNumTasks(taskTypeToUse, 0, -1);
              }
            };
            return (
              <ContractCard
                key={task.hashId}
                user={user}
                taskType={taskTypeToUse}
                primaryTask={task}
                isCompletedTask
                handleCompleteTask={(requireCreatorAction?: boolean) =>
                  handleCompleteTask(task.hashId, requireCreatorAction)
                }
                handleCompleteRelatedTask={(
                  relatedTaskId: string,
                  relatedTaskType: TaskStage,
                  requireCreatorAction?: boolean,
                ) => {
                  if (relatedTaskType === taskTypeToUse) {
                    handleCompleteTask(relatedTaskId,requireCreatorAction);
                  } else {
                    setNumTasks(relatedTaskType, 0, -1);
                  }
                  setCompletedTaskIds((prevTaskIds) => new Set([...prevTaskIds, relatedTaskId]));
                }}
                decrementUnreadMessageCount={decrementUnreadMessageCount}
                completedTaskIds={completedTaskIds}
              />
            );
          })}
      </Stack>
    </Container>
  );
}
