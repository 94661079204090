import { SupportedFormat, SupportedPlatform } from "models/Common";
import {
  DeliverableContent,
  DeliverableVideo,
  deserializeDeliverableContent,
  deserializeDeliverableVideo,
  deserializeLiveContent,
  deserializeProductAccess,
  deserializeScript,
  LiveContent,
  ProductAccess,
  Script,
  TaskStatus,
  ContractReview,
  deserializeContractReview,
  TaskStage,
} from "components/contracts/tasks/models/Common";
import { ContractOfferBrandReview } from "components/contracts/brand_review/types";

export interface RelatedTask {
  hashId: string;
  deadline: Date;
  format: SupportedFormat;
  platform: SupportedPlatform;
  status: TaskStatus;
  taskType: TaskStage;
  referralLinkStatus?: TaskStatus;
  promoCodeStatus?: TaskStatus;
  numUnreadMessages?: number;
  waitingForCreator?: boolean;
}

function deserializeRelatedTask(data: any): RelatedTask {
  return {
    hashId: data.hash_id,
    deadline: new Date(data.deadline),
    format: data.format,
    platform: data.platform,
    status: data.status,
    taskType: data.task_type,
    referralLinkStatus: data.referral_link_status,
    promoCodeStatus: data.promo_code_status,
    numUnreadMessages: data.num_unread_messages,
    waitingForCreator: data.waiting_for_creator,
  };
}

export interface Task {
  isTestCampaign: boolean;
  brandName?: string;
  campaignId: string;
  hashId: string;
  dateCreated: Date;
  deadline: Date;
  enableAutoApprove: boolean;
  status: TaskStatus;
  avatarUrl?: string;
  adminContractUrl: string;
  brandContractUrl: string;
  displayName: string;
  creatorId: string;
  brandMessagesLastRead?: Date;
  numUnreadMessages?: number;
  referralLinkStatus?: TaskStatus;
  promoCodeStatus?: TaskStatus;
  referralLinkUrl?: string;
  promoCode?: string;
  deliverable?: DeliverableContent;
  liveContent?: LiveContent;
  deliverableVideo?: DeliverableVideo;
  script?: Script;
  productAccess?: ProductAccess;
  contractReview?: ContractReview;
  contractOfferReview?: ContractOfferBrandReview;
  taskType?: TaskStage;
  relatedTasks?: RelatedTask[];
}

export function deserializeTask(data: any): Task {
  return {
    isTestCampaign: data.is_test_campaign,
    brandName: data.brand_name,
    campaignId: data.campaign_id,
    hashId: data.hash_id,
    dateCreated: new Date(data.date_created),
    deadline: data.deadline ? new Date(data.deadline) : undefined,
    enableAutoApprove: data.enable_auto_approve,
    status: data.status,
    avatarUrl: data.contract?.avatar_url,
    adminContractUrl: data.contract?.admin_contract_url,
    brandContractUrl: data.contract?.brand_contract_url,
    displayName: data.contract?.display_name,
    creatorId: data.contract?.creator_id,
    brandMessagesLastRead: data.brand_messages_last_read
      ? new Date(data.brand_messages_last_read)
      : undefined,
    numUnreadMessages: data.num_unread_messages,
    referralLinkStatus: data.referral_link_status,
    promoCodeStatus: data.promo_code_status,
    referralLinkUrl: data.referral_link_url,
    promoCode: data.promo_code,
    deliverable: data.deliverable ? deserializeDeliverableContent(data.deliverable) : undefined,
    liveContent: data.live_content ? deserializeLiveContent(data.live_content) : undefined,
    deliverableVideo: data.deliverable_video
      ? deserializeDeliverableVideo(data.deliverable_video)
      : undefined,
    script: data.script ? deserializeScript(data.script) : undefined,
    productAccess: data.product_access ? deserializeProductAccess(data.product_access) : undefined,
    contractReview: data.contract_review
      ? deserializeContractReview(data.contract_review)
      : undefined,
    contractOfferReview: data.contract_offer_review,
    taskType: data.task_type,
    relatedTasks: data.related_tasks
      ? data.related_tasks.map((relatedTask: any) => deserializeRelatedTask(relatedTask))
      : undefined,
  };
}
