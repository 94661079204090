import React, { useEffect, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { AuthContext } from "auth/AuthContext";
import { User } from "firebase/auth";
import ReactPlayer from "react-player";

import {
  ActionIcon,
  Anchor,
  Button,
  Card,
  Collapse,
  Container,
  Flex,
  Group,
  Pill,
  Stack,
  Text,
  Textarea,
  TextInput,
  ThemeIcon,
  Timeline,
  Title,
  Tooltip,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { Notifications } from "@mantine/notifications";

import {
  IconBrandInstagram,
  IconBrandTiktok,
  IconBrandYoutubeFilled,
  IconCheck,
  IconChevronDown,
  IconClock,
  IconEdit,
  IconExternalLink,
  IconGift,
  IconNotes,
  IconVideo,
  IconVideoPlus,
  IconX,
} from "@tabler/icons-react";

import { showFailureNotification, showSuccessNotification } from "components/common/Notifications";
import {
  approveContent,
  fetchMessages,
  fetchScriptsForDeliverable,
  fetchVideosForDeliverable,
  getDeliverablesViewData,
  requestContentRevision,
} from "components/contracts/common/Api";
import CollapsibleCard from "components/contracts/common/CollapsibleCard";
import {
  ContentStatus,
  ContractType,
  ProductAccessInfoNeededFromCreatorType,
  ProductAccessInfoToProvideToCreatorType,
  ProductAccessStatus,
  UsageRightsRequestStatus,
} from "components/contracts/common/Common";
import { getSortOrder } from "components/contracts/deliverables/ContractDeliverables";
import Deliverable, { ContractDeliverableStatus } from "components/contracts/models/Deliverable";
import { safeScriptHtml } from "components/contracts/common/ScriptContent";
import MessageModel, { MessageParty } from "components/contracts/models/Message";
import { Message } from "components/contracts/common/MessageHistory";
import TitleAndCaption from "components/contracts/common/TitleAndCaption";
import { getFormattedCurrency } from "components/contracts/dashboard/Utils";
import DeliverableVideo from "components/contracts/models/DeliverableVideo";
import Script from "components/contracts/models/Script";
import VersionedDeliverableVideo from "components/contracts/models/VersionedDeliverableVideo";
import {
  BrandInputResult,
  CreatorInputComponent,
  ProductAccessBadge,
} from "components/contracts/review/BrandProductAccessInput";
import { getApproveButtonText } from "components/contracts/review/ContentReviewCardActions";
import { EditFields } from "components/contracts/review/EditReferralLinkAndPromoCode";
import LiveContentVerification from "components/contracts/review/LiveContentVerification";
import { fromISODateString, toLongDateString } from "utils/DateUtils";
import { SupportedFormat, SupportedPlatform } from "models/Common";
import {
  deserializeUsageRightsRequest,
  UsageRightsRequestDetails,
} from "components/contracts/models/UsageRightsRequestDetails";
import { UsageRightsContentVerification } from "components/contracts/review/UsageRightsContentVerification";

const MESSAGE_LENGTH_LIMIT = 3000;

// Translate deliverable format into a name, but the format does
// not need to include the platform name.
function makeDeliverableFormatName(deliverableFormatStr: string): string {
  switch (deliverableFormatStr) {
    case "INSTAGRAM_DEDICATED_REEL":
      return "Dedicated Reel";
    case "YOUTUBE_30S_INTEGRATED_VIDEO":
    case "YOUTUBE_60S_INTEGRATED_VIDEO":
    case "YOUTUBE_90S_INTEGRATED_VIDEO":
      return "Integrated Video";
    case "TIKTOK_DEDICATED_VIDEO":
    case "YOUTUBE_DEDICATED_VIDEO":
      return "Dedicated Video";
    case "YOUTUBE_SHORT":
      return "Short";
    case "UGC":
      return "UGC";
    default:
      return null;
  }
}

const ValuePill = ({
  type,
  meetsCondition,
  amount,
  paymentStatus,
}: {
  type: string;
  meetsCondition: boolean;
  amount: number;
  paymentStatus: number;
}) => {
  let paymentStatusStr = "";
  let statusBackgroundColor = "";
  let textColor = "white";
  switch (paymentStatus) {
    case 1:
      paymentStatusStr = "UNPAID";
      statusBackgroundColor = "#FA5252";
      break;
    case 2:
      paymentStatusStr = "PARTIALLY PAID";
      statusBackgroundColor = "#D0EBFF";
      textColor = "blue.6";
      break;
    case 3:
      paymentStatusStr = "PAID";
      statusBackgroundColor = "#228BE6";
      break;
    default:
      paymentStatusStr = "UNKNOWN";
      statusBackgroundColor = "#868E96";
      break;
  }

  // Override if it doesn't meet the conditions
  if (!meetsCondition) {
    paymentStatusStr = "INELIGIBLE";
    statusBackgroundColor = "#868E96";
  }

  const text = `${getFormattedCurrency(amount)} ${type.toUpperCase()} - ${paymentStatusStr}`;
  return (
    <Pill
      c={textColor}
      styles={{
        root: {
          backgroundColor: statusBackgroundColor,
        },
      }}>
      <Text inherit size="10" fw={600}>
        {text}
      </Text>
    </Pill>
  );
};

const BrandContractHeader = ({
  brandName,
  signatureFirstName,
  signatureLastName,
  creatorFirstName,
  campaignName,
  campaignId,
  adgroupName,
  adGroupId,
  creativeBriefUrl,
  amount,
  bonusAmount,
  earnedBonusAmount,
  eligibleForBonus,
  meetsBonusCondition,
  paymentStatus,
  bonusPaymentStatus,
}: {
  brandName: string;
  signatureFirstName: string;
  signatureLastName: string;
  creatorFirstName: string;
  campaignName: string;
  campaignId: number;
  adgroupName: string;
  adGroupId: number;
  creativeBriefUrl: string;
  amount: number;
  bonusAmount: number;
  earnedBonusAmount: number;
  eligibleForBonus: boolean;
  meetsBonusCondition: boolean;
  paymentStatus: number;
  bonusPaymentStatus: number;
}) => {
  let name = `${signatureFirstName} ${signatureLastName}`;
  if (name.trim().length === 0) {
    name = creatorFirstName;
  }
  name = name || "";

  return (
    <Stack w="100%">
      <Group justify="space-between" preventGrowOverflow={false}>
        <Title size={32}>{brandName || name ? `🤝 ${brandName} / ${name}` : ""}</Title>
        <Group>
          <ValuePill type="Base" meetsCondition amount={amount} paymentStatus={paymentStatus} />
          {eligibleForBonus ? (
            <ValuePill
              type="Bonus"
              meetsCondition={meetsBonusCondition}
              amount={meetsBonusCondition ? earnedBonusAmount : bonusAmount}
              paymentStatus={bonusPaymentStatus}
            />
          ) : null}
        </Group>
      </Group>
      <Group gap="xl" preventGrowOverflow={false}>
        <Group gap="xs">
          <Title size={12} fw={400}>
            Campaign:
          </Title>
          <Anchor href={`/campaigns/${campaignId}`} target="_blank" underline="never">
            <Title size={12} fw={400}>
              {campaignName}
            </Title>
          </Anchor>
        </Group>
        <Group gap="xs">
          <Title size={12} fw={400}>
            Ad group:
          </Title>
          <Anchor href={`/campaigns/${campaignId}/${adGroupId}`} target="_blank" underline="never">
            <Title size={12} fw={400}>
              {adgroupName}
            </Title>
          </Anchor>
        </Group>
        <Button
          component="a"
          leftSection={<IconExternalLink size="0.7rem" />}
          size="compact-sm"
          variant="transparent"
          href={creativeBriefUrl}
          target="_blank">
          <Title size={12} fw={400}>
            View Creative Brief
          </Title>
        </Button>
      </Group>
    </Stack>
  );
};

const Chevron = ({
  opened,
  toggle,
  disabled,
  size = "1rem",
}: {
  opened: boolean;
  toggle?: () => void;
  disabled?: boolean;
  size?: string | number;
}) => {
  return (
    <ActionIcon variant="transparent" onClick={toggle} color="gray" disabled={disabled}>
      <IconChevronDown
        size={size}
        style={{
          transform: opened ? "rotate(180deg)" : "rotate(0deg)",
          transition: "transform 0.3s ease",
        }}
      />
    </ActionIcon>
  );
};

const PlatformIcon = ({ platform }: { platform: string }) => {
  switch (platform) {
    case "instagram":
      return <IconBrandInstagram size="1.5rem" />;
    case "tiktok":
      return <IconBrandTiktok size="1.5rem" />;
    case "youtube":
      return <IconBrandYoutubeFilled size="1.5rem" />;
    default:
      return null;
  }
};

const LabeledChevron = ({
  isOpened,
  label,
  toggle,
}: {
  isOpened: boolean;
  label: string;
  toggle: () => void;
}) => {
  return (
    <Group justify="flex-end" gap={0}>
      <Text inherit fz={12} c="dimmed" fw={400} onClick={toggle}>
        {label}
      </Text>
      <Chevron opened={isOpened} toggle={toggle} size="0.75rem" />
    </Group>
  );
};

const CollapsibleTitle = ({
  text,
  isOpened,
  toggle,
  disabled,
}: {
  text: string;
  isOpened: boolean;
  toggle: () => void;
  disabled?: boolean;
}) => {
  return (
    <Group justify="space-between" preventGrowOverflow={false}>
      <Title size={14} fw={600}>
        {text}
      </Title>
      {disabled || (
        <LabeledChevron
          isOpened={isOpened}
          toggle={toggle}
          label={isOpened ? "Close" : "View more"}
        />
      )}
    </Group>
  );
};

const Messages = ({ messages }: { messages: MessageModel[] }) => {
  if (messages.length === 0) {
    return null;
  }

  const messagesToDisplay = messages.map((message) => (
    <Message
      key={`${message.dateCreated}-${message.senderType}`}
      message={message}
      messageViewer={MessageParty.BRAND}
      handleDeleteMessage={null}
      handleEditMessage={null}
      showAdminOptions={false}
    />
  ));

  return (
    <Stack>
      <Title size={14} fw={600}>
        Messages
      </Title>
      {messagesToDisplay}
    </Stack>
  );
};

const ContentFeedback = ({
  script,
  videoDraft,
  creatorName,
  contentStatus,
  onAddMessage,
  updateReviewStatus,
  updateDeliverableStatusCallback,
  isMissingReferralLink,
  isMissingPromoCode,
}: {
  script?: Script;
  videoDraft?: DeliverableVideo;
  creatorName: string;
  contentStatus: ContentStatus;
  onAddMessage: (newMessage: any) => void;
  updateReviewStatus: (reviewStatus: ContentStatus, date: Date) => void;
  updateDeliverableStatusCallback: (deliverableStatus: ContractDeliverableStatus) => void;
  isMissingReferralLink?: boolean;
  isMissingPromoCode?: boolean;
}) => {
  const [messageText, setMessageText] = useState("");
  const [approveButtonLoading, setApproveButtonLoading] = useState(false);
  const [revisionButtonLoading, setRevisionButtonLoading] = useState(false);

  const handleApproveContent = () => {
    setApproveButtonLoading(true);
    approveContent({
      deliverableId: script ? script.deliverableId : videoDraft.deliverable.id,
      messageText,
      scriptId: script?.scriptId,
      videoId: videoDraft?.videoId,
    })
      .then((response: any) => {
        const { message, success, reviewDate, reviewStatus, status } = response;
        if (success) {
          onAddMessage(message);
          updateReviewStatus(reviewStatus, fromISODateString(reviewDate));
          updateDeliverableStatusCallback(status);
          setMessageText("");
          showSuccessNotification({
            message: (
              <Text>
                {script ? "Concept" : "Video"} by{" "}
                <Text span fw="bold">
                  {creatorName}
                </Text>{" "}
                approved!
              </Text>
            ),
          });
          setApproveButtonLoading(false);
        } else {
          showFailureNotification({
            message: (
              <Text>
                Unable to approve {script ? "concept" : "video"} by{" "}
                <Text span fw="bold">
                  {creatorName}
                </Text>
                .
              </Text>
            ),
          });
          setApproveButtonLoading(false);
        }
      })
      .catch(() => {
        showFailureNotification({
          message: (
            <Text>
              Unable to approve {script ? "concept" : "video"} by{" "}
              <Text span fw="bold">
                {creatorName}
              </Text>
              .
            </Text>
          ),
        });
        setApproveButtonLoading(false);
      });
  };

  const handleRequestRevision = () => {
    setRevisionButtonLoading(true);
    requestContentRevision({
      deliverableId: script ? script.deliverableId : videoDraft.deliverable.id,
      messageText,
      scriptId: script?.scriptId,
      videoId: videoDraft?.videoId,
    })
      .then((response: any) => {
        const { message, success, reviewDate, reviewStatus } = response;
        if (success) {
          onAddMessage(message);
          updateReviewStatus(reviewStatus, fromISODateString(reviewDate));
          setMessageText("");
          showSuccessNotification({
            message: (
              <Text>
                {script ? "Concept" : "Video"} revision feedback sent to{" "}
                <Text span fw="bold">
                  {creatorName}
                </Text>{" "}
                successfully.
              </Text>
            ),
          });
          setRevisionButtonLoading(false);
        } else {
          showFailureNotification({
            message: (
              <Text>
                Unable to request revision for{" "}
                <Text span fw="bold">
                  {creatorName}
                </Text>
                &apos;s {script ? "concept." : "video."}
              </Text>
            ),
          });
          setRevisionButtonLoading(false);
        }
      })
      .catch(() => {
        showFailureNotification({
          message: (
            <Text>
              Unable to request revision for{" "}
              <Text span fw="bold">
                {creatorName}
              </Text>
              &apos;s {script ? "concept." : "video."}
            </Text>
          ),
        });
        setRevisionButtonLoading(false);
      });
  };

  if (
    contentStatus !== ContentStatus.IN_PROGRESS &&
    contentStatus !== ContentStatus.PENDING_REVIEW
  ) {
    // There is no feedback possible for scripts/videos not in the pending or in review states.
    // NOTE: If we want to change our mind, we will have to somehow change the state on the DB.
    return null;
  }
  const missingLinkOrPromoCodeMessage = `Provide a ${isMissingReferralLink ? "Referral Link" : ""}${
    isMissingReferralLink && isMissingPromoCode ? " and " : ""
  }${isMissingPromoCode ? "Promo Code" : ""} Before approving the content`;
  const shouldBlockReview = isMissingReferralLink || isMissingPromoCode;

  return (
    <Stack gap="xs">
      <Textarea
        placeholder="Optional"
        label={
          <Text ml={1} mb={4} fw="500" inherit>
            Message {creatorName}
          </Text>
        }
        autosize
        minRows={3}
        value={messageText}
        onChange={(event) => setMessageText(event.currentTarget.value)}
        error={
          messageText.length > MESSAGE_LENGTH_LIMIT &&
          `Feedback must not exceed ${MESSAGE_LENGTH_LIMIT} characters.`
        }
      />
      <Group justify="right">
        <Button
          disabled={messageText.length === 0 || messageText.length > MESSAGE_LENGTH_LIMIT}
          loading={revisionButtonLoading}
          onClick={handleRequestRevision}
          leftSection={<IconEdit size="1rem" />}
          variant="light"
          color="red"
          size="sm">
          Request Revision
        </Button>
        <Tooltip label={missingLinkOrPromoCodeMessage} disabled={!shouldBlockReview}>
          <Button
            disabled={messageText.length > MESSAGE_LENGTH_LIMIT || shouldBlockReview}
            loading={approveButtonLoading}
            onClick={handleApproveContent}
            leftSection={<IconCheck size="1rem" />}
            variant="light"
            color="teal"
            size="sm">
            {getApproveButtonText({
              isScript: !!script,
              isVideo: !!videoDraft,
              canSendMessages: false,
              messageText,
              forUgc: videoDraft && videoDraft.deliverable.format === SupportedFormat.UGC,
            })}
          </Button>
        </Tooltip>
      </Group>
    </Stack>
  );
};

const ScriptEntry = ({
  creatorName,
  script,
  scriptMessages,
  onAddMessage,
  updateDeliverableStatusCallback,
  defaultOpen = false,
  dueDate,
  isMissingPromoCode,
  isMissingReferralLink,
}: {
  creatorName: string;
  script: Script;
  scriptMessages: MessageModel[];
  onAddMessage: (newMessage: any) => void;
  updateDeliverableStatusCallback: (deliverableStatus: ContractDeliverableStatus) => void;
  defaultOpen?: boolean;
  dueDate?: Date;
  isMissingPromoCode?: boolean;
  isMissingReferralLink?: boolean;
}) => {
  const [contentStatus, setContentStatus] = useState<ContentStatus>(script.status);
  const [title, setTitle] = useState<string>("");
  const [icon, setIcon] = useState<React.ReactNode>(null);
  const [borderColor, setBorderColor] = useState<string>(undefined);
  const [reviewText, setReviewText] = useState<string>("");
  const [reviewDate, setReviewDate] = useState<Date>(script.reviewDate);

  const [opened, { toggle }] = useDisclosure(defaultOpen);

  useEffect(() => {
    let newTitle;
    let newIcon;
    let newBorderColor;
    switch (contentStatus) {
      case 1:
        newTitle = "Concept Draft";
        newIcon = (
          <ThemeIcon color="red.6" radius="xl" size="sm">
            <IconNotes style={{ width: "70%", height: "70%" }} />
          </ThemeIcon>
        );
        newBorderColor = "var(--mantine-color-red-6)";
        break;
      case 0:
      case 2:
        newTitle = "Concept Draft";
        newIcon = (
          <ThemeIcon color="blue.1" radius="xl" size="sm">
            <IconNotes style={{ width: "70%", height: "70%" }} />
          </ThemeIcon>
        );
        newBorderColor = "var(--mantine-color-blue-1)";
        break;
      case 3:
        // Approval status value
        newTitle = "Approved Concept";
        newIcon = (
          <ThemeIcon color="blue.6" radius="xl" size="sm">
            <IconCheck style={{ width: "70%", height: "70%" }} />
          </ThemeIcon>
        );
        newBorderColor = "var(--mantine-color-blue-6)";
        break;
      default:
        break;
    }

    let newReviewText = `Submitted ${toLongDateString(script.submissionDate)}`;
    if (contentStatus === ContentStatus.APPROVED) {
      newReviewText = `Approved ${toLongDateString(reviewDate)}`;
    } else if (contentStatus === ContentStatus.REVISIONS_REQUESTED) {
      newReviewText = `Revision requested ${toLongDateString(reviewDate)}`;
    } else if (contentStatus === ContentStatus.PENDING_REVIEW && !!dueDate) {
      newReviewText = `Due ${toLongDateString(dueDate)}`;
    }
    setTitle(newTitle);
    setIcon(newIcon);
    setBorderColor(newBorderColor);
    setReviewText(newReviewText);
  }, [contentStatus, reviewDate]);

  const safeHtml = safeScriptHtml(script.text);

  return (
    <Timeline.Item
      bullet={icon}
      lineVariant={defaultOpen ? "dashed" : "solid"}
      styles={{
        itemBullet: {
          border: `solid ${borderColor}`,
        },
      }}
      title={<CollapsibleTitle text={title} isOpened={opened} toggle={toggle} />}>
      <Stack w="100%">
        <Title size={12} c="dimmed" fw={400}>
          {reviewText}
        </Title>
        <Collapse in={opened}>
          <Stack w="100%">
            <Card withBorder shadow="xs">
              <div dangerouslySetInnerHTML={{ __html: safeHtml }} />
            </Card>
            <Messages messages={scriptMessages} />
            <ContentFeedback
              script={script}
              creatorName={creatorName}
              contentStatus={contentStatus}
              onAddMessage={onAddMessage}
              updateReviewStatus={(reviewStatus: ContentStatus, date: Date) => {
                setContentStatus(reviewStatus);
                setReviewDate(date);
              }}
              updateDeliverableStatusCallback={updateDeliverableStatusCallback}
              isMissingPromoCode={isMissingPromoCode}
              isMissingReferralLink={isMissingReferralLink}
            />
          </Stack>
        </Collapse>
      </Stack>
    </Timeline.Item>
  );
};

const DummyScriptEntry = ({ expectedDate }: { expectedDate: Date }) => {
  const icon = (
    <ThemeIcon color="blue.1" radius="xl" size="sm" autoContrast>
      <IconNotes style={{ width: "70%", height: "70%" }} />
    </ThemeIcon>
  );
  return (
    <Timeline.Item
      bullet={icon}
      lineVariant="dashed"
      styles={{
        itemBullet: {
          border: `solid var(--mantine-color-blue-1)`,
        },
      }}
      title={
        <Title size={14} fw={600}>
          Awaiting Concept
        </Title>
      }>
      <Title size={12} c="dimmed" fw={400}>
        Due on {toLongDateString(expectedDate)}
      </Title>
    </Timeline.Item>
  );
};

const VideoDraftEntry = ({
  creatorName,
  videoDraft,
  videoMessages,
  onAddMessage,
  updateDeliverableStatusCallback,
  defaultOpen = false,
  dueDate,
  isMissingPromoCode,
  isMissingReferralLink,
}: {
  creatorName: string;
  videoDraft: DeliverableVideo;
  videoMessages: MessageModel[];
  onAddMessage: (newMessage: any) => void;
  updateDeliverableStatusCallback: (deliverableStatus: ContractDeliverableStatus) => void;
  defaultOpen?: boolean;
  dueDate?: Date;
  isMissingPromoCode?: boolean;
  isMissingReferralLink?: boolean;
}) => {
  const [contentStatus, setContentStatus] = useState<ContentStatus>(videoDraft.contentStatus);
  const [title, setTitle] = useState<string>("");
  const [icon, setIcon] = useState<React.ReactNode>(null);
  const [borderColor, setBorderColor] = useState<string>(undefined);
  const [reviewText, setReviewText] = useState<string>("");
  const [reviewDate, setReviewDate] = useState<Date>(videoDraft.reviewDate);

  const [opened, { toggle }] = useDisclosure(defaultOpen);

  useEffect(() => {
    let newTitle;
    let newIcon;
    let newBorderColor;
    switch (contentStatus) {
      case 1:
        // Pending review
        newTitle = "Video Draft";
        newIcon = (
          <ThemeIcon color="red.6" radius="xl" size="sm" autoContrast>
            <IconVideo style={{ width: "70%", height: "70%" }} />
          </ThemeIcon>
        );
        newBorderColor = "var(--mantine-color-red-6)";
        break;
      case 0:
      case 2:
        newTitle = "Video Draft";
        newIcon = (
          <ThemeIcon color="blue.1" radius="xl" size="sm" autoContrast>
            <IconVideo style={{ width: "70%", height: "70%" }} />
          </ThemeIcon>
        );
        newBorderColor = "var(--mantine-color-blue-1)";
        break;
      case 3:
        // Approval status value
        newTitle = "Approved Video";
        newIcon = (
          <ThemeIcon color="blue.6" radius="xl" size="sm">
            <IconCheck style={{ width: "70%", height: "70%" }} />
          </ThemeIcon>
        );
        newBorderColor = "var(--mantine-color-blue-6)";
        break;
      default:
        break;
    }

    let newReviewText = `Submitted ${toLongDateString(videoDraft.dateCreated)}`;
    if (contentStatus === ContentStatus.APPROVED) {
      newReviewText = `Approved ${toLongDateString(reviewDate)}`;
    } else if (contentStatus === ContentStatus.REVISIONS_REQUESTED) {
      newReviewText = `Revision requested ${toLongDateString(reviewDate)}`;
    } else if (contentStatus === ContentStatus.PENDING_REVIEW && !!dueDate) {
      newReviewText = `Due ${toLongDateString(dueDate)}`;
    }
    setTitle(newTitle);
    setIcon(newIcon);
    setBorderColor(newBorderColor);
    setReviewText(newReviewText);
  }, [contentStatus, reviewDate]);

  return (
    <Timeline.Item
      bullet={icon}
      lineVariant={defaultOpen ? "dashed" : "solid"}
      styles={{
        itemBullet: {
          border: `solid ${borderColor}`,
        },
      }}
      title={<CollapsibleTitle text={title} isOpened={opened} toggle={toggle} />}>
      <Stack w="100%">
        <Title size={12} c="dimmed" fw={400}>
          {reviewText}
        </Title>
        <Collapse in={opened}>
          <Stack w="100%">
            <Group justify="center">
              <Card withBorder shadow="xs" miw={640}>
                <ReactPlayer url={videoDraft.location} muted controls />
                <TitleAndCaption
                  title={videoDraft.title}
                  caption={videoDraft.caption}
                  width={640}
                />
              </Card>
            </Group>
            <Messages messages={videoMessages} />
            <ContentFeedback
              videoDraft={videoDraft}
              creatorName={creatorName}
              contentStatus={contentStatus}
              onAddMessage={onAddMessage}
              updateReviewStatus={(reviewStatus: ContentStatus, date: Date) => {
                setContentStatus(reviewStatus);
                setReviewDate(date);
              }}
              updateDeliverableStatusCallback={updateDeliverableStatusCallback}
              isMissingPromoCode={isMissingPromoCode}
              isMissingReferralLink={isMissingReferralLink}
            />
          </Stack>
        </Collapse>
      </Stack>
    </Timeline.Item>
  );
};

const DummyVideoDraftEntry = ({ expectedDate }: { expectedDate: Date }) => {
  const icon = (
    <ThemeIcon color="blue.1" radius="xl" size="sm" autoContrast>
      <IconVideo style={{ width: "70%", height: "70%" }} />
    </ThemeIcon>
  );
  return (
    <Timeline.Item
      bullet={icon}
      lineVariant="dashed"
      styles={{
        itemBullet: {
          border: `solid var(--mantine-color-blue-1)`,
        },
      }}
      title={
        <Title size={14} fw={600}>
          Awaiting Video Draft
        </Title>
      }>
      <Title size={12} c="dimmed" fw={400}>
        Due on {toLongDateString(expectedDate)}
      </Title>
    </Timeline.Item>
  );
};

/**
 * There is exactly one live item at the end that is always shown.
 * How it displays depends on if it has been reached
 */
const LiveTimelineItem = ({
  deliverable,
  deliverableStatus,
  setDeliverableStatus,
  brandName,
  defaultOpen = false,
}: {
  deliverable: Deliverable;
  deliverableStatus: ContractDeliverableStatus;
  setDeliverableStatus: (status: ContractDeliverableStatus) => void;
  brandName: string;
  defaultOpen?: boolean;
}) => {
  const [disputeReason, setDisputeReason] = useState<string>(deliverable.liveContentDisputeReason);
  const [icon, setIcon] = useState<React.ReactNode>(null);
  const [borderColor, setBorderColor] = useState<string>(undefined);
  const [title, setTitle] = useState<string>("");
  const [subtitle, setSubtitle] = useState<string>("");
  const [hasMore, setHasMore] = useState<boolean>(false);

  const [opened, { toggle }] = useDisclosure(defaultOpen);

  useEffect(() => {
    const revisionsRequested =
      deliverableStatus === ContractDeliverableStatus.LIVE_CONTENT_REVISIONS_REQUESTED;
    let newTitle;
    let newSubtitle;
    let newIcon = <IconVideoPlus size={12} />;
    let newBorderColor;
    let newHasMore = false;
    if (revisionsRequested) {
      newTitle = "Revisions Requested";
      newSubtitle = deliverable.liveContentReviewDatetime
        ? `On ${toLongDateString(deliverable.liveContentReviewDatetime)}`
        : "";
      newIcon = (
        <ThemeIcon color="gray.6" radius="xl" size="sm">
          <IconVideoPlus style={{ width: "70%", height: "70%" }} />
        </ThemeIcon>
      );
      newBorderColor = "var(--mantine-color-gray-6)";
      newHasMore = true;
    } else if (
      deliverable.liveContentSubmissionDatetime &&
      deliverableStatus !== ContractDeliverableStatus.WAITING_FOR_LIVE_CONTENT
    ) {
      newTitle = "Video Live";
      newSubtitle = `On ${toLongDateString(deliverable.liveContentSubmissionDatetime)}`;
      newIcon = (
        <ThemeIcon color="red.6" radius="xl" size="sm">
          <IconVideoPlus style={{ width: "70%", height: "70%" }} />
        </ThemeIcon>
      );
      newBorderColor = "var(--mantine-color-red-6)";
      newHasMore = true;
    } else {
      // The video has not gone live at all, we are still waiting for it.
      // NOTE: we may have a live URL if the creator has not finished the submission process.
      newTitle = "Go Live";
      newSubtitle = `Due ${toLongDateString(deliverable.timeline.minLiveDate)}`;
      newIcon = (
        <ThemeIcon color="gray.6" radius="xl" size="sm">
          <IconVideoPlus style={{ width: "70%", height: "70%" }} />
        </ThemeIcon>
      );
      newBorderColor = "var(--mantine-color-gray-6)";
      newHasMore = false;
    }
    // Override the icon to checkmark if the content has been approved
    if (
      deliverableStatus === ContractDeliverableStatus.COMPLETE ||
      deliverableStatus === ContractDeliverableStatus.LIVE_CONTENT_APPROVED
    ) {
      newIcon = (
        <ThemeIcon color="blue.6" radius="xl" size="sm">
          <IconVideoPlus style={{ width: "70%", height: "70%" }} />
        </ThemeIcon>
      );
      newBorderColor = "var(--mantine-color-blue-6)";
    }
    setIcon(newIcon);
    setBorderColor(newBorderColor);
    setTitle(newTitle);
    setSubtitle(newSubtitle);
    setHasMore(newHasMore);
  }, [deliverableStatus]);

  const revisionsRequested =
    deliverableStatus === ContractDeliverableStatus.LIVE_CONTENT_REVISIONS_REQUESTED;
  const liveContentApproved =
    deliverableStatus === ContractDeliverableStatus.COMPLETE ||
    deliverableStatus === ContractDeliverableStatus.LIVE_CONTENT_APPROVED;

  return (
    <Timeline.Item
      bullet={icon}
      styles={{
        itemBullet: {
          border: `solid ${borderColor}`,
        },
      }}
      title={
        <CollapsibleTitle text={title} isOpened={opened} toggle={toggle} disabled={!hasMore} />
      }>
      <Stack>
        <Title size={12} c="dimmed" fw={400}>
          {subtitle}
        </Title>
        {hasMore && (
          <Collapse in={opened}>
            <LiveContentVerification
              deliverableId={deliverable.id}
              deliverableStatus={deliverableStatus}
              setDeliverableStatus={setDeliverableStatus}
              disputeReason={disputeReason}
              setDisputeReason={setDisputeReason}
              creatorHandle={deliverable.creatorHandle}
              brandName={brandName}
              hasUsageRights={
                deliverable.usageRightsDays > 0 || deliverable.usageRightsInPerpetuity
              }
              liveContentUrl={deliverable.liveContentUrl}
              assetUrl={deliverable.assetUrl}
              adCode={deliverable.adCode}
              platform={deliverable.platform}
              deliverableFormat={deliverable.format}
              reviewDeadline={deliverable.liveContentReviewDeadline}
              usageRightsEndDate={deliverable.usageRightsExpirationDate}
              usageRightsInPerpetuity={deliverable.usageRightsInPerpetuity}
              hasDraftContent={false}
            />
            {revisionsRequested && (
              <Stack gap="xs">
                <Text inherit fz={14}>
                  You have requested revisions to the live video.
                </Text>
                <Card bg="gray.0">
                  <Group align="top" gap="xs">
                    <IconClock size={16} color="#868E96" />
                    <Stack gap="xs">
                      <Text inherit c="gray.6" fz={12} fw={600}>
                        Waiting for Creator to Resubmit
                      </Text>
                      <Text inherit fz={12} fw={600}>
                        Live Link
                      </Text>
                      <Group gap="xs">
                        <TextInput
                          miw={600}
                          disabled
                          leftSection={<IconCheck size={16} width={400} />}
                          value={deliverable.liveContentUrl}
                        />
                        <Button
                          variant="light"
                          color="blue.6"
                          component="a"
                          href={deliverable.liveContentUrl}
                          target="_blank">
                          <IconExternalLink size={16} />
                        </Button>
                      </Group>
                      <Text inherit fz={12} fw={600}>
                        Revisions
                      </Text>
                      <Text c="gray.5">Please make the following changes: {disputeReason}</Text>
                    </Stack>
                  </Group>
                </Card>
              </Stack>
            )}
            {liveContentApproved && (
              <Stack gap={2}>
                <Text inherit fz={14}>
                  You have approved the live video!
                </Text>
                <Text inherit fz={14} fw={600}>
                  Live Video
                </Text>
                <Group gap="xs">
                  <TextInput
                    miw={600}
                    disabled
                    leftSection={<IconCheck size={16} width={400} />}
                    value={deliverable.liveContentUrl}
                  />
                  <Button
                    variant="light"
                    color="blue.6"
                    component="a"
                    href={deliverable.liveContentUrl}
                    target="_blank">
                    <IconExternalLink size={16} />
                  </Button>
                </Group>
              </Stack>
            )}
          </Collapse>
        )}
      </Stack>
    </Timeline.Item>
  );
};

const UsageRightsContractTimelineItem = ({
  usageRightsRequest,
  status,
  setStatus,
  disputeReason,
  setDisputeReason,
  disputeDate,
  setDisputeDate,
}: {
  usageRightsRequest: UsageRightsRequestDetails;
  status: UsageRightsRequestStatus;
  setStatus: (status: UsageRightsRequestStatus) => void;
  disputeReason: string;
  setDisputeReason: (reason: string) => void;
  disputeDate: Date;
  setDisputeDate: (date: Date) => void;
}) => {
  const [icon, setIcon] = useState<React.ReactNode>(null);
  const [borderColor, setBorderColor] = useState<string>(undefined);
  const [title, setTitle] = useState<string>("");
  const [subtitle, setSubtitle] = useState<string>("");
  const [hasMore, setHasMore] = useState<boolean>(false);

  const [opened, { toggle }] = useDisclosure(
    UsageRightsRequestStatus.REJECTED &&
      (!disputeDate ||
        (usageRightsRequest.creatorLastUpdateTime &&
          usageRightsRequest.creatorLastUpdateTime > disputeDate)),
  );

  useEffect(() => {
    const revisionsRequested =
      disputeDate &&
      (!usageRightsRequest.creatorLastUpdateTime ||
        disputeDate > usageRightsRequest.creatorLastUpdateTime);
    let newTitle;
    let newSubtitle;
    let newIcon = <IconVideoPlus size={12} />;
    let newBorderColor;
    let newHasMore = false;
    if (status === UsageRightsRequestStatus.REJECTED) {
      newTitle = "Request Rejected";
      newIcon = (
        <ThemeIcon color="red.6" radius="xl" size="sm">
          <IconX style={{ width: "70%", height: "70%" }} />
        </ThemeIcon>
      );
      newBorderColor = "var(--mantine-color-red-6)";
      newHasMore = false;
    } else if (status === UsageRightsRequestStatus.ACCEPTED) {
      newTitle = "Final Video and Partnership Codes";
      newSubtitle = usageRightsRequest.creatorLastUpdateTime
        ? `On ${toLongDateString(disputeDate)}`
        : "";
      newIcon = (
        <ThemeIcon color="blue.6" radius="xl" size="sm">
          <IconVideoPlus style={{ width: "70%", height: "70%" }} />
        </ThemeIcon>
      );
      newBorderColor = "var(--mantine-color-blue-6)";
      newHasMore = true;
    } else if (revisionsRequested) {
      newTitle = "Revisions Requested";
      newSubtitle = disputeDate ? `On ${toLongDateString(disputeDate)}` : "";
      newIcon = (
        <ThemeIcon color="gray.6" radius="xl" size="sm">
          <IconVideoPlus style={{ width: "70%", height: "70%" }} />
        </ThemeIcon>
      );
      newBorderColor = "var(--mantine-color-gray-6)";
      newHasMore = true;
    } else {
      newTitle = "Final Video and Partnership Codes";
      newSubtitle = `Due ${toLongDateString(usageRightsRequest.dueDate)}`;
      newIcon = (
        <ThemeIcon color="red.6" radius="xl" size="sm">
          <IconVideoPlus style={{ width: "70%", height: "70%" }} />
        </ThemeIcon>
      );
      newBorderColor = "var(--mantine-color-red-6)";
      newHasMore = true;
    }

    if (!usageRightsRequest.video) {
      // if there is no video to review, we don't show anything
      newHasMore = false;
    }
    setIcon(newIcon);
    setBorderColor(newBorderColor);
    setTitle(newTitle);
    setSubtitle(newSubtitle);
    setHasMore(newHasMore);
  }, [status, disputeReason]);
  return (
    <Timeline.Item
      bullet={icon}
      styles={{
        itemBullet: {
          border: `solid ${borderColor}`,
        },
      }}
      title={
        <CollapsibleTitle text={title} isOpened={opened} toggle={toggle} disabled={!hasMore} />
      }>
      <Stack>
        <Title size={12} c="dimmed" fw={400}>
          {subtitle}
        </Title>
        {!usageRightsRequest.video && (
          <Text inherit>
            Waiting for creator to submit final video file
            {usageRightsRequest.adCodeRequired && " and partnership ad code"}
          </Text>
        )}
        {hasMore && (
          <Collapse in={opened}>
            <UsageRightsContentVerification
              usageRightsRequest={usageRightsRequest}
              usageRightsStatus={status}
              setUsageRightsStatus={setStatus}
              disputeReason={disputeReason}
              setDisputeReason={(reason: string) => {
                setDisputeReason(reason);
                setDisputeDate(new Date());
              }}
              creatorHandle={usageRightsRequest.creatorHandle || "Creator"}
              assetUrl={
                usageRightsRequest.video?.transcodedLocation ||
                usageRightsRequest.video?.videoLocation
              }
              adCode={
                usageRightsRequest.platform === SupportedPlatform.INSTAGRAM
                  ? usageRightsRequest.instagramAdCode
                  : usageRightsRequest.tiktokAdCode
              }
              platform={usageRightsRequest.platform}
              usageRightsEndDate={usageRightsRequest.usageRightsEndDate}
              usageRightsInPerpetuity={usageRightsRequest.usageRightsInPerpetuity}
              disputeCallback={() => {
                // After a dispute has been made, we may want to collapse.
                const shouldBeOpen =
                  status !== UsageRightsRequestStatus.REJECTED &&
                  (!disputeDate ||
                    (usageRightsRequest.creatorLastUpdateTime &&
                      usageRightsRequest.creatorLastUpdateTime > disputeDate));
                if ((shouldBeOpen && !opened) || (!shouldBeOpen && opened)) {
                  // Toggle to align the should be open state with the open state
                  toggle();
                }
              }}
            />
          </Collapse>
        )}
      </Stack>
    </Timeline.Item>
  );
};

function compareScriptDates(a: Script, b: Script): number {
  if (!a && !b) {
    return 0;
  }
  if (!a) {
    return -11;
  }
  if (!b) {
    return 1;
  }
  let aDate = a.reviewDate || a.submissionDate;
  if (!(aDate instanceof Date)) {
    aDate = fromISODateString(aDate);
  }
  let bDate = b.reviewDate || b.submissionDate;
  if (!(bDate instanceof Date)) {
    bDate = fromISODateString(bDate);
  }
  return aDate.getTime() - bDate.getTime();
}

function compareVideoDraftDates(a: DeliverableVideo, b: DeliverableVideo): number {
  if (!a && !b) {
    return 0;
  }
  if (!a) {
    return -11;
  }
  if (!b) {
    return 1;
  }
  let aDate = a.reviewDate || a.dateCreated;
  if (!(aDate instanceof Date)) {
    aDate = fromISODateString(aDate);
  }
  let bDate = b.reviewDate || b.dateCreated;
  if (!(bDate instanceof Date)) {
    bDate = fromISODateString(bDate);
  }
  return aDate.getTime() - bDate.getTime();
}

const StatusPill = ({ status }: { status: ContractDeliverableStatus }) => {
  let text = "";
  let statusBackgroundColor = "";
  let textColor = "white";
  switch (status) {
    case ContractDeliverableStatus.WAITING_FOR_SCRIPT:
    case ContractDeliverableStatus.SCRIPT_REVISIONS_REQUESTED:
    case ContractDeliverableStatus.WAITING_FOR_VIDEO_DRAFT:
    case ContractDeliverableStatus.VIDEO_DRAFT_REVISIONS_REQUESTED:
    case ContractDeliverableStatus.WAITING_FOR_LIVE_CONTENT:
    case ContractDeliverableStatus.LIVE_CONTENT_REVISIONS_REQUESTED:
      text = "WAITING FOR CREATOR";
      statusBackgroundColor = "#FFF9DB";
      textColor = "yellow.6";
      break;
    case ContractDeliverableStatus.SCRIPT_SUBMITTED:
      text = "REVIEW CONCEPT";
      statusBackgroundColor = "#FFF5F5";
      textColor = "red.6";
      break;
    case ContractDeliverableStatus.VIDEO_DRAFT_SUBMITTED:
      text = "REVIEW VIDEO DRAFT";
      statusBackgroundColor = "#FFF5F5";
      textColor = "red.6";
      break;
    case ContractDeliverableStatus.LIVE_CONTENT_SUBMITTED:
      text = "REVIEW LIVE CONTENT";
      statusBackgroundColor = "#FFF5F5";
      textColor = "red.6";
      break;
    default:
      break;
  }
  return (
    text.length > 0 && (
      <Pill
        c={textColor}
        styles={{
          root: {
            backgroundColor: statusBackgroundColor,
          },
        }}>
        <Text inherit size="10" fw={600}>
          {text}
        </Text>
      </Pill>
    )
  );
};

const UsageRightsStatusPill = ({
  status,
  creatorLastUpdateTime,
  disputeDate,
}: {
  status: UsageRightsRequestStatus;
  creatorLastUpdateTime?: Date;
  disputeDate?: Date;
}) => {
  const brandActionRequired =
    creatorLastUpdateTime && (!disputeDate || disputeDate < creatorLastUpdateTime);
  const revisionsRequested =
    disputeDate && (!creatorLastUpdateTime || disputeDate > creatorLastUpdateTime);

  let text = "";
  let statusBackgroundColor = "";
  let textColor = "white";

  if (status === UsageRightsRequestStatus.REJECTED) {
    text = "REQUEST DENIED";
    statusBackgroundColor = "var(--mantine-color-gray-1";
    textColor = "gray.6";
  } else if (status === UsageRightsRequestStatus.ACCEPTED) {
    text = "APPROVED";
    statusBackgroundColor = "#D0EBFF";
    textColor = "blue.6";
  } else if (revisionsRequested || !creatorLastUpdateTime) {
    text = "WAITING FOR CREATOR";
    statusBackgroundColor = "#FFF9DB";
    textColor = "yellow.6";
  } else if (brandActionRequired) {
    text = "ACTION REQUIRED: APPROVE VIDEO AND CODE";
    statusBackgroundColor = "#FFF5F5";
    textColor = "red.6";
  }

  return (
    text.length > 0 && (
      <Pill
        c={textColor}
        styles={{
          root: {
            backgroundColor: statusBackgroundColor,
          },
        }}>
        <Text inherit size="10" fw={600}>
          {text}
        </Text>
      </Pill>
    )
  );
};

const DEFAULT_OPEN_STATES = new Set([
  ContractDeliverableStatus.SCRIPT_SUBMITTED,
  ContractDeliverableStatus.VIDEO_DRAFT_SUBMITTED,
  ContractDeliverableStatus.LIVE_CONTENT_SUBMITTED,
]);

const BrandDeliverableCard = ({
  creatorName,
  brandName,
  deliverable,
  isFirstDeliverable,
}: {
  creatorName: string;
  brandName: string;
  deliverable: Deliverable;
  isFirstDeliverable: boolean;
}) => {
  const [scripts, setScripts] = useState<Script[]>([]);
  const [videoDrafts, setVideoDrafts] = useState<DeliverableVideo[]>([]);
  const [scriptMessages, setScriptMessages] = useState<Record<string, MessageModel[]>>({});
  const [videoMessages, setVideoMessages] = useState<Record<string, MessageModel[]>>({});
  const [missingReferralLink, setMissingReferralLink] = useState<boolean>(false);
  const [missingPromoCode, setMissingPromoCode] = useState<boolean>(false);
  const [referralLinkState, setReferralLinkState] = useState<string>(null);
  const [promoCodeState, setPromoCodeState] = useState<string>(null);
  const [liveContentActive, setLiveContentActive] = useState<number>(0);
  const [deliverableStatus, setDeliverableStatus] = useState<ContractDeliverableStatus>(
    deliverable.status,
  );

  const [opened, { toggle }] = useDisclosure(
    DEFAULT_OPEN_STATES.has(deliverable.status) || isFirstDeliverable,
  );

  useEffect(() => {
    setMissingReferralLink(deliverable.missingReferralLink);
    setMissingPromoCode(deliverable.missingPromoCode);
    setReferralLinkState(deliverable.referralLinkRedirectUrl);
    setPromoCodeState(deliverable.promoCode);
    const abortController = new AbortController();
    const futures = [];
    futures.push(fetchScriptsForDeliverable(deliverable.id, abortController));
    futures.push(fetchVideosForDeliverable(deliverable.id, abortController));
    Promise.all(futures).then((responsePair) => {
      const [scriptResponse, videoResponse] = responsePair;
      // Handle video responses first, as updates to scripts may need to see how many videos there are
      const { success: videoSuccess, versionedDeliverableVideo } = videoResponse || {
        success: false,
        versionedDeliverableVideo: null,
      };
      if (videoSuccess) {
        const versionedDeliverableVideoObj =
          VersionedDeliverableVideo.deserialize(versionedDeliverableVideo);
        const deliverableVideos = versionedDeliverableVideoObj?.deliverableVideos || [];
        // const deliverableVideos = (versionedDeliverableVideo?.deliverableVideos ||
        //   []) as VideoDraft[];
        setVideoDrafts(deliverableVideos.sort(compareVideoDraftDates));
        const messageResults = deliverableVideos.map((dv) =>
          fetchMessages({
            deliverableId: deliverable.id,
            messageParty: "1",
            videoId: dv.videoId,
            abortController,
          }),
        );
        Promise.all(messageResults).then((responses) => {
          const newVideoMessages = { ...videoMessages };
          responses.forEach((response, idx) => {
            const { success, messages } = response || { success: false, messages: null };
            if (success) {
              const deserializedMessages = messages.map((message: any) =>
                MessageModel.deserialize(message),
              );
              newVideoMessages[deliverableVideos[idx].videoId] = deserializedMessages;
            }
          });
          setVideoMessages(newVideoMessages);
        });
      }
      const { success: scriptSuccess, scripts: responseScripts } = scriptResponse || {
        success: false,
        scriptSuccess: null,
      };
      if (scriptSuccess) {
        setScripts(
          responseScripts
            .map((responseScript: any) => Script.deserialize(responseScript))
            .filter((script: Script) => script.status !== ContentStatus.IN_PROGRESS)
            .sort(compareScriptDates),
        );
        // Fetch and store the messages for each of the script drafts
        const scriptsArr = responseScripts as Script[];
        const messageResults = scriptsArr.map((script) =>
          fetchMessages({
            deliverableId: deliverable.id,
            messageParty: "1",
            scriptId: script.scriptId,
            abortController,
          }),
        );
        Promise.all(messageResults).then((responses) => {
          const newScriptMessages = { ...scriptMessages };
          responses.forEach((response, idx) => {
            const { success, messages } = response || { success: false, messages: null };
            if (success) {
              const deserializedMessages = messages.map((message: any) =>
                MessageModel.deserialize(message),
              );
              newScriptMessages[scriptsArr[idx].scriptId] = deserializedMessages;
            }
          });
          setScriptMessages(newScriptMessages);
        });
      }
      // Calculate the active timeline item
      let newLiveContentActive = 0;
      if (deliverable.liveContentSubmissionDatetime) {
        newLiveContentActive = scripts.length + videoDrafts.length;
      } else if (videoDrafts.length > 0) {
        newLiveContentActive = scripts.length + videoDrafts.length - 1;
      } else if (scripts.length > 0) {
        newLiveContentActive = scripts.length - 1;
      }
      if (scripts.length === 0) {
        newLiveContentActive += 1;
      }
      if (videoDrafts.length === 0) {
        newLiveContentActive += 1;
      }
      setLiveContentActive(newLiveContentActive);
    });
    return () => {
      abortController.abort();
    };
  }, [deliverable]);

  return (
    <Card withBorder radius="md" py="xs" px="lg">
      <Stack w="100%">
        <Group justify="space-between">
          <Group>
            <Chevron opened={opened} toggle={toggle} size="1.25rem" />
            <PlatformIcon platform={deliverable.platform} />
            <Title size={16} fw={600}>
              {makeDeliverableFormatName(deliverable.format?.toUpperCase())}
            </Title>
          </Group>
          <StatusPill status={deliverableStatus} />
        </Group>
        <Collapse in={opened}>
          <Container mb={20}>
            <EditFields
              deliverableId={deliverable.id}
              platform={deliverable.platform}
              format={deliverable.format}
              creatorHandle={deliverable.creatorHandle}
              requiresReferralLink={deliverable.requiresReferralLink}
              requiresPromoCode={deliverable.requiresPromoCode}
              isMissingReferralLink={missingReferralLink}
              isMissingPromoCode={missingPromoCode}
              setIsMissingReferralLink={setMissingReferralLink}
              setIsMissingPromoCode={setMissingPromoCode}
              referralLink={referralLinkState}
              promoCode={promoCodeState}
              setReferralLink={setReferralLinkState}
              setPromoCode={setPromoCodeState}
              disableEditing={false}
            />
          </Container>
          <Timeline active={liveContentActive} lineWidth={1} bulletSize={24} ml={4} color="blue">
            {deliverable.timeline.requiresScriptReview && scripts?.length === 0 && (
              <DummyScriptEntry expectedDate={deliverable.timeline.scriptDate} />
            )}
            {scripts.map((script, idx) => (
              <ScriptEntry
                key={`script-${script.scriptId}`}
                creatorName={creatorName}
                script={script}
                scriptMessages={scriptMessages[script.scriptId] || []}
                onAddMessage={(newMessage: any) => {
                  if (!newMessage) {
                    return;
                  }
                  const sentMessage = MessageModel.deserialize(newMessage);
                  const newScriptMessages = { ...scriptMessages };
                  newScriptMessages[script.scriptId] = [
                    ...scriptMessages[script.scriptId],
                    sentMessage,
                  ];
                  setScriptMessages(newScriptMessages);
                }}
                updateDeliverableStatusCallback={setDeliverableStatus}
                defaultOpen={
                  !deliverable.liveContentSubmissionDatetime &&
                  videoDrafts.length === 0 &&
                  idx === scripts.length - 1
                }
                dueDate={deliverable.nextDueDate}
                isMissingPromoCode={deliverable.missingPromoCode}
                isMissingReferralLink={deliverable.missingReferralLink}
              />
            ))}
            {deliverable.timeline.requiresVideoReview && videoDrafts?.length === 0 && (
              <DummyVideoDraftEntry expectedDate={deliverable.timeline.videoDraftDate} />
            )}
            {videoDrafts.map((videoDraft, idx) => {
              let isOpen = idx === videoDrafts.length - 1;
              if (deliverable.format === SupportedFormat.UGC) {
                isOpen = isOpen && deliverableStatus !== ContractDeliverableStatus.COMPLETE;
              } else {
                isOpen = isOpen && !deliverable.liveContentSubmissionDatetime;
              }
              return (
                <VideoDraftEntry
                  key={`videodraft-${videoDraft.videoId}`}
                  creatorName={creatorName}
                  videoDraft={videoDraft}
                  videoMessages={videoMessages[videoDraft.videoId] || []}
                  onAddMessage={(newMessage: any) => {
                    if (!newMessage) {
                      return;
                    }
                    const sentMessage = MessageModel.deserialize(newMessage);
                    const newVideoMessages = { ...videoMessages };
                    newVideoMessages[videoDraft.videoId] = [
                      ...videoMessages[videoDraft.videoId],
                      sentMessage,
                    ];
                    setVideoMessages(newVideoMessages);
                  }}
                  updateDeliverableStatusCallback={setDeliverableStatus}
                  defaultOpen={isOpen}
                  dueDate={deliverable.nextDueDate}
                  isMissingPromoCode={deliverable.missingPromoCode}
                  isMissingReferralLink={deliverable.missingReferralLink}
                />
              );
            })}
            {}
            {deliverable.format !== SupportedFormat.UGC && (
              <LiveTimelineItem
                deliverable={deliverable}
                deliverableStatus={deliverableStatus}
                setDeliverableStatus={setDeliverableStatus}
                brandName={brandName}
                defaultOpen={!!deliverable.liveContentSubmissionDatetime}
              />
            )}
          </Timeline>
        </Collapse>
      </Stack>
    </Card>
  );
};

const BrandUsageRightsContractCard = ({
  usageRightsRequest,
}: {
  usageRightsRequest: UsageRightsRequestDetails;
}) => {
  const [status, setStatus] = useState<UsageRightsRequestStatus>(usageRightsRequest.status);
  const [disputeReason, setDisputeReason] = useState<string>(usageRightsRequest.disputeReason);
  const [disputeDate, setDisputeDate] = useState<Date>(usageRightsRequest.reviewDate);

  const [opened, { toggle }] = useDisclosure(true);

  return (
    <Card withBorder radius="md" py="xs" px="lg">
      <Stack w="100%">
        <Group justify="space-between">
          <Group>
            <Chevron opened={opened} toggle={toggle} size="1.25rem" />
            <PlatformIcon platform={usageRightsRequest.platform} />
            <Title size={16} fw={600}>
              Usage Rights Request
              {/* {makeDeliverableFormatName(deliverable.format?.toUpperCase())} */}
            </Title>
          </Group>
          <UsageRightsStatusPill
            status={status}
            disputeDate={disputeDate}
            creatorLastUpdateTime={usageRightsRequest.creatorLastUpdateTime}
          />
        </Group>
        <Collapse in={opened}>
          <Timeline active={1} lineWidth={1} bulletSize={24} ml={4} color="blue">
            <UsageRightsContractTimelineItem
              usageRightsRequest={usageRightsRequest}
              status={status}
              setStatus={setStatus}
              disputeReason={disputeReason}
              setDisputeReason={setDisputeReason}
              disputeDate={disputeDate}
              setDisputeDate={setDisputeDate}
            />
          </Timeline>
        </Collapse>
      </Stack>
    </Card>
  );
};

const ProductAccessInput = ({
  contractId,
  signatureFirstName,
  signatureLastName,
  productAccessDescription,
  productAccessBrandOutput,
  productAccessCreatorInput,
  productAccessInfoNeededFromCreator,
  productAccessInfoToProvideToCreator,
  productAccessOtherInfoNeededFromCreator,
  productAccessOtherInfoToProvideToCreator,
  productAccessStatus,
}: {
  contractId: string;
  signatureFirstName: string;
  signatureLastName: string;
  productAccessDescription: string;
  productAccessInfoNeededFromCreator: ProductAccessInfoNeededFromCreatorType;
  productAccessInfoToProvideToCreator: ProductAccessInfoToProvideToCreatorType;
  productAccessOtherInfoNeededFromCreator: string;
  productAccessOtherInfoToProvideToCreator: string;
  productAccessCreatorInput: string;
  productAccessBrandOutput: string;
  productAccessStatus: ProductAccessStatus;
}) => {
  const [brandOutput, setBrandOutput] = useState<string>(productAccessBrandOutput);
  const [status, setStatus] = useState<ProductAccessStatus>(productAccessStatus);
  const waitingForCreator =
    [
      ProductAccessStatus.CREATOR_ACTION_REQUIRED,
      ProductAccessStatus.CREATOR_RESUBMIT_REQUIRED,
    ].indexOf(status) > -1;
  const isComplete = status === ProductAccessStatus.COMPLETED;

  return (
    <CollapsibleCard
      isOpen={!isComplete}
      header={
        <Group justify="space-between">
          <Flex align="center" wrap="nowrap" gap="sm">
            <IconGift />
            <Text span>
              Send Product
              {productAccessDescription ? ` - ${productAccessDescription}` : ""}
            </Text>
          </Flex>
          <Flex justify="right" align="center" gap="xs">
            <ProductAccessBadge productAccessStatus={status} />
          </Flex>
        </Group>
      }
      controls={null}
      content={
        [ProductAccessStatus.COMPLETED, ProductAccessStatus.BRAND_ACTION_REQUIRED].indexOf(status) >
        -1 ? (
          <Card withBorder radius="md" py="sm" px="lg">
            <Stack gap={4}>
              <CreatorInputComponent
                productAccessInfoNeededFromCreator={productAccessInfoNeededFromCreator}
                signatureFirstName={signatureFirstName}
                signatureLastName={signatureLastName}
                productAccessCreatorInput={productAccessCreatorInput}
                productAccessOtherInfoNeededFromCreator={productAccessOtherInfoNeededFromCreator}
                isComplete={isComplete}
              />
              <BrandInputResult
                contractHashId={contractId}
                signatureFirstName={signatureFirstName}
                signatureLastName={signatureLastName}
                status={status}
                setStatus={setStatus}
                productAccessInfoNeededFromCreator={productAccessInfoNeededFromCreator}
                productAccessInfoToProvideToCreator={productAccessInfoToProvideToCreator}
                productAccessOtherInfoToProvideToCreator={productAccessOtherInfoToProvideToCreator}
                productAccessBrandOutput={brandOutput}
                setProductAccessBrandOutput={setBrandOutput}
              />
            </Stack>
          </Card>
        ) : null
      }
      disabledCollapse={waitingForCreator}
    />
  );
};

const ProductAccessSection = ({
  hasProductAccess,
  contractId,
  signatureFirstName,
  signatureLastName,
  productAccessDescription,
  productAccessInfoNeededFromCreator,
  productAccessInfoToProvideToCreator,
  productAccessOtherInfoNeededFromCreator,
  productAccessOtherInfoToProvideToCreator,
  productAccessCreatorInput,
  productAccessBrandOutput,
  productAccessStatus,
}: {
  hasProductAccess: boolean;
  contractId: string;
  signatureFirstName: string;
  signatureLastName: string;
  productAccessDescription: string;
  productAccessInfoNeededFromCreator: ProductAccessInfoNeededFromCreatorType;
  productAccessInfoToProvideToCreator: ProductAccessInfoToProvideToCreatorType;
  productAccessOtherInfoNeededFromCreator: string;
  productAccessOtherInfoToProvideToCreator: string;
  productAccessCreatorInput: string;
  productAccessBrandOutput: string;
  productAccessStatus: ProductAccessStatus;
}) => {
  if (!hasProductAccess) {
    return null;
  }

  return (
    <Stack>
      <Title size={20} fw={600}>
        Product Access
      </Title>
      <ProductAccessInput
        contractId={contractId}
        signatureFirstName={signatureFirstName}
        signatureLastName={signatureLastName}
        productAccessDescription={productAccessDescription}
        productAccessInfoNeededFromCreator={productAccessInfoNeededFromCreator}
        productAccessInfoToProvideToCreator={productAccessInfoToProvideToCreator}
        productAccessOtherInfoNeededFromCreator={productAccessOtherInfoNeededFromCreator}
        productAccessOtherInfoToProvideToCreator={productAccessOtherInfoToProvideToCreator}
        productAccessCreatorInput={productAccessCreatorInput}
        productAccessBrandOutput={productAccessBrandOutput}
        productAccessStatus={productAccessStatus}
      />
    </Stack>
  );
};

const BrandContract = () => {
  const [contractType, setContractType] = useState<ContractType>(ContractType.INFLUENCER);
  const [brandName, setBrandName] = useState("");
  const [adGroupId, setAdGroupId] = useState<number>(null);
  const [adGroupName, setAdGroupName] = useState<string>(null);
  const [campaignHash, setCampaignHash] = useState<number>(null);
  const [campaignName, setCampaignName] = useState<string>(null);
  const [creatorFirstName, setCreatorFirstName] = useState<string>(null);
  const [signatureFirstName, setSignatureFirstName] = useState<string>("");
  const [signatureLastName, setSignatureLastName] = useState<string>("");
  const [creativeBriefUrl, setCreativeBriefUrl] = useState<string>("");
  const [contractAmountInMinorUnits, setContractAmountInMinorUnits] = useState<number>(0);
  const [bonusAmountInMinorUnits, setBonusAmountInMinorUnits] = useState<number>(0);
  const [earnedBonusAmountInMinorUnits, setEarnedBonusAmountInMinorUnits] = useState<number>(0);
  const [eligibleForBonus, setEligibleForBonus] = useState<boolean>(false);
  const [meetsBonusCondition, setMeetsBonusCondition] = useState<boolean>(false);
  const [paymentStatus, setPaymentStatus] = useState<number>(0);
  const [bonusPaymentStatus, setBonusPaymentStatus] = useState<number>(0);
  const [productAccessNeeded, setProductAccessNeeded] = useState<boolean>(false);
  const [productAccessDescription, setProductAccessDescription] = useState<string>(null);
  const [productAccessBrandOutput, setProductAccessBrandOutput] = useState<string>(null);
  const [productAccessCreatorInput, setProductAccessCreatorInput] = useState<string>(null);
  const [productAccessInfoNeededFromCreator, setProductAccessInfoNeededFromCreator] =
    useState<number>();
  const [productAccessInfoToProvideToCreator, setProductAccessInfoToProvideToCreator] =
    useState<number>();
  const [productAccessOtherInfoNeededFromCreator, setProductAccessOtherInfoNeededFromCreator] =
    useState<string>(null);
  const [productAccessOtherInfoToProvideToCreator, setProductAccessOtherInfoToProvideToCreator] =
    useState<string>(null);
  const [productAccessStatus, setProductAccessStatus] = useState<number>();
  const [deliverables, setDeliverables] = useState<Deliverable[]>([]);
  const [sortOrder, setSortOrder] = useState<number[]>([]);
  const [usageRightsRequest, setUsageRightsRequest] = useState<UsageRightsRequestDetails>();

  const user: User = useOutletContext<AuthContext>()?.user;
  const { contractId } = useParams();

  useEffect(() => {
    const abortController = new AbortController();
    getDeliverablesViewData(user, contractId, abortController).then((response) => {
      const { success, contract } = response || { success: false, contract: null };
      if (!success) {
        return;
      }
      setContractType(contract.contractType);
      setBrandName(contract.brandName);
      setAdGroupId(contract.adGroupId);
      setAdGroupName(contract.adGroupName);
      setCampaignHash(contract.campaignHash);
      setCampaignName(contract.campaignName);
      setCreatorFirstName(contract.firstName);
      setSignatureFirstName(contract.signatureFirstName);
      setSignatureLastName(contract.signatureLastName);
      setCreativeBriefUrl(contract.creativeBriefUrl);
      setContractAmountInMinorUnits(contract.contractAmountInMinorUnits);
      setBonusAmountInMinorUnits(contract.bonusAmountInMinorUnits);
      setEarnedBonusAmountInMinorUnits(contract.earnedBonusAmountInMinorUnits);
      setEligibleForBonus(!!contract.bonusCondition && contract.bonusCondition.length > 0);
      setMeetsBonusCondition(contract.meetsBonusCondition);
      setPaymentStatus(contract.paymentStatus);

      setProductAccessNeeded(contract.hasProductAccess);
      setProductAccessDescription(contract.productAccessDescription);
      setProductAccessBrandOutput(contract.productAccessBrandOutput);
      setProductAccessCreatorInput(contract.productAccessCreatorInput);
      setProductAccessInfoNeededFromCreator(contract.productAccessInfoNeededFromCreator);
      setProductAccessInfoToProvideToCreator(contract.productAccessInfoToProvideToCreator);
      setProductAccessOtherInfoNeededFromCreator(contract.productAccessOtherInfoNeededFromCreator);
      setProductAccessOtherInfoToProvideToCreator(
        contract.productAccessOtherInfoToProvideToCreator,
      );
      setProductAccessStatus(contract.productAccessStatus);
      setBonusPaymentStatus(contract.bonusPaymentStatus);

      // Deserialize Deliverables
      const deserializedDeliverables = (contract.deliverables as []).map((deliverable) =>
        Deliverable.deserialize(deliverable),
      );
      const deliverableSortOrder = getSortOrder(deserializedDeliverables, true);

      setDeliverables(deserializedDeliverables);
      setSortOrder(deliverableSortOrder);

      const deserializedUsageRightsRequest = contract.usageRightsRequest
        ? deserializeUsageRightsRequest(contract.usageRightsRequest)
        : null;
      if (deserializeUsageRightsRequest) {
        setUsageRightsRequest(deserializedUsageRightsRequest);
      }
    });
    return () => {
      abortController.abort();
    };
  }, [contractId]);

  const sortedDeliverables = sortOrder.map((sortIndex) => deliverables[sortIndex]);

  return (
    <Stack p="xl">
      <Notifications />
      <BrandContractHeader
        brandName={brandName}
        signatureFirstName={signatureFirstName}
        signatureLastName={signatureLastName}
        creatorFirstName={creatorFirstName}
        campaignId={campaignHash}
        campaignName={campaignName}
        adGroupId={adGroupId}
        adgroupName={adGroupName}
        amount={contractAmountInMinorUnits}
        bonusAmount={bonusAmountInMinorUnits}
        earnedBonusAmount={earnedBonusAmountInMinorUnits}
        eligibleForBonus={eligibleForBonus}
        meetsBonusCondition={meetsBonusCondition}
        paymentStatus={paymentStatus}
        bonusPaymentStatus={bonusPaymentStatus}
        creativeBriefUrl={creativeBriefUrl}
      />
      <ProductAccessSection
        hasProductAccess={productAccessNeeded}
        contractId={contractId}
        signatureFirstName={signatureFirstName}
        signatureLastName={signatureLastName}
        productAccessDescription={productAccessDescription}
        productAccessBrandOutput={productAccessBrandOutput}
        productAccessCreatorInput={productAccessCreatorInput}
        productAccessInfoNeededFromCreator={productAccessInfoNeededFromCreator}
        productAccessInfoToProvideToCreator={productAccessInfoToProvideToCreator}
        productAccessOtherInfoNeededFromCreator={productAccessOtherInfoNeededFromCreator}
        productAccessOtherInfoToProvideToCreator={productAccessOtherInfoToProvideToCreator}
        productAccessStatus={productAccessStatus}
      />
      <Title size={20} fw={600}>
        Deliverables
      </Title>
      {contractType === ContractType.USAGE_RIGHTS ? (
        <BrandUsageRightsContractCard usageRightsRequest={usageRightsRequest} />
      ) : (
        <Stack mt="sm" gap="sm">
          {sortedDeliverables.map((deliverable, idx) => (
            <BrandDeliverableCard
              key={deliverable.id}
              creatorName={
                signatureFirstName?.length > 0 || signatureLastName?.length > 0
                  ? `${signatureFirstName} ${signatureLastName}`
                  : creatorFirstName
              }
              brandName={brandName}
              deliverable={deliverable}
              isFirstDeliverable={idx === 0}
            />
          ))}
        </Stack>
      )}
    </Stack>
  );
};

export default BrandContract;
