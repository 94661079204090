import React, { useState } from "react";

import { Card, Flex, Stack, Text, ThemeIcon } from "@mantine/core";

import { IconAlertCircle } from "@tabler/icons-react";

import { markMessagesRead } from "components/contracts/tasks/api/Api";

import { Task } from "components/contracts/tasks/models/Task";
import { TaskStage } from "components/contracts/tasks/models/Common";
import { toPSTDateTimeStringNoYear } from "utils/DateUtils";

import TaskCardHeader from "components/contracts/tasks/cards/TaskCardHeader";
import TaskCardContent from "components/contracts/tasks/content/TaskCardContent";
import ProductAccessCard from "components/contracts/tasks/cards/ProductAccessCard";
import ContractOfferReviewCard from "components/contracts/tasks/cards/ContractOfferReviewCard";
import ContractReviewCard from "components/contracts/tasks/cards/ContractReviewCard";

function AutoApproveNotification({
  autoApprove,
  deadline,
}: {
  autoApprove: boolean;
  deadline: Date;
}) {
  if (!autoApprove) {
    return null;
  }

  return (
    <Card bg="var(--mantine-color-red-light)" p={4}>
      <Flex gap={4} align="center" justify="center">
        <ThemeIcon color="red" radius="xl" variant="transparent">
          <IconAlertCircle size="1.2rem" />
        </ThemeIcon>
        <Text fw="700" size="sm" c="red">
          If no action is taken, this content will be auto-approved on{" "}
          {toPSTDateTimeStringNoYear(deadline)}.
        </Text>
      </Flex>
    </Card>
  );
}

export default function TaskCard({
  taskType,
  task,
  handleCompleteTask,
  isCompletedTask,
  showAdminOptions,
  decrementUnreadMessageCount,
}: {
  taskType: TaskStage;
  task: Task;
  handleCompleteTask: (requireCreatorAction?: boolean) => void;
  decrementUnreadMessageCount?: () => void;
  isCompletedTask?: boolean;
  showAdminOptions?: boolean;
}) {
  // These Tasks are self-contained and don't have headers.
  if (taskType === TaskStage.PRODUCT_ACCESS) {
    return <ProductAccessCard 
        taskId={task.hashId}
        displayName={task.displayName}
        productAccess={task.productAccess}
        deadline={task.deadline}
        handleCompleteTask={handleCompleteTask}
      />
  }
  
  if (taskType === TaskStage.CONTRACT_REVIEW) {
    return (
      <ContractReviewCard
        user={null}
        primaryTask={task}
        taskType={taskType}
        handleCompleteTask={handleCompleteTask}
        decrementUnreadMessageCount={decrementUnreadMessageCount}
        isCompletedTask={isCompletedTask}
      />
    );
  }

  if (taskType === TaskStage.CONTRACT_OFFER_REVIEW) {
    return (
      <ContractOfferReviewCard
        user={null}
        primaryTask={task}
        taskType={taskType}
        handleCompleteTask={handleCompleteTask}
        decrementUnreadMessageCount={decrementUnreadMessageCount}
        isCompletedTask={isCompletedTask}
      />
    );
  }

  const [numUnreadMessages, setNumUnreadMessages] = useState(task.numUnreadMessages);
  const [messagesLastRead, setMessagesLastRead] = useState(task.brandMessagesLastRead);

  const clearUnreadMessages = () => {
    if (numUnreadMessages > 0) {
      decrementUnreadMessageCount?.();
    }
    setNumUnreadMessages(0);
    setMessagesLastRead(new Date());
    markMessagesRead({ taskId: task.hashId });
  };

  return (
    <Card radius="md" mt="sm" withBorder>
      <Stack gap="xs">
        <AutoApproveNotification autoApprove={task.enableAutoApprove} deadline={task.deadline} />
        <TaskCardHeader
          deliverableFormat={task.deliverable.format}
          platform={task.deliverable.platform}
          deadline={task.deadline}
          taskType={taskType}
          referralLinkStatus={task.referralLinkStatus}
          promoCodeStatus={task.promoCodeStatus}
          numUnreadMessages={numUnreadMessages}
          waitingForCreator={isCompletedTask}
        />
        <TaskCardContent
          taskType={taskType}
          task={task}
          handleCompleteTask={handleCompleteTask}
          isCompletedTask={isCompletedTask}
          showAdminOptions={showAdminOptions}
          clearUnreadMessages={clearUnreadMessages}
          messagesLastRead={messagesLastRead}
        />
      </Stack>
    </Card>
  );
}
