import React from "react";
import { Center, Container, Flex, Group, Loader, Stack, Text, Title } from "@mantine/core";

import { CampaignMetrics } from "components/campaign/dashboard/CampaignMetrics";
import { useCampaignAppContext } from "campaigns/CampaignAppShell";
import CampaignTodoList from "campaigns/main/campaignPage/CampaignTodoList";
import CampaignOverview from "campaigns/main/campaignPage/CampaignOverview";
import { ReferralLinkType } from "models/Campaign";
import { CAMPAIGN_PORTAL } from "campaigns/PathConstants";
import { useMatch, useNavigate } from "react-router-dom";
import CampaignsDropdown from "components/campaign/CampaignsDropdown";
import Spacer from "components/Spacer";
import { IconInfoCircle } from "@tabler/icons-react";
import ProductGuideLink from "campaigns/create/common/ProductGuideLink";

const CampaignResults = () => {
  const navigate = useNavigate();
  const isMetricsAllSelected = useMatch(`${CAMPAIGN_PORTAL}/metrics`) != null;
  const { selectedCampaign, selectedAdGroup } = useCampaignAppContext();

  // alert("foobar");
  if (!selectedCampaign?.hash_id && !isMetricsAllSelected) {
    return (
      <Container fluid h="100vh">
        <Center h="100%">
          <Loader color="blue" />
        </Center>
      </Container>
    );
  }

  return (
    <>
      <Stack style={{ gap: 24 }}>
        <Title order={2}>Metrics</Title>
        <Group
          bg="white"
          align="flex-start"
          pl="24px"
          pr="24px"
          pt="16px"
          pb="16px"
          style={{ flexWrap: "nowrap", borderRadius: 16 }}>
          <IconInfoCircle
            size={24}
            style={{
              flexShrink: 0,
            }}
          />
          <Text size="sm">
            Metrics include all verified videos. Live videos that have not yet been verified are not
            included. Videos are attributed to the date they were posted. Spend numbers include
            platform fees and are approximate. Visit our <ProductGuideLink /> for more details.
          </Text>
        </Group>
        <CampaignsDropdown
          onCampaignSelected={(campaignHashIdString) => {
            if (campaignHashIdString) {
              navigate(`${CAMPAIGN_PORTAL}/${campaignHashIdString}`);
            } else {
              navigate(`${CAMPAIGN_PORTAL}/metrics`);
            }
          }}
          selectedCampaignHashId={selectedCampaign?.hash_id}
        />
      </Stack>
      <Spacer height={24} />
      {selectedCampaign ? (
        <Flex direction="column" gap={12}>
          <CampaignTodoList campaign={selectedCampaign} />
          {/* <CampaignCreatorProgress campaign={selectedCampaign} /> */}
          <CampaignOverview
            campaign={selectedCampaign}
            adGroup={selectedAdGroup}
            tracksClicks={
              (selectedCampaign.referral_link_type ?? ReferralLinkType.NONE) !==
              ReferralLinkType.NONE
            }
          />
          <CampaignMetrics
            campaignId={selectedCampaign?.hash_id}
            adGroupId={selectedAdGroup != null ? selectedAdGroup.id : null}
            showMetrics
            campaignTracksClicks={
              (selectedCampaign.referral_link_type ?? ReferralLinkType.NONE) !==
              ReferralLinkType.NONE
            }
          />
        </Flex>
      ) : null}
    </>
  );
};

export default CampaignResults;
