import { Select } from "@mantine/core";
import { CAMPAIGN_PORTAL } from "campaigns/PathConstants";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "reduxStore/hooks";

const CampaignsDropdown = ({
  onCampaignSelected,
  selectedCampaignHashId,
}: {
  onCampaignSelected: (campaignHashIdString: string) => void;
  selectedCampaignHashId: number | null;
}) => {
  const navigate = useNavigate();
  const campaigns = useAppSelector((state) => state.campaigns.allCampaigns);

  const campaignsData = Object.entries(campaigns).map(([campaignId, campaign]) => {
    return {
      value: campaign.hash_id.toString(),
      label: campaign.title,
    };
  });

  return (
    <Select
      searchable
      label="Campaign"
      data={campaignsData}
      placeholder="Select campaign"
      value={selectedCampaignHashId?.toString() || null}
      clearable
      onChange={(_value, option) => {
        onCampaignSelected(_value);
      }}
      style={{
        flexShrink: 0,
        flexGrow: 1,
      }}
    />
  );
};

export default CampaignsDropdown;
