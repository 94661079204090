import { CAMPAIGN_PATH, CAMPAIGN_PORTAL } from "campaigns/PathConstants";
import React, { useEffect, useState } from "react";
import { useNavigate, useMatch } from "react-router-dom";
import NavBarButton from "campaigns/navbar/v2/buttons/components/NavBarButton";
import {
  IconFileInvoice,
  IconList,
  IconSpeakerphone,
  IconUser,
  IconVocabulary,
} from "@tabler/icons-react";

const CampaignsNavBarButton = ({ showMinimizedVersion }: { showMinimizedVersion: boolean }) => {
  // navigator
  const navigate = useNavigate();

  const isViewCampaignsSelected = useMatch(`${CAMPAIGN_PORTAL}/overview`) != null;
  const isCreativeBriefsSelected = useMatch(`${CAMPAIGN_PORTAL}/creative_briefs`) != null;
  const isCreatorProgressSelected = useMatch(`${CAMPAIGN_PORTAL}/creator_progress/all`) != null;
  const isSpecificCreatorProgressSelected =
    useMatch(`${CAMPAIGN_PORTAL}/${CAMPAIGN_PATH}/creator_progress`) != null;
  const isContractsSelected = useMatch(`${CAMPAIGN_PORTAL}/contracts_overview`) != null;
  const isContractsTabsSelected =
    useMatch(`${CAMPAIGN_PORTAL}/contracts_overview/:activeTab`) != null;
  const isChildrenRouteMatched =
    isViewCampaignsSelected ||
    isCreativeBriefsSelected ||
    isContractsSelected ||
    isContractsTabsSelected ||
    isCreatorProgressSelected ||
    isSpecificCreatorProgressSelected;

  const [isExpanded, setIsExpanded] = useState<boolean>(isChildrenRouteMatched);

  useEffect(() => {
    // If I’m in the collapsed nav menu state and I navigate to a subpage, and then I expand the menu,
    // it’d be great for the page that I’m currently on to be expanded
    if (!showMinimizedVersion && isChildrenRouteMatched) {
      setIsExpanded(true);
    }
  }, [showMinimizedVersion]);

  const childButtons = (
    <>
      <NavBarButton
        isSelected={isViewCampaignsSelected}
        LeftIconName={IconList}
        onClick={() => {
          navigate(`${CAMPAIGN_PORTAL}/overview`);
        }}
        title="View Campaigns"
        variant={showMinimizedVersion ? "secondaryHover" : "secondary"}
      />
      <NavBarButton
        isSelected={isCreativeBriefsSelected}
        LeftIconName={IconVocabulary}
        onClick={() => {
          navigate(`${CAMPAIGN_PORTAL}/creative_briefs`);
        }}
        title="Creative Briefs"
        variant={showMinimizedVersion ? "secondaryHover" : "secondary"}
      />
      <NavBarButton
        isSelected={isCreatorProgressSelected || isSpecificCreatorProgressSelected}
        LeftIconName={IconUser}
        onClick={() => {
          navigate(`${CAMPAIGN_PORTAL}/creator_progress/all`);
        }}
        title="Creator Progress"
        variant={showMinimizedVersion ? "secondaryHover" : "secondary"}
      />
      <NavBarButton
        isSelected={isContractsSelected || isContractsTabsSelected}
        LeftIconName={IconFileInvoice}
        onClick={() => {
          navigate(`${CAMPAIGN_PORTAL}/contracts_overview`);
        }}
        title="Contracts"
        variant={showMinimizedVersion ? "secondaryHover" : "secondary"}
      />
    </>
  );
  return (
    <>
      <NavBarButton
        hoverChildren={childButtons}
        isExpanded={isExpanded}
        isSelected={showMinimizedVersion && isChildrenRouteMatched}
        LeftIconName={IconSpeakerphone}
        onClick={() => {
          if (!showMinimizedVersion) {
            setIsExpanded(!isExpanded);
          }
        }}
        showExpansionToggle
        showMinimizedVersion={showMinimizedVersion}
        title="Campaigns"
      />
      {isExpanded && !showMinimizedVersion ? childButtons : null}
    </>
  );
};

export default CampaignsNavBarButton;
