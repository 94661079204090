import {
  ActionIcon,
  Anchor,
  Drawer,
  Flex,
  Group,
  Pill,
  Popover,
  Stack,
  Table,
  Title,
  Tooltip,
} from "@mantine/core";

import { IconDownload, IconEdit } from "@tabler/icons-react";

import {
  fetchCampaignOverview,
  getBrandMetricsCsv,
  getCampaignAdGroupOverallStats,
  translateCampaignProgressResponse,
} from "campaigns/api/fetchCampaignOverview";
import { SpendBar } from "components/campaign/dashboard/CampaignOverviewComponents";
import {
  BudgetType,
  Campaign,
  CampaignAdGroupOverview,
  CampaignProgressReportResponse,
  CampaignStatus,
  ReferralLinkType,
} from "models/Campaign";
import React, { useEffect, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { useAppSelector } from "reduxStore/hooks";
import { formatCurrencyAmount, getAbbreviatedNumber } from "utils/AnalyticsUtils";
import EditBudget from "campaigns/budget/EditBudget";
import { ExportDropdownContents } from "campaigns/main/campaignPage/ExportDropdownContents";
import { useClickOutside, useDisclosure } from "@mantine/hooks";
import { CAMPAIGN_PATH, CAMPAIGN_PORTAL } from "campaigns/PathConstants";
import CreateCampaign from "campaigns/create/CreateCampaign";

const CampaignStatusPill = ({ campaignStatus }: { campaignStatus: CampaignStatus }) => {
  let pillColor = "var(--mantine-color-gray-0)";
  let textColor = "var(--mantine-color-gray-6)";
  switch (campaignStatus) {
    case CampaignStatus.Active:
      pillColor = "var(--mantine-color-green-0)";
      textColor = "var(--mantine-color-green-6)";
      break;
    case CampaignStatus.Onboarding:
      pillColor = "var(--mantine-color-yellow-0)";
      textColor = "var(--mantine-color-yellow-6)";
      break;
    case CampaignStatus.Paused:
      pillColor = "var(--mantine-color-orange-0)";
      textColor = "var(--mantine-color-orange-6)";
      break;
    case CampaignStatus.Completed:
      pillColor = "var(--mantine-color-blue-0)";
      textColor = "var(--mantine-color-blue-6)";
      break;
    default:
      break;
  }
  return (
    <Pill
      styles={{
        root: {
          backgroundColor: pillColor,
        },
        label: {
          color: textColor,
        },
      }}>
      {CampaignStatus[campaignStatus]}
    </Pill>
  );
};

const CampaignRow = ({
  campaign,
  hasSomeClickCampaign,
  onEditClick,
  showEdit,
}: {
  campaign: Campaign;
  hasSomeClickCampaign: boolean;
  onEditClick: (campaign: Campaign) => void;
  showEdit: boolean;
}) => {
  const navigate = useNavigate();
  const [campaignOverview, setCampaignOverview] = useState<CampaignProgressReportResponse | null>(
    null,
  );
  const [adGroupOverviews, setAdGroupOverviews] = useState<CampaignAdGroupOverview[] | null>(null);

  useEffect(() => {
    const abortController = new AbortController();

    // reset on campaign change so we don't see the previous campaign's content while new campaigns overview is loading
    setCampaignOverview(null);
    setAdGroupOverviews(null);

    fetchCampaignOverview(campaign.hash_id, abortController).then((campaignProgress) => {
      setCampaignOverview(campaignProgress);
      setAdGroupOverviews(translateCampaignProgressResponse(campaignProgress));
    });

    return () => {
      abortController.abort();
    };
  }, [campaign.hash_id]);

  // Overall
  const overallStats = getCampaignAdGroupOverallStats(adGroupOverviews || []);
  const {
    totalBudgetSpent,
    totalBudgetAllocated,
    monthlyBudgetSpent,
    monthlyBudgetAllocated,
    numLiveContent,
    views,
    cpm,
    clicks,
    cpc,
  } = overallStats;

  return (
    <Table.Tr key={campaign.hash_id}>
      <Table.Td colSpan={2}>
        {showEdit ? (
          <Group
            style={{
              gap: "8px",
              flexWrap: "nowrap",
              width: "100%",
              display: "inline-flex",
            }}>
            <ActionIcon
              component="div"
              variant="subtle"
              color="gray"
              onClick={() => {
                // navigate(`${CAMPAIGN_PORTAL}/${campaign.hash_id}/edit`);
                onEditClick(campaign);
              }}
              h="16px"
              w="16px">
              <IconEdit />
            </ActionIcon>
            <Anchor
              size="sm"
              onClick={() => {
                navigate(`/campaigns/${campaign.hash_id}/`);
              }}>
              {campaign.title}
            </Anchor>
          </Group>
        ) : (
          <Anchor
            size="sm"
            onClick={() => {
              navigate(`/campaigns/${campaign.hash_id}/`);
            }}>
            {campaign.title}
          </Anchor>
        )}
      </Table.Td>
      <Table.Td>
        <CampaignStatusPill campaignStatus={campaign.status ?? CampaignStatus.Unknown} />
      </Table.Td>
      <Table.Td colSpan={2}>
        <Flex gap="xs" justify="space-between" align="center">
          {campaign.current_budget
            ? `${formatCurrencyAmount(campaign.current_budget)} (${
                BudgetType[campaign.budget_type ?? BudgetType.Once]
              })`
            : "-"}
          <EditBudget campaignHashId={campaign.hash_id} campaignStatus={campaign.status} />
        </Flex>
      </Table.Td>
      <Table.Td colSpan={2}>
        {campaignOverview && campaign.current_budget ? (
          <SpendBar
            budget={100 * campaignOverview.budget}
            spent={campaignOverview.budget_type > 0 ? monthlyBudgetSpent : totalBudgetSpent}
            allocated={
              campaignOverview.budget_type > 0 ? monthlyBudgetAllocated : totalBudgetAllocated
            }
          />
        ) : (
          "-"
        )}
      </Table.Td>
      <Table.Td>{getAbbreviatedNumber(views)}</Table.Td>
      <Table.Td>{cpm ? `${formatCurrencyAmount(cpm)}` : "None"}</Table.Td>
      {hasSomeClickCampaign ? (
        <Table.Td>
          {campaign.referral_link_type === ReferralLinkType.NONE
            ? "N/A"
            : getAbbreviatedNumber(clicks)}
        </Table.Td>
      ) : undefined}
      {hasSomeClickCampaign ? (
        <Table.Td>
          {cpc && campaign.referral_link_type !== ReferralLinkType.NONE
            ? `${formatCurrencyAmount(cpc)}`
            : "None"}
        </Table.Td>
      ) : undefined}
      <Table.Td>{numLiveContent}</Table.Td>
    </Table.Tr>
  );
};

const AllCampaignsOverview = ({ showEdit }: { showEdit: boolean }) => {
  const navigate = useNavigate();
  const campaigns = useAppSelector((state) => state.campaigns.allCampaigns);
  const [downloading, setDownloading] = useState(false);
  // const [exportDate, setExportDate] = useState<Date | null>(null);

  const campaignEditPathMatch = useMatch(`${CAMPAIGN_PORTAL}/${CAMPAIGN_PATH}/edit`);
  const isEditingCampaignMatch =
    campaignEditPathMatch != null &&
    !Number.isNaN(parseInt(campaignEditPathMatch.params.campaignId, 10));

  const [exportMenuIsOpen, { close: closeExportMenu, open: openExportMenu }] = useDisclosure(false);
  const [editCampaignDrawerIsOpen, { close: closeEditCampaign, open: openEditCampaign }] =
    useDisclosure(isEditingCampaignMatch);
  const [editingCampaignHashId, setEditingCampaignHashId] = useState<number | null>(
    isEditingCampaignMatch ? parseInt(campaignEditPathMatch.params.campaignId, 10) : null,
  );

  const ref = useClickOutside(() => {
    // setExportDate(null);
    closeExportMenu();
  }, ["mouseup", "touchend", "keydown"]);

  let hasSomeClickCampaign = false;
  Object.entries(campaigns).forEach(([s, campaign]: [string, Campaign]) => {
    if (campaign.referral_link_type !== ReferralLinkType.NONE) {
      hasSomeClickCampaign = true;
    }
  });

  return (
    <>
      <Stack
        style={{
          "--stack-gap": "16px",
          borderRadius: 24,
          backgroundColor: "var(--mantine-color-white)",
        }}
        p={24}>
        <Flex justify="space-between">
          <Title size="h4">Campaign Overview</Title>
          <Popover withArrow opened={exportMenuIsOpen} width={300}>
            <Popover.Target>
              <Tooltip label="Export Metrics" disabled={exportMenuIsOpen}>
                <ActionIcon
                  variant="subtle"
                  onClick={() => {
                    if (exportMenuIsOpen) {
                      // setExportDate(null);
                      closeExportMenu();
                    } else {
                      openExportMenu();
                    }
                  }}
                  loading={downloading}>
                  <IconDownload size="1.3rem" />
                </ActionIcon>
              </Tooltip>
            </Popover.Target>
            <Popover.Dropdown ref={ref}>
              <ExportDropdownContents
                exportAction={(forContracts, forDeliverables, forDate) => {
                  // setExportDate(null);
                  setDownloading(true);
                  if (forDate) {
                    getBrandMetricsCsv(forContracts, forDeliverables, forDate).finally(() =>
                      setDownloading(false),
                    );
                  } else {
                    getBrandMetricsCsv(forContracts, forDeliverables, null).finally(() =>
                      setDownloading(false),
                    );
                  }
                  closeExportMenu();
                }}
              />
            </Popover.Dropdown>
          </Popover>
        </Flex>
        <Table stickyHeader style={{ tableLayout: "fixed" }}>
          <Table.Thead>
            <Table.Tr>
              <Table.Th colSpan={2}>Name</Table.Th>
              <Table.Th>Status</Table.Th>
              <Table.Th colSpan={2}>Budget</Table.Th>
              <Table.Th colSpan={2}>Spend</Table.Th>
              <Table.Th>Views</Table.Th>
              <Table.Th>CPM</Table.Th>
              {hasSomeClickCampaign ? <Table.Th>Clicks</Table.Th> : undefined}
              {hasSomeClickCampaign ? <Table.Th>CPC</Table.Th> : undefined}
              <Table.Th>Live Content</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {Object.entries(campaigns).map(([campaignId, campaign]) => (
              <CampaignRow
                key={campaignId}
                campaign={campaign}
                hasSomeClickCampaign={hasSomeClickCampaign}
                showEdit={showEdit}
                onEditClick={(editCampaign) => {
                  setEditingCampaignHashId(editCampaign.hash_id);
                  openEditCampaign();
                  navigate(`${CAMPAIGN_PORTAL}/${editCampaign.hash_id}/edit`);
                }}
              />
            ))}
          </Table.Tbody>
        </Table>
      </Stack>
      <Drawer
        opened={editCampaignDrawerIsOpen && editingCampaignHashId !== null}
        onClose={() => {
          closeEditCampaign();
          setEditingCampaignHashId(null);
          navigate(`${CAMPAIGN_PORTAL}/overview`);
        }}
        position="right"
        size="80%">
        <CreateCampaign campaignHashId={editingCampaignHashId} />;
      </Drawer>
    </>
  );
};

export default AllCampaignsOverview;
