import { CreatorDetails } from "components/discovery/Datamodels";
import { SupportedFormat, SupportedPlatform, UsageRightsDuration } from "models/Common";
import { DeliverablePackage } from "models/DeliverablePackage";

// This is for when a Brand or we Interally review a contract before sending out a contract
export enum ContractReviewStatus {
  IN_REVIEW = 0,
  APPROVED = 1,
  REJECTED = 2,
}

export type ContractReviewDeliverable = {
  format?: SupportedFormat;
  platform?: SupportedPlatform;
  usageRightsDays?: number;
};

export type ContractBrandReview = {
  id: number;
  date_created: string;
  last_updated: string;
  creator_id: number;
  campaign_id: number;
  recommended_price: number;
  creator_asking_price: number;
  deliverables: ContractReviewDeliverable[];
  deliverable_package_usage_rights: UsageRightsDuration;
  deliverable_package_usage_rights_price: number;
  deliverable_package_creator_asking_usage_rights_price: number;
  deliverable_package_instagram_price_debug?: string;
  deliverable_package_tiktok_dedicated_video_price_debug?: string;
  deliverable_package_youtube_short_price_debug?: string;
  deliverable_package_youtube_dedicated_video_price_debug?: string;
  // No deliverable_package_youtube_30s_integrated_video_price_debug
  deliverable_package_youtube_60s_integrated_video_price_debug?: string;
  deliverable_package_youtube_90s_integrated_video_price_debug?: string;
  status: ContractReviewStatus;
  brand_max_price?: number;
  brand_feedback?: string;
  brand_review_status: ContractReviewStatus;
  internal_review_status?: ContractReviewStatus;
  contract_hash_id?: number;
};

export type CampaignContractBrandPendingReviewCount = {
  campaign_id: string;
  campaign_name: string;
  pending_review_link: string;
  pending_review_count: number;
};

export type CampaignContractBrandReview = CampaignContractBrandPendingReviewCount & {
  contract_reviews: ContractBrandReview[];
  contract_offer_reviews: ContractOfferBrandReview[];
};

export type ContractOfferBrandReview = {
  uuid: string;
  creator_id: number;
  last_updated: string;
  brand_review_status: ContractReviewStatus;
  deliverable_packages: DeliverablePackage[];
  brand_feedback?: string;
  // Exists only when there was a contract created for this review
  contract_hash_id?: number;
  // Set when returned for tasks
  creator_details?: CreatorDetails;
};
