import React, { useEffect, useState } from "react";

import { Avatar, Flex, Group, Progress, rem, Stack, Tabs, Text } from "@mantine/core";

import { useAppSelector } from "reduxStore/hooks";
import Spacer from "components/Spacer";
import { DailyProgress, getDailyProgress } from "admin/api/labeling/progressApi";

const TabData = [
  {
    label: (
      <Group align="center" justify="center">
        Creator Sets
      </Group>
    ),
    href: "#creator-sets",
    key: "creator-sets",
  },
  {
    label: (
      <Group align="center" justify="center">
        Creator Labeling
      </Group>
    ),
    href: "#creator-labeling",
    key: "creator-labeling",
  },
  {
    label: (
      <Group align="center" justify="center">
        My History
      </Group>
    ),
    href: "#history",
    key: "history",
  },
  {
    label: (
      <Group align="center" justify="center">
        Daily Scores
      </Group>
    ),
    href: "#team",
    key: "team",
  },
  {
    label: (
      <Group align="center" justify="center">
        Tasks
      </Group>
    ),
    href: "#tasks",
    key: "tasks",
  },
];

const AdminLabelingTabs = ({
  activeTab,
  setActiveTab,
}: {
  activeTab: string;
  setActiveTab: (tab: string) => void;
}) => {
  const items = TabData.map((item) => (
    <Tabs.Tab key={item.key} value={item.key}>
      {item.label}
    </Tabs.Tab>
  ));
  return (
    <Flex
      align="flex-end"
      style={{
        flexGrow: 1,
        flexBasis: 0,
      }}
      wrap="nowrap">
      <Tabs value={activeTab} onChange={setActiveTab}>
        <Tabs.List>{items}</Tabs.List>
      </Tabs>
    </Flex>
  );
};

const AdminLabelingProgress = ({
  syncProgress,
  setSyncProgress,
}: {
  syncProgress: boolean;
  setSyncProgress: (syncProgress: boolean) => void;
}) => {
  const [dailyProgress, setDailyProgress] = useState<DailyProgress>();
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    if (syncProgress) {
      getDailyProgress(setDailyProgress);
      setSyncProgress(false);
    }
  }, [syncProgress]);

  useEffect(() => {
    if (dailyProgress) {
      setProgress((100 * (dailyProgress?.labeled || 0)) / (dailyProgress?.total || 850));
    }
  }, [dailyProgress]);

  return (
    <Stack gap={4} justify="center" align="center" py="xs">
      <Text size="sm">
        Creator{" "}
        <Text span inherit fw={700}>
          {dailyProgress?.labeled || 0}
        </Text>{" "}
        out of {dailyProgress?.total || 850}
      </Text>
      <Stack w="100%">
        <Progress value={progress} size="sm" color="green" />
      </Stack>
    </Stack>
  );
};

const AdminLabelingUserHeader = () => {
  const user = useAppSelector((state) => state.me.user);
  const companyName = user.brand?.brand_name || user.name;

  return (
    <Flex
      py="xs"
      justify="flex-end"
      style={{
        flexGrow: 1,
        flexBasis: 0,
      }}>
      <Group gap={0} wrap="nowrap">
        <Avatar w={36} h={36} src={user.brand?.avatar_url} style={{ flex: "none" }} />
        <Spacer width={12} />
        <Stack gap={0}>
          {companyName ? (
            <Text
              style={{
                fontSize: "14px",
                fontWeight: "700",
                lineHeight: "155%",
              }}>
              {companyName}
            </Text>
          ) : null}
          <Text
            lineClamp={1}
            style={{
              fontSize: "11px",
              fontWeight: "400",
              lineHeight: "155%",
            }}>
            {user.email}
          </Text>
        </Stack>
      </Group>
    </Flex>
  );
};

export const AdminLabelingHeader = ({
  syncProgress,
  setSyncProgress,
  activeTab,
  setActiveTab,
}: {
  syncProgress: boolean;
  setSyncProgress: (syncProgress: boolean) => void;
  activeTab: string;
  setActiveTab: (activeTab: string) => void;
}) => {
  return (
    <header
      style={{
        height: "rem(120px)",
        // marginBottom: "rem(150px)",
        // backgroundColor: "var(--mantine-color-body)",
        borderBottom:
          "rem(1px) solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4))",
        paddingLeft: "var(--mantine-spacing-md)",
        paddingRight: "var(--mantine-spacing-md)",
      }}>
      <Flex
        justify="space-between"
        align="flex-end"
        h="rem(120px)"
        gap={0}
        style={{
          position: "sticky",
          top: 0,
        }}>
        <AdminLabelingTabs activeTab={activeTab} setActiveTab={setActiveTab} />
        <AdminLabelingProgress syncProgress={syncProgress} setSyncProgress={setSyncProgress} />
        <AdminLabelingUserHeader />
      </Flex>
    </header>
  );
};

export default AdminLabelingHeader;
