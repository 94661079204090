import React, { useEffect, useState } from "react";
import {
  fetchContractReviewData,
  saveContractOfferReview,
  saveContractReview,
} from "components/contracts/common/Api";
import { CreatorDetails } from "components/discovery/Datamodels";
import { Center, Container, Loader, Stack, Title } from "@mantine/core";
import {
  ContractBrandReview,
  ContractOfferBrandReview,
  ContractReviewStatus,
} from "components/contracts/brand_review/types";
import { Notifications, notifications } from "@mantine/notifications";
import NothingHereComponent from "components/general/NothingHere";
import { ContractOfferApprovalCard } from "components/contracts/brand_review/ContractOfferBrandReviewCard";
import { ContractApprovalCard } from "components/contracts/brand_review/ContractBrandReviewCard";

export default function ContractApprovalFeed({
  adGroupId,
  campaignId,
  showAdminOptions,
}: {
  adGroupId?: string;
  campaignId?: string;
  showAdminOptions?: boolean;
}) {
  if (!campaignId && !campaignId && !adGroupId) {
    return null;
  }

  if (campaignId && adGroupId) {
    throw new Error("Cannot specify both campaignId and adGroupId");
  }
  const [creatorDetails, setCreatorDetails] = useState<Record<number, CreatorDetails>>({});
  const [contractReviews, setContractReviews] = useState<ContractBrandReview[]>([]);
  const [contractOfferReviews, setContractOfferReviews] = useState<ContractOfferBrandReview[]>([]);
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    fetchContractReviewData(campaignId, adGroupId, showAdminOptions).then((data) => {
      // We support two types of review for the migration
      setContractReviews(data.contract_reviews);
      setContractOfferReviews(data.contract_offer_reviews || []);
      // Creator details are a combined set of reviews and offer reviews
      setCreatorDetails(data.creator_details);
      // Prefill the brand price with the recommended price
      setContractReviews((prevReviews) => {
        const updatedReviews = prevReviews.map((review) => {
          if (review.brand_max_price === 0) {
            return {
              ...review,
              brand_max_price: review.recommended_price,
            };
          }
          return review;
        });
        return updatedReviews;
      });

      // Prefill the brand approved price with the suggested price
      setContractOfferReviews((prevReviews) => {
        const updatedReviews = prevReviews.map((prevReview) => {
          return {
            ...prevReview,
            deliverable_packages: prevReview.deliverable_packages.map((pkg) => {
              if (!pkg.brand_approved_price || pkg.brand_approved_price <= 0) {
                return {
                  ...pkg,
                  brand_approved_price: pkg.suggested_price,
                };
              }
              return pkg;
            }),
          };
        });
        return updatedReviews;
      });
      setLoaded(true);
    });
  }, []);

  const declineCreator = (contractReviewId: number, index: number, brandReview: boolean) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { brand_max_price, brand_feedback } = contractReviews[index];

    const brandStatus = brandReview ? ContractReviewStatus.REJECTED : undefined;
    const internalStatus = brandReview ? undefined : ContractReviewStatus.REJECTED;
    setLoaded(false);
    saveContractReview(
      contractReviewId,
      brand_feedback,
      brandStatus,
      internalStatus,
      brand_max_price,
    )
      .then(() => {
        setLoaded(true);
        notifications.show({
          color: "blue",
          title: "Declining Creator",
          message: "Creator has be declined successfully!",
          autoClose: false,
        });
        setContractReviews((prevReviews) => {
          const updatedReviews = prevReviews.map((review) => {
            if (review.id === contractReviewId) {
              return {
                ...review,
                brand_review_status: brandStatus,
              };
            }
            return review;
          });
          return updatedReviews;
        });
      })
      .catch(() => {
        notifications.show({
          color: "red",
          title: "Error Declining Creator",
          message: "Error occured while saving!",
          autoClose: true,
        });
        setLoaded(true);
      });
  };

  /**
   * Decline a ContractOfferBrandReview.
   * @param contractReviewId The uuid of the ContractOfferBrandReview.
   * @param index The index of the review in the list of reviews. Needed for results and feedback.
   */
  const declineOffer = (contractReviewId: string, index: number) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention

    // Assemble packages approval info structures. In order to trigger a decline, we must have a
    // non-empty list of package rejections.
    const offerReview = contractOfferReviews[index];
    const declines: {
      [uuid: string]: { brand_approved: boolean; max_offer_cap: number; feedback: string };
    } = {};
    offerReview.deliverable_packages.forEach((pkg, idx) => {
      declines[pkg.uuid] = {
        // This is the only field that matters, all must be rejected.
        brand_approved: false,
        // Price is immaterial to a rejection.
        max_offer_cap: 0,
        // No feedback for rejected offers, because all packages are rejected.
        feedback: null,
      };
    });
    setLoaded(false);
    saveContractOfferReview(contractReviewId, declines)
      .then(() => {
        setLoaded(true);
        notifications.show({
          color: "blue",
          title: "Declining Creator",
          message: "Creator has be declined successfully!",
          autoClose: false,
        });
        setContractOfferReviews((prevReviews) => {
          const updatedReviews = prevReviews.map((review) => {
            if (review.uuid === contractReviewId) {
              return {
                ...review,
                brand_review_status: ContractReviewStatus.REJECTED,
              };
            }
            return review;
          });
          return updatedReviews;
        });
      })
      .catch(() => {
        notifications.show({
          color: "red",
          title: "Error Declining Creator",
          message: "Error occured while saving!",
          autoClose: true,
        });
        setLoaded(true);
      });
  };

  const approveOffer = (contractReviewId: number, index: number, brandReview: boolean) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { brand_max_price, brand_feedback } = contractReviews[index];
    const brandStatus = brandReview ? ContractReviewStatus.APPROVED : undefined;
    const internalStatus = brandReview ? undefined : ContractReviewStatus.APPROVED;
    setLoaded(false);

    saveContractReview(
      contractReviewId,
      brand_feedback,
      brandStatus,
      internalStatus,
      brand_max_price,
    )
      .then(() => {
        setLoaded(true);

        notifications.show({
          color: "blue",
          title: "Approve Creator with Max Offer",
          message: "Creator has been successfully Approved with new Max Offer!",
          autoClose: false,
        });
        setContractReviews((prevReviews) => {
          const updatedReviews = prevReviews.map((review) => {
            if (review.id === contractReviewId) {
              return {
                ...review,
                brand_review_status: brandStatus,
              };
            }
            return review;
          });
          return updatedReviews;
        });
      })
      .catch(() => {
        setLoaded(true);

        notifications.show({
          color: "red",
          title: "Error Approve Creator with Max Offer",
          message: "Error occured while saving!",
          autoClose: true,
        });
      });
  };

  /**
   * Approve a ContractOfferBrandReview
   * @param contractReviewId The uuid of the ContractOfferBrandReview
   * @param index The index of the review in the list of reviews
   * @param packageApprovalFlags Flags for which packages are approved and which are declined.
   * @param feedbacks Feedbacks per package, regardless of approval status.
   */
  const approveOfferReview = (
    contractReviewId: string,
    index: number,
    packageApprovalFlags: boolean[],
    feedbacks: string[],
  ) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const offerReview = contractOfferReviews[index];
    setLoaded(false);

    // Assemble packages approval structures
    const approvals: {
      [uuid: string]: { brand_approved: boolean; max_offer_cap: number; feedback: string };
    } = {};
    offerReview.deliverable_packages.forEach((pkg, idx) => {
      approvals[pkg.uuid] = {
        brand_approved: packageApprovalFlags[idx],
        max_offer_cap: pkg.brand_approved_price,
        feedback: feedbacks[idx],
      };
    });

    const hasAnApproval = packageApprovalFlags.some((val) => val);
    const brandStatus = hasAnApproval ? ContractReviewStatus.APPROVED : undefined;

    saveContractOfferReview(contractReviewId, approvals)
      .then((response) => {
        setLoaded(true);
        if (!response.success) {
          notifications.show({
            color: "red",
            title: "Error approving creator",
            message: "Error occured while saving!",
            autoClose: false,
          });
          return;
        }

        notifications.show({
          color: "blue",
          title: "Approve Creator with Max Offer",
          message: "Creator has been successfully Approved with new Max Offer!",
          autoClose: true,
        });
        setContractOfferReviews((prevReviews) => {
          const updatedReviews = prevReviews.map((review) => {
            if (review.uuid === contractReviewId) {
              return {
                ...review,
                brand_review_status: brandStatus,
              };
            }
            return review;
          });
          return updatedReviews;
        });
      })
      .catch(() => {
        setLoaded(true);

        notifications.show({
          color: "red",
          title: "Error Approve Creator with Max Offer",
          message: "Server error while approving.",
          autoClose: false,
        });
      });
  };

  return (
    <Container>
      <Notifications />
      <Stack gap="xs">
        {loaded && !showAdminOptions && (
          <Title order={2} fw="500">
            Contract Review
          </Title>
        )}
        {!loaded && (
          <Center mt="xl">
            <Loader />
          </Center>
        )}
        {loaded && contractReviews.length === 0 && <NothingHereComponent />}
        {contractReviews.map((contractReview, index) => {
          const creatorDetail = creatorDetails[contractReview.creator_id];
          const {
            deliverables,
            deliverable_package_usage_rights: usageRightsDuration,
            deliverable_package_usage_rights_price: usagePrice,
            deliverable_package_creator_asking_usage_rights_price: creatorAskingUsageRightsPrice,
          } = contractReview;
          const inReview = contractReview.brand_review_status === ContractReviewStatus.IN_REVIEW;
          const accepted = contractReview.brand_review_status === ContractReviewStatus.APPROVED;
          const rejected = contractReview.brand_review_status === ContractReviewStatus.REJECTED;

          return (
            <ContractApprovalCard
              key={contractReview.id}
              contractReview={contractReview}
              deliverables={deliverables}
              deliverablePackageInfo={{
                usageRightsDuration,
                usagePrice,
                creatorAskingUsageRightsPrice,
              }}
              creatorDetail={creatorDetail}
              isAccepted={accepted}
              isRejected={rejected}
              isInReview={inReview}
              showAdminOptions={showAdminOptions}
              index={index}
              setContractReviews={setContractReviews}
              approveOffer={approveOffer}
              declineCreator={declineCreator}
            />
          );
        })}
        {contractOfferReviews.map((contractOfferReview, index) => {
          const creatorDetail = creatorDetails[contractOfferReview.creator_id];
          const inReview =
            contractOfferReview.brand_review_status === ContractReviewStatus.IN_REVIEW;
          const accepted =
            contractOfferReview.brand_review_status === ContractReviewStatus.APPROVED;
          const rejected =
            contractOfferReview.brand_review_status === ContractReviewStatus.REJECTED;

          return (
            <ContractOfferApprovalCard
              key={contractOfferReview.uuid}
              contractReview={contractOfferReview}
              creatorDetail={creatorDetail}
              isAccepted={accepted}
              isRejected={rejected}
              isInReview={inReview}
              showAdminOptions={showAdminOptions}
              index={index}
              setContractReviews={setContractOfferReviews}
              approveOffer={approveOfferReview}
              declineCreator={declineOffer}
            />
          );
        })}
      </Stack>
    </Container>
  );
}
