export const NAV_BAR_WIDTH = 300;
export const NAV_BAR_V2_COLLAPSED_WIDTH = 96;

export enum ContractFeedType {
  All,
  ProductAccess = "product_access",
  PromoCodesAndLinks = "promo_codes_and_links",
  ReviewContent = "review_content",
  LiveVerification = "live_verification",
}

export default null;
