import React, { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";

import {
  Alert,
  Anchor,
  Button,
  Card,
  Center,
  Container,
  Flex,
  Group,
  LoadingOverlay,
  Modal,
  SimpleGrid,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { useDisclosure, useInterval } from "@mantine/hooks";

import {
  IconAlertTriangle,
  IconContract,
  IconFileAlert,
  IconHourglassLow,
  IconInfoCircle,
  IconMailPlus,
} from "@tabler/icons-react";

import { showFailureNotification } from "components/common/Notifications";
import { ContractBrandReviewDeliverable } from "components/contracts/brand_review/ContractBrandReviewDeliverable";
import { makeDeliverableList } from "components/contracts/brand_review/ContractOfferBrandReviewCard";
import { getUsageRightsDays } from "components/contracts/common/Common";
import {
  creatorAcceptPackage,
  creatorDeclineOffer,
  getContractOfferForCreator,
} from "components/offers/Api";
import { ContractOfferStatus } from "models/Common";
import { DeliverablePackage } from "models/DeliverablePackage";
import { fromISODateString } from "utils/DateUtils";

/**
 * This type is incompatible with models/ContractOffer because of the need to communicate only limited details.
 */
type ContractOfferForCreator = {
  uuid: string;
  status: ContractOfferStatus;
  deliverable_packages: DeliverablePackage[];
  creator_expiry: Date;
  // Exists only when there was a contract created for this review
  contract_hash_id?: string;
};

// Simple deserialize to turn the date string into a date object
const deserializeContractOffer = (offer: any) => {
  return {
    ...offer,
    creator_expiry: offer.creator_expiry ? fromISODateString(offer.creator_expiry) : undefined,
  } as ContractOfferForCreator;
};

const enum ErrorDisplayType {
  UNKNOWN = 0,
  NOT_FOUND = 1,
  NOT_READY = 2,
}

const ErrorDisplay = ({ type }: { type: ErrorDisplayType }) => {
  switch (type) {
    case ErrorDisplayType.NOT_FOUND:
      return (
        <Center>
          <Stack>
            <Center>
              <IconFileAlert size={50} color="var(--mantine-color-dark-2)" />
            </Center>
            <Center>
              <Title
                order={3}
                styles={{
                  root: {
                    textAlign: "center",
                  },
                }}>
                This page is no longer available.
              </Title>
            </Center>
            <Center maw={500}>
              <Text
                size="lg"
                c="var(--mantine-color-gray-6)"
                styles={{
                  root: {
                    textAlign: "center",
                  },
                }}>
                If you are still interested in this campaign, please email your 1stCollab
                representative or contact us at{" "}
                <Anchor href="mailto:hello@1stcollab.com">hello@1stcollab.com</Anchor> for help.
              </Text>
            </Center>
          </Stack>
        </Center>
      );
    case ErrorDisplayType.NOT_READY:
      return (
        <Center>
          <Stack>
            <Center>
              <IconFileAlert size={50} color="var(--mantine-color-dark-2)" />
            </Center>
            <Center>
              <Title
                order={3}
                styles={{
                  root: {
                    textAlign: "center",
                  },
                }}>
                This page is not ready yet.
              </Title>
            </Center>
            <Center maw={500}>
              <Text
                size="lg"
                c="var(--mantine-color-gray-6)"
                styles={{
                  root: {
                    textAlign: "center",
                  },
                }}>
                Please check back later. If it doesn&apos;t seem to be working, reach out to your
                1stCollab representative or contact us at{" "}
                <Anchor href="mailto:hello@1stcollab.com">hello@1stcollab.com</Anchor> for help.
              </Text>
            </Center>
          </Stack>
        </Center>
      );
    default:
      return null;
  }
};

const AcceptedOfferCard = ({
  contractOffer,
  brandName,
}: {
  contractOffer: ContractOfferForCreator;
  brandName: string;
}) => {
  return (
    <Center>
      <Stack>
        <Center>
          <IconContract size={50} color="var(--mantine-color-dark-2)" />
        </Center>
        <Center>
          <Title
            order={3}
            maw={500}
            styles={{
              root: {
                textAlign: "center",
              },
            }}>
            Congratulations! You have accepted an offer from {brandName}.
          </Title>
        </Center>
        <Center maw={500}>
          <Text
            size="lg"
            c="var(--mantine-color-gray-6)"
            styles={{
              root: {
                textAlign: "center",
              },
            }}>
            You can find your contract{" "}
            <Anchor href={`/contracts/${contractOffer.contract_hash_id}`}>here</Anchor>.
          </Text>
        </Center>
      </Stack>
    </Center>
  );
};

const stringForLiveDates = (liveMonths: string[]) => {
  if (!liveMonths || liveMonths.length === 0) {
    return (
      <Text size="sm" fw={700}>
        No dates available for this offer, sorry!
      </Text>
    );
  }
  return (
    <Text span size="sm">
      <Text span fw={700}>
        Available Live Dates:{" "}
      </Text>
      <Text>{liveMonths.join(", ")}</Text>
    </Text>
  );
};

const PackageCard = ({
  title,
  deliverablePackage,
  liveMonths,
  selectPackage,
  interactive,
  readOnly,
}: {
  title: string;
  deliverablePackage: DeliverablePackage;
  liveMonths: string[];
  selectPackage?: () => void;
  interactive?: boolean;
  readOnly?: boolean;
}) => {
  const simplifiedDeliverables = makeDeliverableList(deliverablePackage);
  const creatorAskingPrice =
    (deliverablePackage?.creator_asking_price || 0) +
    (deliverablePackage?.creator_asking_usage_rights_price || 0);

  return (
    <Card px="md" shadow="none" py="sm" radius="md" m="xs" withBorder>
      <Flex justify="space-between" direction="column" h="100%" gap="md">
        <Stack gap="xs">
          <Text size="md">{title}</Text>
          <Title order={3}>
            {new Intl.NumberFormat(undefined, {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 0,
            }).format(deliverablePackage?.price)}
          </Title>
          <Text size="xs" c="var(--mantine-color-dark-2)">
            Your asking price:{" "}
            {new Intl.NumberFormat(undefined, {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 0,
            }).format(creatorAskingPrice)}
          </Text>
          {simplifiedDeliverables.map((deliverable) => (
            <ContractBrandReviewDeliverable
              key={`deliverable-card-${deliverable.format}`}
              format={deliverable.format}
              platform={deliverable.platform}
              usageRights={getUsageRightsDays(deliverable.usageRights)}
              width="100%"
            />
          ))}
          {stringForLiveDates(liveMonths)}
        </Stack>
        <Text />
        {interactive && (
          <Button
            onClick={selectPackage}
            disabled={readOnly || !liveMonths || liveMonths.length === 0}>
            Select this offer
          </Button>
        )}
      </Flex>
    </Card>
  );
};

/**
 * Content intended to be shown in the confirmation modal.
 */
const PackageConfirmationContent = ({
  deliverablePackage,
  liveMonths,
  creatorEmail,
  closeModal,
}: {
  deliverablePackage: DeliverablePackage;
  liveMonths: string[];
  creatorEmail: string;
  closeModal: () => void;
}) => {
  const [additionalEmails, setAdditionalEmails] = useState<string>("");
  // Failure something that requires a refresh.
  const [showFailureAlert, setShowFailureAlert] = useState<boolean>(false);
  // Email failure, specifically, requires correction of the email field.
  const [showEmailFailure, setShowEmailFailure] = useState<boolean>(false);
  // Powers the loader for the confirm button.
  const [confirmPending, setConfirmPending] = useState<boolean>(false);

  const navigate = useNavigate();

  // Called when the creator confirms the package they have selected is what they want to proceed with.
  const confirmPackage = () => {
    setConfirmPending(true);
    creatorAcceptPackage(deliverablePackage.uuid, additionalEmails)
      .then((response) => {
        if (response.success && response.contract_hash_id) {
          // Go to the generated contract page if successful
          navigate(`/contracts/${response.contract_hash_id}`);
          setShowFailureAlert(false);
          setShowEmailFailure(false);
        } else if (response.result?.failure_reason === "email") {
          setShowEmailFailure(true);
          setShowFailureAlert(false);
        } else {
          setShowEmailFailure(false);
          setShowFailureAlert(true);
        }
      })
      .catch(() => {
        setShowEmailFailure(false);
        setShowFailureAlert(true);
      })
      .finally(() => {
        setConfirmPending(false);
      });
  };

  return (
    <Stack>
      <Alert icon={<IconInfoCircle />} color="yellow">
        <Text size="sm">
          This partnership is not final until you sign the contract on the next page.
        </Text>
      </Alert>
      <PackageCard
        title="Your Selected Offer"
        deliverablePackage={deliverablePackage}
        liveMonths={liveMonths}
        interactive={false}
        readOnly
      />
      <Card withBorder bg="var(--mantine-color-gray-0)">
        <Group wrap="nowrap">
          <Stack h="100px" justify="space-between" align="flex-start">
            <IconMailPlus />
            {/* <Text /> */}
          </Stack>
          <Stack h="100px" align="flex-start" gap="xs">
            <Text size="sm">
              <Text fw={700}>We will email your contract to {creatorEmail}.</Text>
              <Text>
                If you would like to forward your contract to additional emails, please enter them
                below.
              </Text>
            </Text>
            <Group>
              <TextInput
                w={400}
                placeholder="example1@example.com, example2@example.com"
                value={additionalEmails}
                onChange={(event) => setAdditionalEmails(event.currentTarget.value)}
                error={showEmailFailure}
              />
            </Group>
          </Stack>
        </Group>
      </Card>
      <Group justify="flex-end">
        <Button variant="outline" onClick={closeModal} color="var(--mantine-color-blue-6)">
          Cancel
        </Button>
        {showFailureAlert ? (
          <Button onClick={() => window.location.reload()} color="var(--mantine-color-blue-6)">
            Exit & Reload Page
          </Button>
        ) : (
          <Button
            onClick={confirmPackage}
            color="var(--mantine-color-blue-6)"
            loading={confirmPending}>
            Continue to Contract
          </Button>
        )}
      </Group>
      {showFailureAlert && (
        <Alert icon={<IconAlertTriangle />} color="red">
          Error: There seems to be something wrong with this offer, please reload and try again.
        </Alert>
      )}
      {showEmailFailure && (
        <Alert icon={<IconAlertTriangle />} color="red">
          Error: There seems to be something wrong with the email addresses you have provided,
          please confirm them and try again.
        </Alert>
      )}
    </Stack>
  );
};

const somethingWentWrong = () => {
  showFailureNotification({
    title: "Something went wrong",
    message: (
      <Text span>
        Please try again and if it doesn&apos;t work give us a shout at{" "}
        <Anchor href="mailto:hello@1stcollab.com">hello@1stcollab.com</Anchor>.
      </Text>
    ),
    stayOpen: true,
  });
};

const CountdownContent = ({ expiry, title }: { expiry: Date | null; title: string }) => {
  const [hoursLeft, setHoursLeft] = useState<number>(48);
  const [minutesLeft, setMinutesLeft] = useState<number>(0);
  const [secondsLeft, setSecondsLeft] = useState<number>(0);

  const updateTime = () => {
    const now = new Date();
    if (expiry.getTime() < now.getTime()) {
      setHoursLeft(0);
      setMinutesLeft(0);
      setSecondsLeft(0);
    }

    let diffInSeconds = Math.max(0, Math.floor((expiry.getTime() - now.getTime()) / 1000));

    if (diffInSeconds >= 3600) {
      setHoursLeft(Math.floor(diffInSeconds / 3600));
      diffInSeconds %= 3600;
    } else {
      setHoursLeft(0);
    }

    if (diffInSeconds >= 60) {
      setMinutesLeft(Math.floor(diffInSeconds / 60));
      diffInSeconds %= 60;
    } else {
      setMinutesLeft(0);
    }

    setSecondsLeft(diffInSeconds);
  };

  const interval = useInterval(() => updateTime(), 1000);

  useEffect(() => {
    if (expiry) {
      interval.start();
      return interval.stop;
    }
    return undefined;
  }, [expiry]);

  return (
    <Card bg="var(--mantine-color-gray-1)">
      <Group>
        <IconHourglassLow size={16} color="var(--mantine-color-gray-6)" />
        <Text size="sm" fw={600} c="var(--mantine-color-gray-6)">
          {title}:
        </Text>
        <Text size="sm" fw={600}>
          {`${hoursLeft} hours : ${minutesLeft} minutes : ${secondsLeft} seconds`}
        </Text>
      </Group>
    </Card>
  );
};

export const OfferSelection = () => {
  const { offerId } = useParams();

  // Shown when the page is first loading.
  const [loading, setLoading] = useState<boolean>(false);

  // Details pulled from the contract offer retrieved from the backend
  const [contractOffer, setContractOffer] = useState<ContractOfferForCreator>(null);
  const [liveDates, setLiveDates] = useState<Record<string, string[]>>(null);
  const [brandName, setBrandName] = useState<string>("");
  const [creatorName, setCreatorName] = useState<string>("");
  const [creatorEmail, setCreatorEmail] = useState<string>("");

  // Used to display the "something went wrong" state
  const [loadingErrorCondition, setLoadingErrorCondition] = useState<boolean>(false);
  // Set when the creator has declined an offer. We don't want to allow further actions.
  const [declined, setDeclined] = useState<boolean>(false);

  // Which package the creator has selected, to be used with the confirmation modal.
  const [selectedPackage, setSelectedPackage] = useState<DeliverablePackage>(null);
  // The open state of the confirmation modal.
  const [confirmationModalOpened, { open: openConfirmation, close: closeConfirmation }] =
    useDisclosure(false);

  useEffect(() => {
    setLoading(true);
    getContractOfferForCreator(offerId)
      .then((response) => {
        if (response.success) {
          setContractOffer(deserializeContractOffer(response.offer));
          setLiveDates(response.liveDates);
          setCreatorName(response.creatorName);
          setCreatorEmail(response.creatorEmail);
          setBrandName(response.brandName);
          setLoadingErrorCondition(false);
        } else {
          setContractOffer(null);
          setLiveDates(null);
          setCreatorName("");
          setCreatorEmail("");
          setBrandName("");
          setLoadingErrorCondition(true);
        }
      })
      .catch(() => {
        setContractOffer(null);
        setLiveDates(null);
        setCreatorName("");
        setCreatorEmail("");
        setBrandName("");
        setLoadingErrorCondition(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  // Decline the offer and all of its packages. Offer decline happens at the offer level.
  const declineOffer = () => {
    creatorDeclineOffer(contractOffer?.uuid)
      .then((response) => {
        if (response.success) {
          setDeclined(true);
        } else {
          somethingWentWrong();
        }
      })
      .catch(() => {
        somethingWentWrong();
      });
  };

  // All packages are "lowball" offers if they are all below half of what the creator is asking.
  const allPackagesAreLowballs =
    contractOffer?.deliverable_packages &&
    contractOffer?.deliverable_packages?.length > 0 &&
    contractOffer?.deliverable_packages.every(
      (pkg) =>
        pkg.price <
        ((pkg.creator_asking_price || 0) + (pkg.creator_asking_usage_rights_price || 0)) / 2,
    );

  if (loadingErrorCondition) {
    return (
      <Container style={{ minWidth: "1000px" }}>
        <ErrorDisplay type={ErrorDisplayType.NOT_FOUND} />
      </Container>
    );
  } else if (contractOffer?.contract_hash_id) {
    return (
      <Container style={{ minWidth: "1000px" }}>
        <AcceptedOfferCard contractOffer={contractOffer} brandName={brandName} />
      </Container>
    );
  } else if (
    !loading &&
    (!contractOffer?.deliverable_packages || contractOffer?.deliverable_packages?.length === 0)
  ) {
    return (
      <Container style={{ minWidth: "1000px" }}>
        <ErrorDisplay type={ErrorDisplayType.NOT_READY} />
      </Container>
    );
  }
  return (
    <Container style={{ minWidth: "1000px" }}>
      <LoadingOverlay visible={loading} zIndex={1000} overlayProps={{ blur: 4 }} />
      <Modal
        size={670}
        opened={confirmationModalOpened}
        onClose={closeConfirmation}
        title={
          <Text size="lg" fw={600}>
            Confirm your selection
          </Text>
        }>
        <PackageConfirmationContent
          deliverablePackage={selectedPackage}
          liveMonths={(liveDates && liveDates[selectedPackage?.uuid]) || []}
          creatorEmail={creatorEmail}
          closeModal={closeConfirmation}
        />
      </Modal>
      <Card shadow="none" padding="md" radius="md" m="xs" withBorder>
        <Stack>
          <Title>
            🤝 {brandName} / {creatorName}
          </Title>
          <CountdownContent
            expiry={contractOffer?.creator_expiry}
            title={
              contractOffer?.deliverable_packages?.length > 1
                ? "Offers Expire In"
                : "Offer Expires In"
            }
          />
          <Text>
            The link to this page will be emailed to you at {creatorEmail}. You will have 48 hours
            to take action by accepting one of these packages or declining the opportunity
            altogether.
          </Text>
          {allPackagesAreLowballs && (
            // Show an alert recognizing that the offers are below what they expect.
            <Alert icon={<IconInfoCircle size={16} />} color="yellow">
              <Text size="sm" c="var(--mantine-color-dark-6)">
                We noticed the offers from {brandName} are significantly below your asking rates.
                While we&apos;d love to make something work, we understand if these are too far
                below your standard rates.
              </Text>
            </Alert>
          )}
          <SimpleGrid cols={Math.min(2, contractOffer?.deliverable_packages?.length)}>
            {contractOffer?.deliverable_packages.map((pkg, idx) => {
              return (
                <PackageCard
                  key={pkg.uuid}
                  title={`Offer #${idx + 1}`}
                  deliverablePackage={contractOffer.deliverable_packages?.at(idx)}
                  selectPackage={() => {
                    // Record which package was selected and open the confirmation modal for it.
                    // Acceptance of the package happens in that modal.
                    setSelectedPackage(contractOffer?.deliverable_packages?.at(idx));
                    openConfirmation();
                  }}
                  liveMonths={liveDates[contractOffer.deliverable_packages?.at(idx)?.uuid] || []}
                  interactive
                  readOnly={declined}
                />
              );
            })}
          </SimpleGrid>
          <Flex justify="flex-end" align="center">
            <Button color="red" onClick={declineOffer} disabled={declined}>
              Decline All Offers
            </Button>
          </Flex>
        </Stack>
      </Card>
    </Container>
  );
};

export default OfferSelection;
