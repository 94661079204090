import React, { useState } from "react";

import { useDisclosure } from "@mantine/hooks";

import {
  ActionIcon,
  Modal,
  NumberInput,
  Text,
  Button,
  Stack,
  Flex,
  Group,
  Radio,
} from "@mantine/core";

import { IconCheck } from "@tabler/icons-react";

import { markEligibleForBonus } from "components/contracts/common/Api";

import { formatAmount } from "components/contracts/dashboard/Utils";

import { showSuccessNotification, showFailureNotification } from "components/common/Notifications";

export default function EarnedBonusAmountModal({
  contractId,
  displayName,
  bonusAmountInMinorUnits,
  earnedBonusAmountInMinorUnits,
  opened,
  close,
  handleRefetchContract,
}: {
  contractId: string;
  displayName: string;
  bonusAmountInMinorUnits: number;
  earnedBonusAmountInMinorUnits: number;
  opened: boolean;
  close: () => void;
  handleRefetchContract: (contractId: string, handleFetched: () => void) => void;
}) {
  const remainingBonusAmountInMinorUnits = bonusAmountInMinorUnits - earnedBonusAmountInMinorUnits;

  const [secondModalOpened, { open: openSecond, close: closeSecond }] = useDisclosure();
  const [isFullBonus, setIsFullBonus] = useState(true);
  const [paymentFinalized, setPaymentFinalized] = useState(false);
  const [amountInMinorUnits, setAmountInMinorUnits] = useState<number>(
    remainingBonusAmountInMinorUnits,
  );
  const [loading, setLoading] = useState(false);

  const handleConfirm = () => {
    setLoading(true);
    markEligibleForBonus({ contractId, earnedBonusAmount: amountInMinorUnits })
      .then((response) => {
        if (response.success) {
          showSuccessNotification({
            message: `Successfully marked ${displayName}'s contract as eligible for ${formatAmount(
              amountInMinorUnits,
            )} / ${formatAmount(bonusAmountInMinorUnits)} of their bonus.`,
          });
        } else {
          showFailureNotification({ message: response.error });
        }

        handleRefetchContract(contractId, () => {
          setLoading(false);
          closeSecond();
        });
      })
      .catch(() => {
        showFailureNotification({
          message: `Failed to mark ${displayName}'s contract as eligible for bonus.`,
        });
        setLoading(false);
        closeSecond();
      });
  };

  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        size="xs"
        title={<Text fw="600">Select Earned Bonus Amount</Text>}>
        <Stack>
          <Stack>
            <Radio
              checked={isFullBonus}
              label="Full Bonus Amount"
              onChange={() => {
                setIsFullBonus(true);
                setPaymentFinalized(true);
                setAmountInMinorUnits(Math.round(remainingBonusAmountInMinorUnits));
              }}
            />
            <Radio
              checked={!isFullBonus}
              label="Partial Bonus Amount"
              onChange={() => {
                setPaymentFinalized(false);
                setIsFullBonus(false);
              }}
            />
          </Stack>
          <Flex align="center" gap="sm">
            <NumberInput
              miw="85%"
              disabled={isFullBonus}
              value={amountInMinorUnits / 100}
              onChange={(value: number) => {
                setPaymentFinalized(false);
                setAmountInMinorUnits(Math.round(value * 100));
              }}
              min={0}
              max={Math.round(remainingBonusAmountInMinorUnits / 100)}
              decimalScale={2}
              fixedDecimalScale
              decimalSeparator="."
              thousandSeparator=","
              hideControls
              prefix="$"
            />
            <ActionIcon
              size="1.5rem"
              color="blue"
              variant="light"
              disabled={isFullBonus || paymentFinalized}
              onClick={() => setPaymentFinalized(true)}>
              <IconCheck />
            </ActionIcon>
          </Flex>
          <Button
            disabled={!isFullBonus && !paymentFinalized}
            onClick={() => {
              close();
              openSecond();
            }}>
            Continue
          </Button>
        </Stack>
      </Modal>
      <Modal
        opened={secondModalOpened}
        onClose={closeSecond}
        size="xs"
        title={<Text fw="600">Confirm Earned Bonus Amount</Text>}>
        <Stack>
          <Text>
            <Text span fw="600">
              {displayName}
            </Text>{" "}
            is eligible for{" "}
            <Text span fw="600">
              {formatAmount(amountInMinorUnits)}
            </Text>{" "}
            of their total possible bonus of{" "}
            <Text span fw="600">
              {formatAmount(bonusAmountInMinorUnits)}
            </Text>
            . The remaining unearned bonus amount on their contract will be{" "}
            <Text span fw="600">
              {formatAmount(remainingBonusAmountInMinorUnits - amountInMinorUnits)}
            </Text>
            .
          </Text>
          <Group grow justify="center">
            <Button onClick={closeSecond} variant="default" color="gray">
              Cancel
            </Button>
            <Button loading={loading} onClick={handleConfirm}>
              Confirm
            </Button>
          </Group>
        </Stack>
      </Modal>
    </>
  );
}
