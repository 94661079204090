import React, { useState } from "react";
import { ActionIcon, Image, Tooltip, Transition } from "@mantine/core";
import { IconMenu2 } from "@tabler/icons-react";
import logo from "1stcollab_logo_icon.svg";

const LogoCollapseIcon = ({ onClick }: { onClick: () => void }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <Tooltip
      label="Expand Navigation Bar"
      color="var(--mantine-color-dark-3)"
      position="right-start">
      <ActionIcon
        variant="transparent"
        aria-label="Expand Navigation Bar"
        onClick={onClick}
        size={40}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        style={{
          ...(hovered ? { "--ai-hover": "var(--mantine-color-blue-0)" } : {}),
        }}>
        {/* First icon (visible when not hovered) */}
        <Transition
          mounted={!hovered}
          transition="fade"
          duration={300}
          timingFunction="ease-in-out">
          {(styles) => (
            <Image src={logo} h="40px" w="40px" style={{ ...styles, position: "absolute" }} />
          )}
        </Transition>

        {/* Second icon (visible when hovered) */}
        <Transition mounted={hovered} transition="fade" duration={300} timingFunction="ease-in-out">
          {(styles) => <IconMenu2 style={{ ...styles, position: "absolute" }} size={24} />}
        </Transition>
      </ActionIcon>
    </Tooltip>
  );
};

export default LogoCollapseIcon;
