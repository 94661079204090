import React, { useState, KeyboardEvent, useEffect } from "react";
import { Button, TextField, Box, SelectList, Tag, Flex } from "gestalt";
import "gestalt/dist/gestalt.css";
import { User, User as FirebaseUser, getAuth } from "firebase/auth";

import { API_URL } from "configs/Configs";
import Spacer from "components/Spacer";
import { User as UserProfile } from "models/User";
import { getByUsernameOrEmail } from "utils/UserUtils";
import { handleResult } from "utils/ApiUtils";
import { CampaignAdGroup } from "models/Campaign";
import { Platform } from "components/discovery/Datamodels";

export const ADMIN_URL = `${API_URL}/brannan/`;
export const MANUAL_ADD_TO_CREATOR_SET_URL = `${API_URL}/creatorsets/ops_manual_add_creator_to_creator_set/`;

const handleSubmit = async (
  requestUser: User,
  key: string,
  onLoading?: () => void,
  onSuccess?: (user: UserProfile | null) => void,
  onError?: () => void,
) => {
  if (onLoading) onLoading();
  try {
    const firebaseToken = await requestUser.getIdToken();
    const user = await getByUsernameOrEmail(firebaseToken, key);
    if (onSuccess) onSuccess(user);
  } catch (exception) {
    if (onError) onError();
  }
};

// sets a user to the user returned by the username lookup.
export const UserLookup = ({
  requestUser,
  onLoading,
  onSuccess,
  onError,
}: {
  requestUser: User;
  onLoading?: () => void;
  onSuccess?: (user: UserProfile | null) => void;
  onError?: () => void;
}) => {
  const [key, setKey] = useState("");

  return (
    <Box alignItems="end" direction="row" display="flex" wrap>
      <Box flex="grow">
        <TextField
          id="user_lookup"
          onChange={({ value }) => {
            setKey(value);
          }}
          label="Lookup by Username or Email"
          size="lg"
          onKeyDown={({
            event,
            value,
          }: {
            event: KeyboardEvent<HTMLInputElement>;
            value: string;
          }) => {
            if (event.code === "Enter") {
              handleSubmit(requestUser, key, onLoading, onSuccess, onError);
            }
          }}
        />
      </Box>
      <Spacer width={16} />
      <Box paddingY={1} flex="none">
        <Button
          text="Lookup User"
          color="blue"
          onClick={() => {
            handleSubmit(requestUser, key, onLoading, onSuccess, onError);
          }}
        />
      </Box>
    </Box>
  );
};

export type Archetype = {
  id: number;
  name: string;
};

export type CampaignInfo = {
  brand_id: string;
  brand_shortname: string;
  brand_name: string;
  campaign_id: number;
  archetypes: Archetype[];
  campaign_title: string;
  ad_groups: CampaignAdGroup[];
};

export type CampaignRecords = Record<number, CampaignInfo>;

export type BrandInfoCreatorSet = {
  id: number;
  creatorset_name: string;
};
export type BrandInfoCampaignParameters = {
  id: number;
  name: string;
  platforms: Platform[];
};

export type BrandInfo = {
  brand_id: number;
  brand_shortname: string;
  brand_name: string;
  creatorsets: BrandInfoCreatorSet[];
  campaign_parameters: BrandInfoCampaignParameters[];
};

export type BrandRecords = Record<number, BrandInfo>;

export type AdminBrandCreatorSetResponse = { brands: BrandRecords };

export type CampaignArchetypeResponse = {
  campaigns: CampaignRecords;
};

export type InternalSearchers = {
  emails: string[];
};

export const fetchCampaignData = async (abortController: AbortController, includeAny = false) => {
  const firebaseToken = await getAuth().currentUser?.getIdToken();
  // Create a URL object and set the query parameters on it
  const url = new URL(`${ADMIN_URL}get_campaign_archetype_data`);
  url.searchParams.append("includeAny", String(includeAny));

  const request = new Request(url.toString(), {
    // Use url.toString() to get the full URL string with query parameters
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
  });
  const response: CampaignArchetypeResponse = await handleResult(request, abortController);
  return response;
};

export const fetchAdminBrandData = async (abortController: AbortController, includeAny = false) => {
  const firebaseToken = await getAuth().currentUser?.getIdToken();
  // Create a URL object and set the query parameters on it
  const url = new URL(`${ADMIN_URL}get_brand_archetype_data`);
  url.searchParams.append("includeAny", String(includeAny));

  const request = new Request(url.toString(), {
    // Use url.toString() to get the full URL string with query parameters
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
  });
  const response: AdminBrandCreatorSetResponse = await handleResult(request, abortController);
  return response;
};

export const fetchSearchersData = async (user: FirebaseUser) => {
  const firebaseToken = await user.getIdToken();
  const request = new Request(`${ADMIN_URL}get_internal_search_users`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
  });
  const response: InternalSearchers = await handleResult(request);
  return response;
};

export const getCampaignSelectList = (campaignData: CampaignRecords) =>
  Object.keys(campaignData).map((key) => {
    const campaign = campaignData[Number(key)];
    return (
      <SelectList.Option
        key={campaign.campaign_id}
        label={campaign.campaign_title}
        value={String(campaign.campaign_id)}
      />
    );
  });

export const getBrandSelectList = (brandData: BrandRecords) => {
  // Sort brandData by brand_name
  const sortedBrandData = Object.values(brandData).sort((a, b) =>
    a.brand_name.localeCompare(b.brand_name),
  );
  return sortedBrandData.map((brand) => {
    return (
      <SelectList.Option
        key={brand.brand_id}
        label={brand.brand_name}
        value={String(brand.brand_id)}
      />
    );
  });
};
export const getCreatorSetSelectList = (creatorSets: BrandInfoCreatorSet[]) =>
  creatorSets.map((creatorSet) => {
    return (
      <SelectList.Option
        key={creatorSet.id}
        label={creatorSet.creatorset_name}
        value={String(creatorSet.id)}
      />
    );
  });

export const getBrandSelectData = (brandData: BrandRecords) =>
  Object.keys(brandData).map((key) => {
    const brand = brandData[Number(key)];
    return {
      label: brand.brand_name,
      value: String(brand.brand_id),
    };
  });

export const getCampaignParametersSelectData = (
  campaignParameters: BrandInfoCampaignParameters[],
) =>
  campaignParameters.map((parameter) => {
    return {
      label: parameter.name,
      value: String(parameter.id),
    };
  });

export const getBrandCreatorSetSelectData = (creatorSets: BrandInfoCreatorSet[]) =>
  creatorSets.map((creatorSet) => {
    return {
      label: creatorSet.creatorset_name,
      value: String(creatorSet.id),
    };
  });

// Move this into a different file after
export const InputSeedsTextField = ({
  seeds,
  setSeeds,
  label,
  formId,
}: {
  seeds: string[];
  setSeeds: React.Dispatch<React.SetStateAction<string[]>>;
  label: string;
  formId: string;
}) => {
  const [currentSeed, setCurrentSeed] = useState("");
  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        setSeeds((currTags) => [...currTags, currentSeed]);
        setCurrentSeed("");
      }}>
      <TextField
        label={label}
        id={formId}
        onChange={({ value }) => setCurrentSeed(value)}
        tags={seeds.map((item, index) => (
          <Tag
            key={item}
            onRemove={() => {
              setSeeds((currTags) => currTags.filter((_, i) => i !== index));
            }}
            text={item}
          />
        ))}
        value={currentSeed}
      />
    </form>
  );
};

export const PlatformSelector = ({
  platforms,
  setPlatform,
}: {
  platforms: string[];
  setPlatform: React.Dispatch<React.SetStateAction<string>>;
}) => (
  <SelectList
    id="platforms"
    onChange={({ value }) => {
      // Split the string by commas
      setPlatform(value.toLowerCase());
    }}
    label="Platform"
    size="lg">
    {platforms.map((platform) => (
      <SelectList.Option key={platform} label={platform} value={platform} />
    ))}
  </SelectList>
);

export type DatasetTypeTuple = [string, string];

export const DatasetJobTypeSelector = ({
  datasetJobTypes,
  setDatasetJobType,
}: {
  datasetJobTypes: DatasetTypeTuple[];
  setDatasetJobType: React.Dispatch<React.SetStateAction<string>>;
}) => (
  <SelectList
    id="DatasetJobTypes"
    onChange={({ value }) => {
      // Split the string by commas
      setDatasetJobType(value.toLowerCase());
    }}
    label="Dataset Job Type"
    size="lg">
    {datasetJobTypes.map(([key, value]) => (
      <SelectList.Option key={key} label={value} value={key} />
    ))}
  </SelectList>
);

export const addCreatorToCreatorOutreachReq = async (
  user: FirebaseUser,
  campaignId: number,
  emailAddress: string,
  contactFirstname: string,
  profileLink: string,
) => {
  const firebaseToken = await user.getIdToken();
  const request = new Request(`${ADMIN_URL}add_creator_for_outreach`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
    body: JSON.stringify({
      campaignId,
      emailAddress,
      contactFirstname,
      profileLink,
    }),
  });
  const response: CampaignArchetypeResponse = await handleResult(request);
  return response;
};

export const BrandCreatorSetSelector = ({
  brandData,
  platforms,
  brandId,
  setBrandId,
  setCreatorSetId,
  setPlatform,
}: {
  brandData: BrandRecords;
  platforms: string[];
  brandId: number;
  setBrandId: (brandId: number) => void;
  setCreatorSetId: (creatorSetId: number) => void;
  setPlatform: (platform: string) => void;
}) => {
  const [availableCreatorSets, setAvailableCreatorSets] = useState([]);
  const [brandDataIsValid, setBrandDataIsValid] = useState(false);

  useEffect(() => {
    setBrandDataIsValid(brandData !== undefined && Object.keys(brandData).length > 0);
  }, [brandData]);

  useEffect(() => {
    if (
      brandId === 0 ||
      Object.keys(brandData).length === 0 ||
      !brandData[brandId] ||
      !brandData[brandId].creatorsets
    ) {
      setAvailableCreatorSets([]);
      return;
    }
    setAvailableCreatorSets(brandData[brandId].creatorsets);
    // set the creator_set_id to be the first creatorSetId.
    if (availableCreatorSets.length > 0) {
      setCreatorSetId(availableCreatorSets[0].id);
    } else {
      setCreatorSetId(null);
    }
  }, [brandId]);

  return (
    <div>
      {brandDataIsValid ? (
        <Flex direction="row">
          <Box width={125} alignItems="end" direction="row" display="flex">
            <SelectList
              id="brand_name"
              onChange={({ value }) => {
                // Split the string by commas
                setBrandId(Number(value));
              }}
              label="Brand Name"
              size="lg">
              <SelectList.Option key="0" label="Select a Brand" value="0" />
              {getBrandSelectList(brandData)}
            </SelectList>
          </Box>
          <Spacer width={16} />
          <Box width={200}>
            <SelectList
              id="creator_set"
              onChange={({ value }) => {
                // Split the string by commas
                setCreatorSetId(Number(value));
              }}
              label="Creator Set Name"
              size="lg">
              {getCreatorSetSelectList(availableCreatorSets)}
            </SelectList>
          </Box>
          <Spacer width={16} />
          <Box width={125}>
            <PlatformSelector platforms={platforms} setPlatform={setPlatform} />
          </Box>
        </Flex>
      ) : null}
    </div>
  );
};

export default null;
