import React from "react";

import {
  Avatar,
  Button,
  Center,
  Flex,
  Group,
  rem,
  Stack,
  Table,
  Text,
  Title,
  UnstyledButton,
} from "@mantine/core";
import {
  IconBrandInstagram,
  IconBrandTiktok,
  IconBrandYoutubeFilled,
  IconChevronDown,
  IconChevronUp,
  IconCircleCheck,
  IconCircleX,
  IconExternalLink,
  IconSelector,
  IconVideo,
} from "@tabler/icons-react";

import SimpleSocialHandleButtonRow from "components/contracts/common/SimpleSocialHandleButtonRow";
import { SocialHandle } from "components/contracts/dashboard/Utils";

// Avoid using displayUsername
export function getExternalDisplayUsername(socialHandles: SocialHandle[]) {
  const handle = socialHandles.find((h) => h.handle);
  return handle ? handle.handle : "";
}

// Translate deliverable format enum into pretty name
export function makeDeliverableFormatName(deliverableFormatStr: string): string {
  switch (deliverableFormatStr) {
    case "INSTAGRAM_DEDICATED_REEL":
      return "Instagram Reel";
      break;
    case "TIKTOK_DEDICATED_VIDEO":
      return "Tiktok Video";
      break;
    case "YOUTUBE_30S_INTEGRATED_VIDEO":
      return "YouTube 30s Video";
      break;
    case "YOUTUBE_60S_INTEGRATED_VIDEO":
      return "YouTube 60s Video";
      break;
    case "YOUTUBE_90S_INTEGRATED_VIDEO":
      return "YouTube 90s Video";
      break;
    case "YOUTUBE_DEDICATED_VIDEO":
      return "YouTube Dedicated Video";
      break;
    case "YOUTUBE_SHORT":
      return "YouTube Short";
      break;
    default:
      return null;
  }
}

// Translate deliverable format enum into pretty component
export function makeDeliverableFormatRep(deliverableFormat: string, key?: any) {
  let icon = null;
  const text = makeDeliverableFormatName(deliverableFormat);
  switch (deliverableFormat) {
    case "INSTAGRAM_DEDICATED_REEL":
      icon = <IconBrandInstagram size="1.25rem" />;
      break;
    case "TIKTOK_DEDICATED_VIDEO":
      icon = <IconBrandTiktok size="1.25rem" />;
      break;
    case "YOUTUBE_30S_INTEGRATED_VIDEO":
      icon = <IconBrandYoutubeFilled size="1.25rem" />;
      break;
    case "YOUTUBE_60S_INTEGRATED_VIDEO":
      icon = <IconBrandYoutubeFilled size="1.25rem" />;
      break;
    case "YOUTUBE_90S_INTEGRATED_VIDEO":
      icon = <IconBrandYoutubeFilled size="1.25rem" />;
      break;
    case "YOUTUBE_DEDICATED_VIDEO":
      icon = <IconBrandYoutubeFilled size="1.25rem" />;
      break;
    case "YOUTUBE_SHORT":
      icon = <IconBrandYoutubeFilled size="1.25rem" />;
      break;
    case "UGC":
      icon = <IconVideo size="1.25rem" />;
      break;
    default:
      return null;
  }
  return (
    <Group key={key} justify="flex-start" gap="xs" wrap="nowrap" preventGrowOverflow={false}>
      {icon}
      <Title order={6} textWrap="nowrap" fw={400}>
        {text}
      </Title>
    </Group>
  );
}

// Translate a boolean into a pretty icon
export function iconFromBoolean(condition: boolean) {
  return condition ? (
    <IconCircleCheck size="1.5rem" stroke={2.0} color="green" />
  ) : (
    <IconCircleX color="red" size="1.5rem" />
  );
}

// Make the simple action button (only one action right now)
export function actionButton(contractId: number) {
  return (
    <Button
      component="a"
      leftSection={<IconExternalLink size="0.7rem" />}
      size="compact-sm"
      variant="transparent"
      href={`/campaigns/contracts_overview/details/${contractId}`}
      target="_blank"
      disabled={!contractId}>
      View Contract
    </Button>
  );
}

// Make a pretty creator rep
export function makeCreatorRep(
  creatorName: string,
  creatorId: number,
  avatarUrl: string,
  socialHandles: SocialHandle[],
  isStaff: boolean,
) {
  return isStaff ? (
    <Flex align="center" wrap="nowrap" gap="sm">
      <Avatar
        component="a"
        href={`/ops_home/creator?creatorId=${creatorId}`}
        target="_blank"
        src={avatarUrl}
        size="md"
        radius="xl"
      />
      <Stack gap={0.2}>
        <Text
          component="a"
          href={`/admin/creator?creatorId=${creatorId}`}
          target="_blank"
          c="black"
          size="md"
          fw={700}>
          {creatorName}
        </Text>
        <SimpleSocialHandleButtonRow socialHandles={socialHandles} />
      </Stack>
    </Flex>
  ) : (
    <Flex align="center" wrap="nowrap" gap="sm">
      <Avatar src={avatarUrl} size="md" radius="xl" />
      <Stack gap={0.2}>
        <Text size="md" fw={700}>
          {creatorName}
        </Text>
        <SimpleSocialHandleButtonRow socialHandles={socialHandles} />
      </Stack>
    </Flex>
  );
}

// For use with PaginatedTableSort
export function sortData<T>(data: T[], payload: { sortBy: keyof T | null; reversed: boolean }) {
  const { sortBy } = payload;

  return [...data].sort((a, b) => {
    let compareResult = 0;
    const aVal = a[sortBy];
    const bVal = b[sortBy];

    if (typeof aVal === "number" && typeof bVal === "number") {
      compareResult = (aVal as number) - (bVal as number);
    } else if (aVal instanceof Date && bVal instanceof Date) {
      compareResult = (aVal as Date).getTime() - (bVal as Date).getTime();
    } else if (typeof aVal === "boolean" && typeof bVal === "boolean") {
      compareResult = (aVal ? 1 : 0) - (bVal ? 1 : 0);
    } else {
      compareResult = aVal.toString().localeCompare(bVal.toString());
    }
    return payload.reversed ? compareResult : -compareResult;
  });
}

interface ThProps {
  children: React.ReactNode;
  reversed: boolean;
  sorted: boolean;
  onSort(): void;
  // minimum width to pass to the inner Table.Th element
  miw?: any;
}

// Table heading with sorting for use with PaginatedTableSort
export function Th({ children, reversed, sorted, onSort, miw }: ThProps) {
  let Icon: React.ElementType;
  if (sorted) {
    if (reversed) {
      Icon = IconChevronUp;
    } else {
      Icon = IconChevronDown;
    }
  } else {
    Icon = IconSelector;
  }

  return (
    <Table.Th miw={miw}>
      <UnstyledButton onClick={onSort}>
        <Group justify="space-between" wrap="nowrap" preventGrowOverflow={false}>
          <Text fw={500} fz="sm">
            {children}
          </Text>
          <Center>
            <Icon style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
          </Center>
        </Group>
      </UnstyledButton>
    </Table.Th>
  );
}
