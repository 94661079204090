import { API_URL } from "configs/Configs";
import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";

/**
 * Retrive creator-approppriate offer information from the backend.
 *
 * This data is needed for the creator to make a decision on which package to proceed to an offer.
 * @param offerId The UUID of the contract offer
 * @returns A response structure with data interpreted by the caller.
 */
export async function getContractOfferForCreator(offerId: string) {
  const requestUrl = new URL(`${API_URL}/api/offers/get_contract_offer_for_creator`);
  requestUrl.searchParams.append("offerId", offerId);

  const request = await createRequestWithFirebaseToken({ url: requestUrl });

  const response = await handleResult(request);
  return response;
}

export async function creatorAcceptPackage(packageId: string, additionalEmails: string) {
  const requestUrl = new URL(`${API_URL}/api/offers/creator_accept_package`);
  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      packageId,
      additionalEmails,
    }),
  });

  const response = await handleResult(request);
  return response;
}

/**
 * Decline an entire offer.
 *
 * @param offerId A UUID of the offer
 */
export async function creatorDeclineOffer(offerId: string) {
  const requestUrl = new URL(`${API_URL}/api/offers/creator_decline_offer`);
  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      offerId,
    }),
  });

  const response = await handleResult(request);
  return response;
}
