import { ActionIcon, AppShell, Divider, Flex, Image, MantineStyleProp, Stack } from "@mantine/core";
import {
  IconBook,
  IconHome,
  IconLayoutSidebarLeftCollapse,
  IconLogout,
  IconSettings,
  IconVideo,
} from "@tabler/icons-react";
import LoginAsUserSelector from "admin/utils/LoginAsUserSelector";
import { logout } from "auth/firebaseAuthHelpers";
import React from "react";
import logo from "1stcollab_logo.svg";
import NavBarButton from "campaigns/navbar/v2/buttons/components/NavBarButton";
import { useMatch, useNavigate } from "react-router-dom";
import { User } from "models/User";
import CompanyAndUserHeader from "campaigns/navbar/main/CompanyAndUserHeader";
import { CAMPAIGN_PORTAL } from "campaigns/PathConstants";
import TaskNavBarButton from "campaigns/navbar/v2/buttons/TaskNavBarButton";
import DiscoverCreatorsNavBarButton from "campaigns/navbar/v2/buttons/DiscoverCreatorsNavBarButton";
import MyCreatorsNavBarButton from "campaigns/navbar/v2/buttons/MyCreatorsNavBarButton";
import CampaignsNavBarButton from "campaigns/navbar/v2/buttons/CampaignsNavBarButton";
import ResultsNavBarButton from "campaigns/navbar/v2/buttons/ResultsNavBarButton";
import LogoCollapseIcon from "campaigns/navbar/v2/buttons/components/LogoCollapseIcon";

const NavBarV2 = ({
  isCollapsed,
  onCollapseNavBar,
  style,
  user,
}: {
  isCollapsed: boolean;
  onCollapseNavBar: () => void;
  style?: MantineStyleProp;
  user: User;
}) => {
  // navigator
  const navigate = useNavigate();

  const isHomeSelected = useMatch(`${CAMPAIGN_PORTAL}`) != null;
  const isSettingsSelected = useMatch(`${CAMPAIGN_PORTAL}/settings`) != null;
  const isContentLibraryButtonSelected = useMatch(`${CAMPAIGN_PORTAL}/content_library`) != null;

  return (
    <Flex
      direction="column"
      justify="space-between"
      h="100%"
      style={{
        ...(isCollapsed
          ? {
              paddingTop: 16,
              paddingBottom: 16,
              paddingLeft: 24,
              paddingRight: 24,
            }
          : { padding: 16 }),
        ...style,
      }}>
      {/* TOP SECTION START */}
      <AppShell.Section style={{ flexShrink: 0 }}>
        <Stack style={{ "--stack-gap": "16px" }}>
          <Flex justify={isCollapsed ? "center" : "space-between"} align="center">
            {isCollapsed ? (
              <LogoCollapseIcon onClick={onCollapseNavBar} />
            ) : (
              <>
                <Image src={logo} h="40px" />
                <ActionIcon
                  size={24}
                  variant="transparent"
                  aria-label="Collapse Navigation Bar"
                  onClick={onCollapseNavBar}>
                  <IconLayoutSidebarLeftCollapse color="var(--mantine-color-gray-5)" />
                </ActionIcon>
              </>
            )}
          </Flex>
          <Divider
            style={{
              marginLeft: isCollapsed ? -24 : -16,
              marginRight: isCollapsed ? -24 : -16,
            }}
          />
        </Stack>
      </AppShell.Section>
      {/* TOP SECTION END */}
      {/* MAIN SECTION START */}
      <Flex
        direction="column"
        pb="16px"
        pt="16px"
        style={{
          gap: "0px",
          flexGrow: "1",
          overflowY: "scroll",
          ...(isCollapsed
            ? {
                // need to change margin so we can let badge in minimized icons overflow bounds of Button
                marginLeft: -24,
                marginRight: -24,
                alignItems: "center",
              }
            : {}),
        }}>
        <NavBarButton
          isSelected={isHomeSelected}
          LeftIconName={IconHome}
          onClick={() => {
            navigate(`${CAMPAIGN_PORTAL}`);
          }}
          showMinimizedVersion={isCollapsed}
          title="Home"
        />
        <TaskNavBarButton showMinimizedVersion={isCollapsed} />
        <DiscoverCreatorsNavBarButton showMinimizedVersion={isCollapsed} />
        <MyCreatorsNavBarButton showMinimizedVersion={isCollapsed} />
        <NavBarButton
          isSelected={isContentLibraryButtonSelected}
          LeftIconName={IconVideo}
          onClick={() => {
            navigate(`${CAMPAIGN_PORTAL}/content_library`);
          }}
          showMinimizedVersion={isCollapsed}
          title="Content Library"
        />
        <ResultsNavBarButton showMinimizedVersion={isCollapsed} />
        <CampaignsNavBarButton showMinimizedVersion={isCollapsed} />
      </Flex>
      {/* MAIN SECTION END */}
      {/* BOTTOM SECTION START */}
      <AppShell.Section style={{ flexShrink: 0 }}>
        <Stack style={{ "--stack-gap": "12px" }}>
          <Divider
            style={{
              marginLeft: isCollapsed ? -24 : -16,
              marginRight: isCollapsed ? -24 : -16,
            }}
          />
          {isCollapsed ? null : <LoginAsUserSelector />}
          <Stack style={{ "--stack-gap": "0px" }}>
            {isCollapsed ? <LoginAsUserSelector showMinimizedVersion /> : null}
            <NavBarButton
              isSelected={false}
              LeftIconName={IconBook}
              onClick={() => {
                window.open(
                  "https://1stcollab.notion.site/1stCollab-Brand-Product-Guide-c260996ea008436c9837f6078b83bdab",
                  "_blank",
                );
              }}
              showMinimizedVersion={isCollapsed}
              title="Product Guide"
            />
            <NavBarButton
              isSelected={isSettingsSelected}
              LeftIconName={IconSettings}
              onClick={() => {
                navigate(`${CAMPAIGN_PORTAL}/settings`);
              }}
              showMinimizedVersion={isCollapsed}
              title="Settings"
            />
            <NavBarButton
              isSelected={false}
              LeftIconName={IconLogout}
              onClick={() => {
                logout(() => {
                  navigate("/");
                });
              }}
              showMinimizedVersion={isCollapsed}
              title="Logout"
            />
          </Stack>
          <CompanyAndUserHeader user={user} showMinimizedVersion={isCollapsed} />
        </Stack>
      </AppShell.Section>
      {/* BOTTOM SECTION END */}
    </Flex>
  );
};

export default NavBarV2;
