import React from "react";
import { User } from "firebase/auth";

import { Flex, Group, Table, Text, Title } from "@mantine/core";

import { Contract } from "components/contracts/dashboard/Utils";
import PendingPaymentRow from "components/contracts/dashboard/PendingPaymentRow";

export default function PendingPaymentsTable({
  user,
  contracts,
  handleRefetchContract,
  campaignName,
}: {
  user: User;
  contracts: Contract[];
  handleRefetchContract: (contractId: string, handleFetched: () => void) => void;
  campaignName?: string;
}) {
  const totalPendingPayment = contracts.reduce(
    (total, contract) => total + contract.remainingAmountInMinorUnits,
    0,
  );

  const rows = contracts.map((contract) => (
    <PendingPaymentRow
      key={`pending-payment-${contract.contractId}`}
      user={user} // TODO (victoria 3.2024): using for display name in ManualPaymentModal - need to get rid of this
      contractId={contract.contractId}
      displayName={contract.displayName}
      socialHandles={contract.socialHandles}
      amountInMinorUnits={contract.amountInMinorUnits}
      bonusAmountInMinorUnits={
        contract.meetsBonusCondition
          ? contract.earnedBonusAmountInMinorUnits
          : contract.bonusAmountInMinorUnits
      }
      remainingAmountInMinorUnits={contract.remainingAmountInMinorUnits}
      remainingBonusAmountInMinorUnits={contract.remainingBonusAmountInMinorUnits}
      earnedBonusAmountInMinorUnits={contract.earnedBonusAmountInMinorUnits}
      hasBonus={contract.hasBonus}
      bonusCondition={contract.bonusCondition}
      contractStatus={contract.contractStatus}
      paymentStatus={contract.paymentStatus}
      bonusPaymentStatus={contract.bonusPaymentStatus}
      avatarUrl={contract.avatarUrl}
      awaitingPaymentDetails={contract.awaitingPaymentDetails}
      readyForPayment={contract.readyForPayment}
      meetsBonusCondition={contract.meetsBonusCondition}
      hasBonusCondition={contract.hasBonus}
      deliverables={contract.deliverables}
      payments={contract.payments}
      stripeAccountUrl={contract.stripeAccountUrl}
      closeUrl={contract.closeUrl}
      handleRefetchContract={handleRefetchContract}
    />
  ));

  return (
    <>
      <Group align="flex-end">
        <Title order={3} fw="500">
          {campaignName || "Pending Payments"}
        </Title>
        <Text>
          {(totalPendingPayment / 100).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </Text>
      </Group>
      <Table.ScrollContainer minWidth={500}>
        <Table withTableBorder horizontalSpacing="xs" verticalSpacing="xs">
          <Table.Thead>
            <Table.Tr>
              <Table.Th fw={500}>Creator</Table.Th>
              <Table.Th fw={500}>Social Profiles</Table.Th>
              <Table.Th fw={500}>
                <Flex justify="center">Live Date</Flex>
              </Table.Th>
              <Table.Th fw={500}>
                <Flex justify="center">Payment Status</Flex>
              </Table.Th>
              <Table.Th fw={500}>
                <Flex justify="center">Ready for Payment</Flex>
              </Table.Th>
              <Table.Th fw={500}>
                <Flex justify="center">Value</Flex>
              </Table.Th>
              <Table.Th fw={500}>
                <Flex justify="center">Remainder</Flex>
              </Table.Th>
              <Table.Th fw={500}>
                <Flex justify="center">Bonus</Flex>
              </Table.Th>
              <Table.Th fw={500}>
                <Flex justify="center">Bonus Remainder</Flex>
              </Table.Th>
              <Table.Th fw={500}>
                <Flex justify="center">Bonus Condition</Flex>
              </Table.Th>
              <Table.Th />
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {rows.length === 0 && (
              <Table.Tr>
                <Table.Td colSpan={6}>
                  <Text size="sm" c="gray">
                    No contracts pending payment.
                  </Text>
                </Table.Td>
              </Table.Tr>
            )}
            {rows}
          </Table.Tbody>
        </Table>
      </Table.ScrollContainer>
    </>
  );
}
