import React, { useState } from "react";

import { Card, Stack } from "@mantine/core";

import { showFailureNotification, showSuccessNotification } from "components/common/Notifications";
import { CreatorOfferApprovalActionRow } from "components/contracts/brand_review/ContractOfferBrandReviewCard";
import { ContractOfferBrandReview } from "components/contracts/brand_review/types";
import {
  approveContractOfferReview,
  rejectContractOfferReview,
} from "components/contracts/tasks/api/Api";
import { CreatorHeader } from "components/contracts/tasks/content/ContractReviewContent";
import { ContractReviewStatus } from "components/contracts/tasks/models/Common";
import PlatformTable from "components/creator_lists/unified_creator_rep/PlatformTable";

function ActionRow({
  taskId,
  displayName,
  contractReview,
  handleCompleteTask,
}: {
  taskId: string;
  displayName: string;
  contractReview: ContractOfferBrandReview;
  handleCompleteTask: (waitingForCreator?: boolean) => void;
}) {
  // We currently do not use the loading states, but maybe we will in the future
  const [approveLoading, setApproveLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);

  const [checkedStates, setCheckedStates] = useState<boolean[]>(
    Array.from({ length: contractReview.deliverable_packages.length }, () => true),
  );
  const [packageFeedbacks, setPackageFeedbacks] = useState<string[]>(
    Array.from({ length: contractReview.deliverable_packages.length }, () => ""),
  );
  const [contractReviews, setContractReviews] = useState<ContractOfferBrandReview[]>([
    contractReview,
  ]);

  const handleApproveContractOfferReview = () => {
    setApproveLoading(true);

    // Assemble packages approval structures
    const approvals: {
      [uuid: string]: { brand_approved: boolean; max_offer_cap: number; feedback: string };
    } = {};
    // Have to use the one from the reviews list, as it may have had its brand approved price updated
    const review = contractReviews?.at(0);
    if (!review) {
      showFailureNotification({
        title: "Failed to approve packages for creator",
        message: "Unknown error",
      });
      setApproveLoading(false);
      return;
    }
    review.deliverable_packages.forEach((pkg, idx) => {
      approvals[pkg.uuid] = {
        brand_approved: checkedStates[idx],
        max_offer_cap: pkg.brand_approved_price,
        feedback: packageFeedbacks[idx],
      };
    });

    approveContractOfferReview({ taskId, packageApproval: approvals })
      .then((response) => {
        if (response.success) {
          handleCompleteTask();
          showSuccessNotification({
            title: "Creator approved",
            message: `Packages for ${displayName} have been approved.`,
          });
        } else {
          showFailureNotification({
            title: "Failed to approve packages for creator",
            message: response.error,
          });
        }
      })
      .catch((e) => {
        showFailureNotification({
          title: "Failed to approve packages for creator",
          message: e.message,
        });
      })
      .finally(() => {
        setApproveLoading(false);
      });
  };

  const handleRejectContractOfferReview = () => {
    setRejectLoading(true);
    rejectContractOfferReview({ taskId })
      .then((response) => {
        if (response.success) {
          handleCompleteTask();
          showSuccessNotification({
            title: "Creator declined",
            message: `${displayName} has been declined and will not be a part of this campaign.`,
          });
        } else {
          showFailureNotification({
            title: "Failed to decline creator",
            message: response.error,
          });
        }
      })
      .catch((e) => {
        showFailureNotification({
          title: "Failed to decline creator",
          message: e.message,
        });
      })
      .finally(() => {
        setRejectLoading(false);
      });
  };

  const inReview = contractReview.brand_review_status === ContractReviewStatus.IN_REVIEW;
  const accepted = contractReview.brand_review_status === ContractReviewStatus.APPROVED;
  const rejected = contractReview.brand_review_status === ContractReviewStatus.REJECTED;

  return (
    <CreatorOfferApprovalActionRow
      contractReview={contractReview}
      isAccepted={accepted}
      isRejected={rejected}
      isInReview={inReview}
      showAdminOptions={false}
      checkedStates={checkedStates}
      setCheckedStates={setCheckedStates}
      packageFeedbacks={packageFeedbacks}
      setPackageFeedbacks={setPackageFeedbacks}
      setContractReviews={setContractReviews}
      approveOffer={handleApproveContractOfferReview}
      declineCreator={handleRejectContractOfferReview}
    />
  );
}

export default function ContractOfferReviewContent({
  taskId,
  contractReview,
  deadline,
  handleCompleteTask,
}: {
  taskId: string;
  contractReview: ContractOfferBrandReview;
  deadline: Date;
  handleCompleteTask: (waitingForCreator?: boolean) => void;
}) {
  // NOTE(albert, 11/14/24): We set this as a state variable so that the
  // PlatformTable doesn't re-render every time the ActionRow re-renders.
  // This is a hack to prevent unnecessary re-renders, since the underlying
  // has a useEffect that depends on this value.
  const [defaultExpandedPlatforms] = useState<string[]>([]);

  // We take the suggested price as the approval price whenever there isn't an approval price.
  const contractReviewWithDefaultPrice = {
    ...contractReview,
    deliverable_packages: contractReview.deliverable_packages.map((pkg) => {
      if (!pkg.brand_approved_price || pkg.brand_approved_price <= 0) {
        return {
          ...pkg,
          brand_approved_price: pkg.suggested_price,
        };
      }
      return pkg;
    }),
  };

  const displayName =
    contractReview.creator_details.youtube_channel?.title ||
    contractReview.creator_details.tiktok_profile?.info?.display_name ||
    contractReview.creator_details.instagram_profile?.info?.display_name;

  return (
    <Card radius="md" mt="sm" withBorder>
      <Stack gap="xs">
        <CreatorHeader
          displayName={displayName}
          creatorDetails={contractReviewWithDefaultPrice.creator_details}
          deadline={deadline}
        />
        <PlatformTable
          creator={contractReviewWithDefaultPrice.creator_details}
          defaultExpandedPlatforms={defaultExpandedPlatforms}
          refetchThumbnails
        />
        <ActionRow
          taskId={taskId}
          displayName={displayName}
          contractReview={contractReviewWithDefaultPrice}
          handleCompleteTask={handleCompleteTask}
        />
      </Stack>
    </Card>
  );
}
