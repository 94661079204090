import React, { useState } from "react";

import { Stack, Text } from "@mantine/core";

import { RelatedTask } from "components/contracts/tasks/models/Task";

import CollapsibleCard from "components/contracts/common/CollapsibleCard";
import TaskCardHeader from "components/contracts/tasks/cards/TaskCardHeader";
import TaskCardContent from "components/contracts/tasks/content/TaskCardContent";
import { TaskStage } from "components/contracts/tasks/models/Common";

function RelatedTaskCard({
  task,
  handleCompleteRelatedTask,
  completedTaskIds,
}: {
  task: RelatedTask;
  handleCompleteRelatedTask: (
    relatedTaskId: string,
    relatedTaskType: TaskStage,
    requireCreatorAction?: boolean,
  ) => void;
  completedTaskIds: Set<string>;
}) {
  const [waitingForCreatorStatus, setWaitingForCreatorStatus] = useState(task.waitingForCreator);

  const handleCompleteTask = (requireCreatorAction?: boolean) => {
    handleCompleteRelatedTask(task.hashId, task.taskType, requireCreatorAction);
    if (requireCreatorAction) {
      setWaitingForCreatorStatus(true);
    }
  };

  return (
    <CollapsibleCard
      isOpen={false}
      disableHoverStyle
      lazyLoadContent
      header={
        <TaskCardHeader
          deliverableFormat={task.format}
          platform={task.platform}
          deadline={task.deadline}
          taskType={task.taskType}
          referralLinkStatus={task.referralLinkStatus}
          promoCodeStatus={task.promoCodeStatus}
          numUnreadMessages={task.numUnreadMessages}
          waitingForCreator={waitingForCreatorStatus}
          showAsCompleted={!waitingForCreatorStatus && completedTaskIds.has(task.hashId)}
        />
      }
      content={
        <TaskCardContent
          taskType={task.taskType}
          taskId={task.hashId}
          handleCompleteTask={handleCompleteTask}
        />
      }
    />
  );
}

export default function RelatedTasks({
  tasks,
  handleCompleteRelatedTask,
  completedTaskIds,
}: {
  tasks: RelatedTask[];
  handleCompleteRelatedTask: (
    relatedTaskId: string,
    relatedTaskType: TaskStage,
    requireCreatorAction?: boolean,
  ) => void;
  completedTaskIds: Set<string>;
}) {
  if (!tasks || tasks.length === 0) {
    return null;
  }

  return (
    <Stack mt="sm" gap="sm">
      <Text>Other tasks in this contract:</Text>
      {tasks.map((task) => (
        <RelatedTaskCard
          key={task.hashId}
          task={task}
          handleCompleteRelatedTask={handleCompleteRelatedTask}
          completedTaskIds={completedTaskIds}
        />
      ))}
    </Stack>
  );
}
