import { Loader, Stack, Tabs, Text, Title } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "reduxStore/hooks";
import { fetchMyBrand } from "reduxStore/meSlice";

import AccountSettings from "brand/settings/AccountSettings";
import BrandAbout from "brand/settings/BrandAbout";
import BrandContact from "brand/settings/BrandContact";
import { BrandOutreach } from "brand/settings/BrandOutreach";
import { Brand } from "models/Brand";

const BrandSettings = ({ brand }: { brand: Brand }) => {
  return (
    <Stack>
      <BrandAbout brand={brand} />
      <BrandContact brand={brand} />
      <BrandOutreach brand={brand} />
    </Stack>
  );
};

const Settings = () => {
  const dispatch = useAppDispatch();
  const brand = useAppSelector((state) => state.me.brand);
  const user = useAppSelector((state) => state.me.user);

  const [isFetchingBrand, setIsFetchingBrand] = useState<boolean>(false);

  useEffect(() => {
    const abortController = new AbortController();

    setIsFetchingBrand(true);
    dispatch(fetchMyBrand({ abortController }))
      .unwrap()
      .then(({ aborted }) => {
        if (!aborted) {
          setIsFetchingBrand(false);
        }
      });

    return () => {
      abortController.abort();
    };
  }, []);

  if (isFetchingBrand) {
    return <Loader />;
  }
  if (!brand) {
    return null;
  }

  if (!user.notification_preferences || !user.notification_preferences.enable_notifications) {
    return (
      <Stack style={{ "--stack-gap": "24px" }}>
        <Title order={1}>Brand Settings</Title>
        <BrandSettings brand={brand} />
      </Stack>
    );
  }
  return (
    <Stack style={{ "--stack-gap": "24px" }}>
      <Title order={1}>Settings</Title>
      <Tabs defaultValue="brand">
        <Tabs.List mb="lg">
          <Tabs.Tab value="brand">
            <Text fw="500" size="sm">
              Brand Settings
            </Text>
          </Tabs.Tab>
          <Tabs.Tab value="account">
            <Text fw="500" size="sm">
              Account Settings
            </Text>
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="brand">
          <BrandSettings brand={brand} />
        </Tabs.Panel>
        <Tabs.Panel value="account">
          <AccountSettings brand={brand} user={user} />
        </Tabs.Panel>
      </Tabs>
    </Stack>
  );
};

export default Settings;
