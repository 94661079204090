import React from "react";

import { toShortDateString } from "utils/DateUtils";
import { User } from "firebase/auth";

import Deliverable from "components/contracts/models/Deliverable";

import { ContractStatus, PaymentStatus } from "components/contracts/common/Common";

import { Group, Stack, Table, Text, Tooltip, ThemeIcon } from "@mantine/core";

import { IconInfoCircle } from "@tabler/icons-react";

import ContractActions from "components/contracts/dashboard/ContractActions";
import ContractAmount from "components/contracts/dashboard/ContractAmount";
import CreatorAvatar from "components/contracts/common/CreatorAvatar";
import PayButton from "components/contracts/dashboard/PayButton";
import SocialHandleButtons from "components/contracts/dashboard/SocialHandleButtons";

import { SEMI_BOLD_FONT_WEIGHT } from "components/contracts/dashboard/Constants";
import { SocialHandle } from "components/contracts/dashboard/Utils";

import PaymentStatusBadge from "components/contracts/dashboard/PaymentStatusBadge";
import ReadyForPayment from "components/contracts/dashboard/ReadyForPayment";

import { Payment, PaymentType } from "components/contracts/models/Payment";

function LastLiveDate({ deliverables }: { deliverables: Deliverable[] }) {
  const maxLiveDate = new Date(
    Math.max(
      ...deliverables.map(
        (deliverable) =>
          deliverable?.liveContentSubmissionDatetime?.getTime() ||
          deliverable.lastUpdated.getTime(),
      ),
    ),
  );
  return <Text fw={SEMI_BOLD_FONT_WEIGHT}>{toShortDateString(maxLiveDate)}</Text>;
}

export default function PendingPaymentRow({
  user,
  contractId,
  displayName,
  socialHandles,
  amountInMinorUnits,
  bonusAmountInMinorUnits,
  remainingAmountInMinorUnits,
  earnedBonusAmountInMinorUnits,
  remainingBonusAmountInMinorUnits,
  contractStatus,
  paymentStatus,
  bonusPaymentStatus,
  hasBonus,
  bonusCondition,
  avatarUrl,
  awaitingPaymentDetails,
  readyForPayment,
  meetsBonusCondition,
  hasBonusCondition,
  deliverables,
  payments,
  stripeAccountUrl,
  closeUrl,
  handleRefetchContract,
}: {
  user: User;
  contractId: string;
  displayName: string;
  socialHandles: SocialHandle[];
  amountInMinorUnits: number;
  bonusAmountInMinorUnits: number;
  remainingAmountInMinorUnits: number;
  remainingBonusAmountInMinorUnits: number;
  earnedBonusAmountInMinorUnits: number;
  contractStatus: ContractStatus;
  paymentStatus: PaymentStatus;
  bonusPaymentStatus: PaymentStatus;
  hasBonus: boolean;
  bonusCondition: string;
  avatarUrl: string;
  awaitingPaymentDetails: boolean;
  readyForPayment: boolean;
  meetsBonusCondition: boolean;
  hasBonusCondition: boolean;
  deliverables: Deliverable[];
  payments: Payment[];
  stripeAccountUrl: string;
  closeUrl: string;
  handleRefetchContract: (contractId: string, handleFetched: () => void) => void;
}) {
  return (
    <Table.Tr>
      <Table.Td>
        <CreatorAvatar displayName={displayName} avatarUrl={avatarUrl} />
      </Table.Td>
      <Table.Td>
        <SocialHandleButtons socialHandles={socialHandles} />
      </Table.Td>
      <Table.Td style={{ textAlign: "center" }}>
        <LastLiveDate deliverables={deliverables} />
      </Table.Td>
      <Table.Td style={{ textAlign: "center" }}>
        <PaymentStatusBadge readyForPayment={readyForPayment} paymentStatus={paymentStatus} />
      </Table.Td>
      <Table.Td style={{ textAlign: "center" }}>
        <ReadyForPayment
          contractAccepted
          contractComplete={contractStatus === ContractStatus.COMPLETE}
          paymentStatus={paymentStatus}
          awaitingPaymentDetails={awaitingPaymentDetails}
          readyForPayment={readyForPayment}
        />
      </Table.Td>
      <Table.Td style={{ textAlign: "center" }}>
        <ContractAmount amountInMinorUnits={amountInMinorUnits} />
      </Table.Td>
      <Table.Td style={{ textAlign: "center" }}>
        <ContractAmount amountInMinorUnits={remainingAmountInMinorUnits} />
      </Table.Td>
      <Table.Td style={{ textAlign: "center" }}>
        <ContractAmount amountInMinorUnits={bonusAmountInMinorUnits || 0} />
      </Table.Td>
      <Table.Td style={{ textAlign: "center" }}>
        <ContractAmount amountInMinorUnits={remainingBonusAmountInMinorUnits || 0} />
      </Table.Td>
      <Table.Td style={{ textAlign: "center" }}>
        {hasBonus && (
          <Tooltip label={bonusCondition}>
            <ThemeIcon style={{ cursor: "pointer" }} size="1.6rem" variant="subtle" color="gray">
              <IconInfoCircle />
            </ThemeIcon>
          </Tooltip>
        )}
      </Table.Td>
      <Table.Td>
        <Group gap="sm" justify="right" align="center">
          <Stack gap="xs">
            <PayButton
              contractId={contractId}
              readyForPayment={!awaitingPaymentDetails && readyForPayment}
              paymentStatus={paymentStatus}
              paymentType={PaymentType.BASE}
              displayName={displayName}
              remainingAmountInMinorUnits={remainingAmountInMinorUnits}
              handleRefetchContract={handleRefetchContract}
            />
            {hasBonus && (
              <PayButton
                contractId={contractId}
                readyForPayment={!awaitingPaymentDetails && readyForPayment}
                meetsBonusCondition={meetsBonusCondition}
                paymentStatus={bonusPaymentStatus}
                paymentType={PaymentType.BONUS}
                displayName={displayName}
                remainingAmountInMinorUnits={remainingBonusAmountInMinorUnits}
                handleRefetchContract={handleRefetchContract}
              />
            )}
          </Stack>
          <ContractActions
            user={user} // TODO (victoria 3.2024): using for display name in ManualPaymentModal - need to get rid of this
            contractId={contractId}
            archived={false}
            contractStatus={contractStatus}
            paymentStatus={paymentStatus}
            bonusPaymentStatus={bonusPaymentStatus}
            readyForPayment={readyForPayment}
            meetsBonusCondition={meetsBonusCondition}
            hasBonusCondition={hasBonusCondition}
            remainingAmountInMinorUnits={remainingAmountInMinorUnits}
            bonusAmountInMinorUnits={bonusAmountInMinorUnits}
            earnedBonusAmountInMinorUnits={earnedBonusAmountInMinorUnits}
            displayName={displayName}
            payments={payments}
            stripeAccountUrl={stripeAccountUrl}
            closeUrl={closeUrl}
            handleRefetchContract={handleRefetchContract}
            hideDelete
          />
        </Group>
      </Table.Td>
    </Table.Tr>
  );
}
