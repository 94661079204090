import {
  Badge,
  Box,
  Button,
  DefaultMantineColor,
  Flex,
  Group,
  Indicator,
  Popover,
  Text,
  Tooltip,
} from "@mantine/core";
import { Icon, IconChevronDown, IconChevronUp, IconProps } from "@tabler/icons-react";
import { NAV_BAR_WIDTH } from "campaigns/navbar/consts";
import React, { useState } from "react";

interface ShowBadgeProps {
  isVisible: boolean;
  badgeColor?: DefaultMantineColor;
  badgeText: React.ReactNode;
}

const RightSectionContent = ({
  isExpanded,
  showBadgeProps,
  showExpansionToggle,
}: {
  isExpanded: boolean;
  showBadgeProps: ShowBadgeProps | null;
  showExpansionToggle: boolean;
}) => {
  if (showBadgeProps && showBadgeProps.isVisible) {
    return (
      <Badge color={showBadgeProps.badgeColor || "var(--mantine-color-red-6)"}>
        {showBadgeProps.badgeText}
      </Badge>
    );
  }

  const IconName = isExpanded ? IconChevronUp : IconChevronDown;

  return showExpansionToggle ? (
    <IconName height={20} width={20} color="var(--mantine-color-gray-6)" />
  ) : null;
};

type NavBarButtonType = "primary" | "secondary" | "secondaryHover";

function getButtonUnselectedColor(variant: NavBarButtonType): DefaultMantineColor {
  switch (variant) {
    case "primary":
      return "var(--mantine-color-black)";
    case "secondary":
      return "var(--mantine-color-gray-6)";
    case "secondaryHover":
      return "var(--mantine-color-gray-6)";
    default:
      return "var(--mantine-color-black)"; // default
  }
}

const ButtonIcon = ({
  isSelected,
  LeftIconName,
  showBadgeProps = null,
  showIndicator = false,
}: {
  isSelected: boolean;
  LeftIconName?: React.ForwardRefExoticComponent<
    Omit<IconProps, "ref"> & React.RefAttributes<Icon>
  >;
  showBadgeProps?: ShowBadgeProps | null;
  showIndicator?: boolean;
}) => {
  const icon = (
    <LeftIconName
      color={isSelected ? "var(--mantine-color-white)" : "var(--mantine-color-gray-6)"}
      height={24}
      style={{
        flexShrink: 0,
      }}
      width={24}
    />
  );

  // showBadgeProps takes precedence over showIndicator
  if (showBadgeProps && showBadgeProps.isVisible) {
    return (
      <Indicator
        color={showBadgeProps.badgeColor || "var(--mantine-color-red-6)"}
        label={showBadgeProps.badgeText}
        style={{
          "--indicator-translate-x": "70%",
          "--indicator-translate-y": "-25%",
        }}
        withBorder
        size={18.66}>
        {icon}
      </Indicator>
    );
  }

  return showIndicator ? (
    <Indicator
      style={{
        "--indicator-translate-x": "-20%",
        "--indicator-translate-y": "-10%",
      }}
      color="var(--mantine-color-red-6)"
      size={10}
      withBorder>
      {icon}
    </Indicator>
  ) : (
    icon
  );
};

const NavBarButton = ({
  hoverChildren = null,
  isExpanded = false,
  isSelected,
  LeftIconName,
  onClick,
  showBadgeProps = null,
  showExpansionToggle = false,
  showIndicator = false,
  showMinimizedVersion = false,
  title,
  variant = "primary",
}: {
  hoverChildren?: React.ReactNode | null;
  isExpanded?: boolean;
  isSelected: boolean;
  LeftIconName?: React.ForwardRefExoticComponent<
    Omit<IconProps, "ref"> & React.RefAttributes<Icon>
  >;
  onClick: () => void;
  showBadgeProps?: ShowBadgeProps | null;
  showExpansionToggle?: boolean;
  showIndicator?: boolean;
  showMinimizedVersion?: boolean;
  title: string;
  variant?: NavBarButtonType;
}) => {
  const [isButtonHovered, setIsButtonHovered] = useState<boolean>(false);
  const [isPopupMenuHovered, setIsPopupMenuHovered] = useState<boolean>(false);

  const buttonUnselectedColor = getButtonUnselectedColor(variant);

  const mainButtonContent = (
    <Button
      onMouseEnter={() => setIsButtonHovered(true)}
      onMouseLeave={() => setIsButtonHovered(false)}
      fullWidth={!showMinimizedVersion}
      justify="space-between"
      onClick={onClick}
      pb="10.5px"
      pl={variant === "secondary" ? "30px" : "12px"}
      pr="12px"
      pt="10.5px"
      rightSection={
        showMinimizedVersion ? null : (
          <Flex style={{ flexShrink: 0 }}>
            <RightSectionContent
              isExpanded={isExpanded}
              showBadgeProps={showBadgeProps}
              showExpansionToggle={showExpansionToggle}
            />
          </Flex>
        )
      }
      style={{
        border: "0px",
        flexShrink: 0,
        height: "fit-content",
        ...(!isSelected ? { "--button-hover": "var(--mantine-color-blue-0)" } : {}),
        ...(showMinimizedVersion ? { overflow: "visible" } : {}), // allow badging to overflow bounds and be visible
      }}
      styles={{
        label: {
          flexShrink: showMinimizedVersion ? 0 : 1, // can shrink text but don't shrink icon
          ...(showMinimizedVersion ? { overflow: "visible" } : {}),
        },
      }}
      variant={isSelected ? "filled" : "transparent"}>
      {showMinimizedVersion ? (
        <ButtonIcon
          isSelected={isSelected}
          LeftIconName={LeftIconName}
          showBadgeProps={showBadgeProps}
          showIndicator={showIndicator}
        />
      ) : (
        <Flex
          align="center"
          justify="flex-start"
          style={{
            gap: 12,
            minWidth: 0, // An initial setting on flex items is min-width: auto. This means that a flex item, by default, cannot be smaller than the size of its content. Need this to support text-overflow: ellipsis, overflow: hidden
          }}>
          <ButtonIcon isSelected={isSelected} LeftIconName={LeftIconName} />
          <Group style={{ gap: 8, overflow: "hidden" }}>
            <Text
              c={isSelected ? "var(--mantine-color-white)" : buttonUnselectedColor}
              fw={600}
              lh="var(--mantine-line-height-sm)"
              size="md"
              style={{ flexShrink: 1, overflow: "hidden", textOverflow: "ellipsis" }}>
              {title}
            </Text>
            {showIndicator ? (
              <Indicator color="var(--mantine-color-red-6)" position="middle-center" size={8}>
                <Box style={{ width: 8 }} />
              </Indicator>
            ) : null}
          </Group>
        </Flex>
      )}
    </Button>
  );

  return showMinimizedVersion ? (
    <Popover
      transitionProps={{
        keepMounted: true,
        exitDelay: 100,
      }}
      width={NAV_BAR_WIDTH - 24} // paddingX in original nav bar is 16, in popup its just 4 (so 16 - 4 = 12 on each side, 12 * 2 = 24 for right and left padding)
      shadow="md"
      disabled={!showMinimizedVersion || !hoverChildren}
      position="right-start"
      offset={{
        crossAxis: 36, // height of label tooltip is 30 + 6 for padding
        mainAxis: 5,
      }}
      trapFocus
      opened={isButtonHovered || isPopupMenuHovered}>
      <Popover.Target>
        <Tooltip
          label={title}
          events={{
            hover: showMinimizedVersion,
            focus: false,
            touch: false,
          }}
          color="var(--mantine-color-dark-3)"
          position={hoverChildren ? "right-start" : "right"}>
          {mainButtonContent}
        </Tooltip>
      </Popover.Target>
      <Popover.Dropdown
        onMouseEnter={() => setIsPopupMenuHovered(true)}
        onMouseLeave={() => setIsPopupMenuHovered(false)}
        style={{
          padding: 4,
        }}>
        {hoverChildren}
      </Popover.Dropdown>
    </Popover>
  ) : (
    mainButtonContent
  );
};

export default NavBarButton;
