import React from "react";

import { ThemeIcon } from "@mantine/core";

import { IconCheck, IconVideo } from "@tabler/icons-react";

import { DeliverableVideo } from "components/contracts/tasks/models/Common";

import DeliverableStep from "components/contracts/tasks/cards/DeliverableStep";
import VideoDraftContentDisplay from "components/contracts/tasks/content/VideoDraftContentDisplay";
import { ContentStatus } from "components/contracts/common/Common";

function VideoDraftStep({
  taskId,
  displayName,
  deliverableVideo,
  version,
  isCurrent,
  isLatestVideoDraft,
  missingReferralLink,
  missingPromoCode,
  waitingForCreator,
  handleCompleteTask,
  numUnreadMessages,
  messagesLastRead,
  clearUnreadMessages,
  showAdminOptions,
}: {
  taskId: string;
  displayName: string;
  deliverableVideo: DeliverableVideo;
  version: number;
  isCurrent: boolean;
  isLatestVideoDraft: boolean;
  missingReferralLink: boolean;
  missingPromoCode: boolean;
  waitingForCreator?: boolean;
  handleCompleteTask?: (requireCreatorAction?: boolean) => void;
  numUnreadMessages?: number;
  messagesLastRead?: Date;
  clearUnreadMessages?: () => void;
  showAdminOptions?: boolean;
}) {
  const isApproved = deliverableVideo.status === ContentStatus.APPROVED;

  let label = null;
  let icon = null;

  if (isApproved) {
    label = "Approved Video";
    icon = (
      <ThemeIcon radius="xl" size="1.4rem">
        <IconCheck size="0.9rem" />
      </ThemeIcon>
    );
  } else if (waitingForCreator && isLatestVideoDraft) {
    label = "Current Video Draft";
    icon = (
      <ThemeIcon radius="xl" size="1.4rem" color="yellow">
        <IconVideo size="0.9rem" />
      </ThemeIcon>
    );
  } else if (isCurrent) {
    label = "Current Video Draft";
    icon = (
      <ThemeIcon radius="xl" size="1.4rem" color="red">
        <IconVideo size="0.9rem" />
      </ThemeIcon>
    );
  } else {
    label = `Video Draft #${version}`;
    icon = (
      <ThemeIcon radius="xl" size="1.4rem" color="blue" variant="light">
        <IconVideo size="0.9rem" />
      </ThemeIcon>
    );
  }

  return (
    <DeliverableStep
      isOpen={(isCurrent && !waitingForCreator) || (numUnreadMessages && numUnreadMessages > 0)}
      label={label}
      submissionDate={deliverableVideo.dateCreated}
      icon={icon}
      content={
        <VideoDraftContentDisplay
          taskId={taskId}
          displayName={displayName}
          deliverableVideo={deliverableVideo}
          isLatestVideoDraft={isLatestVideoDraft}
          missingReferralLink={missingReferralLink}
          missingPromoCode={missingPromoCode}
          handleCompleteTask={handleCompleteTask}
          messagesLastRead={messagesLastRead}
          clearUnreadMessages={clearUnreadMessages}
          showAdminOptions={showAdminOptions}
        />
      }
      reviewDate={deliverableVideo.reviewDate}
      reviewStatus={deliverableVideo.status}
    />
  );
}

export default function VideoDraftStage({
  taskId,
  displayName,
  videoDrafts,
  missingReferralLink,
  missingPromoCode,
  waitingForCreator,
  handleCompleteTask,
  messagesLastRead,
  clearUnreadMessages,
  showAdminOptions,
}: {
  taskId: string;
  displayName: string;
  videoDrafts: DeliverableVideo[];
  missingReferralLink: boolean;
  missingPromoCode: boolean;
  waitingForCreator?: boolean;
  handleCompleteTask: (requireCreatorAction?: boolean) => void;
  messagesLastRead?: Date;
  clearUnreadMessages?: () => void;
  showAdminOptions?: boolean;
}) {
  if (!videoDrafts || videoDrafts.length === 0) {
    return null;
  }

  return (
    <>
      {[...videoDrafts].reverse().map((deliverableVideo, index) => (
        <VideoDraftStep
          key={`${deliverableVideo.videoId}-${deliverableVideo.deliverableId}`}
          taskId={taskId}
          displayName={displayName}
          deliverableVideo={deliverableVideo}
          version={index + 1}
          isCurrent={deliverableVideo.status === ContentStatus.PENDING_REVIEW}
          isLatestVideoDraft={index === videoDrafts.length - 1}
          missingReferralLink={missingReferralLink}
          missingPromoCode={missingPromoCode}
          waitingForCreator={waitingForCreator}
          handleCompleteTask={handleCompleteTask}
          numUnreadMessages={deliverableVideo.numUnreadMessages}
          messagesLastRead={messagesLastRead}
          clearUnreadMessages={clearUnreadMessages}
          showAdminOptions={showAdminOptions}
        />
      ))}
    </>
  );
}
