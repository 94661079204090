import React, { useState } from "react";

import { toLongDateString, toLongTimeDateString } from "utils/DateUtils";

import { Anchor, Box, Collapse, Group, Space, Stack, Text, Timeline } from "@mantine/core";

import {
  ContractType,
  SUPPORTED_FORMATS_TO_DELIVERABLE_STRINGS,
} from "components/contracts/common/Common";

import {
  SupportedFormat,
  SupportedPlatform,
  SUPPORTED_PLATFORMS_TO_DISPLAY_NAMES,
} from "models/Common";

import {
  IconCircleCheck,
  IconCircleX,
  IconFileDescription,
  IconVideo,
  IconVideoPlus,
} from "@tabler/icons-react";

import { ContractDeliverableStatus } from "components/contracts/models/Deliverable";

import DeliverableVideo from "components/contracts/models/DeliverableVideo";
import ReferralLinkAndPromoCode from "components/contracts/deliverables/ReferralLinkAndPromoCode";
import ScriptDraftTool from "components/contracts/deliverables/ScriptDraftTool";
import ScriptEducation from "components/contracts/deliverables/ScriptEducation";
import VideoDraftTool from "components/contracts/deliverables/VideoDraftTool";
import LiveContentSubmission from "components/contracts/deliverables/live_content/LiveContentSubmission";
import { Chevron } from "components/contracts/common/CollapsibleCard";
import { useDisclosure } from "@mantine/hooks";

enum TimelineBulletState {
  INACTIVE = "inactive",
  AWAITING_FEEDBACK = "waiting",
  PENDING = "pending",
  ACTION_REQUIRED = "action_required",
  COMPLETE = "complete",
}

const TimelineBulletStateToColor = {
  [TimelineBulletState.INACTIVE]: "gray",
  [TimelineBulletState.AWAITING_FEEDBACK]: "yellow",
  [TimelineBulletState.PENDING]: "yellow",
  [TimelineBulletState.ACTION_REQUIRED]: "red",
  [TimelineBulletState.COMPLETE]: "teal",
};

// Implement overdue state
function getTimelineState({
  status,
}: {
  brandName: string;
  format: SupportedFormat;
  status: ContractDeliverableStatus;
  scriptDate: Date;
  videoDraftDate: Date;
  liveDate: Date;
  analyticsDate: Date;
}) {
  let scriptState = TimelineBulletState.INACTIVE;
  let videoDraftState = TimelineBulletState.INACTIVE;
  let liveState = TimelineBulletState.INACTIVE;
  let analyticsState = TimelineBulletState.INACTIVE;

  switch (status) {
    case ContractDeliverableStatus.WAITING_FOR_SCRIPT:
      scriptState = TimelineBulletState.PENDING;
      break;
    case ContractDeliverableStatus.SCRIPT_SUBMITTED:
      scriptState = TimelineBulletState.AWAITING_FEEDBACK;
      break;
    case ContractDeliverableStatus.SCRIPT_REVISIONS_REQUESTED:
      scriptState = TimelineBulletState.ACTION_REQUIRED;
      break;
    case ContractDeliverableStatus.SCRIPT_APPROVED:
    case ContractDeliverableStatus.WAITING_FOR_VIDEO_DRAFT:
      scriptState = TimelineBulletState.COMPLETE;
      videoDraftState = TimelineBulletState.PENDING;
      break;
    case ContractDeliverableStatus.VIDEO_DRAFT_SUBMITTED:
      scriptState = TimelineBulletState.COMPLETE;
      videoDraftState = TimelineBulletState.AWAITING_FEEDBACK;
      break;
    case ContractDeliverableStatus.VIDEO_DRAFT_REVISIONS_REQUESTED:
      scriptState = TimelineBulletState.COMPLETE;
      videoDraftState = TimelineBulletState.ACTION_REQUIRED;
      break;
    case ContractDeliverableStatus.VIDEO_DRAFT_APPROVED:
    case ContractDeliverableStatus.WAITING_FOR_LIVE_CONTENT:
      scriptState = TimelineBulletState.COMPLETE;
      videoDraftState = TimelineBulletState.COMPLETE;
      liveState = TimelineBulletState.PENDING;
      break;
    case ContractDeliverableStatus.LIVE_CONTENT_SUBMITTED:
      scriptState = TimelineBulletState.COMPLETE;
      videoDraftState = TimelineBulletState.COMPLETE;
      liveState = TimelineBulletState.AWAITING_FEEDBACK;
      break;
    case ContractDeliverableStatus.LIVE_CONTENT_REVISIONS_REQUESTED:
      scriptState = TimelineBulletState.COMPLETE;
      videoDraftState = TimelineBulletState.COMPLETE;
      liveState = TimelineBulletState.ACTION_REQUIRED;
      break;
    case ContractDeliverableStatus.LIVE_CONTENT_APPROVED:
    case ContractDeliverableStatus.WAITING_FOR_ASSETS_AND_ANALYTICS:
    case ContractDeliverableStatus.WAITING_FOR_ASSETS:
    case ContractDeliverableStatus.WAITING_FOR_ANALYTICS:
      scriptState = TimelineBulletState.COMPLETE;
      videoDraftState = TimelineBulletState.COMPLETE;
      liveState = TimelineBulletState.COMPLETE;
      analyticsState = TimelineBulletState.PENDING;
      break;
    case ContractDeliverableStatus.ASSETS_AND_ANALYTICS_SUBMITTED:
      scriptState = TimelineBulletState.COMPLETE;
      videoDraftState = TimelineBulletState.COMPLETE;
      liveState = TimelineBulletState.COMPLETE;
      analyticsState = TimelineBulletState.AWAITING_FEEDBACK;
      break;
    case ContractDeliverableStatus.ASSETS_AND_ANALYTICS_REVISIONS_REQUESTED:
      scriptState = TimelineBulletState.COMPLETE;
      videoDraftState = TimelineBulletState.COMPLETE;
      liveState = TimelineBulletState.COMPLETE;
      analyticsState = TimelineBulletState.ACTION_REQUIRED;
      break;
    case ContractDeliverableStatus.ASSETS_AND_ANALYTICS_APPROVED:
    case ContractDeliverableStatus.COMPLETE:
      scriptState = TimelineBulletState.COMPLETE;
      videoDraftState = TimelineBulletState.COMPLETE;
      liveState = TimelineBulletState.COMPLETE;
      analyticsState = TimelineBulletState.COMPLETE;
      break;
    default:
      scriptState = TimelineBulletState.INACTIVE;
      videoDraftState = TimelineBulletState.INACTIVE;
      liveState = TimelineBulletState.INACTIVE;
      analyticsState = TimelineBulletState.INACTIVE;
      break;
  }

  let scriptActive = false;
  let videoActive = false;
  let liveActive = false;
  let analyticsActive = false;
  let activeIndex;
  switch (status) {
    case ContractDeliverableStatus.WAITING_FOR_SCRIPT:
    case ContractDeliverableStatus.SCRIPT_SUBMITTED:
    case ContractDeliverableStatus.SCRIPT_REVISIONS_REQUESTED:
      scriptActive = true;
      activeIndex = 0;
      break;
    case ContractDeliverableStatus.SCRIPT_APPROVED:
    case ContractDeliverableStatus.WAITING_FOR_VIDEO_DRAFT:
    case ContractDeliverableStatus.VIDEO_DRAFT_SUBMITTED:
    case ContractDeliverableStatus.VIDEO_DRAFT_REVISIONS_REQUESTED:
      scriptActive = true;
      videoActive = true;
      activeIndex = 1;
      break;
    case ContractDeliverableStatus.VIDEO_DRAFT_APPROVED:
    case ContractDeliverableStatus.WAITING_FOR_LIVE_CONTENT:
    case ContractDeliverableStatus.LIVE_CONTENT_SUBMITTED:
    case ContractDeliverableStatus.LIVE_CONTENT_REVISIONS_REQUESTED:
      scriptActive = true;
      videoActive = true;
      liveActive = true;
      activeIndex = 2;
      break;
    case ContractDeliverableStatus.LIVE_CONTENT_APPROVED:
    case ContractDeliverableStatus.WAITING_FOR_ASSETS_AND_ANALYTICS:
    case ContractDeliverableStatus.WAITING_FOR_ASSETS:
    case ContractDeliverableStatus.WAITING_FOR_ANALYTICS:
    case ContractDeliverableStatus.ASSETS_AND_ANALYTICS_SUBMITTED:
    case ContractDeliverableStatus.ASSETS_AND_ANALYTICS_REVISIONS_REQUESTED:
    case ContractDeliverableStatus.ASSETS_AND_ANALYTICS_APPROVED:
    case ContractDeliverableStatus.COMPLETE:
      scriptActive = true;
      videoActive = true;
      liveActive = true;
      analyticsActive = true;
      activeIndex = 3;
      break;
    default:
      scriptActive = false;
      videoActive = false;
      liveActive = false;
      analyticsActive = false;
      activeIndex = 4;
      break;
  }

  return {
    scriptState,
    videoDraftState,
    liveState,
    analyticsState,
    scriptActive,
    videoActive,
    liveActive,
    analyticsActive,
    activeIndex,
  };
}

/**
 * Timeline state for UGC contracts. Such contracts do not have the notion of live or analytics dates.
 */
function getUgcTimelineState({
  status,
}: {
  brandName: string;
  format: SupportedFormat;
  status: ContractDeliverableStatus;
  scriptDate: Date;
  videoDraftDate: Date;
}) {
  let scriptState = TimelineBulletState.INACTIVE;
  let videoDraftState = TimelineBulletState.INACTIVE;

  switch (status) {
    case ContractDeliverableStatus.WAITING_FOR_SCRIPT:
      scriptState = TimelineBulletState.PENDING;
      break;
    case ContractDeliverableStatus.SCRIPT_SUBMITTED:
      scriptState = TimelineBulletState.AWAITING_FEEDBACK;
      break;
    case ContractDeliverableStatus.SCRIPT_REVISIONS_REQUESTED:
      scriptState = TimelineBulletState.ACTION_REQUIRED;
      break;
    case ContractDeliverableStatus.SCRIPT_APPROVED:
    case ContractDeliverableStatus.WAITING_FOR_VIDEO_DRAFT:
      scriptState = TimelineBulletState.COMPLETE;
      videoDraftState = TimelineBulletState.PENDING;
      break;
    case ContractDeliverableStatus.VIDEO_DRAFT_SUBMITTED:
      scriptState = TimelineBulletState.COMPLETE;
      videoDraftState = TimelineBulletState.AWAITING_FEEDBACK;
      break;
    case ContractDeliverableStatus.VIDEO_DRAFT_REVISIONS_REQUESTED:
      scriptState = TimelineBulletState.COMPLETE;
      videoDraftState = TimelineBulletState.ACTION_REQUIRED;
      break;
    case ContractDeliverableStatus.VIDEO_DRAFT_APPROVED:
    case ContractDeliverableStatus.COMPLETE:
      scriptState = TimelineBulletState.COMPLETE;
      videoDraftState = TimelineBulletState.COMPLETE;
      break;
    default:
      scriptState = TimelineBulletState.INACTIVE;
      videoDraftState = TimelineBulletState.INACTIVE;
      break;
  }

  let scriptActive = false;
  let videoActive = false;
  let activeIndex;
  switch (status) {
    case ContractDeliverableStatus.WAITING_FOR_SCRIPT:
    case ContractDeliverableStatus.SCRIPT_SUBMITTED:
    case ContractDeliverableStatus.SCRIPT_REVISIONS_REQUESTED:
      scriptActive = true;
      activeIndex = 0;
      break;
    case ContractDeliverableStatus.SCRIPT_APPROVED:
    case ContractDeliverableStatus.WAITING_FOR_VIDEO_DRAFT:
    case ContractDeliverableStatus.VIDEO_DRAFT_SUBMITTED:
    case ContractDeliverableStatus.VIDEO_DRAFT_REVISIONS_REQUESTED:
      scriptActive = true;
      videoActive = true;
      activeIndex = 1;
      break;
    case ContractDeliverableStatus.VIDEO_DRAFT_APPROVED:
    case ContractDeliverableStatus.COMPLETE:
      scriptActive = true;
      videoActive = true;
      activeIndex = 2;
      break;
    default:
      scriptActive = false;
      videoActive = false;
      activeIndex = 2;
      break;
  }

  return {
    scriptState,
    videoDraftState,
    liveState: TimelineBulletState.INACTIVE,
    analyticsState: TimelineBulletState.INACTIVE,
    scriptActive,
    videoActive,
    liveActive: false,
    analyticsActive: false,
    activeIndex,
  };
}

function getTimelineBulletIcon(state: TimelineBulletState, defaultIcon: React.ReactNode) {
  switch (state) {
    case TimelineBulletState.ACTION_REQUIRED:
      return <IconCircleX size={12} />;
    case TimelineBulletState.COMPLETE:
      return <IconCircleCheck size={12} />;
    default:
      return defaultIcon;
  }
}

const TimelineItemChevron = ({
  title,
  openedToUse,
  toggleToUse,
  dueDate,
}: {
  title: string;
  openedToUse: boolean;
  toggleToUse: () => void;
  dueDate: Date;
}) => {
  const [hover, setHover] = useState(false);
  return (
    <Group
      align="flex-start"
      justify="space-between"
      onClick={toggleToUse}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{ cursor: "pointer" }}>
      <Stack gap={3}>
        {title}
        <Text size="xs" mt={4} c="dimmed">
          Due {toLongDateString(dueDate)}
        </Text>
      </Stack>
      <Group gap={2} align="center">
        <Text c="dimmed" size="xs">
          {openedToUse ? "Hide" : "View More"}
        </Text>
        <Chevron opened={openedToUse} toggle={toggleToUse} />
      </Group>
    </Group>
  );
};

export default function ContentSubmissionCardContent({
  id,
  adGroupId,
  contractId,
  contractType,
  platform,
  format,
  deliverableStatus,
  setDeliverableStatus,
  brandName,
  creatorHandle,
  profileLink,
  liveContentUrl,
  adCode,
  assetUrl,
  assetName,
  reuseFinalDraft,
  unsubmittedLiveContent,
  scriptDate,
  videoDraftDate,
  liveDate,
  analyticsDate,
  liveContentReviewDeadline,
  liveContentDisputeReason,
  requiresReferralLink,
  requiresPromoCode,
  missingReferralLink,
  missingPromoCode,
  referralLinkUrl,
  referralLinkRedirectUrl,
  promoCode,
  usageRightsDays,
  usageRightsInPerpetuity,
  creativeBriefUrl,
  latestVideosForContract,
  setLatestVideosForContract,
  handleRefetchUploadedVideos,
  showAdminOptions,
}: {
  id: string;
  adGroupId: number;
  contractId: string;
  contractType: ContractType;
  platform: SupportedPlatform;
  format: SupportedFormat;
  deliverableStatus: ContractDeliverableStatus;
  setDeliverableStatus: (deliverableStatus: ContractDeliverableStatus) => void;
  brandName: string;
  creatorHandle: string;
  profileLink: string;
  liveContentUrl: string;
  adCode: string;
  assetUrl: string;
  assetName: string;
  reuseFinalDraft: boolean;
  unsubmittedLiveContent: boolean;
  scriptDate: Date;
  videoDraftDate: Date;
  liveDate: Date;
  analyticsDate: Date;
  liveContentReviewDeadline: Date;
  liveContentDisputeReason: string;
  requiresReferralLink: boolean;
  requiresPromoCode: boolean;
  missingReferralLink: boolean;
  missingPromoCode: boolean;
  referralLinkUrl: string;
  referralLinkRedirectUrl: string;
  promoCode: string;
  usageRightsDays: number;
  usageRightsInPerpetuity: boolean;
  creativeBriefUrl: string;
  latestVideosForContract: DeliverableVideo[];
  setLatestVideosForContract: (videos: DeliverableVideo[]) => void;
  handleRefetchUploadedVideos: (handleFetched: () => void) => void;
  showAdminOptions: boolean;
}) {
  const timelineState =
    format === SupportedFormat.UGC
      ? getUgcTimelineState({
          brandName,
          format,
          status: deliverableStatus,
          scriptDate,
          videoDraftDate,
        })
      : getTimelineState({
          brandName,
          format,
          status: deliverableStatus,
          scriptDate,
          videoDraftDate,
          liveDate,
          analyticsDate,
        });

  const [liveContentReviewDeadlineState, setLiveContentReviewDeadlineState] =
    useState<Date>(liveContentReviewDeadline);

  // states for collapsible timeline items
  const [openedScript, { toggle: toggleScript }] = useDisclosure(
    timelineState.scriptState === TimelineBulletState.PENDING,
  );
  const [openedVideoDraft, { toggle: toggleVideoDraft }] = useDisclosure(
    timelineState.videoDraftState === TimelineBulletState.PENDING,
  );
  const [openedGoLive, { toggle: toggleGoLive }] = useDisclosure(
    timelineState.liveState === TimelineBulletState.PENDING,
  );

  let titleAndCaptionText = "caption";

  if (platform === SupportedPlatform.YOUTUBE && format !== SupportedFormat.YOUTUBE_SHORT) {
    titleAndCaptionText = "title and description";
  }

  return (
    <Stack>
      <ReferralLinkAndPromoCode
        deliverableId={id}
        format={format}
        creatorHandle={creatorHandle}
        approvedToGoLive={false}
        deliverableStatus={deliverableStatus}
        url={referralLinkUrl}
        redirectUrl={referralLinkRedirectUrl}
        promoCode={promoCode}
        requiresReferralLink={requiresReferralLink}
        requiresPromoCode={requiresPromoCode}
        missingReferralLink={missingReferralLink}
        missingPromoCode={missingPromoCode}
        brandName={brandName}
        platform={platform}
        showAdminOptions={showAdminOptions}
      />
      <Box pb="sm" pl={2}>
        <Timeline
          active={scriptDate ? timelineState.activeIndex : timelineState.activeIndex - 1}
          bulletSize={24}
          lineWidth={2}>
          {scriptDate && (
            <Timeline.Item
              title={
                <TimelineItemChevron
                  openedToUse={openedScript}
                  toggleToUse={toggleScript}
                  title="Submit Concept"
                  dueDate={scriptDate}
                />
              }
              color={TimelineBulletStateToColor[timelineState.scriptState]}
              lineVariant={timelineState.videoActive ? "solid" : "dashed"}
              bullet={getTimelineBulletIcon(
                timelineState.scriptState,
                <IconFileDescription size={12} />,
              )}>
              <Collapse in={openedScript}>
                <Space h="xs" />
                <Text size="sm">
                  {(timelineState.scriptState === TimelineBulletState.INACTIVE ||
                    timelineState.scriptState === TimelineBulletState.PENDING) &&
                    `Submit a description (< 200 words) of the concept you plan to use in your ${SUPPORTED_FORMATS_TO_DELIVERABLE_STRINGS[format]}. The concept will be reviewed by ${brandName}.`}
                  {timelineState.scriptState === TimelineBulletState.AWAITING_FEEDBACK &&
                    `${brandName} is currently reviewing the concept for your ${SUPPORTED_FORMATS_TO_DELIVERABLE_STRINGS[format]}.`}
                  {timelineState.scriptState === TimelineBulletState.ACTION_REQUIRED &&
                    `${brandName} requested revisions to the concept for your ${SUPPORTED_FORMATS_TO_DELIVERABLE_STRINGS[format]}. Please submit an updated concept.`}
                  {timelineState.scriptState === TimelineBulletState.COMPLETE &&
                    `${brandName} approved the concept for your ${SUPPORTED_FORMATS_TO_DELIVERABLE_STRINGS[format]}!`}
                </Text>
                <ScriptDraftTool
                  brandName={brandName}
                  platform={platform}
                  deliverableId={id}
                  deliverableStatus={deliverableStatus}
                  setDeliverableStatus={setDeliverableStatus}
                  showAdminOptions={showAdminOptions}
                />
              </Collapse>
            </Timeline.Item>
          )}

          {videoDraftDate && (
            <Timeline.Item
              title={
                <TimelineItemChevron
                  openedToUse={openedVideoDraft}
                  toggleToUse={toggleVideoDraft}
                  title="Submit Video Draft"
                  dueDate={videoDraftDate}
                />
              }
              color={TimelineBulletStateToColor[timelineState.videoDraftState]}
              lineVariant={timelineState.liveActive ? "solid" : "dashed"}
              bullet={getTimelineBulletIcon(
                timelineState.videoDraftState,
                <IconVideo size={12} />,
              )}>
              <Collapse in={openedVideoDraft}>
                <Space h="xs" />
                <Text size="sm">
                  {(timelineState.videoDraftState === TimelineBulletState.INACTIVE ||
                    timelineState.videoDraftState === TimelineBulletState.PENDING) &&
                    `Submit a draft of your ${SUPPORTED_FORMATS_TO_DELIVERABLE_STRINGS[format]} along with a ${titleAndCaptionText}. The draft will be reviewed by ${brandName}.`}
                  {timelineState.videoDraftState === TimelineBulletState.AWAITING_FEEDBACK &&
                    `${brandName} is currently reviewing the draft for your ${SUPPORTED_FORMATS_TO_DELIVERABLE_STRINGS[format]}.`}
                  {timelineState.videoDraftState === TimelineBulletState.ACTION_REQUIRED &&
                    `${brandName} requested revisions to the draft of your ${SUPPORTED_FORMATS_TO_DELIVERABLE_STRINGS[format]}. Please submit an updated video.`}
                  {format === SupportedFormat.UGC &&
                    timelineState.videoDraftState === TimelineBulletState.COMPLETE &&
                    `${brandName} accepted your ${SUPPORTED_FORMATS_TO_DELIVERABLE_STRINGS[format]}!`}
                  {format !== SupportedFormat.UGC &&
                    timelineState.videoDraftState === TimelineBulletState.COMPLETE &&
                    `${brandName} approved the draft for your ${SUPPORTED_FORMATS_TO_DELIVERABLE_STRINGS[format]}!`}
                </Text>
                <VideoDraftTool
                  brandName={brandName}
                  adGroupId={adGroupId}
                  contractId={contractId}
                  contractType={contractType}
                  deliverableId={id}
                  deliverableFormat={format}
                  deliverableStatus={deliverableStatus}
                  setDeliverableStatus={setDeliverableStatus}
                  latestVideosForContract={latestVideosForContract}
                  setLatestVideosForContract={setLatestVideosForContract}
                  handleRefetchUploadedVideos={handleRefetchUploadedVideos}
                  showAdminOptions={showAdminOptions}
                />
              </Collapse>
            </Timeline.Item>
          )}
          {format !== SupportedFormat.UGC && (
            <Timeline.Item
              title={
                <TimelineItemChevron
                  openedToUse={openedGoLive}
                  toggleToUse={toggleGoLive}
                  title="Go Live"
                  dueDate={liveDate}
                />
              }
              color={TimelineBulletStateToColor[timelineState.liveState]}
              lineVariant={timelineState.analyticsActive ? "solid" : "dashed"}
              bullet={getTimelineBulletIcon(timelineState.liveState, <IconVideoPlus size={12} />)}>
              <Collapse in={openedGoLive}>
                <Space h="xs" />
                {timelineState.liveState === TimelineBulletState.INACTIVE && (
                  <Text size="sm">
                    Post your video on {creatorHandle}&apos;s{" "}
                    <Anchor href={profileLink}>
                      {SUPPORTED_PLATFORMS_TO_DISPLAY_NAMES[platform]}{" "}
                      {platform === SupportedPlatform.YOUTUBE ? "channel" : "account"}{" "}
                    </Anchor>
                    upon receiving approval from {brandName}.
                  </Text>
                )}
                {timelineState.liveState === TimelineBulletState.PENDING && (
                  <Text c="dimmed" size="sm">
                    Post your approved video on {creatorHandle}&apos;s{" "}
                    <Anchor href={profileLink}>
                      {SUPPORTED_PLATFORMS_TO_DISPLAY_NAMES[platform]}{" "}
                      {platform === SupportedPlatform.YOUTUBE ? "channel" : "account"}{" "}
                    </Anchor>
                    and submit the URL below!
                  </Text>
                )}
                {timelineState.liveState === TimelineBulletState.AWAITING_FEEDBACK && (
                  <Text c="dimmed" size="sm">
                    Your live video on {creatorHandle}&apos;s{" "}
                    <Anchor href={profileLink}>
                      {SUPPORTED_PLATFORMS_TO_DISPLAY_NAMES[platform]}{" "}
                      {platform === SupportedPlatform.YOUTUBE ? "channel" : "account"}
                    </Anchor>{" "}
                    is currently being verified by {brandName}. It will be reviewed by{" "}
                    <Text span fw="500">
                      {toLongTimeDateString(liveContentReviewDeadlineState)}
                    </Text>
                    .
                  </Text>
                )}
                {timelineState.liveState === TimelineBulletState.ACTION_REQUIRED && (
                  <Text c="dimmed" size="sm">
                    Your live video on {creatorHandle}&apos;s{" "}
                    <Anchor href={profileLink}>
                      {SUPPORTED_PLATFORMS_TO_DISPLAY_NAMES[platform]}{" "}
                      {platform === SupportedPlatform.YOUTUBE ? "channel" : "account"}
                    </Anchor>{" "}
                    was unable to be verified by {brandName}. Please make the requested changes and
                    resubmit.
                  </Text>
                )}
                {timelineState.liveState === TimelineBulletState.COMPLETE && (
                  <Text c="dimmed" size="sm">
                    Your live video has been verified by {brandName}!
                  </Text>
                )}
                <LiveContentSubmission
                  id={id}
                  contractType={contractType}
                  contractId={contractId}
                  platform={platform}
                  format={format}
                  deliverableStatus={deliverableStatus}
                  setDeliverableStatus={setDeliverableStatus}
                  brandName={brandName}
                  creatorHandle={creatorHandle}
                  liveContentUrl={liveContentUrl}
                  adCode={adCode}
                  assetUrl={assetUrl}
                  assetName={assetName}
                  reuseFinalDraft={reuseFinalDraft}
                  liveDate={liveDate}
                  setLiveContentReviewDeadline={setLiveContentReviewDeadlineState}
                  liveContentDisputeReason={liveContentDisputeReason}
                  requiredVideoReview={!!videoDraftDate}
                  requiresReferralLink={requiresReferralLink}
                  requiresPromoCode={requiresPromoCode}
                  missingReferralLink={missingReferralLink}
                  missingPromoCode={missingPromoCode}
                  referralLinkUrl={referralLinkUrl}
                  promoCode={promoCode}
                  usageRightsDays={usageRightsDays}
                  usageRightsInPerpetuity={usageRightsInPerpetuity}
                  creativeBriefUrl={creativeBriefUrl}
                  unsubmittedLiveContent={unsubmittedLiveContent}
                />
              </Collapse>
            </Timeline.Item>
          )}
        </Timeline>
      </Box>
    </Stack>
  );
}
