import { IconSearch, IconThumbUp, IconUserSearch } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import NavBarButton from "campaigns/navbar/v2/buttons/components/NavBarButton";
import { useMatch, useNavigate } from "react-router-dom";
import { CAMPAIGN_PORTAL, CREATOR_SET_PATH } from "campaigns/PathConstants";
import { useAppDispatch, useAppSelector } from "reduxStore/hooks";
import { getCreatorSetItems } from "reduxStore/creatorSetsSlice";
import { CreatorSetItem } from "components/discovery/Datamodels";
import { useSelector } from "react-redux";
import { selectCreatorSets } from "reduxStore/creatorSetsSelectors";

const DiscoverCreatorsNavBarButton = ({
  showMinimizedVersion,
}: {
  showMinimizedVersion: boolean;
}) => {
  // navigator
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((state) => state.me.user);
  const selectedCreatorSets = useSelector(selectCreatorSets) as CreatorSetItem[];

  const isCreatorSearchSelected = useMatch(`${CAMPAIGN_PORTAL}/discover`) != null;
  const isRecommendationsSelected = useMatch(`${CAMPAIGN_PORTAL}/creator_sets/review`) != null;
  const isSpecificCreatorSetRecommendationsSelected =
    useMatch(`${CAMPAIGN_PORTAL}/creator_sets/${CREATOR_SET_PATH}/recommended`) != null;
  const isChildrenRouteMatched =
    isCreatorSearchSelected ||
    isRecommendationsSelected ||
    isSpecificCreatorSetRecommendationsSelected;

  const [isExpanded, setIsExpanded] = useState<boolean>(isChildrenRouteMatched);
  const [numRecommendations, setNumRecommendations] = useState<number>(0);

  useEffect(() => {
    const abortController = new AbortController();
    if (currentUser) {
      dispatch(getCreatorSetItems(abortController));
    }
    return () => {
      abortController.abort();
    };
  }, [currentUser]);

  useEffect(() => {
    const total = selectedCreatorSets.reduce(
      (sum, creatorSet) => sum + creatorSet.num_needs_review,
      0,
    );
    setNumRecommendations(total);
  }, [selectedCreatorSets]);

  useEffect(() => {
    // If I’m in the collapsed nav menu state and I navigate to a subpage, and then I expand the menu,
    // it’d be great for the page that I’m currently on to be expanded
    if (!showMinimizedVersion && isChildrenRouteMatched) {
      setIsExpanded(true);
    }
  }, [showMinimizedVersion]);

  const childButtons = (
    <>
      <NavBarButton
        isSelected={isCreatorSearchSelected}
        LeftIconName={IconUserSearch}
        onClick={() => {
          navigate(`${CAMPAIGN_PORTAL}/discover`);
        }}
        title="Creator Search"
        variant={showMinimizedVersion ? "secondaryHover" : "secondary"}
      />
      <NavBarButton
        isSelected={isRecommendationsSelected || isSpecificCreatorSetRecommendationsSelected}
        LeftIconName={IconThumbUp}
        onClick={() => {
          navigate(`${CAMPAIGN_PORTAL}/creator_sets/review`);
        }}
        showBadgeProps={{
          isVisible: numRecommendations > 0,
          badgeText: numRecommendations,
        }}
        title="Recommendations"
        variant={showMinimizedVersion ? "secondaryHover" : "secondary"}
      />
    </>
  );

  return (
    <>
      <NavBarButton
        isExpanded={isExpanded}
        isSelected={showMinimizedVersion && isChildrenRouteMatched}
        LeftIconName={IconSearch}
        onClick={() => {
          if (!showMinimizedVersion) {
            setIsExpanded(!isExpanded);
          }
        }}
        showExpansionToggle
        showIndicator={numRecommendations > 0}
        showMinimizedVersion={showMinimizedVersion}
        title="Discover Creators"
        hoverChildren={childButtons}
      />
      {isExpanded && !showMinimizedVersion ? childButtons : null}
    </>
  );
};

export default DiscoverCreatorsNavBarButton;
