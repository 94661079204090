import React from "react";

import { toShortDateString } from "utils/DateUtils";
import { User } from "firebase/auth";

import Deliverable from "components/contracts/models/Deliverable";

import {
  contractAccepted,
  ContractStatus,
  PaymentStatus,
  RepeatContractValue,
} from "components/contracts/common/Common";

import { Flex, Table, Text } from "@mantine/core";

import ContractActions from "components/contracts/dashboard/ContractActions";
import ContractAmount from "components/contracts/dashboard/ContractAmount";
import ContractStatusBadges from "components/contracts/dashboard/ContractStatusBadges";
import CreatorAvatar from "components/contracts/common/CreatorAvatar";
import DeliverableProgress from "components/contracts/common/DeliverableProgress";
import ReadyForPayment from "components/contracts/dashboard/ReadyForPayment";
import LinkAndPromoStatus from "components/contracts/dashboard/LinkAndPromoStatus";
import SocialHandleButtons from "components/contracts/dashboard/SocialHandleButtons";
import { Payment } from "components/contracts/models/Payment";

import { SEMI_BOLD_FONT_WEIGHT } from "components/contracts/dashboard/Constants";
import { SocialHandle } from "components/contracts/dashboard/Utils";

function LastUpdated({
  lastUpdated,
  deliverables,
}: {
  lastUpdated: Date;
  deliverables: Deliverable[];
}) {
  const deliverablesLastUpdated = deliverables.map((deliverable) =>
    deliverable.lastUpdated.getTime(),
  );
  const maxLastUpdated = new Date(Math.max(lastUpdated.getTime(), ...deliverablesLastUpdated));

  return <Text fw={SEMI_BOLD_FONT_WEIGHT}>{toShortDateString(maxLastUpdated)}</Text>;
}

function DateCreated({ dateCreated }: { dateCreated: Date }) {
  return <Text fw={SEMI_BOLD_FONT_WEIGHT}>{toShortDateString(dateCreated)}</Text>;
}

function LiveDate({ liveDate }: { liveDate: Date }) {
  return <Text fw={SEMI_BOLD_FONT_WEIGHT}>{toShortDateString(liveDate)}</Text>;
}

export default function ContractRow({
  user,
  contractId,
  archived,
  creatorId,
  displayName,
  socialHandles,
  dateCreated,
  lastUpdated,
  liveDate,
  requiresReferralLink,
  missingReferralLink,
  requiresPromoCode,
  missingPromoCode,
  contractStatus,
  paymentStatus,
  isRecurring,
  bonusPaymentStatus,
  avatarUrl,
  amountInMinorUnits,
  remainingAmountInMinorUnits,
  bonusAmountInMinorUnits,
  earnedBonusAmountInMinorUnits,
  awaitingPaymentDetails,
  readyForPayment,
  meetsBonusCondition,
  hasBonusCondition,
  deliverables,
  payments,
  stripeAccountUrl,
  closeUrl,
  repeatState,
  handleRefetchContract,
  handleDeleteContract,
}: {
  user: User;
  contractId: string;
  archived: boolean;
  creatorId: string;
  displayName: string;
  socialHandles: SocialHandle[];
  dateCreated: Date;
  lastUpdated: Date;
  liveDate: Date;
  requiresReferralLink: boolean;
  missingReferralLink: boolean;
  requiresPromoCode: boolean;
  missingPromoCode: boolean;
  contractStatus: ContractStatus;
  paymentStatus: PaymentStatus;
  isRecurring: boolean;
  bonusPaymentStatus: PaymentStatus;
  avatarUrl: string;
  amountInMinorUnits: number;
  remainingAmountInMinorUnits: number;
  bonusAmountInMinorUnits: number;
  earnedBonusAmountInMinorUnits: number;
  awaitingPaymentDetails: boolean;
  readyForPayment: boolean;
  meetsBonusCondition: boolean;
  hasBonusCondition: boolean;
  deliverables: Deliverable[];
  payments: Payment[];
  stripeAccountUrl: string;
  closeUrl: string;
  repeatState: RepeatContractValue;
  handleRefetchContract: (contractId: string, handleFetched: () => void) => void;
  handleDeleteContract: (contractId: string) => void;
}) {
  return (
    <Table.Tr>
      <Table.Td>
        <CreatorAvatar
          creatorId={creatorId}
          displayName={displayName}
          avatarUrl={avatarUrl}
          hasLink
        />
      </Table.Td>
      <Table.Td>
        <SocialHandleButtons socialHandles={socialHandles} />
      </Table.Td>
      <Table.Td style={{ textAlign: "center" }}>
        <DateCreated dateCreated={dateCreated} />
      </Table.Td>
      <Table.Td style={{ textAlign: "center" }}>
        <LastUpdated lastUpdated={lastUpdated} deliverables={deliverables} />
      </Table.Td>
      <Table.Td style={{ textAlign: "center" }}>
        <LiveDate liveDate={liveDate} />
      </Table.Td>
      <Table.Td style={{ textAlign: "center" }}>
        <ContractStatusBadges
          contractStatus={contractStatus}
          paymentStatus={paymentStatus}
          readyForPayment={readyForPayment}
          archived={archived}
          isRecurring={isRecurring}
        />
      </Table.Td>
      <Table.Td style={{ textAlign: "center" }}>
        <DeliverableProgress deliverables={deliverables} />
      </Table.Td>
      <Table.Td style={{ textAlign: "center" }}>
        <ReadyForPayment
          contractAccepted={contractAccepted(contractStatus)}
          contractComplete={contractStatus === ContractStatus.COMPLETE}
          paymentStatus={paymentStatus}
          awaitingPaymentDetails={awaitingPaymentDetails}
          readyForPayment={readyForPayment}
        />
      </Table.Td>
      <Table.Td style={{ textAlign: "center" }}>
        <LinkAndPromoStatus
          requiresReferralLink={requiresReferralLink}
          missingReferralLink={missingReferralLink}
          requiresPromoCode={requiresPromoCode}
          missingPromoCode={missingPromoCode}
        />
      </Table.Td>
      <Table.Td style={{ textAlign: "center" }}>
        <ContractAmount amountInMinorUnits={amountInMinorUnits} />
      </Table.Td>
      <Table.Td style={{ textAlign: "center" }}>
        <ContractAmount amountInMinorUnits={remainingAmountInMinorUnits} />
      </Table.Td>
      <Table.Td>
        <Flex justify="right">
          <ContractActions
            user={user} // TODO (victoria 3.2024): using for display name in ManualPaymentModal - need to get rid of this
            contractId={contractId}
            archived={archived}
            requiresReferralLink={requiresReferralLink}
            contractStatus={contractStatus}
            paymentStatus={paymentStatus}
            bonusPaymentStatus={bonusPaymentStatus}
            readyForPayment={readyForPayment}
            meetsBonusCondition={meetsBonusCondition}
            hasBonusCondition={hasBonusCondition}
            remainingAmountInMinorUnits={remainingAmountInMinorUnits}
            bonusAmountInMinorUnits={bonusAmountInMinorUnits}
            earnedBonusAmountInMinorUnits={earnedBonusAmountInMinorUnits}
            displayName={displayName}
            payments={payments}
            stripeAccountUrl={stripeAccountUrl}
            closeUrl={closeUrl}
            handleRefetchContract={handleRefetchContract}
            handleDeleteContract={handleDeleteContract}
            repeatState={repeatState}
          />
        </Flex>
      </Table.Td>
    </Table.Tr>
  );
}
