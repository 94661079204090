import React, { useState } from "react";
import {
  ActionIcon,
  Box,
  Button,
  CopyButton,
  Flex,
  Group,
  Paper,
  Stack,
  Text,
  TextInput,
  Textarea,
  Tooltip,
} from "@mantine/core";

import { IconCheck, IconCopy } from "@tabler/icons-react";

import { ProductAccess } from "components/contracts/tasks/models/Common";

import {
  saveBrandProductAccessInfo,
  reportIssueWithProductAccessInfo,
} from "components/contracts/tasks/api/Api";

import {
  ProductAccessInfoNeededFromCreatorType,
  ProductAccessInfoToProvideToCreatorType,
} from "components/contracts/common/Common";
import { showFailureNotification, showSuccessNotification } from "components/common/Notifications";

function isValidPromoCode(promoCode: string) {
  return promoCode.length > 0 && promoCode.length <= 255;
}

function getShippingAddress(creatorInput: string) {
  const address = JSON.parse(creatorInput) as {
    firstName: string;
    lastName: string;
    streetAddress: string;
    streetAddress2?: string;
    city: string;
    state: string;
    zipCode: number;
  };

  return (
    <>
      <Text size="sm" fw="500">
        Shipping Address
      </Text>
      <Paper p="sm" radius="sm" w="100%" withBorder>
        <Text size="sm">
          {address.firstName} {address.lastName}
          <br />
          {address.streetAddress}
          <br />
          {address.streetAddress2 ? (
            <>
              {address.streetAddress2}
              <br />
            </>
          ) : null}
          {address.city}, {address.state} {address.zipCode}
        </Text>
      </Paper>
    </>
  );
}

export const CreatorInputComponent = ({
  displayName,
  productAccessInfoNeededFromCreator,
  productAccessCreatorInput,
  productAccessOtherInfoNeededFromCreator,
}: {
  displayName: string;
  productAccessInfoNeededFromCreator: ProductAccessInfoNeededFromCreatorType;
  productAccessCreatorInput: string;
  productAccessOtherInfoNeededFromCreator: string;
}) => {
  switch (productAccessInfoNeededFromCreator) {
    case ProductAccessInfoNeededFromCreatorType.SHIPPING_ADDRESS:
      return <Stack gap={0}>{getShippingAddress(productAccessCreatorInput)}</Stack>;
    case ProductAccessInfoNeededFromCreatorType.ACCOUNT_EMAIL_ADDRESS:
      return (
        <Flex gap="xs" align="flex-end">
          <TextInput
            id="emailAddress"
            label="Email Address"
            value={productAccessCreatorInput}
            miw={620}
            onChange={() => {}}
          />
          <Box pt="lg">
            <CopyButton value={productAccessCreatorInput} timeout={1000}>
              {({ copied, copy }) => (
                <Tooltip label={copied ? "Copied" : "Copy"} withArrow position="right">
                  <ActionIcon color={copied ? "teal" : "gray"} variant="subtle" onClick={copy}>
                    {copied ? <IconCheck size="1.6rem" /> : <IconCopy size="1.6rem" />}
                  </ActionIcon>
                </Tooltip>
              )}
            </CopyButton>
          </Box>
        </Flex>
      );
    case ProductAccessInfoNeededFromCreatorType.ACCOUNT_USERNAME:
      return (
        <Flex gap="xs" align="flex-end">
          <TextInput
            id="userName"
            label="Username"
            value={productAccessCreatorInput}
            miw={620}
            onChange={() => {}}
          />
          <Box pt="lg">
            <CopyButton value={productAccessCreatorInput} timeout={1000}>
              {({ copied, copy }) => (
                <Tooltip label={copied ? "Copied" : "Copy"} withArrow position="right">
                  <ActionIcon color={copied ? "teal" : "gray"} variant="subtle" onClick={copy}>
                    {copied ? <IconCheck size="1.6rem" /> : <IconCopy size="1.6rem" />}
                  </ActionIcon>
                </Tooltip>
              )}
            </CopyButton>
          </Box>
        </Flex>
      );
    case ProductAccessInfoNeededFromCreatorType.OTHER:
      return (
        <>
          <Text size="sm">
            Creator provided info for:{" "}
            <Text span fw="500">
              {productAccessOtherInfoNeededFromCreator}
            </Text>
          </Text>
          <Paper shadow="xs" p="sm" radius="sm" miw={500} withBorder>
            <Flex gap="xs" align="flex-end" mb="sm">
              <Text id="customInfo">{productAccessCreatorInput}</Text>
            </Flex>
          </Paper>
        </>
      );
    default:
      return null;
  }
};

function getProductAccessCreatorInput(productAccess: ProductAccess | null): string {
  if (!productAccess) {
    return "";
  }

  let productAccessCreatorInput = "";
  if (
    productAccess.infoNeededFromCreator ===
    ProductAccessInfoNeededFromCreatorType.ACCOUNT_EMAIL_ADDRESS
  ) {
    productAccessCreatorInput = productAccess.creatorEmail;
  } else if (
    productAccess.infoNeededFromCreator === ProductAccessInfoNeededFromCreatorType.ACCOUNT_USERNAME
  ) {
    productAccessCreatorInput = productAccess.creatorUsername;
  } else if (
    productAccess.infoNeededFromCreator === ProductAccessInfoNeededFromCreatorType.SHIPPING_ADDRESS
  ) {
    productAccessCreatorInput = JSON.stringify(productAccess.creatorShippingAddressJson);
  } else if (productAccess.infoNeededFromCreator === ProductAccessInfoNeededFromCreatorType.OTHER) {
    productAccessCreatorInput = productAccess.creatorCustomInfo;
  }

  return productAccessCreatorInput;
}

function BrandInputResult({
  taskId,
  displayName,
  productAccess,
  handleCompleteTask,
}: {
  taskId: string;
  displayName: string;
  productAccess: ProductAccess;
  handleCompleteTask: () => void;
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const [issueLoading, setIssueLoading] = useState<boolean>(false);
  const [showIssueInput, setShowIssueInput] = useState<boolean>(false);

  const [otherBrandInput, setOtherBrandInput] = useState<string>("");
  const [shippingTrackingCode, setShippingTrackingCode] = useState<string>("");
  const [shippingCarrier, setShippingCarrier] = useState<string>("");
  const [issueMessage, setIssueMessage] = useState<string>("");

  const isNoneRequired =
    productAccess.infoToProvideToCreator === ProductAccessInfoToProvideToCreatorType.NONE;

  const isBrandInputMissing = () => {
    let missing = false;
    if (
      productAccess.infoToProvideToCreator === ProductAccessInfoToProvideToCreatorType.TRACKING_CODE
    ) {
      missing = shippingTrackingCode === "" || shippingCarrier === "";
    } else if (otherBrandInput.length === 0) {
      missing = true;
    }

    return missing;
  };

  const submitIssueToCreator = () => {
    setIssueLoading(true);
    reportIssueWithProductAccessInfo({ taskId, message: issueMessage }).then((response: any) => {
      const { success, error } = response;
      if (success) {
        setShowIssueInput(false);
        showSuccessNotification({
          title: "Product access issue reported",
          message: `${displayName} has been notified of the issue you reported.`,
        });
        handleCompleteTask();
      } else {
        showFailureNotification({ message: error });
      }
    });
  };

  const handleSaveOutput = () => {
    setLoading(true);

    let output = "";
    if (
      productAccess.infoToProvideToCreator ===
        ProductAccessInfoToProvideToCreatorType.ACCESS_CODE ||
      productAccess.infoToProvideToCreator === ProductAccessInfoToProvideToCreatorType.OTHER
    ) {
      output = otherBrandInput;
    } else if (
      productAccess.infoToProvideToCreator === ProductAccessInfoToProvideToCreatorType.TRACKING_CODE
    ) {
      output = JSON.stringify({ shippingCarrier, code: shippingTrackingCode });
    }

    saveBrandProductAccessInfo({
      taskId,
      productAccessBrandOutput: output,
    })
      .then((response: any) => {
        const { success, error } = response;

        if (success) {
          setLoading(false);
          showSuccessNotification({
            title: "Product access provided",
            message: `${displayName} has been notified that they now have access to your product.`,
          });
          handleCompleteTask();
        } else {
          setLoading(false);
          showFailureNotification({ message: error });
        }
      })
      .catch((error) => {
        setLoading(false);
        showFailureNotification({ message: error });
      });
  };

  let brandComp = null;
  switch (productAccess.infoToProvideToCreator) {
    case ProductAccessInfoToProvideToCreatorType.NONE:
      brandComp = (
        <Flex gap="xs" align="flex-start" w="100%">
          <Text fw="500">Please confirm you have provided the creator with Product Access</Text>
        </Flex>
      );
      break;
    case ProductAccessInfoToProvideToCreatorType.ACCESS_CODE:
      brandComp = (
        <Flex gap="xs" align="flex-start" w="100%">
          <TextInput
            label="Please provide the Access Code for the creator to use."
            value={otherBrandInput}
            onChange={(event) => setOtherBrandInput(event.currentTarget.value)}
            error={
              otherBrandInput && !isValidPromoCode(otherBrandInput)
                ? "Promo Code is too long."
                : null
            }
            // size="xs"
            placeholder="EXAMPLE10"
            w="100%"
          />
        </Flex>
      );
      break;
    case ProductAccessInfoToProvideToCreatorType.TRACKING_CODE:
      brandComp = (
        <Stack gap="xs" align="flex-start" w="100%">
          <Text fw="500">Please provide the Tracking Info for the package</Text>
          <TextInput
            label="Shipping Carrier"
            value={shippingCarrier}
            onChange={(event) => setShippingCarrier(event.currentTarget.value)}
            placeholder="UPS, FedEx, USPS, etc."
            w="100%"
          />
          <TextInput
            label="Shipping Tracking Code"
            value={shippingTrackingCode}
            onChange={(event) => setShippingTrackingCode(event.currentTarget.value)}
            placeholder="e.g. 1Z165V640494171111"
            w="100%"
          />
        </Stack>
      );
      break;
    case ProductAccessInfoToProvideToCreatorType.OTHER:
      brandComp = (
        <Stack gap="xs" align="flex-start" w="100%">
          <Textarea
            id={`textarea-${taskId}`}
            label="Information to provide to the creator"
            value={otherBrandInput}
            onChange={(event) => setOtherBrandInput(event.currentTarget.value)}
            placeholder={productAccess.otherInfoToProvideToCreator}
            rows={2}
            w="100%"
          />
        </Stack>
      );
      break;
    default:
      break;
  }

  return (
    <>
      {brandComp}
      <Group gap="xs" justify="right" />
      <Group justify="right">
        {productAccess.infoNeededFromCreator !== ProductAccessInfoNeededFromCreatorType.NONE && (
          <Button
            onClick={() => setShowIssueInput(!showIssueInput)}
            color="red"
            variant="light"
            disabled={issueLoading}>
            Report an Issue
          </Button>
        )}
        <Button
          disabled={(!isNoneRequired && isBrandInputMissing()) || issueLoading}
          loading={loading}
          onClick={handleSaveOutput}>
          {isNoneRequired ? "Confirm" : "Submit"}
        </Button>
      </Group>

      {showIssueInput && (
        <>
          <Box>
            <Textarea
              mt="xs"
              value={issueMessage}
              onChange={(event) => setIssueMessage(event.currentTarget.value)}
              placeholder={`Let ${displayName} know what needs to be fixed`}
            />
          </Box>
          <Group justify="right" gap="xs">
            <Button loading={issueLoading} onClick={() => submitIssueToCreator()} color="red">
              Submit Issue to Creator
            </Button>
          </Group>
        </>
      )}
    </>
  );
}

export default function ProductAccessContent({
  taskId,
  displayName,
  productAccess,
  handleCompleteTask,
}: {
  taskId: string;
  displayName: string;
  productAccess: ProductAccess;
  handleCompleteTask: () => void;
}) {
  return (
    <Stack gap="xs">
      <CreatorInputComponent
        displayName={displayName}
        productAccessInfoNeededFromCreator={productAccess.infoNeededFromCreator}
        productAccessCreatorInput={getProductAccessCreatorInput(productAccess)}
        productAccessOtherInfoNeededFromCreator={productAccess.otherInfoNeededFromCreator}
      />
      <BrandInputResult
        taskId={taskId}
        displayName={displayName}
        productAccess={productAccess}
        handleCompleteTask={handleCompleteTask}
      />
    </Stack>
  );
}