import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Card,
  Divider,
  Group,
  HoverCard,
  Stack,
  Text,
  Title,
  Switch,
  Center,
  Space,
} from "@mantine/core";

import { useAppDispatch } from "reduxStore/hooks";
import { updateNotifPreferences } from "reduxStore/meSlice";

import { Brand } from "models/Brand";
import { User } from "models/User";

export default function AccountSettings({ brand, user }: { brand: Brand; user: User }) {
  const dispatch = useAppDispatch();

  if (!user.notification_preferences) {
    return null;
  }

  const [tasksSummaryNotifications, setTasksSummaryNotifications] = useState(
    user.notification_preferences.tasks_summary_notifications,
  );
  const [activityNotifications, setActivityNotifications] = useState(
    user.notification_preferences.activity_notifications,
  );
  const [isUpdating, setIsUpdating] = useState(false);

  const isDirty =
    tasksSummaryNotifications !== user.notification_preferences.tasks_summary_notifications ||
    activityNotifications !== user.notification_preferences.activity_notifications;

  const handleSave = async () => {
    const abortController = new AbortController();
    setIsUpdating(true);

    dispatch(
      updateNotifPreferences({
        abortController,
        user_key: user.key,
        updatedFields: {
          tasks_summary_notifications: tasksSummaryNotifications,
          activity_notifications: activityNotifications,
        },
      }),
    )
      .unwrap()
      .finally(() => {
        setIsUpdating(false);
      });
  };

  return (
    <Card
      py="20px"
      px="28px"
      style={{
        borderRadius: 12,
        border: "1px solid var(--mantine-color-dark-0)",
        "--stack-gap": "24px",
      }}>
      <Title order={3}>Email Notifications</Title>
      <Stack mt="lg">
        <Group justify="space-between">
          <Stack gap={0}>
            <Title order={4}>Tasks Summary Notifications</Title>
            <Text size="md" c="dimmed">
              Sent once a day, and only if you have tasks overdue or due that day.
            </Text>
          </Stack>
          <HoverCard width={280} shadow="sm" position="top">
            <HoverCard.Target>
              <Box>
                <Switch
                  checked={tasksSummaryNotifications}
                  onChange={(e) => setTasksSummaryNotifications(e.target.checked)}
                />
              </Box>
            </HoverCard.Target>
            <HoverCard.Dropdown>
              <Center>
                <Text size="sm" ta="center">
                  You are currently set as the owner for {brand.display_name}. This email is
                  required for organizational owners.
                  <Space h="xs" />
                  To reassign ownership to another account, please contact us for help.
                </Text>
              </Center>
            </HoverCard.Dropdown>
          </HoverCard>
        </Group>
        <Divider />
        <Group justify="space-between">
          <Stack gap={0}>
            <Title order={4}>Activity Notifications</Title>
            <Text size="md" c="dimmed">
              Sent each time an action is taken in your campaign.
            </Text>
          </Stack>
          <Switch
            checked={activityNotifications}
            onChange={(e) => setActivityNotifications(e.target.checked)}
          />
        </Group>
        <Group justify="right" mt="xs">
          <Button size="sm" loading={isUpdating} disabled={!isDirty} onClick={handleSave}>
            Save changes
          </Button>
        </Group>
      </Stack>
    </Card>
  );
}
