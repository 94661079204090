import React, { useEffect } from "react";

import { Button, PasswordInput, Select, Stack, TextInput } from "@mantine/core";

import { isNotEmpty, useForm } from "@mantine/form";

import { useAppDispatch } from "reduxStore/hooks";
import { updateMyBrand } from "reduxStore/meSlice";

import { Brand } from "models/Brand";
import CardBase from "campaigns/create/common/CardBase";

enum EmailProvider {
  GOOGLE = 1,
  MICROSOFT = 2,
}

const EXISTING_PASSWORD_PLACEHOLDER = "********";

export const BrandOutreach = ({ brand }: { brand: Brand }) => {
  const dispatch = useAppDispatch();
  const form = useForm({
    mode: "uncontrolled",
    initialValues: {
      collabEmail: brand.outreach_email_address ?? "",
      collabEmailPassword:
        brand.outreach_email_configured || brand.outreach_email_password_set
          ? EXISTING_PASSWORD_PLACEHOLDER
          : "",
      collabEmailProvider: brand.outreach_email_provider?.toString(),
    },

    validate: {
      collabEmail: isNotEmpty("Please enter an email address"),
      collabEmailPassword: (value) => {
        if (!value) {
          return "Please enter an email password";
        }
        if (value === EXISTING_PASSWORD_PLACEHOLDER && form.isDirty("collabEmailPassword")) {
          return "Please enter a valid password";
        }
        return null;
      },
      collabEmailProvider: (value) => {
        // 0 === UNKNOWN - force them to select an email provider
        if (value === "0" || !value) {
          return "Please select an email provider";
        }
        return null;
      },
    },
  });

  useEffect(() => {
    form.setInitialValues({
      collabEmail: brand.outreach_email_address ?? "",
      collabEmailPassword: brand.outreach_email_configured ? EXISTING_PASSWORD_PLACEHOLDER : "",
      collabEmailProvider: brand.outreach_email_provider?.toString(),
    });
    form.resetDirty();
  }, [brand]);

  return (
    <CardBase
      isActive
      nodeShownWhenActive={
        <form
          onSubmit={form.onSubmit((values) => {
            dispatch(
              updateMyBrand({
                updatedFields: {
                  ...(form.isDirty("collabEmail") && {
                    outreach_email_address: values.collabEmail,
                  }),
                  ...(form.isDirty("collabEmailPassword") && {
                    outreach_email_password: values.collabEmailPassword,
                  }),
                  ...(form.isDirty("collabEmailProvider") && {
                    outreach_email_provider: Number(values.collabEmailProvider),
                  }),
                },
              }),
            );
          })}>
          <Stack px={0} style={{ "--stack-gap": "24px" }}>
            <TextInput
              withAsterisk
              label="1stCollab Email Address"
              placeholder="1stcollab@yourbrand.com"
              description="This is the email address we’ll use to outreach on behalf of your brand. We suggest your email address begins with 1stcollab@. The email must be registered to your brand’s domain."
              key={form.key("collabEmail")}
              {...form.getInputProps("collabEmail")}
            />
            <PasswordInput
              withAsterisk
              label="1stCollab Email Password"
              description="The password for your 1stCollab Email Address"
              key={form.key("collabEmailPassword")}
              {...form.getInputProps("collabEmailPassword")}
            />
            <Select
              withAsterisk
              placeholder="Select an email provider"
              label="1stCollab Email Provider"
              description="The email service that hosts your 1stcollab@ email address. So for example, select Google if your company uses Gmail and Google Workspace or Microsoft if your company uses Outlook for email. We’ll need this information to connect to the email address for outreach."
              data={[
                { label: "Google", value: EmailProvider.GOOGLE.toString() },
                { label: "Microsoft", value: EmailProvider.MICROSOFT.toString() },
              ]}
              key={form.key("collabEmailProvider")}
              {...form.getInputProps("collabEmailProvider")}
            />
            <Button variant="filled" type="submit">
              Save
            </Button>
          </Stack>
        </form>
      }
      title="Outreach Settings"
      subtitle=""
    />
  );
};

export default BrandOutreach;
