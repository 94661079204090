import React, { useEffect, useState } from "react";

import {
  Button,
  Container,
  Group,
  List,
  LoadingOverlay,
  Paper,
  Space,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { AreaChart, BarChart, LineChart } from "@mantine/charts";

import { getCoreMetrics, refreshCoreMetrics } from "components/metrics/Api";
import { IconRefresh } from "@tabler/icons-react";
import { timeAgo } from "utils/DateUtils";

interface ContractTimeSeriesItem {
  date: string;
  created_contracts: number;
  signed_contracts: number;
  completed_contracts: number;
  created_contracts_14_day_avg: number;
  signed_contracts_14_day_avg: number;
  completed_contracts_14_day_avg: number;
}

interface FunnelVolumeTimeSeriesItem {
  date: string;
  num_ops_accepted_14_day_avg: number;
  num_internal_accepted_14_day_avg: number;
  num_brand_accepted_14_day_avg: number;
  num_email_subscribed_14_day_avg: number;
  brand_accept_rate_14_day_window: number;
  internal_accept_rate_14_day_window: number;
}

interface DiscoveryTimeSeriesItem {
  date: string;
  num_queries_14_day_avg: number;
  num_page_views_14_day_avg: number;
  num_activations_14_day_avg: number;
  num_saves_14_day_avg: number;
}

interface AverageContractValueTimeSeriesItem {
  date: string;
  contract_value_14_day_avg: number;
}

interface ContractDurationTimeSeriesItem {
  date: string;
  p25_completion_days: number;
  p50_completion_days: number;
  p75_completion_days: number;
}

interface OutreachDurationTimeSeriesItem {
  date: string;
  p10_outreach_days: number;
  p25_outreach_days: number;
  p50_outreach_days: number;
  p75_outreach_days: number;
  p90_outreach_days: number;
}

interface OnTimeContractCompletionTimeSeriesItem {
  date: string;
  on_time_pct_30_day: number;
}

interface OutreachConversionTimeSeriesItem {
  date: string;
  conversion_rate_14_day: number;
}

interface PipelineEfficiencyTimeSeriesItem {
  date: string;
  contract_value: number;
  efficiency: number;
}

interface ContractCompletionTimeSeriesItem {
  date: string;
  on_time_pct: number;
  late_pct: number;
  abandoned_pct: number;
}

interface RecurringTimeSeriesItem {
  date: Date;
  gmv: number;
  revenue: number;
}

const NINETY_DAYS_AGO = new Date();
NINETY_DAYS_AGO.setDate(NINETY_DAYS_AGO.getDate() - 90);

// Simulated Mantine colors object
const colors = {
  red: ["#ff0000"],
  blue: ["#0000ff"],
  green: ["#00ff00"],
  yellow: ["#ffff00"],
  purple: ["#800080"],
  orange: ["#ff8c00"],
  teal: ["#008080"],
  pink: ["#ff1493"],
  cyan: ["#00ffff"],
  lime: ["#00ff00"],
  gray: ["#808080"],
  brown: ["#a52a2a"],
};

// Simple hash function for a string
function hashString({ str }: { str: string }) {
  let hash = 0;
  for (let i = 0; i < str.length; i += 1) {
    const char = str.charCodeAt(i);
    hash = hash * 31 + char;
    hash %= 2147483647;
  }
  return Math.abs(hash);
}

function getDeterministicColor({ str }: { str: string }) {
  // Get all color keys (e.g., 'red', 'blue', 'green')
  const colorKeys = Object.keys(colors);

  // Hash the input string to get a numerical value
  const hash = hashString({ str });

  // Use the hash to deterministically select a color key
  const colorKey = colorKeys[hash % colorKeys.length];

  // Use the hash to deterministically select a shade for the chosen color
  const shades = colors[colorKey as keyof typeof colors];
  const shade = shades[hash % shades.length];

  return shade;
}

export default function Metrics() {
  const [loading, setLoading] = useState(false);

  const [contractTimeSeries, setContractTimeSeries] = useState<ContractTimeSeriesItem[]>([]);
  const [cohortGmvTimeSeries, setCohortGmvTimeSeries] = useState([]);
  const [cohortLabels, setCohortLabels] = useState<string[]>([]);
  const [averageContractValueTimeSeries, setAverageContractValueTimeSeries] = useState<
    AverageContractValueTimeSeriesItem[]
  >([]);
  const [contractDurationTimeSeries, setContractDurationTimeSeries] = useState<
    ContractDurationTimeSeriesItem[]
  >([]);
  const [outreachDurationTimeSeries, setOutreachDurationTimeSeries] = useState<
    OutreachDurationTimeSeriesItem[]
  >([]);
  const [outreachConversionTimeSeries, setOutreachConversionTimeSeries] = useState<
    OutreachConversionTimeSeriesItem[]
  >([]);
  const [contractCompletionTimeSeries, setContractCompletionTimeSeries] = useState<
    ContractCompletionTimeSeriesItem[]
  >([]);
  const [onTimeContractCompletionTimeSeries, setOnTimeContractCompletionTimeSeries] = useState<
    OnTimeContractCompletionTimeSeriesItem[]
  >([]);
  const [funnelVolumeTimeSeries, setFunnelVolumeTimeSeries] = useState<
    FunnelVolumeTimeSeriesItem[]
  >([]);
  const [discoveryMetricsTimeSeries, setDiscoveryMetricsTimeSeries] = useState<
    DiscoveryTimeSeriesItem[]
  >([]);
  const [pipelineEfficiencyTimeSeries, setPipelineEfficiencyTimeSeries] = useState<
    PipelineEfficiencyTimeSeriesItem[]
  >([]);
  const [recurringMetrics, setRecurringMetrics] = useState<RecurringTimeSeriesItem[]>([]);
  const [metricsLastUpdated, setMetricsLastUpdated] = useState<Date>(null);
  const [, setUpdateTrigger] = useState(0);

  const fetchMetrics = (abortController?: AbortController) => {
    setLoading(true);
    getCoreMetrics(abortController)
      .then((response) => {
        if (!response) {
          return;
        }

        const {
          success,
          contractMetrics,
          cohortGmvMetrics,
          cohortNames,
          averageContractValueMetrics,
          funnelVolumeMetrics,
          discoveryMetrics,
          recurringContractsMetrics,
          contractDurationMetrics,
          contractCompletionMetrics,
          onTimeContractMetrics,
          outreachDurationMetrics,
          outreachConversionRateMetrics,
          pipelineEfficiencyMetrics,
          lastUpdated,
        } = response;

        if (success) {
          setContractTimeSeries(JSON.parse(contractMetrics));
          setCohortGmvTimeSeries(JSON.parse(cohortGmvMetrics));
          setCohortLabels(cohortNames);
          setAverageContractValueTimeSeries(JSON.parse(averageContractValueMetrics));
          setFunnelVolumeTimeSeries(JSON.parse(funnelVolumeMetrics));
          setDiscoveryMetricsTimeSeries(JSON.parse(discoveryMetrics));
          setRecurringMetrics(JSON.parse(recurringContractsMetrics));
          setContractDurationTimeSeries(JSON.parse(contractDurationMetrics));
          setContractCompletionTimeSeries(JSON.parse(contractCompletionMetrics));
          setOnTimeContractCompletionTimeSeries(JSON.parse(onTimeContractMetrics));
          setOutreachDurationTimeSeries(JSON.parse(outreachDurationMetrics));
          setOutreachConversionTimeSeries(JSON.parse(outreachConversionRateMetrics));
          setPipelineEfficiencyTimeSeries(JSON.parse(pipelineEfficiencyMetrics));
          setMetricsLastUpdated(new Date(lastUpdated));
        }
      })
      .finally(() => setLoading(false));
  };

  const refreshMetrics = (abortController?: AbortController) => {
    setLoading(true);
    refreshCoreMetrics(abortController).finally(() => fetchMetrics(abortController));
  };

  useEffect(() => {
    const abortController = new AbortController();
    fetchMetrics(abortController);
    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setUpdateTrigger((prev) => prev + 1);
    }, 60000);

    return () => clearInterval(timer);
  }, []);

  const cohortSeries = cohortLabels.map((cohortName) => ({
    name: cohortName,
    label: `${cohortName} Cohort`,
    color: getDeterministicColor({ str: cohortName }),
  }));

  return (
    <Paper radius="sm" p="xl">
      <Container>
        <LoadingOverlay visible={loading} />
        <Group justify="space-between">
          <Stack gap={2} px="xl">
            <Title order={1} fw="500">
              Core Metrics
            </Title>
            {metricsLastUpdated && (
              <Text size="sm" c="dimmed">
                Last updated {timeAgo(metricsLastUpdated)}
              </Text>
            )}
          </Stack>
          <Button
            leftSection={<IconRefresh size="1rem" />}
            size="xs"
            onClick={() => refreshMetrics()}>
            Refresh Metrics
          </Button>
        </Group>
        <Title order={2} fw="500" py="sm" px="xl">
          Sourcing Funnel Volume
        </Title>
        <LineChart
          h={300}
          data={funnelVolumeTimeSeries}
          dataKey="date"
          valueFormatter={(value) => value.toFixed(2)}
          withLegend
          series={[
            {
              name: "num_ops_accepted_14_day_avg",
              label: "Ops Approved (14 Day Avg)",
              color: "red",
            },
            {
              name: "num_internal_accepted_14_day_avg",
              label: "Internal Approved (14 Day Avg)",
              color: "yellow",
            },
            {
              name: "num_brand_accepted_14_day_avg",
              label: "Brand Approved (14 Day Avg)",
              color: "blue",
            },
            {
              name: "num_email_subscribed_14_day_avg",
              label: "Emails Sent (14 Day Avg)",
              color: "green",
            },
          ]}
          curveType="natural"
          withDots={false}
          lineChartProps={{ syncId: "sourcing-charts" }}
        />
        <Title order={2} fw="500" py="sm" px="xl">
          Internal vs. Brand Approval Rate
        </Title>
        <LineChart
          h={300}
          data={funnelVolumeTimeSeries}
          dataKey="date"
          valueFormatter={(value) => `${(value * 100).toFixed(2)}%`}
          withLegend
          series={[
            {
              name: "brand_accept_rate_14_day_window",
              label: "Brand Approval Rate (14 Day)",
              color: "cyan",
            },
            {
              name: "internal_accept_rate_14_day_window",
              label: "Internal Approval Rate (14 Day)",
              color: "grape",
            },
          ]}
          curveType="natural"
          withDots={false}
          lineChartProps={{ syncId: "sourcing-charts" }}
        />
        <Space my="sm" />
        <Title order={2} fw="500" py="sm" px="xl">
          Signed vs. Completed Contracts
        </Title>
        <LineChart
          h={300}
          data={contractTimeSeries}
          dataKey="date"
          valueFormatter={(value) => value.toFixed(2)}
          withLegend
          series={[
            { name: "created_contracts_14_day_avg", label: "Created (14 Day Avg)", color: "red" },
            { name: "signed_contracts_14_day_avg", label: "Signed (14 Day Avg)", color: "blue" },
            {
              name: "completed_contracts_14_day_avg",
              label: "Completed (14 Day Avg)",
              color: "green",
            },
          ]}
          curveType="natural"
          withDots={false}
          lineChartProps={{ syncId: "contract-charts" }}
        />
        <Space my="sm" />
        <Title order={2} fw="500" py="sm" px="xl">
          Signed vs. Completed Contracts (GMV)
        </Title>
        <LineChart
          h={300}
          data={contractTimeSeries}
          dataKey="date"
          valueFormatter={(value) =>
            (value / 100).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })
          }
          withLegend
          series={[
            {
              name: "created_contracts_gmv_14_day_avg",
              label: "Created GMV (14 Day Avg)",
              color: "red",
            },
            {
              name: "signed_contracts_gmv_14_day_avg",
              label: "Signed GMV (14 Day Avg)",
              color: "blue",
            },
            {
              name: "completed_contracts_gmv_14_day_avg",
              label: "Completed GMV (14 Day Avg)",
              color: "green",
            },
          ]}
          curveType="natural"
          withDots={false}
          lineChartProps={{ syncId: "contract-charts" }}
        />
        <Space my="sm" />
        <Title order={2} fw="500" py="sm" px="xl">
          Average Contract Value
        </Title>
        <LineChart
          h={300}
          data={averageContractValueTimeSeries}
          dataKey="date"
          valueFormatter={(value) =>
            (value / 100).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })
          }
          withLegend
          series={[
            {
              name: "contract_value_14_day_avg",
              label: "Average Contract Value (14 Day Avg)",
              color: "cyan",
            },
          ]}
          curveType="natural"
          withDots={false}
          lineChartProps={{ syncId: "contract-charts" }}
        />
        <Space my="sm" />
        <Title order={2} fw="500" py="sm" px="xl">
          On-Time Contract Completion
        </Title>
        <LineChart
          h={300}
          data={onTimeContractCompletionTimeSeries}
          dataKey="date"
          valueFormatter={(value) => `${(value * 100).toFixed(2)}%`}
          withLegend
          series={[
            {
              name: "on_time_pct_30_day",
              label: "On-Time Completion (30 Day Window)",
              color: "purple",
            },
          ]}
          curveType="natural"
          withDots={false}
        />
        <Space my="sm" />
        <Stack gap={2} px="xl" py="sm">
          <Title order={2} fw="500">
            Contract Completion by Cohort Month
          </Title>
          <Text size="sm">
            Percentage of contracts that were completed on time, late, or abandoned by cohort month.
          </Text>
          <Stack gap={0}>
            <List>
              <List.Item>
                <Text c="dimmed" size="sm">
                  Contract due date is defined as the latest live date across all deliverables
                  (excluding any extensions).
                </Text>
              </List.Item>
              <List.Item>
                <Text c="dimmed" size="sm">
                  Abandoned is defined as archived or greater than 30 days past due.
                </Text>
              </List.Item>
              <List.Item>
                <Text c="dimmed" size="sm">
                  Recurring contracts are excluded from this chart.
                </Text>
              </List.Item>
            </List>
          </Stack>
        </Stack>
        <BarChart
          h={300}
          data={contractCompletionTimeSeries}
          dataKey="date"
          type="stacked"
          valueFormatter={(value) => `${(value * 100).toFixed(1)}%`}
          yAxisProps={{ domain: [0, 1] }}
          series={[
            { name: "on_time_pct", label: "On Time", color: "teal" },
            { name: "late_pct", label: "Late", color: "yellow" },
            { name: "abandoned_pct", label: "Abandoned", color: "red" },
          ]}
        />
        <Space my="sm" />
        <Stack gap={2} px="xl" py="sm">
          <Title order={2} fw="500">
            Email Outreach Conversion Rate
          </Title>
          <Text size="sm" c="dimmed">
            Conversion rate is defined as the percentage of outreach emails that result in a signed
            contract within 30 days of the initial outreach.
          </Text>
        </Stack>
        <LineChart
          h={300}
          data={outreachConversionTimeSeries}
          dataKey="date"
          valueFormatter={(value) => `${(value * 100).toFixed(2)}%`}
          withLegend
          series={[
            {
              name: "conversion_rate_30_day",
              label: "Conversion Rate (30 Day Window)",
              color: "cyan",
            },
          ]}
          curveType="natural"
          withDots={false}
          lineChartProps={{ syncId: "outreach-charts" }}
        />
        <Space my="sm" />
        <Stack gap={2} px="xl" py="sm">
          <Title order={2} fw="500">
            Negotiation Duration
          </Title>
          <Text size="sm" c="dimmed">
            Negotiation Duration is measured as the number of days between initial email outreach
            and contract signature across signed contracts.
          </Text>
        </Stack>
        <LineChart
          h={300}
          data={outreachDurationTimeSeries}
          dataKey="date"
          valueFormatter={(value) => `${value} days`}
          withLegend
          series={[
            {
              name: "p10_outreach_days",
              label: "p10 Duration (14 Day Window)",
              color: "blue",
            },
            {
              name: "p25_outreach_days",
              label: "p25 Duration (14 Day Window)",
              color: "red",
            },
            {
              name: "p50_outreach_days",
              label: "p50 Duration (14 Day Window)",
              color: "yellow",
            },
            {
              name: "p75_outreach_days",
              label: "p75 Duration (14 Day Window)",
              color: "purple",
            },
            {
              name: "p90_outreach_days",
              label: "p90 Duration (14 Day Window)",
              color: "green",
            },
          ]}
          curveType="natural"
          withDots={false}
          lineChartProps={{ syncId: "outreach-charts" }}
        />
        <Space my="sm" />
        <Stack gap={2} px="xl" py="sm">
          <Title order={2} fw="500">
            Contract Duration
          </Title>
          <Text size="sm" c="dimmed">
            Contract Duration is measured as the number of days between contract signature and
            completion.
          </Text>
        </Stack>
        <LineChart
          h={300}
          data={contractDurationTimeSeries}
          dataKey="date"
          valueFormatter={(value) => `${value} days`}
          withLegend
          series={[
            {
              name: "p25_completion_days",
              label: "p25 Contract Duration (14 Day Window)",
              color: "purple",
            },
            {
              name: "p50_completion_days",
              label: "p50 Contract Duration (14 Day Window)",
              color: "orange",
            },
            {
              name: "p75_completion_days",
              label: "p75 Contract Duration (14 Day Window)",
              color: "teal",
            },
          ]}
          curveType="natural"
          withDots={false}
          lineChartProps={{ syncId: "contract-charts" }}
        />
        <Space my="sm" />
        <Title order={2} fw="500" py="sm" px="xl">
          Booked Recurring GMV and Revenue
        </Title>
        <AreaChart
          h={300}
          data={recurringMetrics}
          dataKey="date"
          valueFormatter={(value) => {
            const amountInDollars = value / 100;
            const formatter = new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
            });
            return formatter.format(amountInDollars);
          }}
          withLegend
          series={[
            {
              name: "gmv",
              label: "Recurring GMV",
              color: "blue",
            },
            {
              name: "revenue",
              label: "Recurring Revenue",
              color: "teal",
            },
          ]}
        />
        <Space my="sm" />
        <Title order={2} fw="500" py="sm" px="xl">
          Self-Serve Discovery
        </Title>
        <LineChart
          h={300}
          data={discoveryMetricsTimeSeries}
          dataKey="date"
          valueFormatter={(value) => value.toFixed(2)}
          withLegend
          series={[
            {
              name: "num_queries_14_day_avg",
              label: "Queries (14 Day Avg)",
              color: "red",
            },
            {
              name: "num_page_views_14_day_avg",
              label: "Page Views (14 Day Avg)",
              color: "yellow",
            },
            {
              name: "num_activations_14_day_avg",
              label: "Activations (14 Day Avg)",
              color: "blue",
            },
            {
              name: "num_saves_14_day_avg",
              label: "Saves (14 Day Avg)",
              color: "green",
            },
          ]}
          curveType="natural"
          withDots={false}
        />
        <Space my="sm" />
        <Title order={2} fw="500" py="sm" px="xl">
          Monthly GMV by Cohort
        </Title>
        <LineChart
          h={300}
          data={cohortGmvTimeSeries}
          dataKey="date"
          valueFormatter={(value) => {
            const amountInDollars = value / 100;
            const formatter = new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
            });
            return formatter.format(amountInDollars);
          }}
          withLegend
          series={cohortSeries}
          curveType="natural"
        />
        <Space my="sm" />
        <Title order={2} fw="500" py="sm" px="xl">
          [WIP] Influencer Pipeline Efficiency
        </Title>
        <LineChart
          h={300}
          data={pipelineEfficiencyTimeSeries}
          dataKey="date"
          valueFormatter={(value) =>
            (value / 100).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })
          }
          withLegend
          series={[
            {
              name: "efficiency",
              label: "Pipeline Efficiency",
              color: "cyan",
            },
            {
              name: "contract_value",
              label: "Contract Value",
              color: "teal",
            },
          ]}
          curveType="natural"
          withDots={false}
        />
      </Container>
    </Paper>
  );
}
