import React, { useEffect, useState } from "react";

import { Stack } from "@mantine/core";

import { OpsDailyTasks } from "admin/app/ops/tasks/OpsDailyTasks";
import { getOpsTasks, getOpsSourcingDailyTasks } from "admin/api/opsApi";
import { useAdminAppContext } from "admin/app/AdminAppShell";
import { CreatorSetSourcingPlan } from "models/OpsTask";

export const AdminCreatorLabelingTasks = ({
  dailyTasks,
  priority,
}: {
  dailyTasks: CreatorSetSourcingPlan[];
  priority: Record<number, number>;
}) => {
  const { opsUsers } = useAdminAppContext();

  return (
    <Stack p="md" w="100%">
      <OpsDailyTasks opsUsers={opsUsers} sourcingAllocations={dailyTasks} priority={priority} />
    </Stack>
  );
};

export default AdminCreatorLabelingTasks;
