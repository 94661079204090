import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  ActionIcon,
  Anchor,
  Button,
  Center,
  Divider,
  Flex,
  Group,
  HoverCard,
  List,
  Loader,
  Modal,
  Paper,
  Pill,
  Stack,
  Table,
  TagsInput,
  Text,
  Textarea,
  TextInput,
  Tooltip,
  Title,
  Switch,
  ThemeIcon,
} from "@mantine/core";

import { Notifications } from "@mantine/notifications";

import { useDisclosure } from "@mantine/hooks";

import { IconPlus, IconEdit, IconInfoCircle, IconAlertCircle } from "@tabler/icons-react";

import { useAppDispatch } from "reduxStore/hooks";
import { addNewCreatorSetToState } from "reduxStore/creatorSetsSlice";

import { showFailureNotification, showSuccessNotification } from "components/common/Notifications";

import {
  CREATOR_SETS_URL,
  CreatorSetItemsResponse,
  fetchCreatorSetItems,
} from "components/creator_sets/CreatorSetUtils";
import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";
import { CreatorSetItem } from "components/discovery/Datamodels";

import ProductGuideLink from "campaigns/create/common/ProductGuideLink";
import EmptyState from "campaigns/main/campaignPage/EmptyState";

const MIN_TEXT_LENGTH_RECOMMENDATIONS = 20;

const validateCreatorSetName = (name: string): boolean => name.length > 0;
const validateCreatorSetDescription = (
  brandRequestedRecommendations: boolean,
  description: string,
): boolean =>
  !brandRequestedRecommendations || description.length > MIN_TEXT_LENGTH_RECOMMENDATIONS;
const validateCreatorSetExampleTopics = (
  brandRequestedRecommendations: boolean,
  exampleTopics: string,
): boolean =>
  !brandRequestedRecommendations || exampleTopics.length > MIN_TEXT_LENGTH_RECOMMENDATIONS;

const validateRequiredFields = ({
  brandRequestedRecommendations,
  name,
  description,
  exampleTopics,
}: {
  brandRequestedRecommendations: boolean;
  name: string;
  description: string;
  exampleTopics: string;
}): boolean =>
  validateCreatorSetName(name) &&
  validateCreatorSetDescription(brandRequestedRecommendations, description) &&
  validateCreatorSetExampleTopics(brandRequestedRecommendations, exampleTopics);

const addNewCreatorSet = async ({
  brandRequestedRecommendations,
  name,
  description,
  exampleTopics,
  exampleHashtags,
  brandRequirements,
  usOnly,
}: {
  brandRequestedRecommendations: boolean;
  name: string;
  description: string;
  exampleTopics: string;
  exampleHashtags: string[];
  brandRequirements: string;
  usOnly: boolean;
}) => {
  const creatorSetInfoEndpoint = `${CREATOR_SETS_URL}create_creator_set/`;
  const request = await createRequestWithFirebaseToken({
    url: creatorSetInfoEndpoint,
    method: "POST",
    body: JSON.stringify({
      brandRequestedRecommendations,
      name,
      description,
      exampleTopics,
      exampleHashtags,
      brandRequirements,
      usOnly,
    }),
  });

  const response = await handleResult(request);
  return response;
};

const modifyCreatorSet = async ({
  brandRequestedRecommendations,
  name,
  description,
  exampleTopics,
  exampleHashtags,
  brandRequirements,
  creatorSetId,
  usOnly,
}: {
  brandRequestedRecommendations: boolean;
  name: string;
  description: string;
  exampleTopics: string;
  exampleHashtags: string[];
  brandRequirements: string;
  creatorSetId: number;
  usOnly: boolean;
}) => {
  const creatorSetInfoEndpoint = `${CREATOR_SETS_URL}modify_creator_set/`;
  const request = await createRequestWithFirebaseToken({
    url: creatorSetInfoEndpoint,
    method: "POST",
    body: JSON.stringify({
      brandRequestedRecommendations,
      name,
      description,
      exampleTopics,
      exampleHashtags,
      brandRequirements,
      creatorSetId,
      usOnly,
    }),
  });

  const response: CreatorSetItemsResponse = await handleResult(request);
  return response;
};

function NewlineText({ text }: { text: string }) {
  const textArray = text.split("\n");

  return (
    <>
      {textArray.map((line: string) => (
        // eslint-disable-next-line react/no-array-index-key
        <Text size="sm" key={`${line}`}>
          {line}
          <br />
        </Text>
      ))}
    </>
  );
}

function InfoHoverCard({ children }: { children: React.ReactNode }) {
  return (
    <HoverCard shadow="md" width={600} position="right">
      <HoverCard.Target>
        <ActionIcon variant="subtle" size="1.0rem">
          <IconInfoCircle />
        </ActionIcon>
      </HoverCard.Target>
      <HoverCard.Dropdown>{children}</HoverCard.Dropdown>
    </HoverCard>
  );
}

function CreatorSetFields({
  brandRequestedRecommendations,
  setBrandRequestedRecommendations,
  recommendationsDescription,
  newCreatorSetName,
  setNewCreatorSetName,
  newCreatorSetDescription,
  setNewCreatorSetDescription,
  newExampleTopics,
  setNewExampleTopics,
  newExampleHashtags,
  setNewExampleHashtags,
  newBrandRequirements,
  setNewBrandRequirements,
  showError,
  usOnly,
  setUsOnly,
}: {
  brandRequestedRecommendations: boolean;
  setBrandRequestedRecommendations: (value: boolean) => void;
  recommendationsDescription: string;
  newCreatorSetName: string;
  setNewCreatorSetName: (value: string) => void;
  newCreatorSetDescription: string;
  setNewCreatorSetDescription: (value: string) => void;
  newExampleTopics: string;
  setNewExampleTopics: (value: string) => void;
  newExampleHashtags: string[];
  setNewExampleHashtags: (value: string[]) => void;
  newBrandRequirements: string;
  setNewBrandRequirements: (value: string) => void;
  showError: boolean;
  usOnly: boolean;
  setUsOnly: (value: boolean) => void;
}) {
  return (
    <>
      <Group pr="xl">
        <Switch
          checked={brandRequestedRecommendations}
          onChange={(event) => setBrandRequestedRecommendations(event.currentTarget.checked)}
          label={
            <Text fw="500" size="md">
              Enable Recommendations (Beta)
            </Text>
          }
          description={recommendationsDescription}
        />
      </Group>
      <TextInput
        label="Name"
        placeholder="Enter a name..."
        withAsterisk
        size="sm"
        value={newCreatorSetName}
        onChange={(event) => setNewCreatorSetName(event.currentTarget.value)}
        error={
          showError && !validateCreatorSetName(newCreatorSetName) && "Enter a creator set name..."
        }
      />
      <Switch
        checked={usOnly}
        onChange={(event) => setUsOnly(event.currentTarget.checked)}
        label={
          <Text fw="500" size="sm">
            Recommend only US creators
          </Text>
        }
        description="If enabled, new creator recommendation for the creator set will include only creators in the United States"
      />
      <Textarea
        label={
          <Flex align="center" gap={2}>
            <Text size="sm" fw="500">
              Description
            </Text>
            <InfoHoverCard>
              <Stack>
                <Title order={3} fw={700}>
                  Description Guidelines
                </Title>
                <Text>
                  Write a short and clear description of the kinds of creators you’re looking for.
                  Include keywords that you think will help surface good creators, explain things
                  out to avoid ambiguity, and avoid using jargon.
                </Text>
                <Text>Please do not include links; we cannot parse them properly.</Text>
                <Divider />
                <Stack gap="xs">
                  <Text fw={500}>Good example:</Text>
                  <Text>
                    Creators who talk about coffee, with a focus on those who feature espresso
                    machines and the technique behind great espresso shots.
                  </Text>
                </Stack>
                <Stack gap="xs">
                  <Text fw={500}>Bad example:</Text>
                  <Text>Spro/coffee focused creators</Text>
                </Stack>
              </Stack>
            </InfoHoverCard>
            {brandRequestedRecommendations && (
              <Text fw="500" size="sm" c="red">
                *
              </Text>
            )}
          </Flex>
        }
        description={
          "A description of the types of creators you want for the creator set. " +
          "Our recommendation system uses this description to source relevant creators, " +
          "so please use an externally understandable description."
        }
        size="sm"
        placeholder="Example: Creators who discuss growth or marketing best practices."
        autosize
        minRows={3}
        value={newCreatorSetDescription}
        onChange={(event) => setNewCreatorSetDescription(event.currentTarget.value)}
        error={
          showError &&
          !validateCreatorSetDescription(brandRequestedRecommendations, newCreatorSetDescription) &&
          "Enter a longer creator set description in order to enable recommendations..."
        }
      />
      <Textarea
        label={
          <Flex align="center" gap={2}>
            <Text size="sm" fw="500">
              Example Topics
            </Text>
            <InfoHoverCard>
              <Stack>
                <Title order={3} fw={700}>
                  Example Topics Guidelines
                </Title>
                <Text>
                  Provide examples of topics that creators in this creator set might discuss in
                  their videos. These are topics that target creators already discuss in their
                  videos, NOT what you want them to discuss in a potential partnership with your
                  brand.
                </Text>
                <Text>
                  Be clear and avoid ambiguity. Please provide at least two example topics.
                </Text>
                <Divider />
                <Stack gap="xs">
                  <Text fw={500}>Good examples:</Text>
                  <List>
                    <List.Item>Best tools for marketing or growth.</List.Item>
                    <List.Item>How to grow your business’s social media presence.</List.Item>
                    <List.Item>Best practices for running ads on Google or Facebook.</List.Item>
                    <List.Item>Metrics to measure the success of your program.</List.Item>
                  </List>
                </Stack>
                <Stack gap="xs">
                  <Text fw={500}>Bad example:</Text>
                  <Text>SEO, Facebook Ads, AdWords, results tracking</Text>
                </Stack>
              </Stack>
            </InfoHoverCard>
            {brandRequestedRecommendations && (
              <Text fw="500" size="sm" c="red">
                *
              </Text>
            )}
          </Flex>
        }
        description="Example topics that creators in this creator set discuss. Please provide at least two example topics. Include topics that these creators already discuss in their videos, NOT what you want them to discuss in a potential partnership with your brand."
        size="sm"
        placeholder={`Example:
- Best tools for marketing or growth.
- How to grow your business’s social media presence.
- Best practices for running ads on Google or Facebook.
- Metrics to measure success of your program.`}
        autosize
        minRows={3}
        value={newExampleTopics}
        onChange={(event) => setNewExampleTopics(event.currentTarget.value)}
        error={
          showError &&
          !validateCreatorSetExampleTopics(brandRequestedRecommendations, newExampleTopics) &&
          "Enter a longer set of example topics in order to enable recommendations..."
        }
      />
      <TagsInput
        label="Example Hashtags"
        description="TikTok and Instagram only. If you already know some hashtags that can surface good creators, add them in. This helps point our recommendations in the right direction, but is not required for recommendations."
        value={newExampleHashtags}
        onChange={(values) => {
          // for each value, if it doesn't start with #, add it
          const updatedValues = values.map((value) => {
            if (value?.length > 0 && value[0] !== "#") {
              return `#${value}`;
            }
            return value;
          });
          const dedupedValues = [...new Set(updatedValues)];
          setNewExampleHashtags(dedupedValues);
        }}
        splitChars={[",", " "]}
      />
      <Textarea
        label="Requirements"
        description="Other requirements or considerations."
        size="sm"
        placeholder={`Example:
- At least 30% of the content on the creators profile must be related to marketing.
- Avoid other companies or brands.
- Avoid creators who only discuss social media.`}
        autosize
        minRows={3}
        value={newBrandRequirements}
        onChange={(event) => setNewBrandRequirements(event.currentTarget.value)}
      />
    </>
  );
}

export const AddCreatorSetModal = ({
  handleAddNewCreatorSet,
  opened,
  close,
}: {
  handleAddNewCreatorSet: (creatorSetItem: CreatorSetItem) => void;
  opened: boolean;
  close: () => void;
}) => {
  const [showError, setShowError] = useState(false);

  const [brandRequestedRecommendations, setBrandRequestedRecommendations] = useState(true);
  const [newCreatorSetName, setNewCreatorSetName] = useState("");
  const [newCreatorSetDescription, setNewCreatorSetDescription] = useState("");
  const [newExampleTopics, setNewExampleTopics] = useState("");
  const [newExampleHashtags, setNewExampleHashtags] = useState([]);
  const [newBrandRequirements, setNewBrandRequirements] = useState("");
  const [usOnly, setUsOnly] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleAddCreatorSet = () => {
    setLoading(true);
    addNewCreatorSet({
      brandRequestedRecommendations,
      name: newCreatorSetName,
      description: newCreatorSetDescription,
      exampleTopics: newExampleTopics,
      exampleHashtags: newExampleHashtags,
      brandRequirements: newBrandRequirements,
      usOnly,
    })
      .then((response) => {
        if (response.success) {
          handleAddNewCreatorSet(response.creatorSet);

          setBrandRequestedRecommendations(true);
          setNewCreatorSetDescription("");
          setNewCreatorSetName("");
          setNewExampleTopics("");
          setNewExampleHashtags([]);
          setNewBrandRequirements("");
          setUsOnly(false);
          showSuccessNotification({
            message: (
              <Text size="sm">
                Added{" "}
                <Text span fw="500" size="sm">
                  {newCreatorSetName}
                </Text>{" "}
                successfully!
              </Text>
            ),
          });
          close();
        } else {
          showFailureNotification({ message: "Failed to create new Creator Set" });
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      opened={opened}
      onClose={close}
      title={
        <Text size="lg" fw="600">
          Add New Creator Set
        </Text>
      }
      zIndex={500}
      size="xl">
      <Stack>
        <CreatorSetFields
          brandRequestedRecommendations={brandRequestedRecommendations}
          setBrandRequestedRecommendations={setBrandRequestedRecommendations}
          recommendationsDescription="Recommendations are enabled after you add 5 creators and are processed in the order they are received. Our queue is currently longer than usual and there may be delays."
          newCreatorSetName={newCreatorSetName}
          setNewCreatorSetName={setNewCreatorSetName}
          newCreatorSetDescription={newCreatorSetDescription}
          setNewCreatorSetDescription={setNewCreatorSetDescription}
          newExampleTopics={newExampleTopics}
          setNewExampleTopics={setNewExampleTopics}
          newExampleHashtags={newExampleHashtags}
          setNewExampleHashtags={setNewExampleHashtags}
          newBrandRequirements={newBrandRequirements}
          setNewBrandRequirements={setNewBrandRequirements}
          showError={showError}
          usOnly={usOnly}
          setUsOnly={setUsOnly}
        />
        <Group justify="right">
          <Button variant="default" onClick={close}>
            Cancel
          </Button>
          <Button
            loading={loading}
            onClick={() => {
              if (
                !validateRequiredFields({
                  brandRequestedRecommendations,
                  name: newCreatorSetName,
                  description: newCreatorSetDescription,
                  exampleTopics: newExampleTopics,
                })
              ) {
                setShowError(true);
              } else {
                setShowError(false);
                handleAddCreatorSet();
              }
            }}>
            Submit
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};

function EditCreatorSet({
  creatorSetItem,
  updateCreatorSetItem,
}: {
  creatorSetItem: CreatorSetItem;
  updateCreatorSetItem: (creatorSetItem: CreatorSetItem) => void;
}) {
  const [opened, { open, close }] = useDisclosure();
  const [
    openedUnderThresholdModal,
    { open: openUnderThresholdModal, close: closeUnderThresholdModal },
  ] = useDisclosure();
  const [showError, setShowError] = useState(false);

  const [brandRequestedRecommendations, setBrandRequestedRecommendations] = useState(
    creatorSetItem.brand_requested_recommendations,
  );
  const [newCreatorSetName, setNewCreatorSetName] = useState(creatorSetItem.name);
  const [newCreatorSetDescription, setNewCreatorSetDescription] = useState(
    creatorSetItem.description,
  );
  const [newExampleTopics, setNewExampleTopics] = useState(creatorSetItem.example_topics);
  const [newExampleHashtags, setNewExampleHashtags] = useState(
    creatorSetItem?.example_hashtags || [],
  );
  const [newBrandRequirements, setNewBrandRequirements] = useState(
    creatorSetItem.brand_requirements,
  );
  const [usOnly, setUsOnly] = useState(creatorSetItem.us_only);

  const [loading, setLoading] = useState(false);

  const handleUpdateCreatorSet = (callbackFn: () => void) => {
    setLoading(true);
    modifyCreatorSet({
      brandRequestedRecommendations,
      name: newCreatorSetName,
      description: newCreatorSetDescription,
      exampleTopics: newExampleTopics,
      exampleHashtags: newExampleHashtags,
      brandRequirements: newBrandRequirements,
      creatorSetId: creatorSetItem.id,
      usOnly,
    })
      .then((response) => {
        if (response.success) {
          updateCreatorSetItem({
            ...creatorSetItem,
            name: newCreatorSetName,
            description: newCreatorSetDescription,
            brand_requested_recommendations: brandRequestedRecommendations,
            example_topics: newExampleTopics,
            brand_requirements: newBrandRequirements,
            us_only: usOnly,
          });
          close();
          callbackFn();
        } else {
          showFailureNotification({ message: "Failed to update Creator Set" });
        }
      })
      .finally(() => setLoading(false));
  };

  const changesDetected =
    newCreatorSetName !== creatorSetItem.name ||
    newCreatorSetDescription !== creatorSetItem.description ||
    brandRequestedRecommendations !== creatorSetItem.brand_requested_recommendations ||
    newExampleTopics !== creatorSetItem.example_topics ||
    newExampleHashtags?.join(", ") !== creatorSetItem?.example_hashtags?.join(", ") ||
    newBrandRequirements !== creatorSetItem.brand_requirements ||
    usOnly !== creatorSetItem.us_only;

  const numAdded = creatorSetItem.num_accepted + creatorSetItem.num_saved;
  const minimumAddedThresholdMet = numAdded >= 5;
  const recommendationDescription = minimumAddedThresholdMet
    ? `The more creators you added, the more accurate the recommendations are. You have added ${numAdded} creators so far.`
    : `You must add at least 5 creators to this Creator Set to enable recommendations. You have added ${numAdded} creators so far. The more creators you add, the more accurate the recommendations are.`;

  return (
    <>
      <Modal
        opened={openedUnderThresholdModal}
        onClose={closeUnderThresholdModal}
        title={
          <Flex align="center" gap="sm">
            <ThemeIcon variant="transparent" color="red">
              <IconAlertCircle />
            </ThemeIcon>
            <Text size="lg" fw="600">
              Save at least five creators to enable recommendations!
            </Text>
          </Flex>
        }
        size="md"
        withCloseButton={false}>
        <Stack>
          <Text>
            Your changes have been saved and recommendations have been turned on for this creator
            set, but we cannot generate recommendations until you have saved at least five creators
            to this creator set. Once you&apos;ve saved five creators, your creator set will be
            added to the recommendations queue.
          </Text>
          <Group justify="right">
            <Button onClick={closeUnderThresholdModal}>Got it</Button>
          </Group>
        </Stack>
      </Modal>
      <Modal
        opened={opened}
        onClose={close}
        title={
          <Text size="lg" fw="600">
            Modify {creatorSetItem.name}
          </Text>
        }
        size="xl">
        <Stack>
          <CreatorSetFields
            brandRequestedRecommendations={brandRequestedRecommendations}
            setBrandRequestedRecommendations={setBrandRequestedRecommendations}
            recommendationsDescription={recommendationDescription}
            newCreatorSetName={newCreatorSetName}
            setNewCreatorSetName={setNewCreatorSetName}
            newCreatorSetDescription={newCreatorSetDescription}
            setNewCreatorSetDescription={setNewCreatorSetDescription}
            newExampleTopics={newExampleTopics}
            setNewExampleTopics={setNewExampleTopics}
            newExampleHashtags={newExampleHashtags}
            setNewExampleHashtags={setNewExampleHashtags}
            newBrandRequirements={newBrandRequirements}
            setNewBrandRequirements={setNewBrandRequirements}
            showError={showError}
            usOnly={usOnly}
            setUsOnly={setUsOnly}
          />
          <Group justify="right">
            <Button variant="default" onClick={close}>
              Cancel
            </Button>
            <Button
              loading={loading}
              disabled={!changesDetected}
              onClick={() => {
                if (
                  !validateRequiredFields({
                    brandRequestedRecommendations,
                    name: newCreatorSetName,
                    description: newCreatorSetDescription,
                    exampleTopics: newExampleTopics,
                  })
                ) {
                  setShowError(true);
                } else {
                  setShowError(false);
                  handleUpdateCreatorSet(() => {
                    if (
                      !creatorSetItem.brand_requested_recommendations &&
                      brandRequestedRecommendations &&
                      !minimumAddedThresholdMet
                    ) {
                      openUnderThresholdModal();
                    }
                  });
                }
              }}>
              Save
            </Button>
          </Group>
        </Stack>
      </Modal>
      <Tooltip label={`Modify ${creatorSetItem.name}`}>
        <ActionIcon variant="subtle" color="gray" onClick={open}>
          <IconEdit size="1rem" />
        </ActionIcon>
      </Tooltip>
    </>
  );
}

export function CreatorSetEmptyState({
  onCreatorSetCreated,
}: {
  onCreatorSetCreated?: (creatorSet: CreatorSetItem) => void;
}) {
  const dispatch = useAppDispatch();
  const [opened, { open, close }] = useDisclosure();

  return (
    <EmptyState
      title="Create your first creator set"
      description="Creator sets are collections of creators. We’ll reach to activated creators in your creator sets and invite them into your campaign."
      secondDescription={
        <>
          For more information about creator sets, check out our <ProductGuideLink />.
        </>
      }
      ctaButton={
        <>
          <AddCreatorSetModal
            handleAddNewCreatorSet={(creatorSetItem: CreatorSetItem) => {
              dispatch(addNewCreatorSetToState({ creatorSet: creatorSetItem }));
              onCreatorSetCreated(creatorSetItem);
            }}
            opened={opened}
            close={close}
          />
          <Button leftSection={<IconPlus size="1rem" />} onClick={open}>
            New Creator Set
          </Button>
        </>
      }
    />
  );
}

function CreatorSetResults({
  creatorSetItems,
  setCreatorSetItems,
}: {
  creatorSetItems: CreatorSetItem[];
  setCreatorSetItems: (creatorSetItems: CreatorSetItem[]) => void;
}) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [opened, { open, close }] = useDisclosure();

  if (!creatorSetItems || creatorSetItems.length === 0) {
    return (
      <CreatorSetEmptyState
        onCreatorSetCreated={(creatorSetItem: CreatorSetItem) => {
          setCreatorSetItems([creatorSetItem]);
          dispatch(addNewCreatorSetToState({ creatorSet: creatorSetItem }));
        }}
      />
    );
  }

  return (
    <>
      <Group justify="space-between" px="xs">
        <Title order={1} fw="600">
          Manage Creator Sets
        </Title>
        <AddCreatorSetModal
          handleAddNewCreatorSet={(creatorSetItem: CreatorSetItem) => {
            setCreatorSetItems([creatorSetItem, ...creatorSetItems]);
            dispatch(addNewCreatorSetToState({ creatorSet: creatorSetItem }));
          }}
          opened={opened}
          close={close}
        />
        <Button leftSection={<IconPlus size="1rem" />} onClick={open}>
          New Creator Set
        </Button>
      </Group>
      <Paper radius="md" p="lg" withBorder>
        <Table horizontalSpacing="xs">
          <Table.Thead>
            <Table.Tr>
              <Table.Th />
              <Table.Th>Creator Set</Table.Th>
              <Table.Th>Num Activated</Table.Th>
              <Table.Th>Num Saved</Table.Th>
              <Table.Th>Associated Campaigns</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {creatorSetItems.map((item, _) => (
              <Table.Tr key={`${item.id}-${item.name}`}>
                <Table.Td px={0}>
                  <EditCreatorSet
                    creatorSetItem={item}
                    updateCreatorSetItem={(creatorSetItem: CreatorSetItem) => {
                      const newCreatorSetItems = creatorSetItems.map((existingItem) => {
                        if (existingItem.id === item.id) {
                          return creatorSetItem;
                        }
                        return existingItem;
                      });
                      setCreatorSetItems(newCreatorSetItems);
                      // dispatch(
                      //   updateCreatorSetNameAndDescription({
                      //     creatorSetId: creatorSetItem.id,
                      //     name: creatorSetItem.name,
                      //     description: creatorSetItem.description,
                      //   }),
                      // );
                    }}
                  />
                </Table.Td>
                <Table.Td>
                  <HoverCard shadow="md" disabled={!item.description}>
                    <HoverCard.Target>
                      <Anchor
                        onClick={() => {
                          navigate(`/campaigns/creator_sets/${item.id}/view`);
                        }}>
                        <Text>{item.name}</Text>
                      </Anchor>
                    </HoverCard.Target>
                    <HoverCard.Dropdown>
                      <Text fw="500" size="sm">
                        Description
                      </Text>
                      <NewlineText text={item.description} />
                      {item.example_topics && (
                        <>
                          <Text fw="500" size="sm" mt="sm">
                            Example Topics
                          </Text>
                          <NewlineText text={item.example_topics} />
                        </>
                      )}
                      {item.brand_requirements && (
                        <>
                          <Text fw="500" size="sm" mt="sm">
                            Requirements
                          </Text>
                          <NewlineText text={item.brand_requirements} />
                        </>
                      )}
                    </HoverCard.Dropdown>
                  </HoverCard>
                </Table.Td>
                <Table.Td>
                  <Anchor
                    onClick={() => {
                      navigate(`/campaigns/creator_sets/${item.id}/view`);
                    }}>
                    {item.num_accepted}
                  </Anchor>
                </Table.Td>
                <Table.Td>
                  <Anchor
                    onClick={() => {
                      navigate(`/campaigns/creator_sets/${item.id}/recommended`);
                    }}>
                    {item.num_saved}
                  </Anchor>
                </Table.Td>
                <Table.Td>
                  {item.campaigns.map((campaign, idx) => (
                    <Pill key={`${item.id}-${campaign}`}>{campaign}</Pill>
                  ))}
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      </Paper>
    </>
  );
}

const SetManagementView = () => {
  const [creatorSetItems, setCreatorSetItems] = useState<CreatorSetItem[]>([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();

    setLoaded(false);
    fetchCreatorSetItems(abortController).then((creatorSets) => {
      setCreatorSetItems(creatorSets);
      setLoaded(true);
    });

    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <>
      <Notifications />
      <Stack>
        {!loaded && (
          <Center>
            <Loader />
          </Center>
        )}
        {loaded && (
          <CreatorSetResults
            creatorSetItems={creatorSetItems}
            setCreatorSetItems={setCreatorSetItems}
          />
        )}
      </Stack>
    </>
  );
};

export default SetManagementView;
