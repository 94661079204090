import { IconListCheck } from "@tabler/icons-react";
import { CAMPAIGN_PORTAL } from "campaigns/PathConstants";
import React, { useEffect } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import NavBarButton from "campaigns/navbar/v2/buttons/components/NavBarButton";
import { useAppDispatch, useAppSelector } from "reduxStore/hooks";
import { selectPendingTaskCount, selectCompletedTaskCount } from "reduxStore/tasksSelectors";
import { fetchTaskCount } from "reduxStore/tasksSlice";

const TaskNavBarButton = ({ showMinimizedVersion }: { showMinimizedVersion: boolean }) => {
  // navigator
  const navigate = useNavigate();

  const isSelected = useMatch(`${CAMPAIGN_PORTAL}/tasks`) != null;

  // Get Task Counts
  const dispatch = useAppDispatch();
  const pendingTaskCount = useAppSelector(selectPendingTaskCount);
  const completedTaskCount = useAppSelector(selectCompletedTaskCount);

  useEffect(() => {
    dispatch(fetchTaskCount({ testMode: false }));
  }, [dispatch]);

  return (
    <NavBarButton
      isSelected={isSelected}
      LeftIconName={IconListCheck}
      onClick={() => {
        navigate(`${CAMPAIGN_PORTAL}/tasks`);
      }}
      title="Tasks"
      showMinimizedVersion={showMinimizedVersion}
      showBadgeProps={{
        isVisible: pendingTaskCount > 0 || completedTaskCount > 0,
        badgeColor: pendingTaskCount > 0 ? "red" : "gray",
        badgeText: pendingTaskCount > 0 ? pendingTaskCount : completedTaskCount,
      }}
    />
  );
};

export default TaskNavBarButton;
