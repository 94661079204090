import { API_URL } from "configs/Configs";
import { createRequest, createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";

import { User } from "firebase/auth";

import {
  ContractStatus,
  ContractType,
  MultiPlatformState,
  PaymentStatus,
  ProductAccessInfoToProvideToCreatorType,
} from "components/contracts/common/Common";
import Deliverable from "components/contracts/models/Deliverable";
import { PaymentType } from "components/contracts/models/Payment";
import { UsageRightsRequestDetails } from "components/contracts/models/UsageRightsRequestDetails";
import { RecurrenceType, RecurrenceFrequency, SupportedPlatform } from "models/Common";
import { ContractReviewStatus } from "components/contracts/brand_review/types";
import { ProductAccessInfoNeededFromCreatorType } from "models/Campaign";

// Fetch all of the Contract data from the API.
export const viewContract = async (contractId: string) => {
  const requestUrl = new URL(`${API_URL}/api/contracts/view_contract`);
  requestUrl.searchParams.append("hashId", contractId);

  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response = await handleResult(request);
  return response;
};

// Sign the Contract via the API.
export const signContract = async (
  hashId: string,
  firstName: string,
  lastName: string,
  email: string,
  isManager: boolean,
  creatorFirstName: string,
  creatorLastName: string,
  creatorEmail: string,
  sendCreatorEmails: boolean,
  companyName: string,
) => {
  const requestUrl = new URL(`${API_URL}/api/contracts/sign_contract`);
  const request = createRequest({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      hashId,
      firstName,
      lastName,
      email,
      isManager,
      creatorFirstName,
      creatorLastName,
      creatorEmail,
      sendCreatorEmails,
      companyName,
    }),
  });

  const response = await handleResult(request);
  return response;
};

export const saveCreatorProductAccessInfo = async (
  contractHashId: string,
  productAccessInfoNeededFromCreator: ProductAccessInfoNeededFromCreatorType,
  productAccessCreatorInput: string | object,
) => {
  const requestUrl = new URL(`${API_URL}/api/contracts/save_creator_product_access_info`);
  const request = createRequest({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      contractHashId,
      productAccessInfoNeededFromCreator,
      productAccessCreatorInput,
    }),
  });

  const response = await handleResult(request);
  return response;
};
export const saveBrandReportIssueWithProductAccess = async (
  contractHashId: string,
  message: string,
) => {
  const requestUrl = new URL(
    `${API_URL}/api/contracts/brand_report_issue_with_product_access_info`,
  );
  const request = createRequest({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      contractHashId,
      message,
    }),
  });
  const response = await handleResult(request);
  return response;
};

export const saveBrandProductAccessInfo = async (
  contractHashId: string,
  productAccessInfoToProvideToCreator: ProductAccessInfoToProvideToCreatorType,
  productAccessBrandOutput: string | object,
) => {
  const requestUrl = new URL(`${API_URL}/api/contracts/save_brand_product_access_info`);
  const request = createRequest({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      contractHashId,
      productAccessInfoToProvideToCreator,
      productAccessBrandOutput,
    }),
  });

  const response = await handleResult(request);
  return response;
};

export const updateAdditionalEmails = async (hashId: string, additionalEmails: string[]) => {
  const requestUrl = new URL(`${API_URL}/api/contracts/update_additional_email`);

  const request = createRequest({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      hashId,
      additionalEmails,
    }),
  });

  const response = await handleResult(request);
  return response;
};

// Sign the Contract via the API.
export const sendEmailAfterSigningContract = async (hashId: string) => {
  const requestUrl = new URL(`${API_URL}/api/contracts/send_email_after_signing_contract`);

  const request = createRequest({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      hashId,
    }),
  });

  const response = await handleResult(request);
  return response;
};

// Update Deliverable Timeline
export const updateDeliverableTimeline = async ({
  deliverableId,
  scriptDateString,
  scriptRevisionDateString,
  videoDraftDateString,
  approvalDateString,
  liveDateString,
  analyticsDateString,
  overrideLiveWindow = false,
}: {
  deliverableId: string;
  scriptDateString: string;
  scriptRevisionDateString: string;
  videoDraftDateString: string;
  approvalDateString: string;
  liveDateString: string;
  analyticsDateString: string;
  overrideLiveWindow?: boolean;
}) => {
  const requestUrl = new URL(`${API_URL}/api/contracts/update_deliverable_timeline`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      deliverableId,
      scriptDateString,
      scriptRevisionDateString,
      videoDraftDateString,
      approvalDateString,
      liveDateString,
      analyticsDateString,
      overrideLiveWindow,
    }),
  });

  const response = await handleResult(request);
  return response;
};

// Fetch active Campaigns from API and display them as dropdown options.
export const getCampaigns = async () => {
  const requestUrl = new URL(`${API_URL}/api/get_campaigns`);

  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response = await handleResult(request);
  return response;
};

// Create a new Contract Review
export const createContractReview = async (
  campaignId: string,
  multiPlatformState: MultiPlatformState,
  deliverables: Deliverable[],
  recommendPrice: number,
  creatorAskingPrice: number,
  originalContractAmount: number,
  contractType: ContractType,
) => {
  const requestUrl = new URL(`${API_URL}/api/contracts/create_contract_review`);

  // Compute POST body fields
  const instagramHandle = multiPlatformState[SupportedPlatform.INSTAGRAM].handle;
  const tiktokHandle = multiPlatformState[SupportedPlatform.TIKTOK].handle;
  const youtubeHandle = multiPlatformState[SupportedPlatform.YOUTUBE].handle;
  const serializedDeliverables = deliverables.map((deliverable) => deliverable.serialize());

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      campaignId,
      instagramHandle,
      tiktokHandle,
      youtubeHandle,
      deliverables: serializedDeliverables,
      recommendPrice,
      creatorAskingPrice,
      originalContractAmount,
      contractType,
    }),
  });

  const response = await handleResult(request);
  return response;
};

// Create a new Contract.
export const saveContract = async (
  repeatContract: boolean,
  repeatInterval: number,
  repeatFrequency: RecurrenceFrequency,
  repeatType: RecurrenceType,
  repeatNumOccurrences: number,
  campaignId: string,
  contractType: ContractType,
  contractAmount: number,
  bonusAmount: number,
  bonusCondition: string,
  creatorFirstName: string,
  creatorEmail: string,
  additionalEmails: string[],
  multiPlatformState: MultiPlatformState,
  deliverables: Deliverable[],
  closeContact: string,
  addendum: string,
  exclusivityAddendum: boolean,
  contractReviewId?: number,
  contractOfferReviewId?: number,
  deliverablePackageId?: number | null,
) => {
  const requestUrl = new URL(`${API_URL}/api/contracts/create_contract`);

  // Compute POST body fields
  const instagramHandle = multiPlatformState[SupportedPlatform.INSTAGRAM].handle;
  const tiktokHandle = multiPlatformState[SupportedPlatform.TIKTOK].handle;
  const youtubeHandle = multiPlatformState[SupportedPlatform.YOUTUBE].handle;
  const serializedDeliverables = deliverables.map((deliverable) => deliverable.serialize());

  // Convert USD amount to minor units
  const contractAmountInMinorUnits = contractAmount * 100;
  const bonusAmountInMinorUnits = bonusAmount * 100;

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      repeatContract,
      repeatInterval,
      repeatFrequency,
      repeatType,
      repeatNumOccurrences,
      campaignId,
      contractType,
      contractAmountInMinorUnits,
      bonusAmountInMinorUnits,
      bonusCondition,
      creatorFirstName,
      creatorEmail,
      additionalEmails,
      instagramHandle,
      tiktokHandle,
      youtubeHandle,
      closeContact,
      deliverables: serializedDeliverables,
      addendum,
      exclusivityAddendum,
      contractReviewId,
      contractOfferReviewId,
      deliverablePackageId,
    }),
  });

  const response = await handleResult(request);
  return response;
};

// Create a new usage rights contract. Although this is the same object in the backend, the endpoint
// and parameters are different.
export const saveUsageRightsContract = async (
  usageRightsRequestId: string,
  contractAmount: number,
  usageRightsDays: string | number,
  adCodeRequired: boolean,
  creatorFirstName: string,
  creatorEmail: string,
  additionalEmails: string[],
  closeContact: string,
  dueDate: Date,
  addendum: string,
  exclusivityAddendum: boolean,
) => {
  const requestUrl = new URL(`${API_URL}/api/contracts/create_usage_rights_contract`);

  // Convert USD amount to minor units
  const contractAmountInMinorUnits = contractAmount * 100;

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      usageRightsRequestId,
      contractAmountInMinorUnits,
      adCodeRequired,
      usageRightsDays,
      creatorFirstName,
      creatorEmail,
      additionalEmails,
      closeContact,
      dueDate,
      addendum,
      exclusivityAddendum,
    }),
  });

  const response = await handleResult(request);
  return response;
};

// Send contract email to creator
export const sendContractEmailToCreator = async (
  closeContact: string,
  creatorEmail: string,
  creatorName: string,
  contractId: string,
) => {
  const requestUrl = new URL(`${API_URL}/api/contracts/send_contract_email_to_creator`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      closeContact,
      creatorName,
      creatorEmail,
      contractId,
    }),
  });

  const response = await handleResult(request);
  return response;
};

// Send offer email to creator
export const sendOfferEmailToCreator = async (offerId: number) => {
  const requestUrl = new URL(`${API_URL}/api/contracts/send_offer_email_to_creator`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      offerId,
    }),
  });

  const response = await handleResult(request);
  return response;
};

// Validate the Contract fields via the API.
export const validateContractFieldsViaAPI = async (
  multiPlatformState: MultiPlatformState,
  campaignId: string,
) => {
  const platformToCreatorHandle = Object.entries(multiPlatformState).reduce(
    (acc, [platform, { selected, handle }]) => {
      if (selected && handle.length > 0) {
        acc[platform.toLowerCase()] = handle;
      }
      return acc;
    },
    {} as { [key: string]: string },
  );

  const requestUrl = new URL(`${API_URL}/api/contracts/validate_contract_fields`);
  requestUrl.searchParams.append(
    "platformToCreatorHandle",
    JSON.stringify(platformToCreatorHandle),
  );
  requestUrl.searchParams.append("campaignId", campaignId);

  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response = await handleResult(request);
  return response;
};

export async function fetchPendingPayments() {
  const requestUrl = new URL(`${API_URL}/api/contracts/fetch_pending_payments`);

  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response = await handleResult(request);
  return response;
}

export const getContractUrlFromContractReview = async (
  contractReviewId: number,
  contractAmount: number,
) => {
  const requestUrl = new URL(`${API_URL}/api/contracts/get_contract_url_from_contract_review`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      contractReviewId,
      contractAmount,
    }),
  });

  const response = await handleResult(request);
  return response;
};

export async function fetchAllPendingContractReviewCount() {
  const requestUrl = new URL(`${API_URL}/api/contracts/fetch_pending_contract_review_count`);
  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response = await handleResult(request);
  return response;
}

// Fetch all Contract Review data for a campaign from the API.
export async function fetchAllContractReviewData() {
  const requestUrl = new URL(`${API_URL}/api/contracts/fetch_all_contract_review_data`);
  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response = await handleResult(request);
  return response;
}

// Fetch Contract Review data for a campaign from the API.
export async function fetchContractReviewData(
  campaignId: string,
  adGroupId: string,
  isAdmin: boolean,
) {
  const requestUrl = new URL(`${API_URL}/api/contracts/fetch_contract_review_data`);
  if (campaignId) {
    requestUrl.searchParams.append("campaignId", campaignId);
  }
  if (adGroupId) {
    requestUrl.searchParams.append("adGroupId", adGroupId);
  }
  if (isAdmin) {
    requestUrl.searchParams.append("isAdmin", "true");
  }
  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response = await handleResult(request);
  return response;
}

export async function saveContractReview(
  contractReviewId: number,
  brandFeedback?: string,
  brandReviewStatus?: ContractReviewStatus,
  internalReviewStatus?: ContractReviewStatus,
  maxOfferCap?: number,
) {
  const requestUrl = new URL(`${API_URL}/api/contracts/save_contract_review`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      contractReviewId,
      maxOfferCap,
      brandFeedback,
      brandReviewStatus,
      internalReviewStatus,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function saveContractOfferReview(
  contractReviewId: string,
  packageApproval: {
    [uuid: string]: { brand_approved: boolean; max_offer_cap: number; feedback: string };
  },
) {
  const requestUrl = new URL(`${API_URL}/api/contracts/save_contract_offer_review`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      contractReviewId,
      packageApproval,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function archiveContractReview(contractReviewId: number) {
  const requestUrl = new URL(`${API_URL}/api/contracts/archive_contract_review`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      contractReviewId,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function archiveContractOfferReview(contractOfferReviewId: string) {
  const requestUrl = new URL(`${API_URL}/api/contracts/archive_contract_offer_review`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      contractOfferReviewId,
    }),
  });

  const response = await handleResult(request);
  return response;
}

// Fetch all Contract data for a campaign from the API.
export async function fetchContracts(campaignId: string) {
  const requestUrl = new URL(`${API_URL}/api/contracts/fetch_contracts`);
  requestUrl.searchParams.append("campaignId", campaignId);

  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response = await handleResult(request);
  return response;
}

// Fetch all Contract data for a brand. This is not a full contract model.
export async function fetchContractsForBrand() {
  const requestUrl = new URL(`${API_URL}/api/contracts/fetch_contracts_for_brand`);

  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response = await handleResult(request);
  return response;
}

// Fetch a single Contract from the API.
export async function fetchContract(contractId: string) {
  const requestUrl = new URL(`${API_URL}/api/contracts/fetch_contract`);
  requestUrl.searchParams.append("contractId", contractId);

  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response = await handleResult(request);
  return response;
}

// Fetch all overdue contracts
export async function fetchOverdueContracts() {
  const requestUrl = new URL(`${API_URL}/api/contracts/fetch_overdue_contracts`);
  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response = await handleResult(request);
  return response;
}

// Fetch all overdue content review
export async function fetchOverdueContentReview() {
  const requestUrl = new URL(`${API_URL}/api/contracts/fetch_overdue_content_review`);
  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response = await handleResult(request);
  return response;
}

// Fetch all contracts for a creator
export async function fetchContractsByCreator(creatorId: number, abortController: AbortController) {
  if (!creatorId) {
    return Promise.resolve(null);
  }
  const requestUrl = new URL(`${API_URL}/api/contracts/fetch_completed_contracts`);
  requestUrl.searchParams.append("creatorId", creatorId.toString());

  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response = await handleResult(request, abortController);
  return response;
}

// Fetch all deliverables for active contracts for the authenticated brand (or admin)
export async function fetchDeliverablesForBrand() {
  const requestUrl = new URL(`${API_URL}/api/contracts/fetch_deliverables_for_brand`);
  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response = await handleResult(request);
  return response;
}

// Fetch a single Deliverable from the API.
export async function fetchDeliverable(deliverableId: string) {
  const requestUrl = new URL(`${API_URL}/api/contracts/fetch_deliverable`);
  requestUrl.searchParams.append("deliverableId", deliverableId);

  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response = await handleResult(request);
  return response;
}

export async function fetchSpendData() {
  const requestUrl = new URL(`${API_URL}/api/contracts/fetch_spend_data`);

  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response = await handleResult(request);
  return response;
}

export async function fetchGMVData(campaignId?: string) {
  const requestUrl = new URL(`${API_URL}/api/contracts/fetch_gmv_data`);
  if (campaignId) {
    requestUrl.searchParams.append("campaignId", campaignId);
  }

  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response = await handleResult(request);
  return response;
}

export async function fetchGMVDataForBrand() {
  const requestUrl = new URL(`${API_URL}/api/contracts/fetch_gmv_data_for_brand`);

  const request = await createRequestWithFirebaseToken({ url: requestUrl });
  const response = await handleResult(request);
  return response;
}

// Delete a Contract.
export async function deleteContract(contractId: string) {
  const requestUrl = new URL(`${API_URL}/api/contracts/delete_contract`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      contractId,
    }),
  });

  const response = await handleResult(request);
  return response;
}

// Update a Contract's status.
export async function updateContractStatus(
  contractId: string,
  status?: ContractStatus,
  paymentStatus?: PaymentStatus,
) {
  const requestUrl = new URL(`${API_URL}/api/contracts/update_contract_status`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      contractId,
      status,
      paymentStatus,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function markReadyForPayment(contractId: string) {
  const requestUrl = new URL(`${API_URL}/api/payments/mark_ready_for_payment`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      contractId,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function markEligibleForBonus({
  contractId,
  earnedBonusAmount,
}: {
  contractId: string;
  earnedBonusAmount: number;
}) {
  const requestUrl = new URL(`${API_URL}/api/payments/mark_eligible_for_bonus`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({ contractId, earnedBonusAmount }),
  });

  const response = await handleResult(request);
  return response;
}

export async function payContract(
  contractId: string,
  amountInMinorUnits: number,
  paymentType: PaymentType,
) {
  const requestUrl = new URL(`${API_URL}/api/payments/pay_contract`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      contractId,
      amountInMinorUnits,
      paymentType,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function getPlatformBalance(user: User) {
  const firebaseToken = await user.getIdToken();
  const requestUrl = new URL(`${API_URL}/api/payments/get_platform_balance`);

  const request = new Request(requestUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
  });

  const response = await handleResult(request);
  return response;
}

export async function getContractDetails(contractId: string) {
  const requestUrl = new URL(`${API_URL}/api/contracts/get_contract_details`);
  requestUrl.searchParams.append("contractId", contractId);

  const request = await createRequestWithFirebaseToken({ url: requestUrl });

  const response = await handleResult(request);
  return response;
}

// Fetch all of the Contract and Deliverable data from the API.
export async function getDeliverablesViewData(
  user: User,
  contractId: string,
  abortController?: AbortController,
) {
  const requestUrl = new URL(`${API_URL}/api/contracts/get_deliverables_view_data`);
  requestUrl.searchParams.append("hashId", contractId);

  const headers = {
    "Content-Type": "application/json",
  } as Record<string, string>;

  if (user) {
    const firebaseToken = await user.getIdToken();
    headers.Authorization = `Bearer ${firebaseToken}`;
  }

  const request = new Request(requestUrl, {
    method: "GET",
    headers,
  });

  const response = await handleResult(request, abortController);
  return response;
}

// Generate a Stripe onboarding link and redirect to it.
export async function onboardStripe(
  contractId: string,
  country: string,
  email: string,
  businessType: string,
  firstName: string,
  lastName: string,
) {
  const requestUrl = new URL(`${API_URL}/api/payments/onboard_stripe`);

  const request = new Request(requestUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      contractId,
      country,
      email,
      businessType,
      firstName,
      lastName,
    }),
  });

  const response = await handleResult(request);
  return response;
}

// Generate a Stripe onboarding link for an existing account and redirect to it.
export async function continueOnboardStripe(contractId: string) {
  const requestUrl = new URL(`${API_URL}/api/payments/continue_onboard_stripe`);

  const request = new Request(requestUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      contractId,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function updateDeliverableStatus(
  user: User,
  deliverableId: string,
  newStatus: string,
) {
  const firebaseToken = await user.getIdToken();
  const requestUrl = new URL(`${API_URL}/api/contracts/update_deliverable_status`);

  const request = new Request(requestUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
    body: JSON.stringify({
      deliverableId,
      newStatus,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function getPresignedUploadUrl(
  contractType: ContractType,
  contractId: string,
  deliverableId: string,
  title: string,
  caption: string,
  fileType: string,
  name: string,
) {
  let request;
  if (contractType === ContractType.USAGE_RIGHTS) {
    const requestUrl = new URL(`${API_URL}/api/contracts/get_presigned_usage_rights_upload_url`);
    request = new Request(requestUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        contractId,
        fileType,
        name,
      }),
    });
  } else {
    const requestUrl = new URL(`${API_URL}/api/contracts/get_presigned_upload_url`);
    request = new Request(requestUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        contractId,
        deliverableId,
        title,
        caption,
        fileType,
        name,
      }),
    });
  }

  const response = await handleResult(request);
  return response;
}

export async function completeSinglePartUpload(
  videoId: string,
  deliverableId: string,
  title: string,
  caption: string,
  integrationTimestamp?: number,
) {
  const requestUrl = new URL(`${API_URL}/api/contracts/complete_single_part_upload`);
  const request = new Request(requestUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      videoId,
      deliverableId,
      title,
      caption,
      integrationTimestamp,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function completeUsageRightsVideoSinglePartUpload(
  videoId: string,
  contractId: string,
) {
  const requestUrl = new URL(
    `${API_URL}/api/contracts/complete_usage_rights_single_part_video_upload`,
  );
  const request = new Request(requestUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      videoId,
      contractId,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function initializeMultipartUpload(
  contractType: ContractType,
  contractId: string,
  deliverableId: string,
  title: string,
  caption: string,
  fileType: string,
  name: string,
  integrationTimestamp?: number,
) {
  let request;
  if (contractType === ContractType.USAGE_RIGHTS) {
    const requestUrl = new URL(
      `${API_URL}/api/contracts/initialize_usage_rights_video_multipart_upload`,
    );
    request = new Request(requestUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        contractId,
        fileType,
        name,
      }),
    });
  } else {
    const requestUrl = new URL(`${API_URL}/api/contracts/initialize_multipart_upload`);
    request = new Request(requestUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        contractId,
        deliverableId,
        title,
        caption,
        fileType,
        name,
        integrationTimestamp,
      }),
    });
  }

  const response = await handleResult(request);
  return response;
}

export async function listMultipartUploadParts(videoId: string, uploadId: string) {
  const requestUrl = new URL(`${API_URL}/api/contracts/list_multipart_upload_parts`);
  requestUrl.searchParams.append("videoId", videoId);
  requestUrl.searchParams.append("uploadId", uploadId);

  const request = new Request(requestUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const response = await handleResult(request);
  return response;
}

export async function createVideoPartUploadUrl(videoId: string, partNumber: number) {
  const requestUrl = new URL(`${API_URL}/api/contracts/create_video_part_upload_url`);
  const request = new Request(requestUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      videoId,
      partNumber,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function completeMultipartUpload(
  contractType: ContractType,
  contractId: string,
  videoId: string,
  uploadId: string,
  parts: any[],
) {
  let request;
  if (contractType === ContractType.USAGE_RIGHTS) {
    const requestUrl = new URL(
      `${API_URL}/api/contracts/complete_usage_rights_video_multipart_upload`,
    );
    request = new Request(requestUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        contractId,
        videoId,
        uploadId,
        parts,
      }),
    });
  } else {
    const requestUrl = new URL(`${API_URL}/api/contracts/complete_multipart_upload`);
    request = new Request(requestUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        videoId,
        uploadId,
        parts,
      }),
    });
  }

  const response = await handleResult(request);
  return response;
}

export async function abortMultipartUpload(videoId: string, uploadId: string) {
  const requestUrl = new URL(`${API_URL}/api/contracts/abort_multipart_upload`);
  const request = new Request(requestUrl, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      videoId,
      uploadId,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function fetchContractsMissingReferralLinkOrPromoCodeForCampaign(
  campaignHashId: string,
  campaignId: string,
) {
  const requestUrl = new URL(
    `${API_URL}/api/contracts/fetch_contracts_missing_referral_link_or_promo_code_for_campaign`,
  );
  if (campaignId) {
    requestUrl.searchParams.append("campaignId", campaignId);
  } else {
    requestUrl.searchParams.append("campaignHashId", campaignHashId);
  }

  const request = await createRequestWithFirebaseToken({ url: requestUrl });

  const response = await handleResult(request);
  return response;
}

export async function fetchContractsMissingReferralLinkOrPromoCodeForAdGroup(adGroupId: string) {
  const requestUrl = new URL(
    `${API_URL}/api/contracts/fetch_contracts_missing_referral_link_or_promo_code_for_ad_group`,
  );
  requestUrl.searchParams.append("adGroupId", adGroupId);

  const request = await createRequestWithFirebaseToken({ url: requestUrl });

  const response = await handleResult(request);
  return response;
}

export async function fetchContractsWithContentForCampaign(
  campaignHashId: string,
  campaignId?: string,
) {
  const requestUrl = new URL(`${API_URL}/api/contracts/fetch_contracts_with_content_for_campaign`);
  if (campaignId) {
    requestUrl.searchParams.append("campaignId", campaignId);
  } else {
    requestUrl.searchParams.append("campaignHashId", campaignHashId);
  }

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
  });

  const response = await handleResult(request);
  return response;
}

export async function fetchContractsWithContentForAdGroup(adGroupId: string) {
  const requestUrl = new URL(`${API_URL}/api/contracts/fetch_contracts_with_content_for_ad_group`);
  requestUrl.searchParams.append("adGroupId", adGroupId);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
  });

  const response = await handleResult(request);
  return response;
}

export async function fetchUploadedContentForContract(contractId: string) {
  const requestUrl = new URL(`${API_URL}/api/contracts/fetch_uploaded_content_for_contract`);
  requestUrl.searchParams.append("contractId", contractId);

  const request = await createRequestWithFirebaseToken({ url: requestUrl });

  const response = await handleResult(request);
  return response;
}

export async function addDeliverableVideo(
  deliverableId: string,
  videoId: string,
  title: string,
  caption: string,
  integrationTimestamp: number,
) {
  const requestUrl = new URL(`${API_URL}/api/contracts/add_deliverable_video`);

  const request = new Request(requestUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      deliverableId,
      videoId,
      title,
      caption,
      integrationTimestamp,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function removeLatestVideoSubmission({ deliverableId }: { deliverableId: string }) {
  const requestUrl = new URL(`${API_URL}/api/contracts/remove_latest_video_submission`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      deliverableId,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function approveContent({
  deliverableId,
  scriptId,
  videoId,
  messageText,
}: {
  deliverableId: string;
  messageText: string;
  scriptId?: string;
  videoId?: string;
}) {
  if (scriptId && videoId) {
    throw new Error("Only one of scriptId and videoId should be provided");
  }

  if (!scriptId && !videoId) {
    throw new Error("One of scriptId and videoId must be provided");
  }

  const requestUrl = new URL(`${API_URL}/api/contracts/approve_content`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      deliverableId,
      scriptId,
      videoId,
      messageText,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function requestContentRevision({
  deliverableId,
  messageText,
  scriptId,
  videoId,
}: {
  deliverableId: string;
  messageText: string;
  scriptId?: string;
  videoId?: string;
}) {
  if (scriptId && videoId) {
    throw new Error("Only one of scriptId and videoId should be provided");
  }

  if (!scriptId && !videoId) {
    throw new Error("One of scriptId and videoId must be provided");
  }

  const requestUrl = new URL(`${API_URL}/api/contracts/request_content_revision`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      deliverableId,
      scriptId,
      videoId,
      messageText,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function fetchMessages({
  deliverableId,
  messageParty,
  scriptId,
  videoId,
  abortController,
}: {
  deliverableId: string;
  messageParty: string;
  scriptId?: string;
  videoId?: string;
  abortController?: AbortController;
}) {
  if (scriptId && videoId) {
    throw new Error("Only one of scriptId and videoId should be provided");
  }

  if (!scriptId && !videoId) {
    throw new Error("One of scriptId and videoId must be provided");
  }

  const requestUrl = new URL(`${API_URL}/api/contracts/fetch_messages`);
  requestUrl.searchParams.append("deliverableId", deliverableId);
  if (videoId) {
    requestUrl.searchParams.append("videoId", videoId);
  }
  if (scriptId) {
    requestUrl.searchParams.append("scriptId", scriptId);
  }
  requestUrl.searchParams.append("messageParty", messageParty);

  const request = new Request(requestUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const response = await handleResult(request, abortController);
  return response;
}

export async function sendMessageToBrand({
  deliverableId,
  messageText,
  scriptId,
  videoId,
}: {
  deliverableId: string;
  messageText: string;
  scriptId?: string;
  videoId?: string;
}) {
  if (scriptId && videoId) {
    throw new Error("Only one of scriptId and videoId should be provided");
  }

  if (!scriptId && !videoId) {
    throw new Error("One of scriptId and videoId must be provided");
  }

  const requestUrl = new URL(`${API_URL}/api/contracts/send_message_to_brand`);

  const request = new Request(requestUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      deliverableId,
      scriptId,
      videoId,
      messageText,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function sendMessageToCreator({
  deliverableId,
  messageText,
  scriptId,
  videoId,
}: {
  deliverableId: string;
  messageText: string;
  scriptId?: string;
  videoId?: string;
}) {
  if (scriptId && videoId) {
    throw new Error("Only one of scriptId and videoId should be provided");
  }

  if (!scriptId && !videoId) {
    throw new Error("One of scriptId and videoId must be provided");
  }

  const requestUrl = new URL(`${API_URL}/api/contracts/send_message_to_creator`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      deliverableId,
      scriptId,
      videoId,
      messageText,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function markMessagesLastRead({ deliverableId }: { deliverableId: string }) {
  const requestUrl = new URL(`${API_URL}/api/contracts/mark_messages_last_read`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      deliverableId,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function markMessagesLastReadForContract({ contractId }: { contractId: string }) {
  const requestUrl = new URL(`${API_URL}/api/contracts/mark_messages_last_read_for_contract`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      contractId,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function saveScript(deliverableId: string, text: string) {
  const requestUrl = new URL(`${API_URL}/api/contracts/save_script`);

  const request = new Request(requestUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      deliverableId,
      text,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function submitScriptForReview(deliverableId: string, scriptId: string) {
  const requestUrl = new URL(`${API_URL}/api/contracts/submit_script_for_review`);

  const request = new Request(requestUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      deliverableId,
      scriptId,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function fetchScriptsForDeliverable(
  deliverableId: string,
  abortController?: AbortController,
) {
  const requestUrl = new URL(`${API_URL}/api/contracts/fetch_scripts_for_deliverable`);
  requestUrl.searchParams.append("deliverableId", deliverableId);

  const request = new Request(requestUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const response = await handleResult(request, abortController);
  return response;
}

export async function fetchVideosForDeliverable(
  deliverableId: string,
  abortController?: AbortController,
) {
  const requestUrl = new URL(`${API_URL}/api/contracts/fetch_videos_for_deliverable`);
  requestUrl.searchParams.append("deliverableId", deliverableId);

  const request = new Request(requestUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const response = await handleResult(request, abortController);
  return response;
}

export async function fetchLatestVideosForContract(contractId: string) {
  const requestUrl = new URL(`${API_URL}/api/contracts/fetch_latest_videos_for_contract`);
  requestUrl.searchParams.append("contractId", contractId);

  const request = new Request(requestUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const response = await handleResult(request);
  return response;
}

export async function revertReviewStatus(
  deliverableId: string,
  scriptId?: string,
  videoId?: string,
) {
  if (scriptId && videoId) {
    throw new Error("Only one of scriptId and videoId should be provided");
  }

  if (!scriptId && !videoId) {
    throw new Error("One of scriptId and videoId must be provided");
  }

  const requestUrl = new URL(`${API_URL}/api/contracts/revert_review_status`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      deliverableId,
      scriptId,
      videoId,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function deleteMessage(messageId: string) {
  const requestUrl = new URL(`${API_URL}/api/contracts/delete_message`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "DELETE",
    body: JSON.stringify({
      messageId,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function editMessage(messageId: string, text: string) {
  const requestUrl = new URL(`${API_URL}/api/contracts/edit_message`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "PATCH",
    body: JSON.stringify({
      messageId,
      text,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function sendMessageAsPlatform({
  deliverableId,
  messageText,
  recipientType,
  scriptId,
  videoId,
}: {
  deliverableId: string;
  messageText: string;
  recipientType: string;
  scriptId?: string;
  videoId?: string;
}) {
  const requestUrl = new URL(`${API_URL}/api/contracts/send_message_as_platform`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      deliverableId,
      scriptId,
      videoId,
      messageText,
      recipientType,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function snoozeContract({ contractId }: { contractId: string }) {
  const requestUrl = new URL(`${API_URL}/api/contracts/update_contract_snooze`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      contractId,
      snooze: true,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function unsnoozeContract({ contractId }: { contractId: string }) {
  const requestUrl = new URL(`${API_URL}/api/contracts/update_contract_snooze`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      contractId,
      snooze: false,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function extendContractDeadlines({
  contractId,
  numWeeks,
}: {
  contractId: string;
  numWeeks: number;
}) {
  const requestUrl = new URL(`${API_URL}/api/contracts/extend_contract_deadlines`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      contractId,
      numWeeks,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function archiveContract({ contractId }: { contractId: string }) {
  const requestUrl = new URL(`${API_URL}/api/contracts/archive_contract`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      contractId,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function unarchiveContract({ contractId }: { contractId: string }) {
  const requestUrl = new URL(`${API_URL}/api/contracts/unarchive_contract`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      contractId,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function reissueContract({
  contractId,
  creatorEmail,
  additionalEmails,
}: {
  contractId: string;
  creatorEmail: string;
  additionalEmails: string[];
}) {
  const requestUrl = new URL(`${API_URL}/api/contracts/reissue_contract`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      contractId,
      creatorEmail,
      additionalEmails,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function submitLiveUrl({
  deliverableId,
  url,
  isEditing,
}: {
  deliverableId: string;
  url: string;
  isEditing: boolean;
}) {
  const requestUrl = new URL(`${API_URL}/api/contracts/submit_live_url`);

  const request = new Request(requestUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      deliverableId,
      url,
      isEditing,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function submitAdCode({
  platform,
  deliverableId,
  adCode,
  isEditing,
}: {
  platform: SupportedPlatform;
  deliverableId: string;
  adCode: string;
  isEditing: boolean;
}) {
  const requestUrl = new URL(`${API_URL}/api/contracts/submit_ad_code`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      platform,
      deliverableId,
      adCode,
      isEditing,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function completeLiveContentSubmission({
  deliverableId,
  reuseFinalDraftAsAsset,
}: {
  deliverableId: string;
  reuseFinalDraftAsAsset: boolean;
}) {
  const requestUrl = new URL(`${API_URL}/api/contracts/complete_live_content_submission`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      deliverableId,
      reuseFinalDraftAsAsset,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function approveLiveUrl({ deliverableId }: { deliverableId: string }) {
  const requestUrl = new URL(`${API_URL}/api/contracts/approve_live_url`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      deliverableId,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function approveUsageRightsContent(usageRightsRequest: UsageRightsRequestDetails) {
  const requestUrl = new URL(`${API_URL}/api/contracts/approve_usage_rights_request`);

  const { contractHash } = usageRightsRequest;
  const requestId = usageRightsRequest.hashId;

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      contractHash,
      requestId,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function requestUsageRightsRevision(
  contractHash: string,
  requestId: string,
  disputeReason: string,
) {
  const requestUrl = new URL(`${API_URL}/api/contracts/request_usage_rights_contract_revision`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({ contractHash, requestId, disputeReason }),
  });

  const response = await handleResult(request);
  return response;
}

export async function requestLiveUrlRevision({
  deliverableId,
  disputeReason,
}: {
  deliverableId: string;
  disputeReason: string;
}) {
  const requestUrl = new URL(`${API_URL}/api/contracts/request_live_url_revision`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({ deliverableId, disputeReason }),
  });

  const response = await handleResult(request);
  return response;
}

export async function markContractAsRepeat({
  contractId,
  canRepeat,
}: {
  contractId: string;
  canRepeat: boolean;
}) {
  const requestUrl = new URL(`${API_URL}/api/contracts/mark_contract_as_repeat`);
  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({ contractId, canRepeat }),
  });

  const response = await handleResult(request);
  return response;
}

export async function getContractReviewStatuses({ contractId }: { contractId: string }) {
  const requestUrl = new URL(`${API_URL}/api/contracts/get_contract_review_statuses`);
  requestUrl.searchParams.append("contractId", contractId);

  const request = await createRequestWithFirebaseToken({ url: requestUrl });

  const response = await handleResult(request);
  return response;
}

export async function recordManualPayment({
  paidBy,
  contractId,
  paymentDate,
  amountInMinorUnits,
  receiptUrl,
  memo,
}: {
  paidBy: string;
  contractId: string;
  paymentDate: string;
  amountInMinorUnits: number;
  receiptUrl: string;
  memo: string;
}) {
  const requestUrl = new URL(`${API_URL}/api/payments/record_manual_payment`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      paidBy,
      contractId,
      paymentDate,
      amountInMinorUnits,
      receiptUrl,
      memo,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function backfillReferralLinksForContract({ contractId }: { contractId: string }) {
  const requestUrl = new URL(`${API_URL}/api/contracts/backfill_referral_links_for_contract`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      contractId,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function addReferralLinkToDeliverable({
  deliverableId,
  url,
}: {
  deliverableId: string;
  url: string;
}) {
  const requestUrl = new URL(`${API_URL}/api/contracts/add_referral_link_to_deliverable`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      deliverableId,
      url,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function addPromoCodeToDeliverable({
  deliverableId,
  promoCode,
}: {
  deliverableId: string;
  promoCode: string;
}) {
  const requestUrl = new URL(`${API_URL}/api/contracts/add_promo_code_to_deliverable`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      deliverableId,
      promoCode,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function getInvoices({ user }: { user: User }) {
  const firebaseToken = await user.getIdToken();
  const requestUrl = new URL(`${API_URL}/api/billing/get_invoices`);

  const request = new Request(requestUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
  });

  const response = await handleResult(request);
  return response;
}

export async function getInvoice({ user, invoiceId }: { user: User; invoiceId: string }) {
  const firebaseToken = await user.getIdToken();
  const requestUrl = new URL(`${API_URL}/api/billing/get_invoice`);
  requestUrl.searchParams.append("invoiceId", invoiceId);

  const request = new Request(requestUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
  });

  const response = await handleResult(request);
  return response;
}

export async function updateBillingEmail({
  user,
  invoiceId,
  email,
}: {
  user: User;
  invoiceId: string;
  email: string;
}) {
  const firebaseToken = await user.getIdToken();
  const requestUrl = new URL(`${API_URL}/api/billing/update_brand_billing_email`);

  const request = new Request(requestUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
    body: JSON.stringify({ invoiceId, email }),
  });

  const response = await handleResult(request);
  return response;
}

export async function voidInvoice({ user, invoiceId }: { user: User; invoiceId: string }) {
  const firebaseToken = await user.getIdToken();
  const requestUrl = new URL(`${API_URL}/api/billing/void_invoice`);

  const request = new Request(requestUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
    body: JSON.stringify({ invoiceId }),
  });

  const response = await handleResult(request);
  return response;
}

export async function sendInvoice({ user, invoiceId }: { user: User; invoiceId: string }) {
  const firebaseToken = await user.getIdToken();
  const requestUrl = new URL(`${API_URL}/api/billing/send_invoice`);

  const request = new Request(requestUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseToken}`,
    },
    body: JSON.stringify({ invoiceId }),
  });

  const response = await handleResult(request);
  return response;
}

export async function fetchContentLibrary(campaignHashId: string, campaignId?: string) {
  const requestUrl = new URL(`${API_URL}/api/contracts/fetch_content_library`);
  if (campaignId) {
    requestUrl.searchParams.append("campaignId", campaignId);
  } else {
    requestUrl.searchParams.append("campaignHashId", campaignHashId);
  }

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
  });

  const response = await handleResult(request);
  return response;
}

export async function fetchContentLibraryForUser() {
  const requestUrl = new URL(`${API_URL}/api/contracts/fetch_content_library_for_user`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
  });

  const response = await handleResult(request);
  return response;
}

export async function submitUsageRightsRequest({
  contractId,
  deliverableIds,
  maxOfferAmount,
  usageRightsDays,
  usageRightsInPerpetuity,
}: {
  contractId: string;
  deliverableIds: string[];
  maxOfferAmount: number;
  usageRightsDays: number;
  usageRightsInPerpetuity: boolean;
}) {
  const requestUrl = new URL(`${API_URL}/api/contracts/submit_usage_rights_request`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      contractId,
      deliverableIds,
      maxOfferAmount,
      usageRightsDays,
      usageRightsInPerpetuity,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function resetStripeAccount({ contractId }: { contractId: string }) {
  const requestUrl = new URL(`${API_URL}/api/contracts/reset_stripe_account`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      contractId,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export async function getContractSummaryMetrics({ adGroupId }: { adGroupId: string }) {
  const requestUrl = new URL(`${API_URL}/api/contracts/get_contract_summary_metrics`);
  requestUrl.searchParams.append("adGroupId", adGroupId);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
  });

  const response = await handleResult(request);
  return response;
}

export async function checkContractOverBudget({
  campaignId,
  contractAmount,
  contractDate,
}: {
  campaignId: string;
  contractAmount: number;
  contractDate: string;
}) {
  const requestUrl = new URL(`${API_URL}/api/budgets/check_contract_over_budget`);

  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      campaignId,
      contractAmount,
      contractDate,
    }),
  });

  const response = await handleResult(request);
  return response;
}

export const getContractHistory = async (contractId: string, abortController: AbortController) => {
  const requestUrl = new URL(`${API_URL}/api/contracts/history/?contract_hash_id=${contractId}`);
  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "GET",
  });
  const response = await handleResult(request, abortController);
  return response;
};

// Ask the backend for all usage rights pertaining to a particular content URL.
// The backend is expected to parse the URL for the content id.
export const getAllUsageRightsRequests = async (
  contentUrl: string,
  abortController?: AbortController,
) => {
  const requestUrl = new URL(`${API_URL}/api/contracts/get_pending_usage_rights_requests`);
  requestUrl.searchParams.append("contentUrl", contentUrl);
  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "GET",
  });
  const response = await handleResult(request, abortController);
  return response;
};

export const finalizeUsageRightsRequestUpload = async (
  contractId: string,
  usageRightsRequestId: string,
  url: string,
  adCode: string,
) => {
  const requestUrl = new URL(`${API_URL}/api/contracts/finalize_usage_rights_upload/`);
  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "POST",
    body: JSON.stringify({
      contractId,
      usageRightsRequestId,
      url,
      adCode,
    }),
  });
  const response = await handleResult(request);
  return response;
};
