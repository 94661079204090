import React, { useEffect, useState } from "react";

import { Center, Loader } from "@mantine/core";

import { getTaskDetails } from "components/contracts/tasks/api/Api";

import { Task, deserializeTask } from "components/contracts/tasks/models/Task";
import { TaskStage } from "components/contracts/tasks/models/Common";

import CodeAndLink from "components/contracts/tasks/content/CodeAndLink";
import ReviewContent from "components/contracts/tasks/content/ReviewContent";

import ProductAccessContent from "components/contracts/tasks/content/ProductAccessContent";

export default function TaskCardContent({
  taskType,
  taskId,
  task,
  handleCompleteTask,
  isCompletedTask,
  showAdminOptions,
  clearUnreadMessages,
  messagesLastRead,
}: {
  taskType: TaskStage;
  taskId?: string;
  task?: Task;
  handleCompleteTask: (requireCreatorAction?: boolean) => void;
  clearUnreadMessages?: () => void;
  messagesLastRead?: Date;
  isCompletedTask?: boolean;
  showAdminOptions?: boolean;
}) {
  if (!taskId && !task) {
    throw new Error("Either taskId or task must be specified");
  }
  if (taskId && task) {
    throw new Error("Only one of taskId or task should be specified");
  }

  const [loading, setLoading] = useState(!!taskId);
  const [fetchedTask, setFetchedTask] = useState<Task>(null);

  useEffect(() => {
    if (taskId) {
      setLoading(true);
      getTaskDetails({ taskId }).then((response) => {
        if (response.success) {
          const deserializedTask = deserializeTask(response.task);
          setFetchedTask(deserializedTask);
        }
        setLoading(false);
      });
    }
  }, [taskId]);

  if (loading) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  const taskToUse = fetchedTask || task;

  if (taskType === TaskStage.CONTRACT_REVIEW || taskType === TaskStage.CONTRACT_OFFER_REVIEW) {
    return null;
  }

  if (taskType === TaskStage.PRODUCT_ACCESS) {
    return (
      <ProductAccessContent
        taskId={taskToUse.hashId}
        productAccess={taskToUse.productAccess}
        displayName={taskToUse.displayName}
        handleCompleteTask={handleCompleteTask}
      />
    );
  }

  if (taskType === TaskStage.CODES_LINKS) {
    return (
      <CodeAndLink
        taskId={taskToUse.hashId}
        platform={taskToUse.deliverable.platform}
        displayName={taskToUse.deliverable.creatorHandle}
        redirectUrl={taskToUse.referralLinkUrl}
        promoCode={taskToUse.promoCode}
        referralLinkStatus={taskToUse.referralLinkStatus}
        promoCodeStatus={taskToUse.promoCodeStatus}
        handleCompleteTask={handleCompleteTask}
      />
    );
  }

  return (
    <ReviewContent
      task={taskToUse}
      handleCompleteTask={handleCompleteTask}
      isCompletedTask={isCompletedTask}
      showAdminOptions={showAdminOptions}
      clearUnreadMessages={clearUnreadMessages}
      messagesLastRead={messagesLastRead}
    />
  );
}
