import React, { useEffect, useState } from "react";
import {
  Alert,
  Anchor,
  Box,
  Button,
  Center,
  Flex,
  Group,
  Loader,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { IconBulbFilled, IconPlus } from "@tabler/icons-react";

import { useCampaignAppContext } from "campaigns/CampaignAppShell";
import CampaignTodoList from "campaigns/main/campaignPage/CampaignTodoList";
import { useAppDispatch, useAppSelector } from "reduxStore/hooks";
import { useMatch, useNavigate } from "react-router-dom";
import AllCampaignsOverview from "campaigns/main/campaignPage/campaigns/AllCampaignsOverview";
import CampaignHomeOnboarding from "campaigns/main/campaignPage/onboarding/CampaignHomeOnboarding";
import { checkNeedsCampaignOnboarding } from "reduxStore/meSlice";
import EmptyState from "campaigns/main/campaignPage/EmptyState";
import ProductGuideLink from "campaigns/create/common/ProductGuideLink";
import { selectUserNeedsCampaignOnboarding } from "reduxStore/meSelectors";
import { CampaignStatus } from "models/Campaign";
import { selectMyLaunchedCampaigns } from "reduxStore/campaignsSelectors";
import { CAMPAIGN_PORTAL } from "campaigns/PathConstants";

const LOCAL_STORAGE_PRODUCT_UPSELL_DISMISSED = "productUpsellDismissed";

const CreateNewCampaignButton = () => {
  const navigate = useNavigate();
  return (
    <Button
      size="sm"
      variant="filled"
      leftSection={<IconPlus size={16} />}
      onClick={() => navigate("/campaigns/create")}>
      New Campaign
    </Button>
  );
};

const CampaignEmptyState = () => {
  return (
    <EmptyState
      title="Start your first campaign"
      description="Launch your first campaign on 1stCollab in less than ten minutes."
      secondDescription={
        <>
          For more information on how campaigns work, check out our <ProductGuideLink />.
        </>
      }
      ctaButton={<CreateNewCampaignButton />}
    />
  );
};

const CampaignPortalHome = () => {
  const dispatch = useAppDispatch();
  const isHomePathMatch = useMatch(`${CAMPAIGN_PORTAL}`) !== null;
  const { selectedCampaign, mainAppShellHeight } = useCampaignAppContext();
  const campaigns = useAppSelector((state) => state.campaigns.allCampaigns);
  const showOnboarding = useAppSelector((state) =>
    selectUserNeedsCampaignOnboarding(state, { checkLaunchedCampaigns: true }),
  );
  const brandHasLaunchedCampaign = useAppSelector(
    (state) => selectMyLaunchedCampaigns(state).length > 0,
  );

  const [onboardingLoading, setOnboardingLoading] = useState<boolean>(true);
  const [campaignsIsLoading, setCampaignsIsLoading] = useState<boolean>(true);

  const [productUpsellDismissed, setProductUpsellDismissed] = useState<boolean>(
    localStorage.getItem(LOCAL_STORAGE_PRODUCT_UPSELL_DISMISSED) != null,
  );

  useEffect(() => {
    const briefsAbortController = new AbortController();
    const activatedCreatorsAbortController = new AbortController();
    const getBrandAbortController = new AbortController();
    const campaignsAbortController = new AbortController();
    dispatch(
      checkNeedsCampaignOnboarding({
        getBrandAbortController,
        briefsAbortController,
        activatedCreatorsAbortController,
        campaignsAbortController,
        shouldFetchCampaigns: true,
      }),
    )
      .unwrap()
      .then((finishedWithoutAborting) => {
        if (finishedWithoutAborting) {
          setOnboardingLoading(false);
          setCampaignsIsLoading(false);
        }
      });

    return () => {
      briefsAbortController.abort();
      activatedCreatorsAbortController.abort();
      getBrandAbortController.abort();
      campaignsAbortController.abort();
    };
  }, []);

  useEffect(() => {
    if (productUpsellDismissed) {
      localStorage.setItem(LOCAL_STORAGE_PRODUCT_UPSELL_DISMISSED, "true");
    } else {
      localStorage.removeItem(LOCAL_STORAGE_PRODUCT_UPSELL_DISMISSED);
    }
  }, [productUpsellDismissed]);

  const hasCampaigns = Object.keys(campaigns).length > 0;

  if (isHomePathMatch && showOnboarding) {
    if (onboardingLoading) {
      return (
        <Center style={{ height: "100%" }}>
          <Loader />
        </Center>
      );
    }
    return <CampaignHomeOnboarding brandHasLaunchedCampaign={brandHasLaunchedCampaign} />;
  }

  const emptyState = !isHomePathMatch ? <CampaignEmptyState /> : null;
  const campaignsContent = hasCampaigns ? (
    <>
      {!isHomePathMatch ? <AllCampaignsOverview showEdit /> : null}
      <CampaignTodoList campaign={selectedCampaign} showCreatorSets />
    </>
  ) : (
    emptyState
  );

  return (
    <Flex
      direction="column"
      gap={12}
      style={{
        height: hasCampaigns && !campaignsIsLoading ? undefined : mainAppShellHeight,
      }}>
      {!isHomePathMatch ? (
        <Group style={{ justifyContent: "space-between" }}>
          <Title order={1}>Campaigns</Title>
          <CreateNewCampaignButton />
        </Group>
      ) : null}
      {!productUpsellDismissed && (
        <Alert
          variant="light"
          color="blue"
          withCloseButton
          icon={<IconBulbFilled />}
          onClose={() => setProductUpsellDismissed(true)}>
          Check out our{" "}
          <Anchor
            size="sm"
            href="https://1stcollab.notion.site/1stCollab-Brand-Product-Guide-c260996ea008436c9837f6078b83bdab"
            target="_blank">
            Product Guide
          </Anchor>{" "}
          for guidance on our platform and tips on how to run a successful campaign!
        </Alert>
      )}
      {campaignsIsLoading ? (
        <Center style={{ height: "100%" }}>
          <Loader />
        </Center>
      ) : (
        campaignsContent
      )}
    </Flex>
  );
};

export default CampaignPortalHome;
