import React, { useState } from "react";
import {
  Button,
  Card,
  Group,
  SimpleGrid,
  Space,
  Stack,
  Text,
  TextInput,
  Title,
  Tooltip,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import { notifications, Notifications } from "@mantine/notifications";
import { IconCheck, IconNotes } from "@tabler/icons-react";

import { deleteContractOffer } from "components/contracts/negotiations/api/ContractOfferApi";
import { useClipboard } from "@mantine/hooks";
import { sendOfferEmailToCreator } from "components/contracts/common/Api";
import { showFailureNotification } from "components/common/Notifications";

const ValidOfferContent = ({ offerId, uuid }: { offerId: number; uuid: string }) => {
  const [copied, setCopied] = useState<boolean>(false);
  const [sent, setSent] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);

  const clipboard = useClipboard();
  const link = uuid ? `https://www.1stcollab.com/offers/${uuid}` : "";

  const handleSendOfferToCreatorEmail = () => {
    setSending(true);
    sendOfferEmailToCreator(offerId)
      .then((response) => {
        if (response.success) {
          setSent(true);
        } else {
          showFailureNotification({
            title: "Failed to send email!",
            message: response.error,
            stayOpen: true,
          });
          setSent(false);
        }
      })
      .catch(() => {
        showFailureNotification({
          title: "Failed to send email!",
          message: "Server error",
          stayOpen: true,
        });
        setSent(false);
      })
      .finally(() => setSending(false));
  };

  return (
    <Group justify="flex-start" grow preventGrowOverflow={false} gap="xs">
      <TextInput value={link} />
      <Button
        maw={200}
        disabled={!uuid}
        onClick={() => {
          clipboard.copy(link);
          setCopied(true);
        }}>
        {copied ? "Copied!" : "Copy to Clipboard"}
      </Button>
      <Button
        maw={200}
        disabled={!uuid || sent}
        loading={sending}
        onClick={handleSendOfferToCreatorEmail}>
        {sent ? "Sent!" : "Send Offer to Creator"}
      </Button>
    </Group>
  );
};

const ButtonGroup = ({
  contractOfferId,
  offerUuid,
  validate,
  disableSave,
  disableWithOffer,
  withAcceptRecommendedPackage,
  recommendedPackageTriggersBrandReview,
  anyPackageTriggersBrandReview,
  disableAccept,
  openDrawer,
  deleteButtonCallback,
  addPackage,
  savePackages,
  acceptRecommendedPackage,
  acceptAllPackages,
  reportProblem,
  extendOfferExpiration,
  isAdmin,
  adminOnly = true,
}: {
  contractOfferId: number;
  offerUuid: string | null;
  validate: boolean;
  disableSave: boolean;
  disableWithOffer: boolean;
  withAcceptRecommendedPackage: boolean;
  recommendedPackageTriggersBrandReview?: boolean;
  anyPackageTriggersBrandReview?: boolean;
  disableAccept: boolean;
  openDrawer: () => void;
  deleteButtonCallback: (() => void) | null;
  addPackage: () => void;
  savePackages: () => void;
  acceptRecommendedPackage: null | (() => void);
  acceptAllPackages: null | (() => void);
  reportProblem: null | (() => void);
  extendOfferExpiration: null | (() => void);
  isAdmin?: boolean;
  adminOnly?: boolean;
}) => {
  let disableForOps = true;
  if (!adminOnly) disableForOps = false;

  const openConfirmDeleteModal = () =>
    modals.openConfirmModal({
      title: "Please confirm that you want to delete this offer.",
      children: (
        <Stack>
          <Text size="sm">Please confirm that you want to delete this offer.</Text>
        </Stack>
      ),
      labels: { confirm: "Confirm", cancel: "Cancel" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () =>
        deleteContractOffer(contractOfferId).then((resp) => {
          if (resp?.status === 204) {
            notifications.show({
              title: "Offer deleted successfully",
              message: "Offer deleted successfully",
            });
            if (deleteButtonCallback) {
              deleteButtonCallback();
            }
          } else {
            notifications.show({
              title: "Error deleting offer",
              message: "Error deleting offer",
              color: "red",
            });
          }
        }),
    });

  return (
    <>
      <Notifications />
      <Button variant="default" leftSection={<IconNotes size={18} />} onClick={openDrawer}>
        View Offer Notes
      </Button>
      <Space my={4} />
      {disableWithOffer && disableForOps ? (
        <Tooltip label="Cannot save any new packages since there is an active offer.">
          <Button color="blue" data-disabled onClick={addPackage}>
            Add Deliverable Package
          </Button>
        </Tooltip>
      ) : (
        <Button color="blue" disabled={validate} onClick={addPackage}>
          Add Deliverable Package
        </Button>
      )}
      {!withAcceptRecommendedPackage && disableWithOffer && disableForOps && (
        <Tooltip label="Cannot save any new packages since there is an active offer.">
          <Button
            data-disabled
            leftSection={<IconCheck size={18} />}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault()}>
            Save All Packages + Continue
          </Button>
        </Tooltip>
      )}
      {!withAcceptRecommendedPackage && (!disableWithOffer || !disableForOps) && (
        <Button
          leftSection={<IconCheck size={18} />}
          color="green"
          disabled={disableSave}
          onClick={savePackages}>
          Save All Packages + Continue
        </Button>
      )}
      <Button color="red" disabled={!contractOfferId} onClick={openConfirmDeleteModal}>
        Delete Offer
      </Button>
      <SimpleGrid cols={[withAcceptRecommendedPackage, true].filter((val) => val).length}>
        {withAcceptRecommendedPackage && (
          <Card withBorder shadow="none">
            <Stack gap="xs">
              <Title order={3}>Manual communication via Close</Title>
              <Text>
                Use this section if you want to proceed with a single recommended offer and will
                communicate with the creator via Close.
              </Text>
              <Button color="green" disabled={disableAccept} onClick={acceptRecommendedPackage}>
                {recommendedPackageTriggersBrandReview
                  ? "Send All Packages to Brand Review (Recommended Requires Brand Review)"
                  : "Continue With Recommended Package"}
              </Button>
              <Button color="red" disabled={disableAccept} onClick={reportProblem}>
                Report a Problem
              </Button>
            </Stack>
          </Card>
        )}
        <Card withBorder shadow="none">
          <Stack gap="xs">
            <Title order={3}>Self-service creator offer acceptance</Title>
            <Text>
              Use this section if you want to generate a link for the creator to select an offer
              themselves. You must send them the link, but they take it from there.
            </Text>
            <Button
              color="green"
              disabled={disableAccept || (offerUuid && offerUuid.length > 0)}
              onClick={acceptAllPackages}>
              {anyPackageTriggersBrandReview
                ? "Send All Packages to Brand Review (Some Package Requires Brand Review)"
                : "Continue With All Packages and Generate Offer Link"}
            </Button>
            <ValidOfferContent offerId={contractOfferId} uuid={offerUuid} />
            <Button disabled={!offerUuid} onClick={extendOfferExpiration}>
              Extend Offer Expiration
            </Button>
          </Stack>
        </Card>
      </SimpleGrid>
    </>
  );
};

export default ButtonGroup;
