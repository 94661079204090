import React from "react";

import { Card, Group } from "@mantine/core";

import { User } from "models/User";

import { TaskStage } from "components/contracts/tasks/models/Common";
import { Task } from "components/contracts/tasks/models/Task";

import TaskTypeBadge from "components/contracts/tasks/common/TaskTypeBadge";
import ContractReviewContent from "components/contracts/tasks/content/ContractReviewContent";

export default function ContractReviewCard({
  user,
  primaryTask,
  taskType,
  handleCompleteTask,
  decrementUnreadMessageCount,
  handleCompleteRelatedTask,
  completedTaskIds,
  isCompletedTask,
}: {
  user: User;
  primaryTask: Task;
  taskType: TaskStage;
  handleCompleteTask: (waitingForCreator?: boolean) => void;
  decrementUnreadMessageCount: () => void;
  handleCompleteRelatedTask?: (relatedTaskId: string, taskType: TaskStage) => void;
  completedTaskIds?: Set<string>;
  isCompletedTask?: boolean;
}) {
  return (
    <Card p="sm" radius="md" withBorder bg="#F8F9FA">
      <Group justify="right">
        <TaskTypeBadge taskType={taskType} />
      </Group>
      <ContractReviewContent
        taskId={primaryTask.hashId}
        deadline={primaryTask.deadline}
        contractReview={primaryTask.contractReview}
        handleCompleteTask={handleCompleteTask}
      />
    </Card>
  );
}
