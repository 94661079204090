import React from "react";
import { Card, Group, Stack, Text } from "@mantine/core";

import { ProductAccess, TaskStage } from "components/contracts/tasks/models/Common";

import ProductAccessContent from "components/contracts/tasks/content/ProductAccessContent";

import ActionBadge from "components/contracts/tasks/common/ActionBadge";
import DueDateBadge from "components/contracts/tasks/common/DueDateBadge";
import { ProductAccessInfoToProvideToCreatorType } from "components/contracts/common/Common";

function TaskCardHeader({
  deadline,
  productAccessType,
}: {
  deadline: Date;
  productAccessType: ProductAccessInfoToProvideToCreatorType;
}) {
  let header = "Access Details";
  if (productAccessType === ProductAccessInfoToProvideToCreatorType.ACCESS_CODE) {
    header = "Access Code";
  } else if (productAccessType === ProductAccessInfoToProvideToCreatorType.TRACKING_CODE) {
    header = "Tracking Details";
  }

  return (
    <Group justify="space-between">
      <Group gap="xs">
        <Text fw="600" size="lg">
          {header}
        </Text>
      </Group>
      <Group gap="xs">
        <ActionBadge taskType={TaskStage.PRODUCT_ACCESS} />
        <DueDateBadge deadline={deadline} />
      </Group>
    </Group>
  );
}

export default function ProductAccessCard({
  taskId,
  displayName,
  productAccess,
  deadline,
  handleCompleteTask,
}: {
  taskId: string;
  displayName: string;
  productAccess: ProductAccess;
  deadline: Date;
  handleCompleteTask: () => void;
}) {
  return (
    <Card radius="md" mt="sm" withBorder>
      <Stack gap="xs">
        <TaskCardHeader
          deadline={deadline}
          productAccessType={productAccess.infoToProvideToCreator}
        />
        <ProductAccessContent
          taskId={taskId}
          displayName={displayName}
          productAccess={productAccess}
          handleCompleteTask={handleCompleteTask}
        />
      </Stack>
    </Card>
  );
}
