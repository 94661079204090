import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { AppShell, Center, Container, Flex, Loader } from "@mantine/core";
import { Outlet, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useUser } from "utils/UserContext";
import { NAV_BAR_V2_COLLAPSED_WIDTH, NAV_BAR_WIDTH } from "campaigns/navbar/consts";
import { Campaign, CampaignAdGroup, BrandToDoSummary } from "models/Campaign";
import { fetchCampaignToDoSummary } from "campaigns/api/fetchCampaignTodos";
import { CreatorSetItem } from "components/discovery/Datamodels";
import { useAppDispatch, useAppSelector } from "reduxStore/hooks";
import { GetCampaignsPayloadAction, getCampaigns } from "reduxStore/campaignsSlice";
import { useElementSize } from "@mantine/hooks";
import { selectCreatorSetNames, selectCreatorSets } from "reduxStore/creatorSetsSelectors";
import { fetchMyBrand } from "reduxStore/meSlice";
import NavBarV2 from "campaigns/navbar/v2/NavBarV2";

type CampaignAppContext = {
  mainAppShellHeight: number;
  selectedCampaign: Campaign | null;
  selectedAdGroup: CampaignAdGroup | null;
  toDoSummary: BrandToDoSummary | null;
};

export function useCampaignAppContext() {
  return useOutletContext<CampaignAppContext>();
}

const CampaignAppShell = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { ref: mainAppShellRef, height: mainAppShellHeight } = useElementSize();

  const [userFromContext, userLoading] = useUser();
  const currentUser = useAppSelector((state) => state.me.user);

  const [isNavBarV2Collapsed, setIsNavBarV2Collapsed] = useState<boolean>(false);
  const [campaigns, setCampaigns] = useState<Campaign[] | null>(null);
  const [creatorSets, setCreatorSets] = useState<CreatorSetItem[] | null>(null);
  const [toDoSummary, setToDoSummary] = useState<BrandToDoSummary | null>(null);

  const selectedCreatorSetNames = useSelector(selectCreatorSetNames) as { [key: number]: string };
  const selectedCreatorSets = useSelector(selectCreatorSets) as CreatorSetItem[];

  const [selectedCampaign, setSelectedCampaign] = useState<Campaign | null>(null);
  const [selectedAdGroup, setSelectedAdGroup] = useState<CampaignAdGroup | null>(null);

  // parse params from url
  const urlParams = useParams();
  const urlCampaignId = urlParams.campaignId ? parseInt(urlParams.campaignId, 10) : null;
  const urlAdGroupId = urlParams.adGroupId ? parseInt(urlParams.adGroupId, 10) : null;

  useEffect(() => {
    if (campaigns?.length > 0) {
      if (urlCampaignId) {
        const matchedCampaign = campaigns?.find((campaign) => campaign.hash_id === urlCampaignId);
        setSelectedCampaign(matchedCampaign);
        if (matchedCampaign) {
          if (urlAdGroupId) {
            const matchedAdGroup = matchedCampaign.ad_groups?.find(
              (adGroup) => adGroup.id === urlAdGroupId,
            );
            setSelectedAdGroup(matchedAdGroup);
            if (!matchedAdGroup) {
              // didn't find a valid ad group for the user's selected campaign so redirectly back to just campaign page
              navigate(`/campaigns/${urlCampaignId}`);
            }
          } else {
            setSelectedAdGroup(null);
          }
        } else {
          // didn't find a valid campaign for the user so redirectly back to main campaign portal
          setSelectedCampaign(null);
          setSelectedAdGroup(null);
          navigate(`/campaigns`);
        }
      } else {
        // if urlCampaignId is not set, we should clear the fields
        setSelectedCampaign(null);
        setSelectedAdGroup(null);
      }
    }
  }, [campaigns, creatorSets, urlParams]);

  useEffect(() => {
    const abortController = new AbortController();
    if (currentUser) {
      dispatch(getCampaigns(abortController))
        .unwrap()
        .then(({ aborted, campaigns: fetchedCampaigns }: GetCampaignsPayloadAction) => {
          if (!aborted) {
            const updatedCampaigns = fetchedCampaigns.map((campaign) => ({
              ...campaign,
              brand_name: campaign.brand?.brand_name,
            }));
            setCampaigns(updatedCampaigns);
          }
        });
      fetchCampaignToDoSummary(abortController).then((todoSummary) => {
        setToDoSummary(todoSummary);
      });
      // Fetch brand as well
      dispatch(fetchMyBrand({ abortController }));
    }
    return () => {
      abortController.abort();
    };
  }, [currentUser]);

  // Update the local state when selectedCreatorSetNames changes
  useEffect(() => {
    const updatedCreatorSets = creatorSets?.map((item) => {
      return {
        ...item,
        name: selectedCreatorSetNames[item.id],
      };
    });
    setCreatorSets(updatedCreatorSets);
  }, [selectedCreatorSetNames]);

  // Update the local state when a new creator set is added
  useEffect(() => {
    if (selectedCreatorSets && creatorSets) {
      // Add the new creator set to the creator set list
      const newCreatorSet = selectedCreatorSets[selectedCreatorSets.length - 1];
      const updatedCreatorSets = [newCreatorSet, ...creatorSets];
      setCreatorSets(updatedCreatorSets);
    }
  }, [selectedCreatorSets]);

  if (userLoading || !currentUser) {
    return (
      <Container fluid h="100vh">
        <Center h="100%">
          <Loader color="blue" />
        </Center>
      </Container>
    );
  }

  const navBarV2Width = isNavBarV2Collapsed ? NAV_BAR_V2_COLLAPSED_WIDTH : NAV_BAR_WIDTH;

  return (
    <AppShell
      navbar={{
        width: navBarV2Width,
        breakpoint: "sm",
      }}
      padding="md">
      <AppShell.Navbar>
        <Flex gap={0} h="100%">
          <NavBarV2
            isCollapsed={isNavBarV2Collapsed}
            style={{ width: isNavBarV2Collapsed ? NAV_BAR_V2_COLLAPSED_WIDTH : NAV_BAR_WIDTH }}
            onCollapseNavBar={() => setIsNavBarV2Collapsed(!isNavBarV2Collapsed)}
            user={currentUser}
          />
        </Flex>
      </AppShell.Navbar>

      <AppShell.Main bg="var(--mantine-color-gray-1)" ref={mainAppShellRef}>
        <Outlet
          context={{
            mainAppShellHeight,
            selectedCampaign,
            selectedAdGroup,
            toDoSummary,
          }}
        />
      </AppShell.Main>
    </AppShell>
  );
};

export default CampaignAppShell;
