import React, { useState } from "react";

import { Card, SegmentedControl, Stack } from "@mantine/core";

import { CreatorSetSourcingSummary } from "admin/app/ops/tasks/CreatorSetSourcingSummary";
import OpsDailyTaskTable from "admin/app/ops/tasks/OpsDailyTaskTable";
import { OpsDailyUserTasks } from "admin/app/ops/tasks/OpsDailyUserTasks";
import { CreatorSetSourcingPlan } from "models/OpsTask";
import { User } from "models/User";

export const OpsDailyTasks = ({
  opsUsers,
  sourcingAllocations,
  priority,
}: {
  opsUsers: User[];
  sourcingAllocations: CreatorSetSourcingPlan[];
  priority: Record<number, number>;
}) => {
  const [control, setControl] = useState("tasks");

  return (
    <Card shadow="xs" padding="md" radius="md" withBorder>
      <Card.Section inheritPadding withBorder py="sm">
        <Stack>
          <OpsDailyUserTasks opsUsers={opsUsers} sourcingAllocations={sourcingAllocations} />
          <OpsDailyTaskTable
            opsUsers={opsUsers}
            sourcingAllocations={sourcingAllocations}
            priority={priority}
          />
        </Stack>
      </Card.Section>
    </Card>
  );
};

export default OpsDailyTasks;
