import React, { useEffect, useState } from "react";

import { User } from "firebase/auth";

import {
  contractExpired,
  ContractStatus,
  ContractType,
  ProductAccessStatus,
  SUPPORTED_FORMAT_TO_SORT_ORDER,
} from "components/contracts/common/Common";
import Deliverable, { ContractDeliverableStatus } from "components/contracts/models/Deliverable";
import DeliverableVideo from "components/contracts/models/DeliverableVideo";
import {
  deserializeUsageRightsRequest,
  UsageRightsRequestDetails,
} from "components/contracts/models/UsageRightsRequestDetails";

import ActionItems, { ActionItemProps } from "components/contracts/deliverables/ActionItems";
import DeliverableCard from "components/contracts/deliverables/DeliverableCard";
import DeliverableProgress from "components/contracts/common/DeliverableProgress";
import ExpirationBanner from "components/contracts/deliverables/ExpirationBanner";
import { UsageRightsCard } from "components/contracts/deliverables/UsageRightsCard";
import Header from "components/contracts/deliverables/Header";
import LoadingError from "components/contracts/common/LoadingError";

import {
  getDeliverablesViewData,
  fetchDeliverable,
  fetchLatestVideosForContract,
  saveCreatorProductAccessInfo,
} from "components/contracts/common/Api";

import {
  Container,
  Group,
  Stack,
  ThemeIcon,
  Title,
  Tooltip,
  LoadingOverlay,
} from "@mantine/core";

import { useForm } from "@mantine/form";
import { Notifications } from "@mantine/notifications";
import ProductAccessSection from "components/contracts/product_access/ProductAccessSection";
import {
  ProductAccessInfoNeededFromCreatorType,
  ProductAccessInfoToProvideToCreatorType,
} from "models/Campaign";

import { showFailureNotification, showSuccessNotification } from "components/common/Notifications";

/**
 * Get the sort order for deliverables.
 * @param deliverables The deliverables to sort
 * @param forBrand True if from the brand perspective, otherwise from the creator perspective
 * @returns The deliverables sorted in some actionable order.
 */
export function getSortOrder(deliverables: Deliverable[], forBrand?: boolean) {
  return Array.from({ length: deliverables.length }, (_, i) => i).sort((a, b) => {
    // Action Required Sorted First
    let actionRequiredA = deliverables[a].actionRequired;
    let actionRequiredB = deliverables[b].actionRequired;
    if (forBrand) {
      // The required actions are actually opposite when viewing from the brand perspective
      actionRequiredA = !actionRequiredA;
      actionRequiredB = !actionRequiredB;
    }
    if (actionRequiredA && !actionRequiredB) {
      return -1;
    } else if (!actionRequiredA && actionRequiredB) {
      return 1;
    }

    // Complete Deliverables Sorted Last
    if (
      deliverables[a].status === ContractDeliverableStatus.COMPLETE &&
      deliverables[b].status !== ContractDeliverableStatus.COMPLETE
    ) {
      return 1;
    } else if (
      deliverables[a].status !== ContractDeliverableStatus.COMPLETE &&
      deliverables[b].status === ContractDeliverableStatus.COMPLETE
    ) {
      return -1;
    }

    // Earliest Next Due Date (or Live Date if no Next Due Date)
    const aNextDate = deliverables[a].nextDueDate || deliverables[a].timeline.liveDate;
    const bNextDate = deliverables[b].nextDueDate || deliverables[b].timeline.liveDate;

    if (aNextDate < bNextDate) {
      return -1;
    } else if (aNextDate > bNextDate) {
      return 1;
    }

    // Deliverable Format Priority
    const aFormatSortOrder = SUPPORTED_FORMAT_TO_SORT_ORDER[deliverables[a].format];
    const bFormatSortOrder = SUPPORTED_FORMAT_TO_SORT_ORDER[deliverables[b].format];

    return aFormatSortOrder - bFormatSortOrder;
  });
}

export default function ContractDeliverables({
  user,
  contractId,
  showAdminOptions = false,
}: {
  user: User;
  contractId: string;
  showAdminOptions?: boolean;
}) {
  const [contractType, setContractType] = useState<ContractType>(ContractType.INFLUENCER);
  const [brandName, setBrandName] = useState("");
  const [adGroupId, setAdGroupId] = useState<number>(null);
  const [creativeBriefUrl, setCreativeBriefUrl] = useState("");
  const [deliverables, setDeliverables] = useState<Deliverable[]>([]);
  const [sortOrder, setSortOrder] = useState<number[]>([]);
  const [usageRightsRequestDetails, setUsageRightsRequestDetails] =
    useState<UsageRightsRequestDetails>(null);
  const [fetchedLatestVideosForContract, setFetchedLatestVideosForContract] = useState<
    DeliverableVideo[]
  >([]);
  const [additionalEmails, setAdditionalEmails] = useState<string[]>([]);
  const [amount, setAmount] = useState(null);
  const [bonusAmount, setBonusAmount] = useState(null);
  const [earnedBonusAmount, setEarnedBonusAmount] = useState(null);
  const [bonusCondition, setBonusCondition] = useState(null);
  const [meetsBonusCondition, setMeetsBonusCondition] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [bonusPaymentStatus, setBonusPaymentStatus] = useState(null);

  const [status, setStatus] = useState(null);
  const [displayName, setDisplayName] = useState("");
  const [signatureEmail, setSignatureEmail] = useState("");

  const [hasPaymentDetails, setHasPaymentDetails] = useState(false);
  const [paymentDetailsComplete, setPaymentDetailsComplete] = useState(false);

  const [expirationDate, setExpirationDate] = useState<Date>(null);

  const [closeUrl, setCloseUrl] = useState<string>("");

  // Product Access
  const [hasProductAccess, setHasProductAccess] = useState(false);
  const [productAccessStatus, setProductAccessStatus] = useState(ProductAccessStatus.NOT_STARTED);
  const [productAccessDueDate, setProductAccessDueDate] = useState<Date>(null);
  const [productAccessDescription, setProductAccessDescription] = useState("");
  const [productAccessInfoNeededFromCreator, setProductAccessInfoNeededFromCreator] = useState(
    ProductAccessInfoNeededFromCreatorType.NONE,
  );
  const [productAccessOtherInfoNeededFromCreator, setProductAccessOtherInfoNeededFromCreator] =
    useState("");
  const [productAccessInfoToProvideToCreator, setProductAccessInfoToProvideToCreator] = useState(
    ProductAccessInfoToProvideToCreatorType.NONE,
  );
  const [productAccessBrandDisputeReason, setProductAccessBrandDisputeReason] = useState("");

  const [productAccessOtherInfoToProvideToCreator, getProductAccessOtherInfoToProvideToCreator] =
    useState("");

  const [productAccessCreatorInput, setProductAccessCreatorInput] = useState("");

  const [productAccessBrandOutput, setProductAccessBrandOutput] = useState("");

  const [contractLoaded, setContractLoaded] = useState(false);
  const [showError, setShowError] = useState(false);

  const [allActionItemProps, setAllActionItemProps] = useState<ActionItemProps[]>([]);

  const stripeForm = useForm({
    initialValues: {
      contractId,
      country: "",
      businessType: "",
      firstName: "",
      lastName: "",
      email: "",
      confirmEmail: "",
    },

    validate: {
      country: (value) => (value ? null : "Please select your country"),
      businessType: (value) => (value ? null : "Please select your business type"),
      firstName: (value) => (value ? null : "Please enter your first name"),
      lastName: (value) => (value ? null : "Please enter your last name"),
      email: (value) =>
        value.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i) ? null : "Invalid email address",
      confirmEmail: (value, values) => (value === values.email ? null : "Emails do not match"),
    },
  });

  useEffect(() => {
    setContractLoaded(false);
    getDeliverablesViewData(user, contractId).then((response) => {
      const { success, contract } = response;

      if (success) {
        setContractType(contract.contractType);
        setBrandName(contract.brandName);
        setAdGroupId(contract.adGroupId);
        setCreativeBriefUrl(contract.creativeBriefUrl);

        // Deserialize Deliverables
        const deserializedDeliverables = (contract.deliverables as []).map((deliverable) =>
          Deliverable.deserialize(deliverable),
        );
        const deliverableSortOrder = getSortOrder(deserializedDeliverables);
        const actionItemProps = deliverableSortOrder.map(
          (sortIndex) =>
            ({
              deliverableId: deserializedDeliverables[sortIndex].id,
              actionRequired: deserializedDeliverables[sortIndex].actionRequired,
              status: deserializedDeliverables[sortIndex].status,
              format: deserializedDeliverables[sortIndex].format,
              nextDueDate: deserializedDeliverables[sortIndex].nextDueDate,
            } as ActionItemProps),
        );

        setDeliverables(deserializedDeliverables);
        setSortOrder(deliverableSortOrder);
        setAllActionItemProps(actionItemProps);

        // If a usage rights contract, deserialize the usage rights request info, as we won't have
        // any traditional deliverables
        if (contract.contractType === ContractType.USAGE_RIGHTS) {
          const deserializedUsageRightsRequest = deserializeUsageRightsRequest(
            contract.usageRightsRequest,
          );
          setUsageRightsRequestDetails(deserializedUsageRightsRequest);
          // We do not want to direct people to the creative brief for this contract because that was
          // for the original content, and this is for usage rights on existing content.
          setCreativeBriefUrl(null);
        }

        // Contract status fields
        setStatus(contract.status);
        setDisplayName(contract.displayName);
        setSignatureEmail(contract.signatureEmail);

        // Payment Amount
        setAmount(contract.contractAmountInMinorUnits);
        setBonusAmount(contract.bonusAmountInMinorUnits);
        setEarnedBonusAmount(contract.earnedBonusAmountInMinorUnits);
        setBonusCondition(contract.bonusCondition);
        setMeetsBonusCondition(contract.meetsBonusCondition);
        setPaymentStatus(contract.paymentStatus);
        setBonusPaymentStatus(contract.bonusPaymentStatus);

        // Payments Details
        setHasPaymentDetails(contract.hasPaymentDetails);
        setPaymentDetailsComplete(contract.paymentDetailsComplete);

        // Expiration Date
        setExpirationDate(new Date(contract.expirationDate));

        setCloseUrl(contract.closeUrl);
        setAdditionalEmails(contract.additionalEmails ? contract.additionalEmails : []);

        // Set initial values for Stripe Form
        stripeForm.setValues((prev) => ({
          ...prev,
          firstName: contract.signatureFirstName,
          lastName: contract.signatureLastName,
          email: contract.signatureEmail,
          confirmEmail: contract.signatureEmail,
        }));

        setFetchedLatestVideosForContract(
          contract.latestDeliverableVideos.map((deliverableVideo: any) =>
            DeliverableVideo.deserialize(deliverableVideo),
          ),
        );

        // Product Access
        setHasProductAccess(contract.hasProductAccess);
        setProductAccessStatus(contract.productAccessStatus);
        setProductAccessDueDate(contract.productAccessDueDate);
        setProductAccessDescription(contract.productAccessDescription);
        setProductAccessInfoNeededFromCreator(contract.productAccessInfoNeededFromCreator);
        setProductAccessInfoToProvideToCreator(contract.productAccessInfoToProvideToCreator);
        setProductAccessOtherInfoNeededFromCreator(
          contract.productAccessOtherInfoNeededFromCreator,
        );
        getProductAccessOtherInfoToProvideToCreator(
          contract.productAccessOtherInfoToProvideToCreator,
        );
        setProductAccessCreatorInput(contract.productAccessCreatorInput);
        setProductAccessBrandOutput(contract.productAccessBrandOutput);
        setProductAccessBrandDisputeReason(contract.productAccessBrandDisputeReason);
        setContractLoaded(true);
        setShowError(false);
      } else {
        setContractLoaded(true);
        setShowError(true);
      }
    });
  }, [contractId]);

  const handleRefetchUploadedVideos = (handleFetched: () => void) => {
    fetchLatestVideosForContract(contractId).then((response) => {
      const { latestVideos, success } = response;
      if (success) {
        const deserializedLatestVideosForContract = latestVideos.map((video: any) =>
          DeliverableVideo.deserialize(video),
        );
        setFetchedLatestVideosForContract(deserializedLatestVideosForContract);
        handleFetched();
      }
    });
  };

  const handleSaveCreatorProductAccessInfo = (productAccessInput: string | object) => {
    saveCreatorProductAccessInfo(
      contractId,
      productAccessInfoNeededFromCreator,
      productAccessInput,
    ).then((response) => {
      const { success } = response;
      if (success) {
        setProductAccessStatus(ProductAccessStatus.BRAND_ACTION_REQUIRED);
        showSuccessNotification({ message: "Successfully saved Product Access Information!" });
      } else {
        showFailureNotification({ message: "Failed to save Product Access Information." });
      }
    });
  };
  const handleRefetchDeliverable = (deliverableId: string, handleFetched: () => void) => {
    fetchDeliverable(deliverableId).then((response) => {
      const { success, deliverable } = response;
      if (success) {
        const deserializedDeliverable = Deliverable.deserialize(deliverable);
        setDeliverables((prev) => {
          const updatedDeliverables = prev.map((d) =>
            d.id === deliverableId ? deserializedDeliverable : d,
          );
          return updatedDeliverables;
        });
        setAllActionItemProps((prev) => {
          const updatedActionItemProps = [...prev];
          const actionItemIndex = updatedActionItemProps.findIndex(
            (actionItem) => actionItem.deliverableId === deliverableId,
          );
          updatedActionItemProps[actionItemIndex] = {
            ...prev[actionItemIndex],
            actionRequired: deserializedDeliverable.actionRequired,
            status: deserializedDeliverable.status,
            format: deserializedDeliverable.format,
            nextDueDate: deserializedDeliverable.nextDueDate,
          };
          return updatedActionItemProps;
        });
        handleFetched();
      }
    });
  };

  const sortedDeliverables = sortOrder.map((sortIndex) => deliverables[sortIndex]);

  return (
    <>
      <Notifications />
      {showError && (
        <Container>
          <LoadingError message="Failed to load contract and deliverables." />
        </Container>
      )}
      {!showError && <LoadingOverlay visible={!contractLoaded} />}
      {contractLoaded && !showError && (
        <Container>
          <ExpirationBanner status={status} expirationDate={expirationDate} />
          <Header
            user={user}
            contractId={contractId}
            amount={amount}
            bonusAmount={bonusAmount}
            earnedBonusAmount={earnedBonusAmount}
            bonusCondition={bonusCondition}
            meetsBonusCondition={meetsBonusCondition}
            paymentStatus={paymentStatus}
            bonusPaymentStatus={bonusPaymentStatus}
            brandName={brandName}
            creativeBriefUrl={creativeBriefUrl}
            displayName={displayName}
            signatureEmail={signatureEmail}
            additionalEmails={additionalEmails}
            setAdditionalEmails={setAdditionalEmails}
            status={status}
            setStatus={setStatus}
            hasPaymentDetails={hasPaymentDetails}
            paymentDetailsComplete={paymentDetailsComplete}
            closeUrl={closeUrl}
            stripeForm={stripeForm}
            showAdminOptions={showAdminOptions}
          />
          <ActionItems
            contractId={contractId}
            contractStatus={status}
            expirationDate={expirationDate}
            hasPaymentDetails={hasPaymentDetails}
            paymentDetailsComplete={paymentDetailsComplete}
            allActionItemProps={allActionItemProps}
          />
          {hasProductAccess && (
            <ProductAccessSection
              brandName={brandName}
              productAccessStatus={productAccessStatus}
              productAccessDueDate={productAccessDueDate}
              productAccessDescription={productAccessDescription}
              productAccessInfoNeededFromCreator={productAccessInfoNeededFromCreator}
              productAccessOtherInfoNeededFromCreator={productAccessOtherInfoNeededFromCreator}
              productAccessInfoToProvideToCreator={productAccessInfoToProvideToCreator}
              productAccessOtherInfoToProvideToCreator={productAccessOtherInfoToProvideToCreator}
              productAccessCreatorInput={productAccessCreatorInput}
              setProductAccessCreatorInput={setProductAccessCreatorInput}
              productAccessBrandOutput={productAccessBrandOutput}
              productAccessBrandDisputeReason={productAccessBrandDisputeReason}
              handleSaveCreatorProductAccessInfo={handleSaveCreatorProductAccessInfo}
            />
          )}
          <Group gap="lg" align="center">
            <Title order={2} mt="lg" mb="sm" fw="500">
              Deliverables
            </Title>
            {sortedDeliverables.length > 1 && !contractExpired(status) && (
              <Tooltip
                offset={16}
                label={`Eligible for payment once all deliverables are ${
                  contractType === ContractType.INFLUENCER ? "live" : "approved"
                }`}
                disabled={status === ContractStatus.COMPLETE}>
                <ThemeIcon mt="xs" variant="transparent" radius="xl">
                  <DeliverableProgress deliverables={sortedDeliverables} />
                </ThemeIcon>
              </Tooltip>
            )}
          </Group>
          <Stack mt="sm" gap="sm">
            {contractType === ContractType.USAGE_RIGHTS ? (
              <UsageRightsCard
                usageRightsRequest={usageRightsRequestDetails}
                contractId={contractId}
                platform={usageRightsRequestDetails.platform}
                format={usageRightsRequestDetails.liveContent?.format}
                brandName={brandName}
              />
            ) : (
              sortedDeliverables.map((deliverable) => (
                <DeliverableCard
                  user={user}
                  id={deliverable.id}
                  adGroupId={adGroupId}
                  contractId={contractId}
                  contractType={contractType}
                  key={deliverable.id}
                  platform={deliverable.platform}
                  format={deliverable.format}
                  status={deliverable.status}
                  brandName={brandName}
                  creatorHandle={deliverable.creatorHandle}
                  profileLink={deliverable.profileLink}
                  liveContentUrl={deliverable.liveContentUrl}
                  adCode={deliverable.adCode}
                  assetUrl={deliverable.assetUrl}
                  assetName={deliverable.assetName}
                  reuseFinalDraft={deliverable.reuseFinalDraft}
                  unsubmittedLiveContent={deliverable.unsubmittedLiveContent}
                  timeline={deliverable.timeline}
                  liveContentReviewDeadline={deliverable.liveContentReviewDeadline}
                  liveContentDisputeReason={deliverable.liveContentDisputeReason}
                  actionRequired={deliverable.actionRequired}
                  requiresReferralLink={deliverable.requiresReferralLink}
                  requiresPromoCode={deliverable.requiresPromoCode}
                  missingReferralLink={deliverable.missingReferralLink}
                  missingPromoCode={deliverable.missingPromoCode}
                  referralLinkUrl={deliverable.referralLinkUrl}
                  referralLinkRedirectUrl={deliverable.referralLinkRedirectUrl}
                  promoCode={deliverable.promoCode}
                  usageRightsDays={deliverable.usageRightsDays}
                  usageRightsInPerpetuity={deliverable.usageRightsInPerpetuity}
                  creativeBriefUrl={creativeBriefUrl}
                  numDeliverablesInContract={sortedDeliverables.length}
                  latestVideosForContract={fetchedLatestVideosForContract}
                  setLatestVideosForContract={setFetchedLatestVideosForContract}
                  handleRefetchDeliverable={handleRefetchDeliverable}
                  handleRefetchUploadedVideos={handleRefetchUploadedVideos}
                  updateActionItem={(newStatus: ContractDeliverableStatus) => {
                    setAllActionItemProps((prev) => {
                      const updatedActionItemProps = [...prev];
                      const actionItemIndex = updatedActionItemProps.findIndex(
                        (actionItem) => actionItem.deliverableId === deliverable.id,
                      );
                      updatedActionItemProps[actionItemIndex] = {
                        ...prev[actionItemIndex],
                        status: newStatus,
                      };
                      return updatedActionItemProps;
                    });
                  }}
                  showAdminOptions={showAdminOptions}
                />
              ))
            )}
          </Stack>
        </Container>
      )}
    </>
  );
}
