import React, { useState, useEffect } from "react";
import {
  Alert,
  Box,
  Card,
  Checkbox,
  ComboboxItem,
  Divider,
  Grid,
  GridCol,
  Group,
  LoadingOverlay,
  NumberInput,
  Select,
  Skeleton,
  Space,
  Stack,
  Text,
  Title,
  Transition,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import {
  IconMessages,
  IconCurrencyDollar,
  IconX,
  IconCheck,
  IconInfoCircle,
} from "@tabler/icons-react";
import { useAuthState } from "react-firebase-hooks/auth";

import { auth } from "auth/firebaseAuthHelpers";
import { PriceMap, memoizedFetchPricing } from "creators/api/creatorApiUtils";
import {
  ContractErrorResponse,
  acceptAllPackagesForOffer,
  acceptRecommendedPackageForOffer,
  addContractOffer,
  extendContractOfferDeadlineByDefaultAmount,
  getLatestContractOfferForOpportunity,
  reportRecommendedPackageProblem,
} from "components/contracts/negotiations/api/ContractOfferApi";
import {
  SUPPORTED_FORMATS_TO_LABELS,
  SUPPORTED_PLATFORMS_TO_FORMATS_AND_TIMELINES,
} from "components/contracts/common/Common";
import { ActivePlatformInfo } from "components/creator/CreatorUtils";
import DeliverableOfferCards from "components/creator/DeliverableOfferCards";
import ButtonGroup from "components/creator/ButtonGroup";
import { InternalCardTitle } from "components/creator/InternalCardTitle";
import InternalNotesDrawer from "components/creator/InternalNotesDrawer";
import UsageRightsPricing from "components/creator/UsageRightsPricing";
import Deliverable from "components/contracts/models/Deliverable";
import {
  ContractOfferStatus,
  SupportedFormat,
  SupportedFormatIntegerToSupportedFormat,
  SupportedPlatform,
  UsageRightsDuration,
  UsageRightsDurationMultiplier,
} from "models/Common";
import { ContractOffer } from "models/ContractOffer";
import { CreatorV2Info } from "models/CreatorV2Info";
import { DeliverablePackage } from "models/DeliverablePackage";
import { Opportunity } from "models/Opportunity";
import { isAdminUser } from "utils/UserUtils";
import { notifications } from "@mantine/notifications";
import { getAbbreviatedNumber } from "utils/AnalyticsUtils";
import { Campaign, MaxOfferAction, UsageRightsPolicy } from "models/Campaign";
import { useSearchParams } from "react-router-dom";
import { fromISODateString, toLongDateTimeString } from "utils/DateUtils";

const SupportedPlatformToPlatformInfo: Record<string, SupportedPlatform> = {
  instagram_profile: SupportedPlatform.INSTAGRAM,
  tiktok_profile: SupportedPlatform.TIKTOK,
  youtube_channel: SupportedPlatform.YOUTUBE,
};

interface SumViews {
  [key: string]: Record<string, number>;
}

const STATES_TO_SHOW_OFFER_UUID = new Set([
  ContractOfferStatus.INTERNAL_APPROVED_OFFER,
  ContractOfferStatus.INTERNAL_APPROVED_BRAND_OFFER,
]);

const getSumPricesAndViews = (resPriceMap: PriceMap, stateMap: Record<SupportedFormat, number>) => {
  const selectedDeliverables: SumViews = Object.keys(resPriceMap)
    .filter((format) => stateMap[format as SupportedFormat] !== 0)
    .reduce((acc, key) => {
      return { ...acc, [key as SupportedFormat]: resPriceMap[key as keyof PriceMap] };
    }, {});

  const sumSuggestedPrice = Object.values(selectedDeliverables).reduce((acc, { upper_price }) => {
    return (acc + upper_price / 100) as number;
  }, 0);
  const totalViewsUpper = Object.values(selectedDeliverables).map(({ upper_views }) => upper_views);

  return { sumSuggestedPrice, totalViewsUpper };
};

/**
 * Floor an input suggested price to the nearest multiple of $10 (no cents)
 * @param input The input to be rounded to the nearest $10
 */
const floorSuggestedPrice = (suggestedPrice: number): number => {
  return suggestedPrice && suggestedPrice > 0 ? Math.floor(suggestedPrice / 10) * 10 : 0;
};

const willTriggerBrandReview = (
  deliverablePackage: DeliverablePackage,
  opportunity: Opportunity,
) => {
  const askingPrice = deliverablePackage?.creator_asking_price
    ? deliverablePackage.creator_asking_price +
      (deliverablePackage.creator_asking_usage_rights_price || 0)
    : 0;
  const suggestedPrice = deliverablePackage?.suggested_price || 0;
  let proposedPrice;
  if (askingPrice && !suggestedPrice) {
    proposedPrice = askingPrice;
  } else if (suggestedPrice && !askingPrice) {
    proposedPrice = suggestedPrice;
  } else if (suggestedPrice && askingPrice) {
    proposedPrice = Math.min(suggestedPrice, askingPrice);
  } else {
    // Neither asking price nor suggested price are available.
    return false;
  }
  if (
    opportunity?.max_offer_cap &&
    opportunity?.max_offer_action === MaxOfferAction.Review &&
    proposedPrice > opportunity?.max_offer_cap
  ) {
    return true;
  }
  return false;
};

const offerTriggersBrandReview = (offer: ContractOffer, opportunity: Opportunity) => {
  return offer?.deliverable_packages
    .filter((deliverablePackage) =>
      new Set([ContractOfferStatus.NEEDS_INTERNAL_REVIEW]).has(deliverablePackage.status),
    )
    .map((deliverablePackage) => willTriggerBrandReview(deliverablePackage, opportunity))
    .some((v) => v);
};

const NegotiationsView = ({
  creatorId,
  creatorInfo,
  opportunities,
  activeOpportunities,
  campaigns,
  activePlatforms,
}: {
  creatorId: number;
  creatorInfo: CreatorV2Info;
  opportunities: Opportunity[];
  campaigns: Campaign[];
  activeOpportunities: Opportunity[];
  activePlatforms: ActivePlatformInfo;
}) => {
  const sortedActiveOpportunities = activeOpportunities.sort((a, b) => b.id - a.id);
  const firstActiveOpportunity =
    sortedActiveOpportunities.length > 0 ? sortedActiveOpportunities[0] : null;
  const firstActiveOpportunityId =
    sortedActiveOpportunities.length > 0 ? sortedActiveOpportunities[0].id : null;
  const firstActiveCampaignName =
    sortedActiveOpportunities.length > 0
      ? sortedActiveOpportunities[0].ad_group.campaign_name
      : null;
  const firstActiveCampaignId =
    sortedActiveOpportunities.length > 0 ? sortedActiveOpportunities[0].ad_group.campaign_id : null;
  const firstActiveCampaign = campaigns && campaigns.find((c) => c.id === firstActiveCampaignId);
  const firstActiveBrandName =
    sortedActiveOpportunities.length > 0 ? sortedActiveOpportunities[0]?.brand_name : "";
  const opportunitiesSelection: ComboboxItem[] = sortedActiveOpportunities.map((opp) => {
    return {
      value: opp.id.toString(),
      label: `${opp.ad_group.campaign_name} (opp: ${opp.id})`,
    } as ComboboxItem;
  });

  // user state
  const [user, loading] = useAuthState(auth);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isOps, setIsOps] = useState<boolean>(false);

  // search params
  const [searchParams, setSearchParams] = useSearchParams();

  // fields needed to pass to DeliverableOfferCards for contract creation
  const firstName = creatorInfo.first_name;
  const { email } = creatorInfo;
  const [closeContactName, setCloseContact] = useState<string>(
    firstActiveOpportunity?.sender_first_name,
  );
  const [brandName, setBrandName] = useState<string>(firstActiveBrandName);
  const [currCampaign, setCurrCampaign] = useState<Campaign>(firstActiveCampaign);
  const [campaignId, setCampaignId] = useState<number>(firstActiveCampaignId);

  // dropdown states
  const [dropdownOpportunity, setDropdownOpportunity] = useState<ComboboxItem | null>({
    value: firstActiveOpportunityId ? firstActiveOpportunityId.toString() : null,
    label: firstActiveCampaignName,
  });
  const [selectedOpportunity, setSelectedOpportunity] = useState<Opportunity | null>(
    firstActiveOpportunity,
  );

  // button validation
  const [validate, setValidate] = useState<boolean>(true);
  const [disableSave, setDisableSave] = useState<boolean>(true);
  const [disableWithOffer, setDisableWithOffer] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [isFetchingOffers, setIsFetchingOffers] = useState<boolean>(false);
  const [isFetchingAdmin, setIsFetchingAdmin] = useState<boolean>(true);

  // pricing data from fetching
  const [totalViewsRange, setTotalViewsRange] = useState({});
  const [hasCountryDiscount, setHasCountryDiscount] = useState<boolean>(false);
  const [discountFactor, setDiscountFactor] = useState<number>(1.0);
  const [priceMap, setPriceMap] = useState<PriceMap>({
    [SupportedFormat.TIKTOK_DEDICATED_VIDEO]: null,
    [SupportedFormat.YOUTUBE_DEDICATED_VIDEO]: null,
    [SupportedFormat.YOUTUBE_30S_INTEGRATED_VIDEO]: null,
    [SupportedFormat.YOUTUBE_60S_INTEGRATED_VIDEO]: null,
    [SupportedFormat.YOUTUBE_90S_INTEGRATED_VIDEO]: null,
    [SupportedFormat.YOUTUBE_SHORT]: null,
    [SupportedFormat.INSTAGRAM_DEDICATED_REEL]: null,
    [SupportedFormat.UGC]: null,
  });

  // package offer states
  const [creatorPrice, setCreatorPrice] = useState<number | string>(0);
  const [suggestedPrice, setSuggestedPrice] = useState<number>(0);
  const [contractOfferStatus, setContractOfferStatus] = useState<ContractOfferStatus>(
    ContractOfferStatus.DRAFT,
  );
  const [currContractOffer, setCurrContractOffer] = useState<ContractOffer | null>(null);
  const [currContractOfferId, setCurrContractOfferId] = useState<number | null>(null);
  const [selectedPackages, setPackages] = useState<Array<[number, DeliverablePackage]>>([]);
  const [fetchedPackages, setFetchedPackages] = useState<
    Array<[number, ContractOfferStatus, DeliverablePackage[]]>
  >([]);
  const [stateMap, setStateMap] = useState<Record<SupportedFormat, number>>({
    [SupportedFormat.TIKTOK_DEDICATED_VIDEO]: 0,
    [SupportedFormat.INSTAGRAM_DEDICATED_REEL]: 0,
    [SupportedFormat.YOUTUBE_DEDICATED_VIDEO]: 0,
    [SupportedFormat.YOUTUBE_SHORT]: 0,
    [SupportedFormat.YOUTUBE_30S_INTEGRATED_VIDEO]: 0,
    [SupportedFormat.YOUTUBE_60S_INTEGRATED_VIDEO]: 0,
    [SupportedFormat.YOUTUBE_90S_INTEGRATED_VIDEO]: 0,
    [SupportedFormat.UGC]: 0,
  });

  // usage rights states
  const isUsageRightsUpfront = currCampaign?.usage_rights_policy === UsageRightsPolicy.UPFRONT;
  const defaultUsageRightsDuration = isUsageRightsUpfront ? currCampaign?.usage_rights_duration : 0;

  const [checkedUsageRights, setCheckedUsageRights] = useState<boolean>(isUsageRightsUpfront);
  const [usagePrice, setUsagePrice] = useState<number>(0);
  const [suggestedUsagePrice, setSuggestedUsagePrice] = useState<number>(
    Number(creatorPrice) * UsageRightsDurationMultiplier[defaultUsageRightsDuration],
  );

  // we assign one usage rights duration to all formats
  // ie. usage rights are at contract level
  const [usageRightsDuration, setUsageRightsDuration] = useState<UsageRightsDuration | null>(
    defaultUsageRightsDuration,
  );

  // contract offer notes
  const [opened, { open, close }] = useDisclosure(false);

  // deliverable package rendered
  const [addedPackages, setAddedPackages] = useState([]);
  const [fetchedPackagesCards, setFetchedPackagesCards] = useState([]);

  // for the recommended package approval process
  const [problemReported, setProblemReported] = useState<boolean>(false);

  useEffect(() => {
    isAdminUser(user)
      .then((res) => {
        setIsAdmin(res);
      })
      .finally(() => {
        setIsFetchingAdmin(false);
      });

    if (user.email.split("@")[1] === "1stcollab.com") {
      setIsOps(false);
    } else {
      setIsOps(true);
    }
  }, [user]);

  // input field validation
  useEffect(() => {
    // at least one deliverable selected
    const hasSelectedDeliverable = Object.values(stateMap).some((val) => val > 0);
    if (
      !isFetching &&
      suggestedPrice > 0 &&
      dropdownOpportunity.value !== null &&
      hasSelectedDeliverable &&
      Number(creatorPrice) > 0
    ) {
      setValidate(false);
    } else {
      setValidate(true);
    }
    if (
      contractOfferStatus === ContractOfferStatus.DRAFT &&
      (selectedPackages.length > 0 || fetchedPackages.length > 0)
    ) {
      setDisableSave(false);
    } else {
      setDisableSave(true);
    }
  }, [
    dropdownOpportunity,
    stateMap,
    selectedPackages,
    fetchedPackages,
    creatorPrice,
    isFetching,
    suggestedPrice,
    contractOfferStatus,
  ]);

  // calculate suggested usage price based on creator price
  useEffect(() => {
    if (Number(creatorPrice) > 0) {
      setSuggestedUsagePrice(
        Number(creatorPrice) * UsageRightsDurationMultiplier[usageRightsDuration],
      );
    }
  }, [creatorPrice, usageRightsDuration]);

  // fetch suggested prices once for all deliverables
  useEffect(() => {
    // if creator has no active opps return early, don't fetch
    if (selectedOpportunity === null) return;
    const channelId = creatorInfo.youtube_channel_id;
    const tiktokId = creatorInfo.tiktok_uuid;
    const instagramId = creatorInfo.instagram_id;
    const abortController = new AbortController();
    const cpm = selectedOpportunity?.ad_group.target_cpm;
    const idList = Object.entries({ channelId, tiktokId, instagramId })
      .filter(([id, val]) => val !== null)
      .reduce((acc, [key, val]) => {
        return { ...acc, [key]: val.toString() };
      }, {});

    // if we want to fetch only selected deliverables
    const deliverables = Object.keys(stateMap)
      .filter((key: SupportedFormat) => stateMap[key] === 1)
      .map((key) => {
        return { format: key } as Deliverable;
      });
    // TODO: don't need to fetch each time just store in state every price map for each opportunity
    memoizedFetchPricing(selectedOpportunity.id, creatorId, cpm, idList, abortController)
      .then((res) => {
        if (res?.price_map) {
          const resPriceMap = res.price_map;
          setPriceMap(res.price_map);

          // set country discount if exists
          if (res.country_discount === "true") {
            setHasCountryDiscount(true);
            setDiscountFactor(Number(res.country_discount_factor));
          } else setHasCountryDiscount(false);

          // set suggested price when we have a deliverable selected
          const hasSelectedDeliverable = Object.values(stateMap).some((val) => val > 0);
          const fetchedPriceMap = Object.values(resPriceMap).some((val) => val !== null);
          if (hasSelectedDeliverable && fetchedPriceMap) {
            const { sumSuggestedPrice, totalViewsUpper } = getSumPricesAndViews(
              resPriceMap,
              stateMap,
            );
            setTotalViewsRange({ ...totalViewsRange, total_upper: totalViewsUpper });
            setSuggestedPrice(floorSuggestedPrice(sumSuggestedPrice));
          } else {
            setSuggestedPrice(0);
          }
        }
      })
      .finally(() => {
        setIsFetching(false);
      });

    // even though there's a useEffect to read
    // in the opportunity id and set the selected opportunity from URL at page load
    // sometimes state isn't set fast, so we need to read from the url here and check if it's updated
    let fetchOppId = selectedOpportunity.id;
    const oppId = searchParams.get("opportunityId");
    const isValidOpp = opportunities.some((o) => o.id === Number(oppId));
    if (oppId && isValidOpp && oppId !== selectedOpportunity.id.toString()) {
      // if the opportunity id in the URL is different from curr selected opportunity
      // fetch the contract offers using the url opportunity id if its included in list of opps
      fetchOppId = Number(oppId);
    }

    // also fetch current contract offer (if exists)
    setIsFetchingOffers(true);
    const res = getLatestContractOfferForOpportunity(fetchOppId, abortController);
    res
      .then((content) => {
        if (!content?.id) {
          setFetchedPackages([]);
          return;
        }
        setFetchedPackages([[content.id, content.status, content.deliverable_packages]]);
        setCurrContractOfferId(content.id);
        setCurrContractOffer(content);
        setContractOfferStatus(content.status);
        // since we have a fetched offer, we cannot have user add and save a new package
        setDisableWithOffer(true);
      })
      .finally(() => {
        setIsFetchingOffers(false);
      });
  }, [selectedOpportunity, stateMap]);

  // update selected opportunity
  useEffect(() => {
    if (
      dropdownOpportunity.value !== null &&
      dropdownOpportunity.value !== selectedOpportunity?.id?.toString()
    ) {
      const currOpportunity = activeOpportunities.filter(
        (opp) => opp.id === Number(dropdownOpportunity.value),
      )[0];
      setSelectedOpportunity(currOpportunity);
      setCampaignId(currOpportunity.ad_group.campaign_id);
      setCurrCampaign(campaigns.find((c) => c.id === currOpportunity.ad_group.campaign_id));
      setBrandName(currOpportunity.brand_name);
    }

    // every time a diff opp is selected from dropdown, clear all added/fetched packages
    setPackages([]);
    setFetchedPackages([]);
    setCurrContractOffer(null);
    setCurrContractOfferId(null);
  }, [dropdownOpportunity]);

  // read in opportunity id from URL if any
  // set dropdown value to opportunity id in URL
  useEffect(() => {
    const oppId = searchParams.get("opportunityId");
    if (oppId) {
      // get the campaign name
      const opp = opportunities.find((o) => o.id === Number(oppId));
      if (!opp) return;
      // set dropdown value to opportunity id in URL
      // if opp id was found in list of active opportunities
      setDropdownOpportunity({
        value: oppId,
        label: opp?.ad_group.campaign_name,
      });
      setSelectedOpportunity(opp);
      setCampaignId(opp.ad_group.campaign_id);
      setCurrCampaign(campaigns.find((c) => c.id === opp.ad_group.campaign_id));
      setBrandName(opp.brand_name);
    } else {
      // if we don't have a opp id in URL, then on first load
      // set the opportunity id in the URL to the selected (default) opportunity if any
      setSearchParams((params) => {
        if (firstActiveOpportunityId === null) return params;
        params.set("opportunityId", firstActiveOpportunityId?.toString());
        return params;
      });
    }
  }, []);

  useEffect(() => {
    if (!campaigns) return;
    const currentCampaign = campaigns.find((c) => c.id === campaignId);
    setCurrCampaign(campaigns.find((c) => c.id === campaignId));
    setCheckedUsageRights(currentCampaign?.usage_rights_policy === UsageRightsPolicy.UPFRONT);
    setUsageRightsDuration(currentCampaign?.usage_rights_duration);
  }, [campaigns, campaignId]);

  const addPackage = () => {
    const idNum = Date.now() + creatorId;

    const debugStrObject: Record<string, string> = {};
    const debugMap = Object.keys(priceMap)
      .filter((format) => {
        return stateMap[format as SupportedFormat] !== 0;
      })
      .forEach((format) => {
        const supportedFormat = format as SupportedFormat;
        const selectedFormat = priceMap[supportedFormat];
        const debugStr = `${selectedFormat.upper_price / 100}\n${getAbbreviatedNumber(
          selectedFormat.upper_views,
        )} views * (${selectedFormat.views_adjustment} views adjustment) * (${
          selectedFormat.format_weight
        } format weight * $${selectedOpportunity.ad_group.target_cpm} CPM)${
          hasCountryDiscount ? ` * ${discountFactor} country discount` : ""
        }`;

        debugStrObject[`${format}_price_debug`] = debugStr;
      });

    const deliverablePackage: DeliverablePackage = {
      id: null,
      // creator: creatorInfo,
      creator_id: creatorId,
      opportunity_id: selectedOpportunity.id,
      opportunity: opportunities.filter((opp) => opp.id === Number(dropdownOpportunity.value))[0],
      contract_offer: null,
      creator_asking_price: Number(creatorPrice),
      creator_asking_usage_rights_price: usagePrice,
      suggested_price: suggestedPrice,
      is_recommended: false,
      status: ContractOfferStatus.DRAFT,
      usage_rights: checkedUsageRights ? usageRightsDuration : null,
      usage_rights_price: null,
      ...stateMap,
      ...debugStrObject,
    };

    setPackages([...selectedPackages, [idNum, deliverablePackage]]);
    setStateMap({
      ...stateMap,
      [SupportedFormat.TIKTOK_DEDICATED_VIDEO]: 0,
      [SupportedFormat.INSTAGRAM_DEDICATED_REEL]: 0,
      [SupportedFormat.YOUTUBE_DEDICATED_VIDEO]: 0,
      [SupportedFormat.YOUTUBE_SHORT]: 0,
      [SupportedFormat.YOUTUBE_60S_INTEGRATED_VIDEO]: 0,
      [SupportedFormat.UGC]: 0,
      // [SupportedFormat.YOUTUBE_30S_INTEGRATED_VIDEO]: 0,
      [SupportedFormat.YOUTUBE_90S_INTEGRATED_VIDEO]: 0,
    });
    setContractOfferStatus(ContractOfferStatus.DRAFT);
    setCreatorPrice(0);
    setSuggestedPrice(0);
  };

  // get active supported formats to disable checkboxes
  const activeSupportedFormats = Object.keys(activePlatforms)
    .filter((profile: keyof ActivePlatformInfo) => activePlatforms[profile] !== null)
    .map((platform) => {
      const activeList: SupportedFormat[] = [];
      const activePlats = SupportedPlatformToPlatformInfo[platform];
      SUPPORTED_PLATFORMS_TO_FORMATS_AND_TIMELINES[activePlats].forEach((formatType) => {
        activeList.push(formatType.value);
      });
      return activeList.filter((format) => {
        // Filter out any formats not supported by the campaign
        const foundAt = currCampaign?.formats?.find(
          (val) => SupportedFormatIntegerToSupportedFormat[val] === format,
        );
        return foundAt !== undefined;
      });
    });

  // deliverable format checkboxes
  const deliverableFormats = Object.entries(SUPPORTED_FORMATS_TO_LABELS)
    .filter(
      ([format, label]) =>
        ![SupportedFormat.YOUTUBE_30S_INTEGRATED_VIDEO].includes(format as SupportedFormat),
    )
    .map(([format, label], __) => {
      const priceLabel = priceMap[format as SupportedFormat]?.upper_price;

      return (
        <GridCol key={format} span={{ base: 12, md: 6, lg: 3 }}>
          <Checkbox
            checked={Boolean(stateMap[format as SupportedFormat])}
            label={`${label} ($${priceLabel ? priceLabel / 100 : "N/A"})`}
            disabled={!activeSupportedFormats.flat().includes(format as SupportedFormat)}
            onChange={(event) => {
              setStateMap({
                ...stateMap,
                [format as SupportedFormat]: Number(event.currentTarget.checked),
              });

              // clear usage rights if unchecked
              if (!event.currentTarget.checked) {
                setSuggestedUsagePrice(
                  Number(creatorPrice) * UsageRightsDurationMultiplier[usageRightsDuration],
                );
                setUsagePrice(0);
              }
            }}
          />
        </GridCol>
      );
    });

  // Compute the Deliverable Package Cards
  useEffect(() => {
    const computedPackages = selectedPackages.map((deliverable) => {
      const deliverableValue = Object.values(deliverable);
      const selectedDeliverableId = deliverable[0];
      const selectedDeliverable = deliverableValue[1] as DeliverablePackage;

      // handler to remove package from selected packages
      const handler = () => {
        const filteredArr = selectedPackages.filter((item) => {
          return item[0] !== selectedDeliverableId;
        });
        setPackages(filteredArr);
      };

      return (
        <DeliverableOfferCards
          key={deliverable[0]}
          isOps={isOps}
          campaign={currCampaign}
          selectedDeliverableId={selectedDeliverableId}
          selectedDeliverable={selectedDeliverable}
          contractStatus={contractOfferStatus}
          // Pass in a function to set the current contract offer.
          setContractStatus={setContractOfferStatus}
          platformPricing={priceMap}
          totalViewsRange={totalViewsRange}
          hasDiscount={hasCountryDiscount}
          discountFactor={discountFactor}
          deletePackageHandler={handler}
        />
      );
    });
    setAddedPackages(computedPackages);
  }, [selectedPackages, priceMap]);

  // fetched packages rendered
  useEffect(() => {
    const computedFetchedPackages = fetchedPackages.map((deliverable) => {
      const allPackages = deliverable[2].sort((a, b) => {
        const aDate = new Date(a.last_updated).getTime();
        const bDate = new Date(b.last_updated).getTime();
        const order = Number(b.is_recommended) - Number(a.is_recommended);
        return order || bDate - aDate;
      });

      if (!allPackages || allPackages.length === 0) return null;
      return allPackages.map((offer) => {
        // handler to remove package from fetched packages
        const deleteHandler = async () => {
          const deliverablePackageArr = fetchedPackages[0][2];
          const filteredFetched = deliverablePackageArr.filter((item) => {
            return item.id !== offer.id;
          });
          setFetchedPackages((prev) => [[prev[0][0], prev[0][1], filteredFetched]]);

          // TODO: this is duplicated code, i'll refactor later
          // filter deleted offers out of fetched packages and save to db
          const contractOffer: ContractOffer = {
            id: filteredFetched.length > 0 ? (filteredFetched[0].contract_offer as any) : null,
            uuid: null,
            creator_id: creatorInfo.id,
            opportunity_id: Number(dropdownOpportunity.value),
            deliverable_packages: filteredFetched.map((dp) => {
              const parsedDp = {
                ...dp,
                // write is done using the id
                // opportunity_id: dp.opportunity_id,
                // creator_id: dp.creator_id,
              };
              delete parsedDp.opportunity;
              delete parsedDp.creator;
              // there's gotta be a better way to do this
              return parsedDp;
            }),
            direction: 0,
            status: 0,
          };

          setIsFetchingOffers(true);
          try {
            const res = await addContractOffer(contractOffer);
            if (res.deliverable_packages) {
              // when saving/updating a new contract offer, we get the contract offer back as res
              notifications.show({
                title: "Packages deleted!",
                message: "All packages deleted and saved successfully.",
                color: "green",
                icon: <IconCheck />,
                autoClose: 3000,
              });
              setCurrContractOffer(res);
              setContractOfferStatus(res.status);
              setCurrContractOfferId(res.id);
              setPackages([]);
              setFetchedPackages([[res.id, res.status, res.deliverable_packages]]);
            } else {
              const errRes = res as unknown as ContractErrorResponse;
              notifications.show({
                title: "Error submitting packages to be deleted on contract offer",
                message: `${errRes.text}. ${errRes.result.message}. Could not submit packages for deletion.`,
                color: "red",
                icon: <IconX />,
                autoClose: false,
              });
            }
          } finally {
            setIsFetchingOffers(false);
          }
        };

        return (
          <DeliverableOfferCards
            key={offer.id}
            isOps={isOps}
            campaign={currCampaign}
            contractOfferId={deliverable[0]}
            selectedDeliverableId={offer.id}
            selectedDeliverable={offer}
            contractStatus={offer.status}
            selectedOpportunity={selectedOpportunity}
            platformPricing={priceMap}
            activePlatforms={activePlatforms}
            fetchedPackages={fetchedPackages}
            setFetchedPackages={setFetchedPackages}
            deletePackageHandler={deleteHandler}
            generateContractData={{
              creatorId: creatorId.toString(),
              contractAmount: null,
              recommendPrice: offer.suggested_price.toString(),
              firstName,
              email,
              closeContactName,
              brandName,
              campaignId: campaignId.toString(),
            }}
          />
        );
      });
    });
    setFetchedPackagesCards(computedFetchedPackages);
  }, [fetchedPackages, priceMap]);

  // pass in both fetched and user inputted packages
  const userPackages = selectedPackages.map((item) => item[1]);
  const fetchedDP = fetchedPackages
    .filter(
      (dp) =>
        ![ContractOfferStatus.BRAND_REJECTED_OFFER, ContractOfferStatus.REJECTED].includes(dp[1]),
    )
    .map(([id, status, item]) => {
      const deliverable = item;
      return deliverable.map((packageItem) => {
        return { ...packageItem, creator_id: creatorId, opportunity_id: selectedOpportunity.id };
      });
    });
  const combinedPackages = [...userPackages, ...fetchedDP.flat()] as DeliverablePackage[];

  // save packages to db
  const savePackages = async () => {
    // trigger post request to endpoint to save on model
    // build contract offer from deliverable packages

    // filter rejected offers out of fetched packages
    // if we only have rejected (empty), then don't pass in the id to update offer
    // i.e we generate a new contract offer
    const filteredFetched = fetchedPackages.filter(
      ([_, status, __]) =>
        ![ContractOfferStatus.REJECTED, ContractOfferStatus.BRAND_REJECTED_OFFER].includes(status),
    );

    const contractOffer: ContractOffer = {
      id: filteredFetched.length > 0 ? (filteredFetched[0][0] as number) : null,
      uuid: null,
      creator_id: creatorInfo.id,
      opportunity_id: Number(dropdownOpportunity.value),
      deliverable_packages: combinedPackages.map((dp) => {
        const parsedDp = {
          ...dp,
          // write is done using the id
          // opportunity_id: dp.opportunity_id,
          // creator_id: dp.creator_id,
        };
        delete parsedDp.opportunity;
        delete parsedDp.creator;
        // there's gotta be a better way to do this
        return parsedDp;
      }),
      direction: 0,
      status: 0,
    };

    setIsFetchingOffers(true);
    try {
      const res = await addContractOffer(contractOffer);
      if (res.deliverable_packages) {
        // when saving/updating a new contract offer, we get the contract offer back as res
        notifications.show({
          title: "Packages saved!",
          message: "All packages saved successfully.",
          color: "green",
          icon: <IconCheck />,
          autoClose: 2000,
        });
        setContractOfferStatus(res.status);
        setCurrContractOffer(res);
        setCurrContractOfferId(res.id);
        setPackages([]);
        setFetchedPackages([[res.id, res.status, res.deliverable_packages]]);
      } else {
        const errRes = res as unknown as ContractErrorResponse;
        notifications.show({
          title: "Error submitting contract offer",
          message: `${errRes.text}. ${errRes.result.message}. Could not submit current packages for a contract offer.`,
          color: "red",
          icon: <IconX />,
          autoClose: false,
        });
      }
    } finally {
      setIsFetchingOffers(false);
    }
  };

  /**
   * Accept the recommended package. Note that the backend will use the flagged package, not
   * necessarily the package we send.  We send the package as a sort of CAS operation.
   */
  const acceptRecommendedPackage = async () => {
    try {
      const recommendedPackages = currContractOffer?.deliverable_packages.filter(
        (pkg) => pkg.is_recommended,
      );
      if (!recommendedPackages) {
        notifications.show({
          title: "No recommended packages",
          message: "There are no recommended packages for this offer.",
          color: "red",
          icon: <IconX />,
          autoClose: false,
        });
        return;
      } else if (recommendedPackages.length > 1) {
        notifications.show({
          title: "Too many recommended packages",
          message: "There are more than one recommended packages for this offer.",
          color: "red",
          icon: <IconX />,
          autoClose: false,
        });
        return;
      }
      const res = await acceptRecommendedPackageForOffer(
        currContractOffer.id,
        recommendedPackages.at(0).id,
      );
      if (res.deliverable_packages) {
        // This should update to internally approved, which should disable the buttons to manipulate it further.
        setContractOfferStatus(res.status);
        setCurrContractOffer(res);
        setCurrContractOfferId(res.id);
        setPackages([]);
        setFetchedPackages([[res.id, res.status, res.deliverable_packages]]);
      } else {
        const errRes = res as unknown as ContractErrorResponse;
        notifications.show({
          title: "Error approving recommended package",
          message: `${errRes.text}. ${errRes.result.message}. Could not approve recommended package for this contract offer.`,
          color: "red",
          icon: <IconX />,
          autoClose: false,
        });
      }
    } catch {
      notifications.show({
        title: "Error approving recommended package",
        message: "Server error",
        color: "red",
        icon: <IconX />,
        autoClose: false,
      });
    }
  };

  const acceptAllPackages = async () => {
    try {
      const res = await acceptAllPackagesForOffer(currContractOffer.id);
      if (res.deliverable_packages) {
        // This should update to internally approved, which should disable the buttons to manipulate it further.
        setContractOfferStatus(res.status);
        setCurrContractOffer(res);
        setCurrContractOfferId(res.id);
        setPackages([]);
        setFetchedPackages([[res.id, res.status, res.deliverable_packages]]);
      } else {
        const errRes = res as unknown as ContractErrorResponse;
        notifications.show({
          title: "Error approving packages for offer",
          message: `Message: ${errRes.text}. ${errRes.result.message}. Could not approve packages for this contract offer.`,
          color: "red",
          icon: <IconX />,
          autoClose: false,
        });
      }
    } catch {
      notifications.show({
        title: "Error approving packages for offer.",
        message: "Server error",
        color: "red",
        icon: <IconX />,
        autoClose: false,
      });
    }
  };

  const extendOfferExpiration = async () => {
    try {
      const res = await extendContractOfferDeadlineByDefaultAmount(currContractOffer.id);
      if (res.success) {
        const { newDeadline } = res;
        notifications.show({
          title: "Offer deadline extended!",
          message: `New offer deadline is ${toLongDateTimeString(fromISODateString(newDeadline))}`,
          color: "green",
          icon: <IconCheck />,
          autoClose: 10000,
        });
      } else {
        const errRes = res as unknown as ContractErrorResponse;
        notifications.show({
          title: "Error extending offer deadline.",
          message: `Message: ${errRes.text}. ${errRes.result.message}. Could not extend the offer deadline.`,
          color: "red",
          icon: <IconX />,
          autoClose: false,
        });
      }
    } catch {
      notifications.show({
        title: "Error extending offer deadline.",
        message: `Server error.`,
        color: "red",
        icon: <IconX />,
        autoClose: false,
      });
    }
  };

  /**
   * Report an issue with this package. Once reported, we should not be able to manipulate this further,
   * though a refresh will allow us to report again.
   */
  const reportPackageProblem = async () => {
    const recommendedPackages = currContractOffer?.deliverable_packages.filter(
      (pkg) => pkg.is_recommended,
    );
    if (!recommendedPackages) {
      notifications.show({
        title: "No recommended packages",
        message: "There are no recommended packages for this offer.",
        color: "red",
        icon: <IconX />,
        autoClose: false,
      });
      return;
    } else if (recommendedPackages.length > 1) {
      notifications.show({
        title: "Too many recommended packages",
        message: "There are more than one recommended packages for this offer.",
        color: "red",
        icon: <IconX />,
        autoClose: false,
      });
      return;
    }
    try {
      const res = await reportRecommendedPackageProblem(
        currContractOffer.id,
        recommendedPackages.at(0).id,
      );
      if (res.success) {
        setProblemReported(true);
      } else {
        const errRes = res as unknown as ContractErrorResponse;
        notifications.show({
          title: "Error reporting recommended package problem",
          message: `${errRes.text}. ${errRes.result.message}. Could not report a problem with the recommended package.`,
          color: "red",
          icon: <IconX />,
          autoClose: false,
        });
      }
    } catch {
      notifications.show({
        title: "Error reporting recommended package problem.",
        message: "Server error",
        color: "red",
        icon: <IconX />,
        autoClose: false,
      });
    }
  };

  return (
    <Card shadow="xs" mx="lg" padding="lg" radius="md" withBorder>
      <Stack>
        <InternalCardTitle
          title="Negotiations"
          icon={<IconMessages size={24} color="var(--mantine-color-blue-filled)" />}
        />
        <Skeleton height="xs" width="md" visible={isFetchingAdmin} />
        {!isAdmin && !isFetchingAdmin && (
          <Text c="dimmed" ml="xs">
            You do not have permissions to view this module.
          </Text>
        )}
        {activeOpportunities.length > 0 && isAdmin && (
          <>
            <Select
              w="30%"
              required
              data={opportunitiesSelection}
              value={dropdownOpportunity ? dropdownOpportunity.value : null}
              label="Select Campaign"
              allowDeselect={false}
              onChange={(_value, option) => {
                const currOpportunity = activeOpportunities.filter(
                  (opp) => opp.id === Number(_value),
                )[0];
                const currCamp = campaigns.find(
                  (c) => c.id === currOpportunity.ad_group.campaign_id,
                );
                setDropdownOpportunity(option);
                const updatedSearchParams = new URLSearchParams(searchParams.toString());
                updatedSearchParams.set("opportunityId", _value);
                setSearchParams(updatedSearchParams.toString());
                setCheckedUsageRights(currCamp.usage_rights_policy === UsageRightsPolicy.UPFRONT);
                setUsageRightsDuration(currCamp?.usage_rights_duration);
                setUsagePrice(
                  Math.round(
                    Number(creatorPrice) *
                      UsageRightsDurationMultiplier[currCamp?.usage_rights_duration],
                  ),
                );
              }}
            />
            <Title order={5} fw={500}>
              Deliverable Package Selection
            </Title>
            <Divider my={2} variant="dotted" label="Deliverable Formats" labelPosition="center" />
            <Grid>{deliverableFormats.map((format) => format)}</Grid>
            <Divider my={2} variant="dotted" label="Deliverable Pricing" labelPosition="center" />
            <Group>
              <NumberInput
                description="Enter price in whole dollars"
                label="Creator Asking Price"
                leftSection={<IconCurrencyDollar />}
                value={creatorPrice}
                allowNegative={false}
                allowDecimal={false}
                onChange={setCreatorPrice}
                clampBehavior="strict"
                min={1}
              />
              <Skeleton height="xs" width="md" visible={isFetching}>
                <NumberInput
                  label="Suggested Price"
                  description="This is automatically set"
                  leftSection={<IconCurrencyDollar />}
                  value={suggestedPrice}
                  allowNegative={false}
                  disabled
                  clampBehavior="strict"
                  min={1}
                />
              </Skeleton>
            </Group>
            <Transition
              mounted={Object.values(stateMap).some((val) => val === 1)}
              transition="scale-y"
              duration={300}
              exitDuration={250}
              timingFunction="ease-in-out"
              keepMounted>
              {(styles) => (
                <Box style={styles}>
                  <UsageRightsPricing
                    isOps={isOps}
                    campaign={currCampaign}
                    platforms={stateMap}
                    creatorPrice={Number(creatorPrice)}
                    usagePrice={usagePrice}
                    setUsagePrice={setUsagePrice}
                    suggestedUsagePrice={suggestedUsagePrice}
                    setSuggestedUsagePrice={setSuggestedUsagePrice}
                    usageRightsDuration={usageRightsDuration}
                    setUsageRightsDuration={setUsageRightsDuration}
                    isUsageRightsUpfront={isUsageRightsUpfront}
                    checkedUsageRights={checkedUsageRights}
                    setCheckedUsageRights={setCheckedUsageRights}
                  />
                </Box>
              )}
            </Transition>
            <InternalNotesDrawer
              user={user}
              opened={opened}
              onClose={close}
              contractOffer={currContractOffer}
              currCampaign={currCampaign}
            />
            <Space my="xs" />
            <ButtonGroup
              contractOfferId={currContractOfferId}
              offerUuid={
                STATES_TO_SHOW_OFFER_UUID.has(contractOfferStatus) ? currContractOffer.uuid : null
              }
              validate={validate}
              disableSave={disableSave}
              disableWithOffer={disableWithOffer}
              // This is triggered on needing internal review
              withAcceptRecommendedPackage={
                contractOfferStatus === ContractOfferStatus.NEEDS_INTERNAL_REVIEW &&
                fetchedPackages?.length > 0
              }
              recommendedPackageTriggersBrandReview={willTriggerBrandReview(
                currContractOffer?.deliverable_packages.filter((pkg) => pkg.is_recommended).at(0),
                selectedOpportunity,
              )}
              anyPackageTriggersBrandReview={offerTriggersBrandReview(
                currContractOffer,
                selectedOpportunity,
              )}
              disableAccept={problemReported || !fetchedPackages || fetchedPackages.length === 0}
              openDrawer={open}
              deleteButtonCallback={() => {
                setContractOfferStatus(null);
                setPackages([]);
                setCreatorPrice(0);
                setFetchedPackagesCards([]);
                setFetchedPackages([]);
              }}
              addPackage={addPackage}
              savePackages={savePackages}
              acceptRecommendedPackage={acceptRecommendedPackage}
              acceptAllPackages={acceptAllPackages}
              reportProblem={reportPackageProblem}
              extendOfferExpiration={extendOfferExpiration}
              isAdmin={isAdmin}
              adminOnly={false} // enable retroactively setting packages for ops
            />
            {willTriggerBrandReview(
              currContractOffer?.deliverable_packages.filter((pkg) => pkg.is_recommended).at(0),
              selectedOpportunity,
            ) && (
              <Alert variant="light" color="red" icon={<IconInfoCircle />}>
                The suggested price for the recommended package is above the max offer threshold.
              </Alert>
            )}
            <Group wrap="wrap" align="stretch">
              {addedPackages.map((option) => option)}
              <LoadingOverlay
                visible={isFetchingOffers}
                loaderProps={{ color: "blue", type: "dots" }}
              />
              {fetchedPackagesCards.map((option) => option)}
            </Group>
          </>
        )}
        {activeOpportunities.length === 0 && isAdmin && (
          <Text c="dimmed" ml="xs">
            No active opportunities
          </Text>
        )}
      </Stack>
    </Card>
  );
};

export default NegotiationsView;
