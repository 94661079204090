import React from "react";

import { Anchor, Badge, Card, Group, Text } from "@mantine/core";

import { useDisclosure } from "@mantine/hooks";

import { User } from "models/User";

import { TaskStage } from "components/contracts/tasks/models/Common";
import { Task } from "components/contracts/tasks/models/Task";
import CreatorDrawer from "components/contracts/review/CreatorDrawer";

import CreatorAvatar from "components/contracts/tasks/common/CreatorAvatar";
import TaskCard from "components/contracts/tasks/cards/TaskCard";
import RelatedTasks from "components/contracts/tasks/cards/RelatedTasks";
import TaskTypeBadge from "components/contracts/tasks/common/TaskTypeBadge";

export default function ContractCard({
  user,
  primaryTask,
  taskType,
  handleCompleteTask,
  handleCompleteRelatedTask,
  decrementUnreadMessageCount,
  completedTaskIds,
  isCompletedTask,
}: {
  user: User;
  primaryTask: Task;
  taskType: TaskStage;
  handleCompleteTask: (requireCreatorAction?: boolean) => void;
  handleCompleteRelatedTask: (
    relatedTaskId: string,
    relatedTaskType: TaskStage,
    requireCreatorAction?: boolean,
  ) => void;
  decrementUnreadMessageCount: () => void;
  completedTaskIds: Set<string>;
  isCompletedTask?: boolean;
}) {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <CreatorDrawer creatorId={primaryTask.creatorId} opened={opened} close={close} />
      <Card p="sm" radius="md" withBorder bg="#F8F9FA">
        <Group justify="space-between">
          <CreatorAvatar
            displayName={primaryTask.displayName}
            avatarUrl={primaryTask.avatarUrl}
            description={
              <Anchor
                href={user.is_staff ? primaryTask.adminContractUrl : primaryTask.brandContractUrl}
                target="_blank">
                <Text size="xs" fw="500">
                  View Contract
                </Text>
              </Anchor>
            }
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              e.stopPropagation();
              open();
            }}
          />
          <TaskTypeBadge taskType={taskType} />
        </Group>
        <TaskCard
          task={primaryTask}
          taskType={taskType}
          isCompletedTask={isCompletedTask}
          handleCompleteTask={handleCompleteTask}
          decrementUnreadMessageCount={decrementUnreadMessageCount}
          showAdminOptions={user.is_staff}
        />
        <RelatedTasks
          tasks={primaryTask.relatedTasks}
          handleCompleteRelatedTask={handleCompleteRelatedTask}
          completedTaskIds={completedTaskIds}
        />
      </Card>
    </>
  );
}
