import { Select } from "@mantine/core";
import { CAMPAIGN_PORTAL } from "campaigns/PathConstants";
import { CreatorSetItem } from "components/discovery/Datamodels";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectCreatorSets } from "reduxStore/creatorSetsSelectors";

const CreatorSetDropdown = ({ selectedCreatorSetId }: { selectedCreatorSetId?: string }) => {
  const navigate = useNavigate();
  const allCreatorSets = useSelector(selectCreatorSets) as CreatorSetItem[];

  const creatorSetsData = allCreatorSets.map((creatorSet) => {
    return {
      value: creatorSet.id.toString(),
      label: creatorSet.name,
    };
  });

  return (
    <Select
      label="Creator Sets"
      data={creatorSetsData}
      placeholder="All Creator Sets"
      value={selectedCreatorSetId || null}
      clearable
      searchable
      onChange={(_value, option) => {
        if (_value) {
          navigate(`${CAMPAIGN_PORTAL}/creator_sets/${_value}/view`);
        } else {
          navigate(`${CAMPAIGN_PORTAL}/creator_sets/all`);
        }
      }}
    />
  );
};

export default CreatorSetDropdown;
