import React from "react";
import { User } from "models/User";
import { Avatar, Group, Stack, Text } from "@mantine/core";
import Spacer from "components/Spacer";

const CompanyAndUserHeader = ({
  showMinimizedVersion = false,
  user,
}: {
  showMinimizedVersion?: boolean;
  user: User;
}) => {
  // TODO (andrew/victoria 10.2023): REMOVE "YOUR COMPANY NAME" when backend is ready
  const companyName = user.brand?.display_name || user.name;
  return (
    <Group gap={0} wrap="nowrap">
      <Avatar w={48} h={48} src={user?.avatar_url} style={{ flex: "none" }} />
      {showMinimizedVersion ? null : (
        <>
          <Spacer width={12} />
          <Stack gap={0}>
            {companyName ? (
              <Text
                style={{
                  fontSize: "14px",
                  fontWeight: "700",
                  lineHeight: "155%",
                }}>
                {companyName}
              </Text>
            ) : null}
            <Text
              lineClamp={1}
              style={{
                fontSize: "11px",
                fontWeight: "400",
                lineHeight: "155%",
              }}>
              {user.email}
            </Text>
          </Stack>
        </>
      )}
    </Group>
  );
};

export default CompanyAndUserHeader;
