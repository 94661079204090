import { Tabs } from "@mantine/core";
import { useCampaignAppContext } from "campaigns/CampaignAppShell";
import React, { useEffect, useState } from "react";
import CreatorSetView from "components/creator_sets/CreatorSetView";
import { CAMPAIGN_PORTAL } from "campaigns/PathConstants";
import { useMatch, useNavigate, useParams } from "react-router-dom";
import BrandExistingPartnersView from "components/creator_sets/BrandExistingPartnersView";

const AllCreatorsView = () => {
  const navigate = useNavigate();

  const isExistingPartnersView =
    useMatch(`${CAMPAIGN_PORTAL}/creator_sets/existing_partners`) != null;

  const [activeTab, setActiveTab] = useState<"1stcollab" | "external">(
    isExistingPartnersView ? "external" : "1stcollab",
  );

  const urlParams = useParams();
  const creatorSetId = urlParams.creatorSetId ? parseInt(urlParams.creatorSetId, 10) : null;
  const [selectedCreatorSetId, setSelectedCreatorSetId] = useState<number | null>(creatorSetId);

  useEffect(() => {
    setActiveTab(isExistingPartnersView ? "external" : "1stcollab");
  }, [isExistingPartnersView]);

  useEffect(() => {
    if (activeTab === "external") {
      setSelectedCreatorSetId(null);
      navigate(`${CAMPAIGN_PORTAL}/creator_sets/existing_partners`);
    } else if (selectedCreatorSetId === null) {
      // don't renavigate if selectedCreatorSetId is set because on first load, we want to preserve the creatorSetId from the url params
      navigate(`${CAMPAIGN_PORTAL}/creator_sets/all`);
    }
  }, [activeTab]);

  return (
    <Tabs
      value={activeTab}
      onChange={(value) => {
        if (value === "external") {
          setActiveTab("external");
        } else {
          setActiveTab("1stcollab");
        }
      }}
      styles={{
        panel: {
          marginTop: 24,
        },
      }}>
      <Tabs.List>
        <Tabs.Tab value="1stcollab">1stCollab Creators</Tabs.Tab>
        <Tabs.Tab value="external">Off-Platform Creators</Tabs.Tab>
      </Tabs.List>
      <Tabs.Panel value="1stcollab">
        <CreatorSetView />
      </Tabs.Panel>
      <Tabs.Panel value="external">
        <BrandExistingPartnersView />
      </Tabs.Panel>
    </Tabs>
  );
};

export default AllCreatorsView;
