import React, { useEffect, useState } from "react";

import {
  Box,
  Card,
  Center,
  Container,
  Divider,
  Flex,
  Group,
  Loader,
  LoadingOverlay,
  MultiSelect,
  Paper,
  Progress,
  SegmentedControl,
  SimpleGrid,
  Stack,
  Switch,
  Table,
  Text,
  Title,
} from "@mantine/core";
import { LineChart } from "@mantine/charts";

import { getCampaignsWithBudget, getBudgetMetricsForCampaigns } from "components/metrics/Api";
import { formatAmount } from "components/contracts/dashboard/Utils";
import { fromISODateString } from "utils/DateUtils";

interface FutureUtilizationData {
  [date: string]: {
    budget: number;
    cumulative_amount: number;
    cumulative_take: number;
  };
}

interface BudgetUtilizationTimeSeriesItem {
  date: string;
  budget: number;
  contracted_gmv: number;
  contracted_take: number;
  contracted_spend: number;
  completed_gmv: number;
  completed_take: number;
  completed_spend: number;
  contracted_utilization: number;
  completed_utilization: number;
}

function formatPercentage(value: number): string {
  return `${(value * 100).toFixed(2)}%`;
}

function formatDollarAmount(amount: number): string {
  return (amount / 100).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
}

function BudgetUtilizationRow({
  campaignName,
  data,
  useContracted,
  shouldHighlight,
}: {
  campaignName: string;
  data: BudgetUtilizationTimeSeriesItem;
  useContracted?: boolean;
  shouldHighlight?: boolean;
}) {
  const spend = useContracted ? data.contracted_spend : data.completed_spend;
  const gmv = useContracted ? data.contracted_gmv : data.completed_gmv;
  const take = useContracted ? data.contracted_take : data.completed_take;
  const utilization = useContracted ? data.contracted_utilization : data.completed_utilization;

  return (
    <Table.Tr bg={shouldHighlight ? "var(--mantine-color-blue-0)" : ""}>
      <Table.Td>
        <Text fw="500">{campaignName}</Text>
      </Table.Td>
      <Table.Td>{formatAmount(data.budget)}</Table.Td>
      <Table.Td>{formatAmount(Math.max(data.budget - spend, 0))}</Table.Td>
      <Table.Td>{formatAmount(gmv)}</Table.Td>
      <Table.Td>{formatAmount(take)}</Table.Td>
      <Table.Td>{formatAmount(spend)}</Table.Td>
      <Table.Td>{formatPercentage(utilization)}</Table.Td>
    </Table.Tr>
  );
}

function SummaryBudgetUtilizationTable({
  campaignIdToName,
  selectedCampaigns,
  campaignMetrics,
  totalMetrics,
}: {
  campaignIdToName: Record<string, string>;
  selectedCampaigns: string[];
  campaignMetrics: Record<string, BudgetUtilizationTimeSeriesItem[]>;
  totalMetrics: BudgetUtilizationTimeSeriesItem[];
}) {
  if (
    !selectedCampaigns ||
    selectedCampaigns.length === 0 ||
    !campaignMetrics ||
    Object.keys(campaignMetrics).length === 0
  ) {
    return null;
  }

  const [value, setValue] = useState("contracted");
  const showContracted = value === "contracted";

  // Sort rows by remaining budget
  const rows = selectedCampaigns
    .sort((a, b) => {
      const aData = campaignMetrics[a][campaignMetrics[a].length - 1];
      const bData = campaignMetrics[b][campaignMetrics[b].length - 1];
      const aRemainingBudget = showContracted
        ? aData.budget - aData.contracted_spend
        : aData.budget - aData.completed_spend;
      const bRemainingBudget = showContracted
        ? bData.budget - bData.contracted_spend
        : bData.budget - bData.completed_spend;
      return bRemainingBudget - aRemainingBudget;
    })
    .map((campaignId) => {
      const campaignName = campaignIdToName[campaignId];
      const data = campaignMetrics[campaignId];
      return (
        <BudgetUtilizationRow
          key={campaignId}
          campaignName={campaignName}
          data={data[data.length - 1]}
          useContracted={showContracted}
        />
      );
    });

  return (
    <Stack>
      <Group justify="space-between">
        <Title order={3} fw="500">
          Budget Utilization Summary
        </Title>
        <SegmentedControl
          size="xs"
          value={value}
          onChange={setValue}
          data={[
            { label: "Contracted", value: "contracted" },
            { label: "Completed", value: "completed" },
          ]}
        />
      </Group>
      <Table withTableBorder highlightOnHover withColumnBorders>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Campaign</Table.Th>
            <Table.Th>Budget</Table.Th>
            <Table.Th>Remaining</Table.Th>
            <Table.Th>GMV</Table.Th>
            <Table.Th>Take</Table.Th>
            <Table.Th>Total</Table.Th>
            <Table.Th>Utilization</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {rows}
          <BudgetUtilizationRow
            campaignName="Total"
            data={totalMetrics[totalMetrics.length - 1]}
            useContracted={showContracted}
            shouldHighlight
          />
        </Table.Tbody>
      </Table>
    </Stack>
  );
}

function BudgetBar({
  month,
  cumulative_amount,
  cumulative_take,
  budget,
}: {
  month: string;
  cumulative_amount: number;
  cumulative_take: number;
  budget: number;
}) {
  const spend = cumulative_amount + cumulative_take;
  const utilization = (spend / budget) * 100;

  const monthString = fromISODateString(month).toLocaleString("default", {
    month: "long",
    year: "numeric",
  });

  return (
    <Stack gap={2}>
      <Group justify="space-between">
        <Text fw="500" size="md">
          {monthString}
        </Text>
        <Text fw="500" c="dimmed" size="sm">
          {formatAmount(spend)} / {formatAmount(budget)} ({utilization.toFixed(2)}%)
        </Text>
      </Group>
      <Progress value={utilization} />
    </Stack>
  );
}

function FutureBudgetBars({
  campaignId,
  futureUtilization,
}: {
  campaignId: string;
  futureUtilization: FutureUtilizationData;
}) {
  const NUM_MONTHS_TO_SHOW = 4;

  const months = [];
  if (futureUtilization && Object.keys(futureUtilization).length > 0) {
    const today = new Date();

    for (let i = 1; i <= NUM_MONTHS_TO_SHOW; i += 1) {
      const firstDay = new Date(today.getFullYear(), today.getMonth() + i, 1);
      const formattedDate = firstDay.toISOString().split("T")[0];
      months.push(formattedDate);
    }
  }

  return (
    <Stack gap="xs">
      <Divider />
      <Title fw="500" order={4}>
        Future Months Utilization
      </Title>
      <SimpleGrid cols={2}>
        {months.map((month) => (
          <BudgetBar key={`${campaignId}-${month}`} month={month} {...futureUtilization[month]} />
        ))}
      </SimpleGrid>
    </Stack>
  );
}

function BudgetUtilizationChart({
  campaignName,
  data,
  campaignId,
  futureUtilization,
}: {
  campaignName: string;
  data: BudgetUtilizationTimeSeriesItem[];
  campaignId?: string;
  futureUtilization?: FutureUtilizationData;
}) {
  if (!data || data.length === 0) {
    return null;
  }

  const currentBudget = data[data.length - 1].budget;
  const currentUtilization = data[data.length - 1].contracted_utilization * 100;

  const [value, setValue] = useState("spend");
  const showUtilization = value === "utilization";

  let series = [];
  let formatter = null;
  let filteredData = null;

  if (showUtilization) {
    series = [
      { name: "contracted_utilization", label: "Utilization - Contracted", color: "blue" },
      { name: "completed_utilization", label: "Utilization - Spent", color: "teal" },
    ];
    formatter = formatPercentage;
    const firstContractedUtilizationIndex = data.findIndex(
      (item) => item.contracted_utilization !== null && item.completed_utilization !== null,
    );
    filteredData = data.slice(firstContractedUtilizationIndex);
  } else {
    series = [
      { name: "budget", label: "Budget", color: "red" },
      { name: "contracted_spend", label: "Contracted Spend", color: "blue" },
      { name: "completed_spend", label: "Completed Spend", color: "teal" },
    ];
    formatter = formatDollarAmount;
    filteredData = data;
  }

  return (
    <Card withBorder radius="md" shadow="xs">
      <Stack>
        <Group justify="space-between">
          <Title order={3} fw="500">
            {campaignName}
          </Title>
          <Stack>
            <Text size="lg">
              <Text span fw="500">
                Budget:
              </Text>{" "}
              {formatAmount(currentBudget)},{" "}
              <Text span fw="500">
                Utilization:
              </Text>{" "}
              {currentUtilization.toFixed(2)}%
            </Text>
          </Stack>
        </Group>
        <Box ml="xl">
          <LineChart
            h={300}
            data={filteredData}
            dataKey="date"
            withRightYAxis
            valueFormatter={formatter}
            series={series}
            curveType="linear"
            withDots={false}
            withLegend
          />
        </Box>
        <Group justify="right" align="flex-start">
          <SegmentedControl
            size="xs"
            value={value}
            onChange={setValue}
            data={[
              { label: "Spend", value: "spend" },
              { label: "Utilization", value: "utilization" },
            ]}
          />
        </Group>
        {futureUtilization && Object.keys(futureUtilization).length > 0 && (
          <FutureBudgetBars campaignId={campaignId} futureUtilization={futureUtilization} />
        )}
      </Stack>
    </Card>
  );
}

export default function BudgetUtilizationDashboard() {
  const [loadingInitialData, setLoadingInitialData] = useState(false);
  const [loadingBudgetData, setLoadingBudgetData] = useState(false);
  const [campaignIdToNameState, setCampaignIdToNameState] = useState<Record<string, string>>({});
  const [selectedCampaigns, setSelectedCampaigns] = useState<string[]>([]);
  const [campaignMetricsData, setCampaignMetricsData] = useState<
    Record<string, BudgetUtilizationTimeSeriesItem[]>
  >({});
  const [totalMetricsData, setTotalMetricsData] = useState<BudgetUtilizationTimeSeriesItem[]>([]);
  const [allFutureUtilizationData, setAllFutureUtilizationData] = useState<
    Record<string, FutureUtilizationData>
  >({});
  const [totalFutureUtilizationData, setTotalFutureUtilizationData] =
    useState<FutureUtilizationData>({});

  const [showInactiveCampaigns, setShowInactiveCampaigns] = useState(false);

  useEffect(() => {
    setLoadingInitialData(true);
    getCampaignsWithBudget()
      .then((response) => {
        const { success, campaignIdToName } = response;
        if (success) {
          const defaultSelectedCampaigns = Object.keys(campaignIdToName);
          setSelectedCampaigns(defaultSelectedCampaigns);
          setCampaignIdToNameState(campaignIdToName);
        }
      })
      .finally(() => setLoadingInitialData(false));
  }, []);

  useEffect(() => {
    if (selectedCampaigns.length > 0) {
      setLoadingBudgetData(true);
      getBudgetMetricsForCampaigns({ campaignIds: selectedCampaigns })
        .then((response) => {
          const {
            success,
            campaignMetrics,
            totalMetrics,
            futureUtilization,
            totalFutureUtilization,
          } = response;
          if (success) {
            const deserializedCampaignMetrics = Object.entries(campaignMetrics).reduce(
              (acc, [campaignId, metrics]: [string, string]) => ({
                ...acc,
                [campaignId]: JSON.parse(metrics),
              }),
              {},
            );
            setCampaignMetricsData(deserializedCampaignMetrics);
            setTotalMetricsData(JSON.parse(totalMetrics));
            setAllFutureUtilizationData(futureUtilization);
            setTotalFutureUtilizationData(totalFutureUtilization);
          }
        })
        .finally(() => setLoadingBudgetData(false));
    }
  }, [selectedCampaigns]);

  const selectorOptions = Object.entries(campaignIdToNameState).map(
    ([campaignId, campaignName]) => ({
      value: campaignId,
      label: campaignName,
    }),
  );

  const filteredCampaigns = selectedCampaigns.filter((campaignId) => {
    if (!showInactiveCampaigns) {
      const campaignMetrics = campaignMetricsData[campaignId] || [];
      if (!campaignMetrics.length) return true; // Include campaigns with no metrics data
      const lastMetric = campaignMetrics[campaignMetrics.length - 1];
      return lastMetric.budget > 0;
    }
    return true;
  });

  return (
    <>
      <LoadingOverlay visible={loadingInitialData || loadingBudgetData} />
      {!loadingInitialData && !loadingBudgetData && (
        <Container fluid>
          <Paper p="xl" radius="md">
            <Stack>
              <Flex justify="space-between">
                <Title order={2} fw="500">
                  Budget Utilization by Campaign
                </Title>
                <Switch
                  checked={showInactiveCampaigns}
                  onChange={(event) => setShowInactiveCampaigns(event.currentTarget.checked)}
                  label="Show Inactive Campaigns"
                />
              </Flex>
              <MultiSelect
                label="Selected Campaigns"
                data={selectorOptions}
                value={filteredCampaigns}
                onChange={setSelectedCampaigns}
                clearable
                searchable
              />
              {loadingBudgetData ? (
                <Center>
                  <Loader />
                </Center>
              ) : (
                <Stack gap="lg">
                  <SummaryBudgetUtilizationTable
                    campaignIdToName={campaignIdToNameState}
                    selectedCampaigns={filteredCampaigns}
                    campaignMetrics={campaignMetricsData}
                    totalMetrics={totalMetricsData}
                  />
                  <BudgetUtilizationChart
                    campaignName="All Campaigns"
                    data={totalMetricsData}
                    futureUtilization={totalFutureUtilizationData}
                  />
                  {filteredCampaigns.map((campaignId) => {
                    const campaignName = campaignIdToNameState[campaignId];
                    return (
                      <BudgetUtilizationChart
                        key={campaignId}
                        campaignId={campaignId}
                        campaignName={campaignName}
                        data={campaignMetricsData[campaignId]}
                        futureUtilization={allFutureUtilizationData[campaignId]}
                      />
                    );
                  })}
                </Stack>
              )}
            </Stack>
          </Paper>
        </Container>
      )}
    </>
  );
}
