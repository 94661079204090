import React from "react";

import { Tooltip } from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";

const InfoCircleWithTooltipNode = ({
  tooltipNode,
  iconColor = "var(--mantine-color-gray-6)",
}: {
  tooltipNode: React.ReactNode;
  iconColor?: string;
}) => (
  <Tooltip multiline w={200} label={tooltipNode}>
    <IconInfoCircle size={12} color={iconColor} />
  </Tooltip>
);

export default InfoCircleWithTooltipNode;
