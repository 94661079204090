import { API_URL } from "configs/Configs";
import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";

import { NotificationPreferences } from "models/User";

const USER_API_URL = `${API_URL}/api/models/users/`;

export const updateNotificationPreferences = async (
  userKey: string,
  updatedFields: NotificationPreferences,
  abortController?: AbortController,
) => {
  const requestUrl = `${USER_API_URL}${userKey}/`;
  const request = await createRequestWithFirebaseToken({
    url: requestUrl,
    method: "PATCH",
    body: JSON.stringify({ notification_preferences: updatedFields }),
  });

  const result = await handleResult(request, abortController);
  return result;
};

export default null;
