export enum InvoiceStatus {
  DRAFT = 1,
  OPEN = 2,
  PAID = 3,
}

export enum InvoiceItemType {
  BASE = 1,
  BONUS = 2,
}

export interface InvoiceItem {
  amount: number;
  earned_bonus_amount: number;
  bonus_completion_date: Date;
  campaign_title: string;
  completion_date: Date;
  hash_id: string;
  name: string;
  avatar_url: string;
}

export interface AugmentedInvoiceItem {
  amount: number;
  avatarUrl: string;
  campaignTitle: string;
  completionDate: Date;
  hashId: string;
  name: string;
  type: InvoiceItemType;
}

export default interface Invoice {
  amount_invoiced: number;
  amount_paid: number;
  base_contracts: InvoiceItem[];
  base_payment: number;
  billing_email: string;
  billing_month: Date;
  bonus_contracts: InvoiceItem[];
  brand_name: string;
  creator_fees: number;
  hash_id: string;
  invoice_date: Date;
  invoice_due_date: Date;
  name: string;
  payment_date: Date;
  platform_fees: number;
  platform_payment: number;
  receipt: string;
  status: InvoiceStatus;
  stripe_customer_url: string;
  stripe_invoice_url: string;
  take_rate: number;
  capped_take: number;
  is_test_brand: boolean;
}

export function getAugmentedBaseInvoiceItem(item: InvoiceItem): AugmentedInvoiceItem {
  return {
    amount: item.amount,
    avatarUrl: item.avatar_url,
    campaignTitle: item.campaign_title,
    completionDate: item.completion_date,
    hashId: item.hash_id,
    name: item.name,
    type: InvoiceItemType.BASE,
  };
}

export function getAugmentedBonusInvoiceItem(item: InvoiceItem): AugmentedInvoiceItem {
  return {
    amount: item.earned_bonus_amount,
    avatarUrl: item.avatar_url,
    campaignTitle: item.campaign_title,
    completionDate: item.bonus_completion_date,
    hashId: item.hash_id,
    name: item.name,
    type: InvoiceItemType.BONUS,
  };
}
